// import React from "react";
import { useAppSelector } from "../../../../common/hooks";
// import InformationCard from "../../../../common/components/Cards/InformationCard";
import { toCurrency } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import InformationCard from "../../../../common/components/Cards/InformationCard";

const { cards: PageDictionary } =
  Language.protected.fiatPages.layout.layout_data;

function CollectionCards() {
  const {
    total_collection,
    total_payout,
    total_collection_count,
    total_mismatched,
    total_mismatched_count,
    total_payout_count,
    total_pending,
    total_pending_count,
  } = useAppSelector((state) => state.payment);

  return (
    <div className="flex w-auto flex-1 items-center gap-x-5 overflow-x-scroll pt-4">
      <InformationCard
        data={toCurrency(total_collection)}
        type={PageDictionary.collection}
        extradata={`${total_collection_count} txns`}
        className="w-full min-w-fit flex-shrink"
      />
      <InformationCard
        data={toCurrency(total_payout)}
        type={PageDictionary.payout}
        extradata={`${total_payout_count} txns`}
        className="w-full min-w-fit flex-shrink"
      />
      <InformationCard
        data={toCurrency(total_pending || 0)}
        type={PageDictionary.pending}
        extradata={`${total_pending_count} txns`}
        className="w-full min-w-fit flex-shrink"
      />
      <InformationCard
        data={toCurrency(total_mismatched || 0)}
        type={PageDictionary.mismatched}
        extradata={`${total_mismatched_count} txns`}
        className="w-full min-w-fit flex-shrink"
      />
    </div>
  );
}

export default CollectionCards;
