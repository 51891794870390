import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";

const service = {
  async getWithdrawal(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.transaction.withdrawal;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getFunding(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.transaction.funding;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
