import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import KycLayout from "../../../components/KycLayout";
import CustomButton from "../../../../../common/components/CustomButton";
import { useAppSelector } from "../../../../../common/hooks";
// import routes from "../../../../../common/routes";
import { tW } from "../../../../../common/utils/helpers";
import Language from "../../../../../common/utils/language/en";

type reviewstate = "PENDING" | "ACTIVE" | null;

const PageDictionary = Language.protected.fiatModals.kyc;

function ReviewKyc() {
  const [reviewState, setReviewState] = useState<reviewstate>(null);
  const { kyc_level } = useAppSelector((state) => state.payment.p2p_info);
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  useEffect(() => {
    // if (pathname === routes.protected.payment.kyc.sucess ) {
    //   setReviewState("COMPLETED");
    // } else {
    //   setReviewState("PENDING");
    // }
    setReviewState(kyc_level);
  }, [pathname, kyc_level]);

  const clickHandler = () => {
    navigate(state.background.pathname, { replace: true });
  };

  return (
    <KycLayout closeHandler={clickHandler}>
      <section className="flex flex-col gap-y-8 pt-14">
        <div
          className={tW(
            "rounded-xl px-9 pt-3 pb-7 text-blue_gray-40",
            reviewState === "ACTIVE" ? "bg-green-400/40" : "bg-[#F7E7DE]"
          )}
        >
          <h6 className="text-base font-bold">
            {reviewState === "PENDING" || reviewState === null
              ? PageDictionary.review.new.title
              : null}
            {reviewState === "ACTIVE" ? PageDictionary.success.cta.title : null}
          </h6>
          <p className="text-1016">
            {reviewState === "PENDING" || reviewState === null
              ? PageDictionary.review.new.subtitle
              : null}
            {reviewState === "ACTIVE"
              ? PageDictionary.success.cta.subtitle
              : null}
          </p>
        </div>
        {reviewState !== "PENDING" ? (
          <CustomButton className="self-start p-3.5" onClick={clickHandler}>
            {reviewState === null
              ? PageDictionary.review.close_btn
              : PageDictionary.success.close_btn}
          </CustomButton>
        ) : null}
      </section>
    </KycLayout>
  );
}

export default ReviewKyc;
