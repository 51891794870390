// import React, { useContext } from "react";
// import { TableContext } from "../../../../../common/components/CustomTable";

type Props = {
  temp?: any;
};

function PaymentPartnerInfo({ temp }: Props) {
  // const checkItem = useContext(TableContext)?.checkRowItem;
  return (
    <div>
      <p className="text-1016">
        {" "}
        <span className="font-bold">Collection:</span> 0.15% capped at N1300{" "}
      </p>
      <p className="text-1016">
        {" "}
        <span className="font-bold">Payout:</span> N15 per transfers
      </p>
    </div>
  );
}

export default PaymentPartnerInfo;
