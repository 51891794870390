import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { HiChevronDown } from "react-icons/hi";
import {
  DropDownItem,
  DropDownMenuContent,
  DropdownMenu,
  DropdownMenuTrigger,
} from "../../../CustomDropDown";
import CountryFlag from "../CountryFlag";
import { switchMultiAccount } from "../../../../../module/appState/store/thunk";
import { getInfoNew } from "../../../../../module/dashboard/store/thunk";
import routes from "../../../../routes";
import { fetchSubUserToken, storeUserToken } from "../../../../service/storage";
import { getMerchantInfo } from "../../../../../module/fiat/store/thunk";

function MultiUserDropDown() {
  const { subAccounts, appState } = useAppSelector((state) => state.appState);
  const { email, root_user_id } = useAppSelector((state) => state.auth.user);
  const { name } = appState.country_data;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const addAccountHandler = () => {
    navigate(routes.protected.settings.add_multi_country, {
      state: { background: location, email },
    });
  };

  const changeAccountHandler = (
    sub_account_email: string,
    is_main: boolean
  ) => {
    if (email === sub_account_email) return;

    navigate(routes.protected.appLoading, {
      state: { background: location },
      replace: true,
    });
    const newToken = fetchSubUserToken();

    if (is_main) {
      if (!newToken) return;
      storeUserToken(newToken);
      if (
        location.pathname.includes(
          routes.protected.fiat_wallet.overview.index
        ) === false
      ) {
        dispatch(getMerchantInfo(root_user_id));
      }
      dispatch(getInfoNew(root_user_id))
        .unwrap()
        .finally(() =>
          navigate(routes.protected.dashboard.crypto, {
            replace: true,
          })
        );
    } else {
      dispatch(switchMultiAccount({ sub_account_email, user_id: root_user_id }))
        .unwrap()
        .then((res) => {
          dispatch(getInfoNew(res.user_id));
          if (
            location.pathname.includes(
              routes.protected.fiat_wallet.overview.index
            ) === false
          ) {
            dispatch(getMerchantInfo(res.user_id));
          }
        })
        .finally(() =>
          navigate(routes.protected.dashboard.crypto, {
            replace: true,
          })
        );
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex cursor-pointer items-center gap-x-2 outline-none">
          <CountryFlag name={name} className="" />
          <HiChevronDown className="ml-1" />
        </button>
      </DropdownMenuTrigger>
      <DropDownMenuContent className="z-10 mt-2 grid w-44  rounded-lg border border-gray-250 bg-white font-bold text-blue_gray-10 shadow-team_header">
        <DropDownItem className="">
          <button
            onClick={addAccountHandler}
            className="w-full pt-5 pb-7 text-center text-sm font-normal leading-3 text-blue-20"
          >
            <span>+ Add Account</span>
          </button>
        </DropDownItem>
        {subAccounts
          ?.filter((account) => account.country !== name)
          .map(({ country, email, is_main }) => (
            <DropDownItem
              onClick={() => changeAccountHandler(email, is_main)}
              className="group  w-full"
              key={email}
            >
              <CountryFlag name={country} className="pb-7 group-last:pb-6" />
            </DropDownItem>
          ))}
      </DropDownMenuContent>
    </DropdownMenu>
  );
}

export default MultiUserDropDown;
