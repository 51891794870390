import React from "react";
import { IconProps } from "../type";

export function CloseIcon({ scale = 1 }: IconProps) {
  return (
    <svg
      width={scale * 20}
      height={scale * 20}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M11.414 10l4.293-4.293a1 1 0 00-1.414-1.414L10 8.586 5.707 4.293A1 1 0 004.293 5.707L8.586 10l-4.293 4.293a1 1 0 001.414 1.414L10 11.414l4.293 4.293a1 1 0 001.414-1.414L11.414 10z"
        clipRule="evenodd"
      />
    </svg>
  );
}
