import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import * as tp from "../types";

const service = {
  // async getTrayItems(id: string) {
  //   const { method, url: makeUrl } = ENDPOINTS.pool.tray;
  //   const url = makeUrl(id);
  //   return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  // },
  async acceptTrayItem(data: tp.IacceptrejectTrayItem) {
    const { method, url } = ENDPOINTS.pool.tray.accept;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async ignoreTrayItem(data: tp.IacceptrejectTrayItem) {
    const { method, url } = ENDPOINTS.pool.tray.ignore;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async getPendingItems(id: string, signal?: any) {
    const { method, url: makeUrl } =
      ENDPOINTS.pool.pending_request.get_pending_request;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, {}, signal);
  },
  async fundPendingItem(data: tp.IfundPendingRequest) {
    const { method, url } = ENDPOINTS.pool.pending_request.fund_pr;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getProccessingItems(id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.pool.processing_request.get_processing_request;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async uploadPaymentEvidence(data: any) {
    const { method, url } = ENDPOINTS.pool.pending_request.fund_pr;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getCompletedItems(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.pool.completed_request;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getJunkItems(id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.pool.junk_request.get_junk_request;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async moveToJunk(data: tp.ImoveToJunk) {
    const { method, url } = ENDPOINTS.pool.junk_request.move_to_junk;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getIssuesItems(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.pool.issues.all;
    const url = makeUrl(id);

    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async sendIssueRequest(data: tp.IissueRequest) {
    const { method, url } = ENDPOINTS.pool.issues.send_issue_request;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },

  async getResolutionThreadList(
    user_id: string,
    txn_ref: string
  ): Promise<tp.APIResponseModel<tp.ImessageThreadItem[]>> {
    const { method, url: makeUrl } = ENDPOINTS.pool.issues.chat_issue_thread;
    const url = makeUrl(user_id, txn_ref);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
