import { useLayoutEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CustomButton from "../../../../common/components/CustomButton";
import { useAppSelector, usePageTitle } from "../../../../common/hooks";
import routes from "../../../../common/routes";
import {
  onboardBusinessNavLinks,
  onboardIndividualNavLinks,
} from "../../../../common/utils/helpers/navHelpers";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import Language from "../../../../common/utils/language/en";
import { routeType } from "../../types";
// import * as storerage from "../../../../common/service/storage";
// import {
//   listBusinessDocuments,
//   listShareholders,
//   retriveBusinessProfile,
// } from "../../../compliance/store/thunk";

const PageDictionary = Language.protected.onboarding_layout;

function Layout() {
  const [query] = useSearchParams();
  const { pathname } = useLocation();

  // const external_link_company_id = storerage.fetchSessionCompany();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    // business_profile: { data: business_profile_data },
    // business_documents: { data: business_documents_data },
    shareholders: {
      loading: shareholder_loading_state,
      // data: shareholder_data,
    },
  } = useAppSelector((state) => state.compliance);
  // const agreement_info = useAppSelector(
  //   (state) => state.auth.company?.agreement_info
  // );
  // const agreement_complete = agreement_info?.signature?.complete;
  // const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const external_link_company = query.get("company_id");
    const external_link_token = query.get("token");

    if (external_link_token) {
      // storerage.storeSessionToken(external_link_token);
    }
    if (external_link_company) {
      // storerage.storeSessionCompany(external_link_company);
    }
  }, []); //eslint-disable-line

  usePageTitle(PageDictionary.page_title);

  const clickHandler = () => {
    const makePath = routes.protected.onboarding.shareholders.add.link;
    const path = makePath(
      pathname.includes("business") ? "business" : "individual"
    );
    navigate(path, {
      state: { background: location },
    });
  };

  const navigateHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    let path = "";
    const routepath: routeType = pathname.includes("business")
      ? "business"
      : "individual";

    const { document, bussiness_profile, shareholders, terms } =
      routes.protected.onboarding;
    const { href } = e.currentTarget;

    switch (true) {
      case href.includes("terms"):
        path = terms.link(routepath);

        break;
      case href.includes("shareholders"):
        path = shareholders.link(routepath);
        // if (shareholder_data.length === 0) {
        // dispatch(listShareholders());
        // }

        break;
      case href.includes("profile"):
        path = bussiness_profile;
        // if (business_profile_data._id === "") {
        // dispatch(retriveBusinessProfile());
        // }
        break;
      case href.includes("document"):
        path = document.link(routepath);
        // const values = Object.values(business_documents_data);
        // if (values.find((doc) => doc.data._id !== "")) break;
        // dispatch(listBusinessDocuments());

        break;
      default:
        break;
    }
    navigate(path);
  };

  return (
    <>
      <header className="">
        <h1 className="select-none text-2226 font-semibold text-blue-10">
          {PageDictionary.title}
        </h1>
        <p className="w-2/4 pt-4 pb-10 font-medium text-gray-110">
          {PageDictionary.copy}
        </p>
        <nav className="onboardingnav flex items-end  justify-between">
          <HorizontalNav
            onClick={navigateHandler}
            navlinks={
              pathname.includes("business")
                ? onboardBusinessNavLinks
                : onboardIndividualNavLinks /* .filter((itm) =>
              !agreement_complete || external_link_company_id
                ? itm.name === "Terms"
                : itm.name !== "Terms"
            ) */
            }
          />
          {location.pathname.includes("shareholder") &&
          shareholder_loading_state === false ? (
            <CustomButton
              disabled={shareholder_loading_state}
              onClick={clickHandler}
              className=" px-2.5 py-3 text-base leading-7"
            >
              {PageDictionary.add_shareholder}
            </CustomButton>
          ) : null}
        </nav>
      </header>
      {/* <div className=" relative "> */}
      <Outlet />
      {/* </div> */}
    </>
  );
}

export default Layout;
