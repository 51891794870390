import React from "react";
import { tW } from "../../../utils/helpers";

type Props = {
  status: string;
};

function LockedStatus({ status }: Props) {
  return (
    <div className="flex items-center justify-center font-bold">
      <span
        className={tW(
          "cursor-pointer rounded-lg px-3 py-1.5 text-white",
          status === "locked" ? "bg-red-20" : "bg-green-300"
        )}
      >
        {status}
      </span>
    </div>
  );
}

export default LockedStatus;
