import { IexportCheckBox, Ifilter, IpaginationInfo } from "../type";

export function updateTotalDataCountAndPages<
  Type1,
  Type2 extends { filter: Ifilter<Type1>; info: IpaginationInfo }
>(state: Type2, temp: Type1[]) {
  state.info.totalDataCount = temp.length;
  state.info.totalPages = Math.ceil(temp.length / state.info.maxPageDataCount);
}

export function pageNumberUpdateHelper<
  Type2,
  Type1 extends {
    info: IpaginationInfo;
    filter: Ifilter<Type2>;
    data: Type2[];
    export: IexportCheckBox;
  }
>(state: Type1, parent: Type2[], payload?: number) {
  if (payload) {
    state.info.currentPage = payload;
  }
  const start = state.info.maxPageDataCount * (state.info.currentPage - 1);
  const end = start + state.info.maxPageDataCount;
  const data = state.filter.isfiltered
    ? state.filter.data.slice(start, end)
    : parent.slice(start, end);
  state.data = [...data];
}
