import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Language from "../../../../common/utils/language/en";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButtom from "../../../../common/components/CustomButton";
import PermissionCheck from "../../components/PermissionCheck";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import { createTeamMember } from "../../store/thunk";
import { iHandleChange, IcreateTeam } from "../../type";
import {
  initial,
  initialState,
  inputshape,
  permissionFormatter,
  shape,
} from "./Helper";
import { useEffectOnce } from "../../../../common/hooks";

const { TeamModal: PageDictionary } = Language.protected.TeamPages;

function CreateTeamMember() {
  const navigate = useNavigate();
  const background = useLocation().state?.background;
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { loading } = useAppSelector((state) => state.team);
  const [check, setCheck] = useState(initial);
  const [data, setData] = useState<IcreateTeam>(initialState);

  const backToTeam = () => {
    if (loading) return;
    navigate(background.pathname, { replace: true });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      Object.values(data).includes("") ||
      Object.values(check).includes(true) === false
    ) {
      return;
    }

    dispatch(createTeamMember(data))
      .unwrap()
      .then(() => {
        navigate(background.pathname, { replace: true, state: null });
      });
  };

  useEffectOnce(() => {
    setData((prev) => ({ ...prev, user_id }));
  });

  useEffect(() => {
    Object.keys(check).forEach((itm) => {
      const type = permissionFormatter(itm);
      if (check[type] && data.team_permissions.includes(type) === false) {
        setData((prev) => ({
          ...prev,
          team_permissions: [...prev.team_permissions, type],
        }));
      }
      if (check[type] === false && data.team_permissions.includes(type)) {
        const prev_permission = data.team_permissions.filter(
          (itm) => itm !== type
        );
        setData((prev) => ({
          ...prev,
          team_permissions: [...prev_permission],
        }));
      }
    });
  }, [JSON.stringify(check)]); //eslint-disable-line

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const checkHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name } = e.target;
    const type = permissionFormatter(name);
    setCheck((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  return (
    <SideModal clickBackDrop={backToTeam}>
      <section className="px-18 pt-24">
        <header className="border-b border-gray-50 pb-1">
          <h3 className="text-4xl font-semibold">
            {PageDictionary.createTitle}
          </h3>
        </header>
        <form className="pt-9" onSubmit={submitHandler} autoComplete="off">
          <div className=" flex flex-col gap-y-4">
            {inputshape.map(({ name, label, type }) => (
              <CustomInput
                key={name}
                onChange={changeHandler}
                name={name}
                type={type}
                label={label}
                autoComplete="off"
              />
            ))}
          </div>
          <div className="mt-7">
            <p className="text-base text-base-30">
              {PageDictionary.roles.label}
            </p>
            <div className="mt-7 flex flex-col gap-y-4">
              {shape.map((itm) => (
                <PermissionCheck
                  key={itm.name}
                  checkHandler={checkHandler}
                  name={itm.name}
                  is_check={check[itm.name]}
                  label={itm.label}
                  tip={itm.tip}
                  disable={false}
                />
              ))}
            </div>
          </div>
          <CustomButtom isloading={loading} className="mt-24 bg-green-400">
            {PageDictionary.createAction}
          </CustomButtom>
        </form>
      </section>
    </SideModal>
  );
}

export default CreateTeamMember;
