// import { useEffect } from "react";
import { NavLink, Outlet } from "react-router-dom";
import {
  // useAppDispatch,
  useAppSelector,
  usePageTitle,
  useScrollToTop,
} from "../../../../common/hooks";
import { renderPoolStatusCount } from "../../../../common/utils/helpers";
import { poolNavlinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";
// import {
//   getCompletedRequests,
//   getJunkItems,
//   getPendingRequests,
//   getProcessingRequest,
// } from "../../store/thunk";

const { PoolPages: PageDictionary } = Language.protected;

function PoolWrapper() {
  usePageTitle(PageDictionary.pageTitle);
  const { titleRef } = useScrollToTop();
  const {
    pending_requests,
    processing_requests,
    issues: { list: issues },
    // completed_requests,
  } = useAppSelector((state) => state.pool);
  const { /* user_id, */ permission } = useAppSelector((state) => state.auth.user);
  // const dispatch = useAppDispatch();

  // fetch pending request when component mounts
  // useEffect(() => {
  //   dispatch(getPendingRequests(user_id));
  //   dispatch(getProcessingRequest(user_id));
  //   dispatch(getCompletedRequests(user_id));
  //   dispatch(getJunkItems(user_id));
  // }, [user_id]); //eslint-disable-line

  // useEffectOnce(() => {
  //   // if (pending_requests.length === 0) {
  //   dispatch(getPendingRequests(user_id));
  //   // }
  //   // if (processing_requests.length === 0) {
  //   dispatch(getProcessingRequest(user_id));
  //   // }
  //   // if (completed_requests.length === 0) {
  //   dispatch(getCompletedRequests(user_id));
  //   // }
  //   // if (issues.length === 0) {
  //   //   dispatch(getIssues(user_id));
  //   // }
  // });

  return (
    <>
      <header ref={titleRef} className="flex items-center justify-between">
        <nav>
          <ul className="flex items-center gap-x-8">
            {poolNavlinks
              .filter((itm) =>
                itm.access ? permission?.includes(itm.access) : itm
              )
              .map(({ link, name }) => (
                <li key={link}>
                  <NavLink
                    to={link}
                    className={({ isActive }) =>
                      isActive
                        ? "flex min-w-[9.375rem] items-center justify-center gap-x-1.5 rounded-2xl bg-blue_gray-10 py-3 px-2.5 text-xl font-bold leading-6.5 text-white "
                        : " flex min-w-[9.375rem] items-center justify-center gap-x-1.5  px-2.5 py-3 text-xl font-bold leading-6.5 text-blue_gray-10"
                    }
                  >
                    <span>{name}</span>
                    {renderPoolStatusCount(
                      pending_requests,
                      processing_requests,
                      issues,
                      name
                    )}
                  </NavLink>
                </li>
              ))}
          </ul>
        </nav>
      </header>
      <Outlet />
    </>
  );
}

export default PoolWrapper;
