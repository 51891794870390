import routes from "../../../../common/routes";
import { downloadObjectAsCSV } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import { IpayoutOrderType } from "../../types";

const { layout: PageDictionary } = Language.protected.fiatPages;

export const filterHelper = (pathname: string): IpayoutOrderType => {
  let data: IpayoutOrderType = "completed";

  switch (true) {
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.completed:
      data = "completed";
      break;
    case pathname === routes.protected.fiat_wallet.overview.withdrawals.failed:
      data = "failed";
      break;
    case pathname === routes.protected.fiat_wallet.overview.withdrawals.pending:
      data = "pending";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.processing:
      data = "processing";
      break;

    default:
      break;
  }

  return data;
};
export const exportClick = (
  pathname: string,
  withdrawals: Record<IpayoutOrderType, { data: any[]; loading: boolean }>
) => {
  const { completed, failed, pending, processing } = withdrawals;

  let data: any[] = [];

  switch (true) {
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.completed:
      data = completed.data;
      break;
    case pathname === routes.protected.fiat_wallet.overview.withdrawals.failed:
      data = failed.data;
      break;
    case pathname === routes.protected.fiat_wallet.overview.withdrawals.pending:
      data = pending.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.processing:
      data = processing.data;
      break;

    default:
      break;
  }

  downloadObjectAsCSV(
    data,
    `${PageDictionary.title} || ${new Date().toDateString()}`
  );
};

export const exportDisableHandler = (
  pathname: string,
  withdrawals: Record<IpayoutOrderType, { data: any[]; loading: boolean }>
) => {
  const { completed, failed, pending, processing } = withdrawals;
  let val = false;

  switch (true) {
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.completed &&
      completed.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.failed &&
      failed.data.length === 0:
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.pending &&
      pending.data.length === 0:
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.withdrawals.processing &&
      processing.data.length === 0:
      break;

    default:
      val = false;
      break;
  }

  return val;
};
