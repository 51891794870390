import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import { IcreateTeam, IsuspendTeamMember, IupdateTeamMember } from "../type";

export const listTeamMembers = createAsyncThunk(
  "team/listTeamMember",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listTeamMembers(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const createTeamMember = createAsyncThunk(
  "team/createTeamMember",
  async (req: IcreateTeam, { rejectWithValue, dispatch, fulfillWithValue }) => {
    return service
      .createTeamMembers(req)
      .then((res) => {
        customToast(res.message);
        dispatch(listTeamMembers(req.user_id));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const editTeamMember = createAsyncThunk(
  "team/editTeamMember",
  async (req: IupdateTeamMember, { rejectWithValue, fulfillWithValue }) => {
    return service
      .editTeamMember(req)
      .then((res) => {
        customToast(res.message);
        // dispatch(listTeamMembers(data.user_id));
        return fulfillWithValue(req).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const suspendTeamMember = createAsyncThunk(
  "team/suspendTeamMember",
  async (req: IsuspendTeamMember, { rejectWithValue, fulfillWithValue }) => {
    return service
      .suspendTeamMember(req)
      .then((res) => {
        return fulfillWithValue(req.team_email).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const liftTeamMemberSuspension = createAsyncThunk(
  "team/liftTeamMemberSuspension",
  async (req: IsuspendTeamMember, { rejectWithValue, fulfillWithValue }) => {
    return service
      .liftTeamMemberSuspension(req)
      .then((res) => {
        return fulfillWithValue(req.team_email).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

// export const assignRole = createAsyncThunk(
//   "team/assignRole",
//   async (data: any, { rejectWithValue }) => {
//     return service
//       .assignTeamRole(data)
//       .then((res) => res.data)
//       .catch((err) => {
//         customToast(err.message , true);
//         return rejectWithValue(err);
//       });
//   }
// );
