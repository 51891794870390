import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  usePageTitle,
  useScrollToTop,
} from "../../../../common/hooks";
import Banner from "../Banner";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import PayoutLayout from "../PayoutLayout";
import CollectionLayout from "../CollectionLayout";
import { getBankInfoFunding, getFiatExchangeRate } from "../../store/thunk";
import routes from "../../../../common/routes";
import { toCurrency } from "../../../../common/utils/helpers";
import { paymentNavlinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";

// import { toCurrency, toDollar } from "../../../../common/utils/helpers";

const { layout: PageDictionary } = Language.protected.fiatPages;

function PaymentLayout() {
  usePageTitle(PageDictionary.title);
  const location = useLocation();
  const { pathname } = location;
  const { titleRef } = useScrollToTop();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { name: country, currencies: countryCurrencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );

  const { payment_config, exchangeInfo } = useAppSelector(
    (state) => state.payment
  );

  useEffectOnce(() => {
    if (!countryCurrencies[0]) return;
    const fiat_currency = countryCurrencies[0];
    dispatch(
      getFiatExchangeRate({
        fiat_currency,
        type: "FROM_FIAT",
        user_id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          getFiatExchangeRate({
            fiat_currency,
            type: "TO_FIAT",
            user_id,
          })
        );
      });
  });

  useEffect(() => {
    if (payment_config?.account_name) return;
    dispatch(getBankInfoFunding(user_id));
  }, [user_id, dispatch, payment_config?.account_name]);

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;

    if (
      href.includes(routes.protected.fiat_wallet.overview.index) &&
      payment_config?.account_name
    ) {
      e.preventDefault();
      navigate(routes.protected.fiat_wallet.overview.transactions.pending);
    }
  };

  return (
    <>
      <header ref={titleRef} className="flex flex-col gap-y-3">
        <h3 className="text-[1.375rem] font-bold leading-6.5 text-blue_gray-10">
          {PageDictionary.title}
        </h3>
        <p className="max-w-4xl">{PageDictionary.copy(country)}</p>
        <nav className="mt-1.5 flex items-end border-b border-gray-20">
          <HorizontalNav
            onClick={clickHandler}
            navlinks={paymentNavlinks.payment}
            containerClassName="pb-0"
            className="border-transparent"
          />
          {(pathname.includes(
            routes.protected.fiat_wallet.overview.withdrawals.index
          ) ||
            pathname.includes(
              routes.protected.fiat_wallet.overview.transactions.index
            )) &&
          !exchangeInfo.isloading ? (
            <div className="flex items-center gap-x-9">
              <div className="flex items-center gap-x-5">
                <p className="text-sm font-semibold text-gray-50">
                  {PageDictionary.layout_data.exchange_rate.usdt_to_fiat(
                    countryCurrencies[0]
                  )}
                </p>
                <p className="text-base font-bold text-[#0075E2]">
                  $1 ~ {toCurrency(exchangeInfo.usdt_to_localFiat)}
                </p>
              </div>
              <div className="flex items-center gap-x-5">
                <p className="text-sm font-semibold text-gray-50">
                  {PageDictionary.layout_data.exchange_rate.fiat_to_usdt(
                    countryCurrencies[0]
                  )}
                </p>
                <p className="text-base font-bold text-[#FF0000]">
                  $1 ~ {toCurrency(exchangeInfo.localFiat_to_usdt)}
                </p>
              </div>
            </div>
          ) : null}
        </nav>
      </header>
      {pathname.includes(
        routes.protected.fiat_wallet.overview.withdrawals.index
      ) ||
      pathname.includes(
        routes.protected.fiat_wallet.overview.transactions.index
      ) ? (
        <>
          <Banner />
          {pathname.includes(
            routes.protected.fiat_wallet.overview.withdrawals.index
          ) ? (
            <PayoutLayout />
          ) : null}
          {pathname.includes(
            routes.protected.fiat_wallet.overview.transactions.index
          ) ? (
            <CollectionLayout />
          ) : null}
        </>
      ) : null}
      <Outlet />
    </>
  );
}

export default PaymentLayout;
