import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../routes";
import Language from "../../../utils/language/en";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getBankInfoFunding } from "../../../../module/fiat/store/thunk";
import { tW } from "../../../utils/helpers";

type props = {
  className?: string;
  type?: string;
  data: any;
  data_tut?: string;
  clickHandler: VoidFunction;
};

const { banner: PageDictionary } = Language.protected.DashboardPage;

function HeaderCard({ className, type, data, data_tut, clickHandler }: props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);

  const state = { state: { background: location } };

  const handleNavigate = (path: string) => navigate(path, state);

  const fundWalletHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (type === PageDictionary.fiat) {
      setLoading(true);
      dispatch(getBankInfoFunding(user_id))
        .unwrap()
        .then((res) => {
          navigate(routes.protected.fiat_wallet.overview.fund.fund, {
            state: { ...state.state, data: res },
          });
        })
        .finally(() => setLoading(false));
    } else {
      handleNavigate(routes.protected.crypto_wallet.fund);
    }
  };
  const withdrawWalletHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const route =
      type === PageDictionary.fiat
        ? routes.protected.fiat_wallet.overview.withdrawal.withdrawal
        : routes.protected.crypto_wallet.withdraw.index;
    handleNavigate(route);
  };

  return (
    <div
      data-tut={data_tut}
      className={tW(
        "min-w-67.5 flex-1 cursor-pointer rounded-md border border-purple-200 bg-white px-6 pt-3.5 pb-2",
        className
      )}
      onClick={clickHandler}
    >
      <header>
        <p className="text-sm font-semibold capitalize text-base-50">{type}</p>
      </header>
      <h5 className="text-3xl font-bold leading-10 text-base-50">{data}</h5>
      <div className="mt-2.5 flex items-center justify-between">
        {type === PageDictionary.capital ? (
          <button
            onClick={fundWalletHandler}
            disabled={loading}
            className="bg-transparent pr-3 text-sm font-bold leading-none text-green-10 disabled:cursor-progress"
          >
            {PageDictionary.cards.fund}
          </button>
        ) : null}
        <button
          onClick={withdrawWalletHandler}
          className="bg-transparent pr-3 text-sm font-bold leading-none text-green-10"
        >
          {PageDictionary.cards.withdraw}
        </button>
      </div>
    </div>
  );
}

export default HeaderCard;
