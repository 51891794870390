import { useEffect, useRef } from "react";

function useScrollToTop() {
  const mountOnce = useRef(false);
  const titleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mountOnce.current) {
      return;
    }

    titleRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });

    mountOnce.current = true;
  }, []);
  return { titleRef };
}

export { useScrollToTop };
