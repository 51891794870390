import React from "react";
import { FiEye } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../routes";

type props = {
  data: any;
};

function Evidence({ data }: props) {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  const clickHandler = () => {
    if (pathname === routes.protected.transactions.withdraw) {
      navigate(routes.protected.transactions.evidence, {
        state: { background: location, data },
      });
    }
  };
  return (
    <div className="flex items-center justify-center">
      <button onClick={clickHandler} className="flex items-center gap-x-0.5">
        <FiEye />
        <span>View</span>
      </button>
    </div>
  );
}

export default Evidence;
