import { useEffect, useState } from "react";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { ExchangeRateInput } from "../ExchangeRateInput";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import {
  addCommas,
  removeCommas,
  removeNonNumeric,
  toCurrency,
  toDollar,
} from "../../../../../common/utils/helpers";
import { iHandleChange, iHandleSubmit } from "../../../types";
import { getCsrfToken, getOtp } from "../../../../auth/store/thunk";
import CustomButton from "../../../../../common/components/CustomButton";

type Istage = "form" | "otp" | "finish";

type IformItem = {
  from_currency: string;
  to_currency: string;
  amount: string;
};

type Props = {
  setInData: React.Dispatch<
    React.SetStateAction<{
      from_currency: string;
      to_currency: string;
      amount: string;
    }>
  >;
  setStage: React.Dispatch<React.SetStateAction<Istage>>;
} & IformItem;

export const CalculatorForm = ({
  amount,
  from_currency,
  setInData,
  setStage,
  to_currency,
}: Props) => {
  const { currencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );
  const { email } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { localFiat_to_usdt, usdt_to_localFiat } = useAppSelector(
    (state) => state.payment.exchangeInfo
  );
  const [loading, setloading] = useState(false);
  // const loading = useAppSelector((state) => state.auth.loading);
  const { capital_wallet } = useAppSelector((state) => state.dashboard.home);
  const { wallet_balance } = useAppSelector((state) => state.payment);

  useEffectOnce(() => {
    if (!currencies[0]) return;
    setInData((prev) => ({
      ...prev,
      from_currency: currencies[0],
      to_currency: "USDT",
    }));
  });

  useEffect(() => {
    if (to_currency === "USDT") {
      setInData((prev) => ({
        ...prev,
        amount: parseInt(prev.amount)
          ? // ? (localFiat_to_usdt * parseInt(prev.amount)).toString()
            addCommas(
              (
                localFiat_to_usdt * parseInt(removeCommas(prev.amount))
              ).toString()
            )
          : "0",
      }));
    } else {
      setInData((prev) => ({
        ...prev,
        // amount: parseInt(prev.amount)
        //   ? (parseInt(prev.amount) / usdt_to_localFiat).toString()
        //   : "0",
        amount: parseInt(prev.amount)
          ? addCommas(
              (
                parseInt(removeCommas(prev.amount)) / usdt_to_localFiat
              ).toString()
            )
          : "0",
      }));
    }
  }, [to_currency]);

  const switchSourceHandler = () => {
    if (!currencies[0]) return;
    if (to_currency === "USDT") {
      setInData((prev) => ({
        ...prev,
        from_currency: "USDT",
        to_currency: currencies[0],
      }));
    } else {
      setInData((prev) => ({
        ...prev,
        from_currency: currencies[0],
        to_currency: "USDT",
      }));
    }
  };

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setInData((prev) => ({
      ...prev,
      amount: addCommas(removeNonNumeric(value)),
    }));
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setloading(true);
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res: any) => {
        dispatch(getOtp({ email, csrf_token: res }))
          .unwrap()
          .then(() => {
            setStage("otp");
          });
      })
      .finally(() => setloading(false));
  };

  return (
    <>
      <div className="mb-10">
        <h2 className="text-center text-xl font-medium text-[#090914] md:text-[1.3rem]">
          Real-time Swap
        </h2>
      </div>
      <div className="">
        <form className="flex flex-col gap-y-11" onSubmit={submitHandler}>
          <div className="px-[2.688rem]">
            <ExchangeRateInput
              value={amount}
              source={from_currency}
              handleChange={changeHandler}
            />
          </div>
          <div className="flex flex-col gap-y-5">
            <div className="flex items-center justify-between ">
              <div className="flex flex-1 flex-col gap-y-2.5 self-stretch bg-[#C3CBE633] pt-5 pb-3 pl-10">
                <div className="flex items-center gap-x-12 text-sm font-bold leading-4">
                  <p className="text-[#6F6F6F]">Exchange Rate</p>
                  <p className="text-[#343435]">
                    $1 ={" "}
                    {toCurrency(
                      to_currency === "USDT"
                        ? localFiat_to_usdt
                        : usdt_to_localFiat
                    )}
                  </p>
                </div>
                <p className="text-1016 text-red-40">
                  Rate varies depending on exchange rate at time of transaction.
                </p>
              </div>
              <CustomButton
                className="flex items-center justify-center rounded-none bg-[#D2D5DE] px-7 py-6"
                onClick={switchSourceHandler}
                type="button"
              >
                <span className="rotate-90 text-3xl text-[#6F6F6F]">
                  <CgArrowsExchangeAlt />
                </span>
              </CustomButton>
            </div>
          </div>
          <div className="px-[2.688rem]">
            <ExchangeRateInput
              value={toDollar(
                parseInt(removeCommas(amount))
                  ? to_currency === "USDT"
                    ? parseInt(removeCommas(amount)) / localFiat_to_usdt
                    : parseInt(removeCommas(amount)) * usdt_to_localFiat
                  : 0
              )
                .split("$")
                .join("")}
              source={to_currency}
              isReciever
            />
          </div>
          <div className="px-[2.688rem]">
            <CustomButton
              disabled={
                removeCommas(amount) === "0" ||
                removeCommas(amount) === "" ||
                (from_currency === "USDT" &&
                  capital_wallet < parseInt(removeCommas(amount))) ||
                (from_currency !== "USDT" &&
                  wallet_balance < parseInt(removeCommas(amount)))
              }
              isloading={loading}
              className="flex  h-[4.063rem] w-full items-center justify-center rounded-[0.938rem] bg-[#0F3DB4] font-medium text-white"
              type="submit"
            >
              Swap
            </CustomButton>
            <div className="mt-2">
              <p className="text-center text-1016 capitalize text-red-40 ">
                Swapping is Free
              </p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
