import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import { paymentModalHelper } from "../../FundWallet";
// import { useAppSelector } from "../../../../../common/hooks";

type Props = {
  title: string;
  isWithdrawalInfo: boolean;
};

function TransactionInformation({ title, isWithdrawalInfo }: Props) {
  const { state } = useLocation();
  // const { withdrawals /* collections */ } = useAppSelector(
  //   (state) => state.payment
  // );
  // const data = {};
  // const data = isWithdrawalInfo
  //   ? withdrawals.find((itm) => itm?.txn_ref === state?.data)
  //       ?.withdrawal_details
  //   : null;
  // : transactions.find((itm) => itm?.txn_ref === state?.data);

  const navigate = useNavigate();
  const handleBackdrop = () => {
    navigate(state.background.pathname, { replace: true });
  };
  return (
    <CenterModal
      handleClose={handleBackdrop}
      className="flex flex-col items-start gap-y-8 px-20 pt-9"
    >
      <h3 className="w-full text-left text-2xl font-bold">{title}</h3>
      <div className="grid grid-cols-1  gap-y-2 rounded border border-dashed border-blue-10 bg-gold-30/20 px-6 pt-7 pb-18">
        {paymentModalHelper.map((itm) =>
          itm.id !== "amount_label" ? (
            <div
              key={itm.id}
              className="flex items-center justify-between  gap-x-24"
            >
              <p className="text-left text-sm text-gray-750">{itm.title}</p>
              <p className="text-right  text-sm font-bold text-base-30">
                {/* {data[itm?.id] ? data[itm?.id] : ""} */}
              </p>
            </div>
          ) : null
        )}
      </div>
    </CenterModal>
  );
}

export default TransactionInformation;
