import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import { addBankAccount } from "../../store/thunk";
import { iHandleChange, iHandleSubmit } from "../../types";

const { bankModal: PageDictonary } =
  Language.protected.SettingsPages.BankSettingsPage;

function AddAccount() {
  const navigate = useNavigate();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { add_bank_account_schema: bank_shema, loading } = useAppSelector(
    (state) => state.settings
  );

  const initialState: any = {};
  bank_shema.forEach((schema) => (initialState[schema.key_name] = ""));
  const [formState, setFormState] = useState(initialState);
  const dispatch = useAppDispatch();

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    const regex = new RegExp("^[0-9]*$");
    if (name !== "account_number") {
      setFormState((prev: any) => ({ ...prev, [name]: value }));
    }
    if (name === "account_number" && regex.test(value)) {
      setFormState((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const closeModal = () => {
    if (!loading) {
      navigate(routes.protected.settings.bank_setting.index, { replace: true });
    }
  };
  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(formState).includes("")) {
      return;
    }
    const data = {
      bank_scheme: bank_shema.map((detail) => ({
        ...detail,
        value: formState[detail.key_name],
      })),
    };
    const req = { data, user_id };
    dispatch(addBankAccount(req))
      .unwrap()
      .then(() => {
        navigate(routes.protected.settings.bank_setting.index, {
          replace: true,
          state: null,
        });
      });
  };

  return (
    <SideModal className="px-14 pt-24" clickBackDrop={closeModal}>
      <header>
        <h4 className="pb-2 pl-5 text-2xl font-bold leading-6.5 text-blue_gray-10">
          {PageDictonary.addtitle}
        </h4>
      </header>
      <form
        className="flex flex-col gap-y-5 border-t border-gray-20 pt-12 pl-5 pr-11"
        onSubmit={submitHandler}
      >
        {bank_shema.map((field) => {
          return (
            <CustomInput
              key={field.key_name}
              label={field.name}
              name={field.key_name}
              value={formState[field.key_name]}
              onChange={changeHandler}
            />
          );
        })}
        {/* <CustomInput label={PageDictonary.bankNameLabel} />
        <CustomInput label={PageDictonary.bankAcclabel} />
        <CustomInput label={PageDictonary.bankNameLabel} /> */}
        <CustomButtom
          // onClick={closeModal}
          isloading={loading}
          className="mt-5 h-12 self-start bg-green-200"
        >
          {PageDictonary.addSubmitAction}
        </CustomButtom>
      </form>
    </SideModal>
  );
}

export default AddAccount;
