import { useLocation } from "react-router-dom";
import TableBodyCheckBox from "./TableBodyCheckBox";
import BankActions from "../../../module/settings/components/BankTableActions";
import TeamActions from "../../../module/team/components/TableData/TeamTableActions";
import BankStatus from "../../../module/settings/components/BankStatus";
import SettlementAmount from "../../../module/pool/components/TableData/SettlementAmount";
import Currency from "../../../module/pool/components/TableData/Currency";
import Amount from "../../../module/pool/components/TableData/Amount";
import TeamEmail from "../../../module/team/components/TableData/TeamEmail";
import TeamAccess from "../../../module/team/components/TableData/TeamAccess";
import Memo from "../../../module/pool/components/TableData/Memo";
import MessageCount from "../../../module/pool/components/TableData/MessageCount";
import Period from "./Period";
import Date from "./Date";
import Evidence from "./Evidence";
import LockedStatus from "./LockedStatus";
import LocalAmount from "./LocalAmount";
import { DataTable } from "./types";
import DollarAmount from "./DollarAmount";
import { tableDataStyle } from "./tablehelper";
import { TransactionRefrence } from "../../../module/pool/components/TableData/TransactionRefrence";
import { AddressStatus } from "../../../module/settings/components/Table";
import BusinessAction from "../../../module/compliance/components/Table/BusinessAction";
import BusinessStatus from "../../../module/compliance/components/Table/BusinessStatus";
import ViewInformation from "../../../module/fiat/components/table/ViewInformation";
import PaymentStatus from "../../../module/fiat/components/table/PaymentStatus";
import PaymentPartnerMapper from "../../../module/fiat/components/table/PaymentPartnerMapper";
import PaymentPartnerManage from "../../../module/fiat/components/table/PaymentPartnerManage";
import PaymentPartnerStatus from "../../../module/fiat/components/table/PaymentPartnerStatus";
import PaymentPartnerInfo from "../../../module/fiat/components/table/PaymentPartnerInfo";
import PaymentPartnerName from "../../../module/fiat/components/table/PaymentPartnerName";
import { useContext } from "react";
import { TableContext } from ".";
import { tW } from "../../utils/helpers";

// const team =
//   "pt-4 pb-3 first:pl-5 first:rounded-tl-2xl text-base px-9 first:rounded-bl-2xl first:border-l last:text-center last:pr-9 last:rounded-tr-2xl last:rounded-br-2xl last:border-r px-4  border-y border-purple-300 ";
// const setting =
//   "border-y border-purple-300 py-4 px-9 text-start text-base font-bold first:rounded-l-2xl first:border-l first:pl-10 last:w-[30px] last:rounded-r-2xl last:rounded-tr-2xl last:border-r last:pr-16";
// const regular = (c_index: number) =>
//   `pt-4 pb-3 ${c_index === 0 ? "" : "border-y"} ${
//     c_index === 1 ? "pl-5 rounded-tl-2xl rounded-bl-2xl border-l" : ""
//   } last:pr-5 last:rounded-tr-2xl last:rounded-br-2xl last:border-r px-1   border-purple-300 text-gray-30 `;

function TableData({ row, col, c_index, r_index, layout_type }: DataTable) {
  const location = useLocation();
  const { pathname } = location;
  const className = tableDataStyle(layout_type, c_index);
  const clickItem = useContext(TableContext)?.clickedItem;

  let template;

  switch (col.dataIndex) {
    case "checkbox":
      template = <TableBodyCheckBox id={row.id} check={row.checkbox} />;
      break;
    case "evidence":
      template = <Evidence data={row} />;
      break;
    case "status_locked":
      template = (
        <LockedStatus status={row[col.key] ? row[col.key] : row.is_clearing} />
      );
      break;
    case "period":
      template = <Period data={row} />;
      break;
    case "team_email":
      template = <TeamEmail index={r_index + 1} email={row[col.key]} />;
      break;
    case "team_actions":
      template = (
        <TeamActions
          // email={row.email}
          status={row.status}
          member_id={row.id}
        />
      );
      break;
    case "team_access":
      template = <TeamAccess permissions={row.permission} />;
      break;
    case "cr_amount":
      template = <Amount amount={row[col.key]} rate={row.tray_exchange_rate} />;
      break;

    case "cr_currency":
      template = <Currency />;
      break;
    case "sp_perk":
    case "pr_perk":
    case "total_commision":
    case "cr_ref":
      template = <span className="font-bold">{row[col.key]}</span>;
      break;
    case "settlement_amount":
      template = <SettlementAmount data={row} />;
      break;
    case "issue_memo":
      template = <Memo txn_ref={row?.txn_reference} issue_id={row?.issue_id} />;
      break;
    case "txn_reference":
      template = <TransactionRefrence txn_ref={row?.txn_reference} />;
      break;
    case "message":
      template = <MessageCount count={row?.message_count} />;
      break;
    case "bank_action":
      template = <BankActions data={row} />;
      break;
    case "bank_status":
      template = <BankStatus data={row} />;
      break;
    case "created_at":
      template = <Date date_data={row?.created_at} />;
      break;
    case "date":
      template = <Date date_data={row.date} />;
      break;
    case "amount":
      template = <LocalAmount data={row.amount * row.rate} />;
      break;
    case "fiat_amount":
      template = <LocalAmount data={row?.amount} />;
      break;
    case "fiat_fee":
      template = <LocalAmount data={row?.fee} />;
      break;
    case "fiat_settled_amount":
      template = <LocalAmount data={row.settled_amount} />;
      break;
    case "fiat_fee_amount":
      template = <LocalAmount data={row.fee_amount} />;
      break;
    case "fiat_balance":
      template = <LocalAmount data={row?.balance} />;
      break;
    case "fiat_order_no":
      template = <TransactionRefrence txn_ref={row?.order_no} />;
      break;
    case "dl_amount":
    case "wallet_amount":
      template = <DollarAmount data={parseInt(row.amount.toString())} />;
      break;
    case "dispute_amount":
      template = <LocalAmount data={row.txn_amount * row.rate} />;
      break;
    case "settlement_server_manage":
    case "settlement_wallet_manage":
      template = (
        <AddressStatus
          is_active={row?.status === "ACTIVE"}
          id={row?.address_id}
        />
      );
      break;
    case "business_action":
      template = (
        <BusinessAction
          status={row?.data?.status}
          doc_title={row?.name}
          doc_link={row?.data?.file}
          doc_type={row?.data?.type}
        />
      );
      break;
    case "business_status":
      template = <BusinessStatus status={row.data?.status} />;
      break;
    case "view_payment_info":
      template = (
        <ViewInformation
          id={row?.txn_ref}
          collection_id={row?.collection_id}
          order_type={row.status?.toLowerCase()}
          bank_ref={row?.bank_ref}
          currency={row?.currency}
          order_no={row?.order_no}
        />
      );
      break;
    case "payment_status":
      template = <PaymentStatus status={row?.status} />;
      break;
    case "payment_partner_name":
      template = <PaymentPartnerName name={row.partner_code} />;
      break;
    case "payment_partner_information":
      template = <PaymentPartnerInfo />;
      break;
    case "payment_partner_status":
      template = <PaymentPartnerStatus status={row.status} />;
      break;
    case "payment_partner_map":
      template = (
        <PaymentPartnerMapper
          partner_code={row.partner_code}
          partner_id={row.partner_id}
          status={row.status}
        />
      );
      break;
    case "payment_partner_manage":
      template = (
        <PaymentPartnerManage
          partner_code={row.partner_code}
          partner_id={row.partner_id}
          status={row.status}
        />
      );
      break;

    default:
      template = row[col.key];
      break;
  }

  return (
    <td
      className={tW(
        className,
        pathname.includes("chat") && pathname.includes(row?.issue_id)
          ? "bg-gray-140"
          : "",
        clickItem && row.partner_id === clickItem
          ? "cursor-pointer border-green-10"
          : ""
      )}
    >
      {template}
    </td>
  );
}

export default TableData;
