// import { useCallback, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  // useDispatchOnce,
  useEffectOnce,
  // useSocketSub,
} from "../../../hooks";
// import customToast from "../../CustomToast";
import CustomTourGuide from "../../CustomTourGuide";
import Header from "../Header";
import SideNav from "../SideNav";
// import MerticsTrigger from "../../../../module/appState/components/MetricsTrigger";
import { getInfoNew } from "../../../../module/dashboard/store/thunk";
import alert from "../../../../static/sounds/atm-cash-machine.wav";
import dooralert from "../../../../static/sounds/doorbell-single-press.wav";
// import { poolActions } from "../../../../module/pool/store/slice";
import * as CT from "../../../service/config/constant";
// import { getIssues } from "../../../../module/pool/store/thunk";
import { fetchUserToken } from "../../../service/storage";
// import { APIResponseSuccessModel } from "../../../types";
import routes from "../../../routes";
import { useCallback, useEffect, useRef } from "react";
import { getMerchantInfo } from "../../../../module/fiat/store/thunk";
import { tW } from "../../../utils/helpers";
import { useSocketSub } from "../../../hooks/useSocketSub";
import { poolActions } from "../../../../module/pool/store/slice";
import customToast from "../../CustomToast";
import { APIResponseSuccessModel } from "../../../types";
import { getIssues } from "../../../../module/pool/store/thunk";

function Protected() {
  const { pathname } = useLocation();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const headerRef = useRef<HTMLHeadingElement | null>(null);
  const {
    // list: issues,
    thread: { txn_reference },
  } = useAppSelector((state) => state.pool.issues);

  const dispatch = useAppDispatch();

  // callback function that add items to pool tray
  const handleTraySubscribe = useCallback((res: APIResponseSuccessModel) => {
    if (res.data) {
      dispatch(poolActions.addTrayItem(res.data));
      new Audio(alert).play();
      customToast(res.message);
    }
  }, []); //eslint-disable-line

  // callback function that change the status of a pending item
  const handlePendingCheck = useCallback((res: APIResponseSuccessModel) => {
    if (res.data) {
      dispatch(poolActions.updatePoolItemStatus(res.data));
      new Audio(dooralert).play();
      customToast(res.message);
    }
  }, []); //eslint-disable-line

  const handleIssuesMessageResponseCheck = useCallback(
    (res: APIResponseSuccessModel) => {
      if (res.data.txn_reference) {
        if (res.data.txn_reference === txn_reference) {
          const data = {
            sent_by: res.data.status,
            txn_reference,
            message: res.data.message,
            upload_url: res.data.upload_url,
            exchanger_name: "",
          };
          dispatch(poolActions.addIssueToThread(data));
        }
        dispatch(
          poolActions.updateUnreadMessageCountWithTxnRef(res.data.txn_reference)
        );
      }
    },
    [] //eslint-disable-line
  );

  // subscribes for tray requests
  useSocketSub(CT.TRAY_REQUEST, handleTraySubscribe);

  // subscribes for pending requests
  useSocketSub(CT.TRAY_REQUEST_NOTIFY, handlePendingCheck);

  // subscribes for Issues
  useSocketSub(CT.DISPUTE_CHAT, handleIssuesMessageResponseCheck);

  // gets the online state of the user and also the dashboard on load of application and on refresh
  useEffectOnce(() => {
    dispatch(getInfoNew(user_id));
    dispatch(getMerchantInfo(user_id));
  });
  // useDispatchOnce(getInfoNew(user_id));

  //  calling for issues so the socket can updaye the unread message count even when pool module has not been loaded

  useEffect(() => {
    dispatch(getIssues(user_id));
  }, [user_id]);

  // useEffectOnce(() => {
  //   // if (issues.length === 0) {
  //   dispatch(getIssues(user_id));
  //   // }
  // });

  if (!fetchUserToken()) {
    return <Navigate replace to={routes.nonprotected.login} />;
  }

  return (
    <div className="h-screen overflow-hidden bg-gold-10 bg-authBg">
      <CustomTourGuide />
      <div
        className={tW(
          "relative flex h-full design_screen:mx-auto design_screen:max-w-screen-design_screen ",
          pathname.includes(routes.protected.dashboard.index)
            ? "bg-[#f9f9f9]"
            : "bg-white"
        )}
      >
        <SideNav className="absolute top-0 left-0 bottom-0 bg-white" />
        <div className="relative ml-64 flex h-full flex-1 flex-col">
          {/* ${
            pathname.includes("pool") ? "overflow-hidden" : "overflow-auto"
          } */}
          {/* h-[calc(100vh-1.8125rem)] */}
          <Header
            ref={headerRef}
            className="absolute right-0 left-0 z-10 bg-white"
          />
          <section
            style={{ marginTop: `${headerRef.current?.offsetHeight}` }}
            className={tW(
              "mt-24  flex-1 overflow-y-scroll px-14 pt-6.5 pb-18",
              pathname.includes(routes.protected.dashboard.index)
                ? ""
                : "pb-[4.5rem]",
              pathname === routes.protected.fiat_wallet.index
                ? "flex flex-col"
                : ""
            )}
          >
            <Outlet />
          </section>

          {/* {pathname.includes(routes.protected.performance.index) ||
          pathname.includes(routes.protected.preview_app) ||
          pathname.includes(routes.protected.onboarding.index) ? null : (
            <MerticsTrigger />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default Protected;
