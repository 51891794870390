import React, { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import {
  Select,
  SelectItem,
} from "../../../../../common/components/CustomSelect";
import CustomInput from "../../../../../common/components/CustomInput";
import CustomButtom from "../../../../../common/components/CustomButton";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useEffectOnce,
} from "../../../../../common/hooks";
import { getCsrfToken, getOtp } from "../../../../auth/store/thunk";
import routes from "../../../../../common/routes";
// import { banks } from "../../../../common/utils/helpers/banks";
import {
  toCurrency,
  validationRules,
} from "../../../../../common/utils/helpers";
import { iHandleChange, iHandleSubmit } from "../../../types";
import Language from "../../../../../common/utils/language/en";
import HorizontalNav from "../../../../../common/components/Layout/HorizontalNav";
import {
  getAllBanks,
  // resolveAccount,
  resolveAccountByCurrency,
} from "../../../store/thunk";
import Dropdown from "../../../../../common/components/CustomSelectBox";

const { WithdrawlForm: PageDictionary } =
  Language.protected.fiatModals.withdrawal;

const initialState = {
  user_id: "",
  amount: "",
  bank_name: "",
  account_code: "",
  currency: "",
  account_number: "",
  memo: "",
};

const initialResolveInfo = {
  name: "",
  isLoading: false,
  error: "",
};

function WithdrawalForm() {
  const navigate = useNavigate();
  const { user_id, email } = useAppSelector((state) => state.auth.user);
  const { currencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );
  const { min_payout_amounts, wallet_balance } = useAppSelector(
    (state) => state.payment
  );
  const userCurrency = currencies[0] as keyof typeof min_payout_amounts;
  const minPayoutAmount = toCurrency(min_payout_amounts[userCurrency]);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [inputs, setInputs] = useState(initialState);
  const [banks, setBanks] = useState<Record<string, string>[]>([]);
  const [errors, setErrors] = useState({ amount: false });
  const dispatch = useAppDispatch();
  const [resolveAccountInfo, setResolveAccountInfo] =
    useState(initialResolveInfo);

  const debounceValue = useDebounce(inputs.account_number, 3000);

  const resolveWithdrawalInfo = useCallback(() => {
    if (!inputs.account_code) return;

    const temp = {
      account_number: debounceValue,
      account_code: inputs.account_code,
      currency: currencies[0],
      user_id,
    };
    setResolveAccountInfo((prev) => ({ ...prev, isLoading: true }));
    dispatch(resolveAccountByCurrency(temp))
      .unwrap()
      .then((res: any) => {
        setResolveAccountInfo((prev) => ({
          ...prev,
          name: res?.account_name,
          error: "",
        }));
      })
      .catch((err) => {
        setResolveAccountInfo((prev) => ({
          ...prev,
          error: err?.message,
          name: "",
        }));
      })
      .finally(() => {
        setResolveAccountInfo((prev) => ({ ...prev, isLoading: false }));
      });
  }, [inputs.account_code, debounceValue]); //eslint-disable-line

  useEffect(() => {
    resolveWithdrawalInfo();
  }, [resolveWithdrawalInfo]); //eslint-disable-line

  useEffectOnce(() => {
    setInputs((prev) => ({
      ...prev,
      user_id,
      currency: currencies[0] ? currencies[0] : "NGN",
    }));

    dispatch(getAllBanks({ user_id, currency: currencies[0] }))
      .unwrap()
      .then((res) => {
        const temp = res
          .map((itm: any) => {
            if (currencies[0] !== "NGN") {
              return {
                bank_name: itm.name,
                code: itm.code,
              };
            }
            return itm;
          })
          .sort((a: any, b: any) => a.bank_name.localeCompare(b.bank_name));
        setBanks(temp);
      });
  });

  const changeHandler: iHandleChange<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    const { name, value } = e.target;
    setInputs((prev) => ({ ...prev, [name]: value }));

    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setErrors((prev) => ({ ...prev, [name]: false }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: true }));
    }
  };

  const selectHandler = useCallback(
    (value: string) => {
      const val = banks.find((itm) => itm.code === value);
      if (!val) return;
      setInputs((prev) => ({
        ...prev,
        bank_name: val.bank_name,
        account_code: val.code,
      }));
    },
    [banks.length] //eslint-disable-line
  );

  const goBack = () => {
    if (loading) {
      return;
    }
    navigate(state?.background?.pathname, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(inputs).includes("")) {
      return;
    }
    setLoading(true);
    dispatch(getCsrfToken({ email: email }))
      .unwrap()
      .then((res) =>
        dispatch(getOtp({ email: email, csrf_token: res }))
          .unwrap()
          .then(() => {
            navigate(
              routes.protected.fiat_wallet.overview.withdrawal
                .withdrawal_authorize,
              {
                state: {
                  ...state,
                  time: dayjs().add(90, "second").toISOString(),
                  data: inputs,
                },
              }
            );
          })
          .finally(() => setLoading(false))
      )
      .finally(() => setLoading(false));
  };

  // console.log(banks);

  return (
    <SideModal clickBackDrop={goBack} className="px-15 pt-24">
      <section className="pr-18">
        <header className="">
          <div>
            <h3 className="text-2xl font-semibold">{PageDictionary.title}</h3>
            <p className="pt-2">{PageDictionary.subtitle}</p>
          </div>
          <nav className=" flex items-end justify-between  gap-x-1 border-b border-gray-20 pt-9">
            <HorizontalNav
              navlinks={[
                {
                  link: routes.protected.fiat_wallet.overview.withdrawal
                    .withdrawal,
                  name: "Fiat-to-Bank",
                },
              ]}
              containerClassName="p-0"
              className="border-transparent p-0 only:p-0"
            />
          </nav>
        </header>
        <form
          className="flex flex-col gap-y-6 pr-8 pt-9"
          onSubmit={submitHandler}
        >
          <CustomInput
            type="text"
            autoComplete="off"
            name="amount"
            value={inputs.amount}
            onChange={changeHandler}
            label={PageDictionary.form.amount_label}
            rules={validationRules.number}
            haserror={errors.amount.toString()}
            errorLabel={`*Minimum balance should be up to ${minPayoutAmount}`}
          />
          <div className="flex flex-col py-0">
            <label
              htmlFor=""
              className="text-base-2 mb-2 inline-block text-base font-medium capitalize"
            >
              {PageDictionary.form.bank_label}
            </label>
            <Dropdown
              className={{
                trigger:
                  "flex !h-[3.75rem] !justify-between !rounded-xl border !border-blue_gray-30 !bg-inputbg pt-2 pb-3 pl-4 !text-black placeholder:text-base placeholder:text-gray-600",
                content: "w-full",
                list: "",
                listitem: "",
              }}
              options={banks.map((itm) => ({
                label: itm.bank_name,
                value: itm.code,
              }))}
              placeHolder="Select..."
              value={inputs.bank_name}
              onChange={selectHandler}

              // isSearchable
              // isMulti={false}
              // placeHolder="Select..."
              // options={banks.map((itm) => ({
              //   label: itm.bank_name,
              //   value: itm.code,
              // }))}
              // onChange={selectHandler}
            />
          </div>
          {/* <Select
            onChange={selectHandler}
            label={PageDictionary.form.bank_label}
            value={inputs.bank_name}
            // className="border-blue_gray-300 h-[3.75rem] w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
            className="h-[3.75rem] rounded-xl border border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
          >
            {banks.length ? (
              banks.map((itm) => (
                <SelectItem key={itm.name} value={itm.code}>
                  {itm.bank_name}
                </SelectItem>
              ))
            ) : (
              <SelectItem value="">No Data Found</SelectItem>
            )}
          </Select> */}

          <CustomInput
            label={PageDictionary.form.account_number_label}
            name="account_number"
            value={inputs.account_number}
            onChange={changeHandler}
            errorLabel={
              resolveAccountInfo.error
                ? resolveAccountInfo.error
                : resolveAccountInfo.name
                ? resolveAccountInfo.name
                : ""
            }
          />

          {resolveAccountInfo.isLoading ? (
            <div className="-mt-4 flex items-center justify-center">
              <PropagateLoader color="#55D8A1" size={15} />
            </div>
          ) : null}

          <div>
            <label
              htmlFor="text-area"
              className="mb-2.5 inline-block text-base font-medium text-black"
            >
              {PageDictionary.form.description_label}
            </label>
            <textarea
              name="memo"
              value={inputs.memo}
              onChange={changeHandler}
              id="text-area"
              cols={30}
              rows={4}
              className="w-full rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
              disabled={loading}
            />
          </div>

          <CustomButtom
            isloading={loading}
            disabled={
              Object.values(inputs).includes("") ||
              parseInt(inputs.amount) < 10 ||
              resolveAccountInfo.name === "" ||
              wallet_balance <= min_payout_amounts[userCurrency]
            }
            className="self-start bg-green-400"
          >
            {PageDictionary.form.submit_btn}
          </CustomButtom>
        </form>
      </section>
    </SideModal>
  );
}

export default WithdrawalForm;
