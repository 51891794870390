import { DropEvent, FileRejection } from "react-dropzone";
import { TiDelete } from "react-icons/ti";
import Dropzone, {
  Iaccept,
} from "../../../../common/components/CustomDragandDrop";
import { tW } from "../../../../common/utils/helpers";

type Props = {
  title: string;
  images: any[];
  onSubmit?: () => void;
  onRemoveMedia: (id: string) => void;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  supportFormatText?: string;
  accept: Iaccept;
  name: string;
  disabled?: boolean;
  required?: boolean;
  hasserror?: boolean;
};

function MediaUpload({
  onDrop,
  images,
  onSubmit,
  onRemoveMedia,
  title,
  supportFormatText,
  accept,
  name,
  disabled,
  required,
  hasserror,
}: Props) {
  return (
    <>
      <div className="shadow-storyImageUpload flex-1">
        <p className="mb-4.5 pl-0.5 text-base font-medium capitalize text-black">
          {title}
        </p>
        <div
          className={tW(
            "overflow-hidden rounded-xl border",
            hasserror ? "border-red-40" : "border-transparent"
          )}
        >
          <Dropzone
            onDrop={onDrop}
            accept={accept}
            supportFormatText={supportFormatText}
            name={name}
            disabled={disabled}
            required={required}
          />
        </div>
        {images.length !== 0 && (
          <div className="pt-5">
            {/* <h5 className="text-gray-250 text-sm font-bold leading-none">
            Uploading - {`${images.length}/${images.length}`} files
          </h5> */}

            <div className="flex flex-col gap-y-2.5 pt-3">
              <h5 className="text-sm font-bold leading-none text-gray-400">
                Uploaded
              </h5>

              {images.map((image) => (
                <div
                  key={image.id}
                  className=" relative flex items-center justify-between rounded border border-green-10 p-2.5 before:absolute before:bottom-0 before:left-0 before:h-1 before:w-1/2 before:bg-purple-450"
                >
                  <span className="text-xs leading-none">{image.name}</span>
                  <button
                    type="button"
                    onClick={() => onRemoveMedia(image.id)}
                    className="text-gray-650 text-base hover:text-red-300"
                  >
                    <TiDelete size={16} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* <button
        disabled={images.length === 0}
        onClick={onSubmit}
        type="button"
        className="mt-11 rounded-md bg-blue-100 px-18 py-3 text-base text-white disabled:bg-slate-400"
      >
        Publish Story
      </button> */}
    </>
  );
}

export default MediaUpload;
