import { lazy } from "react";
import routes from ".";
import {
  ADMIN_PERM,
  ACCEPT_REQUEST_PERM,
  PROCESS_REQUEST_PERM,
  ISSUES_PERM,
} from "../../common/utils/helpers";
import { Iroute } from "./types";

const loadModules = (module: string, link: string) =>
  // lazy(() => import(`../../module/pages/${link}`));
  lazy(() => import(`../../module/${module}/pages/${link}`));

const route: Iroute[] = [
  {
    path: routes.nonprotected.login,
    Component: loadModules("auth", "Login"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.register,
    Component: loadModules("auth", "Register"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.two_fa,
    Component: loadModules("auth", "Enter2Fa"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.resetpassword,
    Component: loadModules("auth", "RestPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.forgotpassword,
    Component: loadModules("auth", "ForgotPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.enter_otp,
    Component: loadModules("auth", "EnterOtp"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.new_password,
    Component: loadModules("auth", "NewPassword"),
    access: "guest-only",
  },
  {
    path: routes.nonprotected.reset_successful,
    Component: loadModules("auth", "ResetSuccessful"),
    access: "guest-only",
  },
  {
    path: routes.protected.notification,
    Component: loadModules("appState", "Notification"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.dashboard.crypto,
    Component: loadModules("dashboard", "Crypto"),
    access: "loggedin-user",
    layout: "dashboard",
  },
  {
    path: routes.protected.dashboard.fiat,
    Component: loadModules("dashboard", "Fiat"),
    access: "loggedin-user",
    layout: "dashboard",
  },
  {
    path: routes.protected.transactions.withdraw,
    Component: loadModules("transactions", "Withdrawal"),
    access: "loggedin-user",
    // layout: "transactions",
  },
  {
    path: routes.protected.transactions.fund,
    Component: loadModules("transactions", "Funding"),
    access: "loggedin-user",
    // layout: "transactions",
  },
  {
    path: routes.protected.crypto_wallet.capital,
    Component: loadModules("crypto", "Capital"),
    access: "loggedin-user",
    layout: "crypto",
  },
  {
    path: routes.protected.crypto_wallet.commission,
    Component: loadModules("crypto", "Commission"),
    access: "loggedin-user",
    layout: "crypto",
  },
  {
    path: routes.protected.team.index,
    Component: loadModules("team", "Team"),
    access: "loggedin-user",
    layout: "team",
    perm: ADMIN_PERM,
  },

  {
    path: routes.protected.settings.bank_setting.index,
    Component: loadModules("settings", "BankSetting"),
    access: "loggedin-user",
    layout: "setting",
  },

  {
    path: routes.protected.settings.reset_pin,
    Component: loadModules("settings", "ResetPin"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.language,
    Component: loadModules("settings", "Language"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.api_setting,
    Component: loadModules("settings", "ApiSetting"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.apps,
    Component: loadModules("settings", "Apps"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.profile,
    Component: loadModules("settings", "Profile"),
    access: "loggedin-user",
  },
  {
    path: routes.protected.settings.price,
    Component: loadModules("settings", "Price"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.settings.settlement.index,
    Component: loadModules("settings", "Settlement"),
    access: "loggedin-user",
    layout: "setting",
  },
  {
    path: routes.protected.performance.metric,
    Component: loadModules("performance", "Metrics"),
    access: "loggedin-user",
    layout: "performance",
  },
  {
    path: routes.protected.performance.chart,
    Component: loadModules("performance", "Charts"),
    access: "loggedin-user",
    layout: "performance",
  },
  {
    path: routes.protected.pool.tray,
    Component: loadModules("pool", "Tray"),
    access: "loggedin-user",
    layout: "pool",
    perm: ACCEPT_REQUEST_PERM,
  },

  {
    path: routes.protected.pool.pending_request,
    Component: loadModules("pool", "PendingRequest"),
    access: "loggedin-user",
    layout: "pool",
    perm: PROCESS_REQUEST_PERM,
  },
  {
    path: routes.protected.pool.processing_request,
    Component: loadModules("pool", "ProcessingRequest"),
    access: "loggedin-user",
    layout: "pool",
    perm: PROCESS_REQUEST_PERM,
  },
  {
    path: routes.protected.pool.junks,
    Component: loadModules("pool", "Junks"),
    access: "loggedin-user",
    layout: "pool",
    perm: PROCESS_REQUEST_PERM,
  },
  {
    path: routes.protected.pool.completed_request,
    Component: loadModules("pool", "CompletedRequest"),
    access: "loggedin-user",
    layout: "pool",
    perm: PROCESS_REQUEST_PERM,
  },
  {
    path: routes.protected.pool.issues.index,
    Component: loadModules("pool", "Issues"),
    access: "loggedin-user",
    layout: "pool",
    perm: ISSUES_PERM,
  },
  {
    path: routes.protected.pool.issues.message.index,
    Component: loadModules("pool", "Issues/IssueChat"),
    access: "loggedin-user",
    perm: ISSUES_PERM,
  },
  {
    path: routes.protected.preview_app,
    Component: loadModules("compliance", "PreviewApp"),
    access: "public",
  },
  {
    path: routes.protected.onboarding.terms.business,
    Component: loadModules("compliance", "Terms"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.terms.individual,
    Component: loadModules("compliance", "Terms"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.document.business,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.document.individual,
    Component: loadModules("compliance", "BusinessDocument"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.bussiness_profile,
    Component: loadModules("compliance", "BusinessProfile"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.shareholders.business,
    Component: loadModules("compliance", "Shareholders"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.onboarding.shareholders.individual,
    Component: loadModules("compliance", "Shareholders"),
    access: "public",
    layout: "onboarding",
  },
  {
    path: routes.protected.fiat_wallet.overview.index,
    Component: loadModules("fiat", "Payment"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.withdrawals.completed,
    Component: loadModules("fiat", "Payout/Completed"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.withdrawals.failed,
    Component: loadModules("fiat", "Payout/Failed"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.withdrawals.pending,
    Component: loadModules("fiat", "Payout/Pending"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.withdrawals.processing,
    Component: loadModules("fiat", "Payout/Processing"),
    access: "loggedin-user",
    layout: "fiat",
  },
  // {
  //   path: routes.protected.fiat_wallet.overview.transactions.index,
  //   Component: loadModules("fiat", "Transactions"),
  //   access: "loggedin-user",
  //   layout: "fiat",
  // },
  {
    path: routes.protected.fiat_wallet.overview.transactions.all,
    Component: loadModules("fiat", "Collections/All"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.pending,
    Component: loadModules("fiat", "Collections/Pending"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.completed,
    Component: loadModules("fiat", "Collections/Completed"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.mismatched,
    Component: loadModules("fiat", "Collections/Mismatched"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.abandoned,
    Component: loadModules("fiat", "Collections/Abandoned"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.expired,
    Component: loadModules("fiat", "Collections/Expired"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.overview.transactions.claimed,
    Component: loadModules("fiat", "Collections/Claimed"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.fiat_wallet.payment_methods.index,
    Component: loadModules("fiat", "PaymentMethods"),
    access: "loggedin-user",
    layout: "fiat",
  },
  {
    path: routes.protected.binance_external.form,
    Component: loadModules("fiat", "ExternalPaymentForm"),
    access: "guest-only",
  },
];

export default route;
