import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import customToast from "../../../common/components/CustomToast";

export const getCapitalHistory = createAsyncThunk(
  "wallet/getCapitalHistory",
  async (user_id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getCapitalHistory(user_id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err).payload;
      });
  }
);

export const getCommissionHistory = createAsyncThunk(
  "wallet/getCommissionHistory",
  async (user_id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getCommissionHistory(user_id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err).payload;
      });
  }
);
