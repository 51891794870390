import React from "react";
import { TooltipRenderProps } from "react-joyride";
import close_icon from "../../../../static/images/icons/close-square.svg";

function TourGuide({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}: TooltipRenderProps) {
  return (
    <div
      {...tooltipProps}
      className="flex w-[26.75rem] flex-col gap-y-3 rounded bg-white pt-4 pr-5 pb-7 pl-8 text-sm"
    >
      <header>
        <div className="flex items-center justify-between">
          <p className="leading-6.5">{`${index + 1}/${size}`}</p>
          <button {...closeProps} className="">
            <img src={close_icon} alt="end tour" />
          </button>
        </div>
        {step.title && (
          <h4 className="text-lg font-bold leading-6.5">{step.title}</h4>
        )}
      </header>
      <p className="pr-1  leading-6.5">{step.content}</p>
      <footer className="flex items-center justify-between">
        <a //eslint-disable-line
          href="#"
          target={"_blank"}
          className="leading-6.5 text-blue-30 underline underline-offset-2 hover:no-underline"
        >
          Learn more
        </a>
        <div className="flex items-center justify-center gap-x-2.5">
          {index > 0 && (
            <button
              {...backProps}
              className="rounded bg-transparent py-1.5 px-3  font-bold "
            >
              Back
            </button>
          )}
          {continuous && (
            <button
              {...primaryProps}
              className="rounded  bg-blue_gray-10 py-1.5 px-7  font-bold text-white"
            >
              Next
            </button>
          )}
        </div>
      </footer>
    </div>
  );
}

export default TourGuide;
