import React, { Dispatch, SetStateAction } from "react";
import { AiFillFileAdd } from "react-icons/ai";
import CustomLoader from "../../../../common/components/CustomLoader";
import customToast from "../../../../common/components/CustomToast";
import { makeCloudinaryPostRequest } from "../../../../common/service/request";
import Language from "../../../../common/utils/language/en";
import { IcompleteWithdrawalstate } from "../../Modal/CompleteWithdrawal";
import { tW } from "../../../../common/utils/helpers";

type Props = {
  setState: Dispatch<SetStateAction<IcompleteWithdrawalstate>>;
  state: string;
  isuploading: boolean;
};

const { CompleteWithdrawal: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

function ImageUploader({ setState, state, isuploading }: Props) {
  const propgressHandler = () => {
    setState((prev) => ({ ...prev, isUploading: true }));
  };

  const fileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const itm = e.target.files?.item(0);
    const reader = new FileReader();
    reader.onload = function (e) {
      setState((prev: any) => ({
        ...prev,
        file: { name: itm?.name, value: itm },
      }));

      makeCloudinaryPostRequest(itm, propgressHandler)
        .then((res) => {
          setState((prev: any) => ({
            ...prev,
            isUploading: false,
            payment_evidence_url: res.data.url,
          }));
        })
        .catch((err) =>
          customToast("Error encountered, Try Upload Again", true)
        );
    };
    if (itm) {
      reader.readAsDataURL(itm);
    }
  };
  return (
    <div
      style={{
        animationName: `${isuploading ? "uploadimage" : ""}`,
        animationDuration: `10s`,
        animationIterationCount: "infinite",
      }}
      className={tW(
        "flex cursor-pointer items-center justify-between gap-x-8 rounded-md border border-dashed border-blue_gray-10 pt-5 pr-11 pb-6 pl-7",
        isuploading ? "animate-bounce" : ""
      )}
    >
      <AiFillFileAdd />
      <label htmlFor="file-input" className="cursor-pointer">
        {state === "" ? PageDictionary.label : state}
      </label>
      <input
        accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        className="hidden"
        type="file"
        name="file"
        id="file-input"
        onChange={fileChangeHandler}
      />
      {isuploading && <CustomLoader />}
    </div>
  );
}

export default ImageUploader;
