import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../../common/components/CustomButton";
import CustomInput from "../../../../../common/components/CustomInput";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../common/hooks/redux";
import Language from "../../../../../common/utils/language/en";
import { iHandleChange, iHandleSubmit } from "../../../types";
import { useCountDown, useEffectOnce } from "../../../../../common/hooks";
import ResendCodeCounter from "../../../../auth/components/ResendCodeCounter";
import dayjs from "dayjs";
import { removeNonNumeric } from "../../../../../common/utils/helpers";
import { getCsrfToken, getOtp } from "../../../../auth/store/thunk";
import { paymentWithdrawal /* payoutMerchant */ } from "../../../store/thunk";
import routes from "../../../../../common/routes";

export const paymentModalHelper = [
  {
    title: "Amount",
    id: "amount",
  },
  {
    title: "Bank",
    id: "bank_name",
  },
  {
    title: "Account Number",
    id: "account_number",
  },
  {
    title: "Account Name",
    id: "account_name",
  },
];

const { AuthorizeWithdrawal: PageDictionary } =
  Language.protected.fiatModals.withdrawal;

const initialState = {
  otp_code: "",
  loading: false,
};

function AuthorizeWithdrawalForm() {
  const navigate = useNavigate();
  const { email } = useAppSelector((state) => state.auth.user);
  const { state } = useLocation();
  const [inputs, setInputs] = useState(initialState);
  const dispatch = useAppDispatch();

  const {
    count: time1,
    counter: counter1,
    start: startCountDown1,
    reset: resetCountDown1,
  } = useCountDown(90);

  const startTime = dayjs(state?.time)?.diff(dayjs(), "second");

  const { count, counter, start } = useCountDown(startTime);

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value, name } = e.target;
    setInputs((prev) => ({ ...prev, [name]: removeNonNumeric(value) }));
  };

  const resendCode = () => {
    if (inputs.loading) return;
    setInputs((prev) => ({ ...prev, loading: true }));
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res) => {
        dispatch(getOtp({ email, csrf_token: res }))
          .unwrap()
          .then(() => {
            resetCountDown1();
            startCountDown1();
          })
          .finally(() => setInputs((prev) => ({ ...prev, loading: false })));
      })
      .finally(() => setInputs((prev) => ({ ...prev, loading: false })));
  };

  useEffectOnce(() => {
    if (startTime > 0 && start !== undefined) {
      start();
    }
  });

  const handlerDisable =
    (counter > 0 && counter1 === 90) || (counter1 > 0 && counter1 < 89);
  const goBack = () => {
    navigate(state?.background?.pathname, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (inputs.loading === true || inputs.otp_code === "") return;
    setInputs((prev) => ({ ...prev, loading: true }));
    dispatch(paymentWithdrawal({ ...state.data, otp_code: inputs.otp_code }))
      .unwrap()
      .then(() => {
        navigate(routes.protected.fiat_wallet.success, {
          state: { ...state },
          replace: true,
        });
      })
      .catch(() => {
        goBack();
      })
      .finally(() => {
        setInputs((prev) => ({ ...prev, loading: false }));
      });
  };

  return (
    <SideModal clickBackDrop={goBack} className="px-15 pt-24">
      <section className="flex flex-col gap-y-12 pr-18">
        <header className="">
          <h3 className="text-2xl font-semibold">{PageDictionary.title}</h3>
          <p className="pt-2">{PageDictionary.subtitle}</p>
        </header>

        <div className="grid grid-cols-2 gap-y-7 gap-x-20">
          {paymentModalHelper.map((itm) => (
            <div key={itm.id} className="flex flex-col gap-y-3">
              <p className="text-sm text-gray-750">{itm.title}</p>
              <p className="text-1919 font-bold text-base-30">
                {state.data[itm.id]}
              </p>
            </div>
          ))}
        </div>
        <form onSubmit={submitHandler} className="mt-5 flex flex-col gap-y-7">
          <CustomInput
            label={PageDictionary.form.password_label}
            name="otp_code"
            value={inputs.otp_code}
            onChange={changeHandler}
          />
          <CustomButtom
            disabled={inputs.otp_code.length <= 6 || inputs.loading}
            className="self-start"
          >
            {PageDictionary.form.submit_btn}
          </CustomButtom>
        </form>
      </section>
      <button
        onClick={resendCode}
        disabled={handlerDisable || inputs.loading}
        className="mt-16 flex cursor-pointer items-center gap-x-5 text-base font-medium text-gray-100 disabled:cursor-not-allowed"
      >
        Didn’t get code? Resend
        {counter > 0 ? (
          <ResendCodeCounter minutes={count.minutes} seconds={count.seconds} />
        ) : null}
        {counter <= 0 && counter1 !== 90 && counter1 > 0 ? (
          <ResendCodeCounter minutes={time1.minutes} seconds={time1.seconds} />
        ) : null}
      </button>
    </SideModal>
  );
}

export default AuthorizeWithdrawalForm;
