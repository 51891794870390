import React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";

export const DropdownMenu = DropdownMenuPrimitive.Root;
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
export const DropdownArrow = DropdownMenuPrimitive.Arrow;
export const DropDownCheckBoxItem = DropdownMenuPrimitive.CheckboxItem;
export const DropDownItemIndicator = DropdownMenuPrimitive.ItemIndicator;

type Props = {
  children: React.ReactNode;
  className?: string;
  onPointerDownOutside?: (e: any) => void;
};

type itemProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};
export const DropDownMenuContent = React.forwardRef<HTMLDivElement, Props>(
  ({ children, onPointerDownOutside, className, ...props }, forwardedRef) => {
    return (
      <DropdownMenuPrimitive.Content
        onPointerDownOutside={onPointerDownOutside}
        className={className}
        ref={forwardedRef}
      >
        {children}
      </DropdownMenuPrimitive.Content>
    );
  }
);


export const DropDownItem = React.forwardRef<HTMLDivElement, itemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <DropdownMenuPrimitive.Item
        className={className}
        {...props}
        ref={forwardedRef}
      >
        {children}
      </DropdownMenuPrimitive.Item>
    );
  }
);

