import { IpaginationInfo } from "../types";

export function updateTotalDataCountAndPages<
  Type1,
  Type2 extends { filterState: Record<string, boolean>; info: IpaginationInfo }
>(state: Type2, temp: Type1[]) {
  state.info.totalDataCount = temp.length;
  state.info.totalPages = Math.ceil(temp.length / state.info.maxPageDataCount);
}

export function pageNumberUpdateHelper<
  Type2,
  Type1 extends {
    info: IpaginationInfo;
    data: Type2[];
  }
>(state: Type1, parent: Type2[], payload?: number) {
  if (payload) {
    state.info.currentPage = payload;
  }
  const start = state.info.maxPageDataCount * (state.info.currentPage - 1);
  const end = start + state.info.maxPageDataCount;
  const data = parent.slice(start, end);
  state.data = [...data];
}
