import React, { createContext, useEffect, useRef } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import Pagination from "../CustomPagination";
import TableData from "./TableData";
import TableHeaderCheckbox from "./TableHeaderCheckbox";
import { Table, ContextTable } from "./types";
import { EmptyTableIcon } from "../../../static/Icons";
import Language from "../../utils/language/en";
import { tableBodyStyle, tableHeadStyle } from "./tablehelper";
import { tW } from "../../utils/helpers";

const { table: PageDictionary } = Language.general;

// const th_team =
//   "sticky top-0 bg-white z-[1] pb-2 pt-7 text-base font-normal text-gray-40 last:pr-9 px-9 text-left first:pl-5 ";
// const th_setting =
//   "z-[1] px-9 text-left text-xs font-bold  text-gray-40 first:pl-10 last:pr-9 last:text-start";
// // const trmp = "sticky top-0 bg-white";
// const th_regular = (id: number) =>
//   ` sticky top-0 bg-white z-[1] pb-5 pt-10 text-base font-normal text-gray-40  ${
//     id === 1 ? "pl-5" : ""
//   } last:pr-9 px-1 `;
// const regbody = " border-spacing-y-2.5 text-center ";
// const teambody = " border-spacing-y-4 text-left";
// const settingbody = "border-spacing-y-6 text-left";

export const TableContext = createContext<ContextTable | null>(null);

function CustomTable({
  cols,
  rows,
  checkRowItem,
  checkAll,
  checkAllHandler,
  isloading,
  emptyTableStyle,
  layout_type,
  currentPage,
  pageChange,
  totalDataCount,
  totalPages,
  title,
  className,
  rowClickHandler,
  clickedItem,
  scrollPageChange,
  nextPage,
}: Table) {
  const clickScrollToTop = useRef<HTMLTableRowElement | null>(null);
  const bodyClassName = tableBodyStyle(layout_type);
  // const headerClassName = tableHeadStyle(layout_type);

  const observerRef = useRef<HTMLTableRowElement>(null);

  useEffect(() => {
    const options = {
      root: null, // viewport
      rootMargin: "0px",
      threshold: 0.5, // When half of the element is visible
    };

    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          entry.target === observerRef.current &&
          scrollPageChange
        ) {
          // Make API call when the last row is in view
          scrollPageChange();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [currentPage, nextPage]);

  return (
    <TableContext.Provider
      value={{ data: rows, checkRowItem, clickedItem, checkAll }}
    >
      <table className={tW(bodyClassName, className)}>
        {title ? (
          <caption className="sticky top-0 z-[1] bg-white text-left text-xl font-bold text-base-50">
            {title}
          </caption>
        ) : null}
        <thead>
          <tr className="">
            {cols.map((col, id) => (
              <th
                className={tW(tableHeadStyle(layout_type, id))}
                key={
                  col.title
                    ? col.title
                    : col.dataIndex
                    ? col.dataIndex
                    : col.key
                }
              >
                {col.dataIndex === "checkbox" && checkAll !== undefined ? (
                  <TableHeaderCheckbox
                    selectAll={checkAll}
                    selectAllHandler={checkAllHandler}
                  />
                ) : (
                  col.title
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {isloading ? (
            <tr className="absolute inset-0 block ">
              <td
                colSpan={9999}
                className="flex h-full items-center  justify-center"
              >
                <div className=" grid justify-items-center">
                  <div className="relative">
                    <AiOutlineLoading className="animate-spin text-[4rem]" />
                  </div>
                  <p className="mt-3">
                    {rows.length === 0
                      ? PageDictionary.fetchingData
                      : PageDictionary.updatingData}
                  </p>
                </div>
              </td>
            </tr>
          ) : null}
          {rows?.length === 0 && emptyTableStyle ? (
            <tr>
              <td colSpan={9999} className={emptyTableStyle}>
                {!isloading ? (
                  <div className="grid items-center justify-items-center">
                    <span className="text-blue_gray-10">
                      <EmptyTableIcon scale={0.125} />
                    </span>
                    <p className="ml-4 text-xs">{PageDictionary.noDataAvail}</p>
                  </div>
                ) : null}
              </td>
            </tr>
          ) : null}
          {rows?.map((row, idx) => (
            <tr
              key={idx}
              ref={
                idx === 0
                  ? clickScrollToTop
                  : idx === rows.length - 1
                  ? observerRef
                  : undefined
              }
              className="text-sm text-gray-300 "
              onClick={() =>
                rowClickHandler ? rowClickHandler(row.partner_id) : null
              }
            >
              {cols.map((col, id) => (
                <TableData
                  key={id}
                  row={row}
                  col={col}
                  c_index={id}
                  r_index={idx}
                  layout_type={layout_type}
                />
              ))}
            </tr>
          ))}
          {isloading && rows.length >= 10 ? (
            <tr className=" ">
              <td
                colSpan={cols.length}
                // className="flex h-full items-center  justify-center"
              >
                <p className="flex items-center justify-center">
                  ...loading
                  <span className="">
                    <AiOutlineLoading className="animate-spin " />
                  </span>
                </p>
              </td>
            </tr>
          ) : null}
        </tbody>
        {totalPages && totalPages > 1 ? (
          <tfoot className="bg-white">
            <tr className="bg-white">
              <td colSpan={9} className="sticky bottom-0 border-none bg-white">
                <Pagination
                  setPage={pageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  dataCount={totalDataCount}
                  tableRef={clickScrollToTop}
                />
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
    </TableContext.Provider>
  );
}

export default CustomTable;
