import {
  BINANCE_PARTNER_ID,
  UBANK_PARTNER_ID,
} from "../../service/config/constant";
// import binanceLogo from "../../../static/images/bnb.png";
// import ubankLogo from "../../../static/images/ubanknew.jpeg";
import instance from "../../../static/images/instancelogo.svg";
// import binanceMobile from "../../../static/images/bnb_transparent.png";
// import instantPay from "../../../static/images/instantpay.svg";

const goldPartnersId = [BINANCE_PARTNER_ID, UBANK_PARTNER_ID];

const config = {
  binance: {
    logo: "/bnb.png",
    mobile_logo: "/bnb_transparent.png",
    bgColor: "#191A1F",
    textColor: "",
  },
  ubank: {
    // logo: "/ubanknew.jpg",
    logo: "",
    mobile_logo: "",
    bgColor: "#f6f7fb",
    textColor: "",
  },
  default: {
    logo: instance,
    mobile_logo: "",
    bgColor: "#040869",
    textColor: "",
  },
};

export const partnersColors = (pid?: string) => {
  if (pid === BINANCE_PARTNER_ID) {
    return config.binance.bgColor;
  }
  if (pid === UBANK_PARTNER_ID) {
    return config.ubank.bgColor;
  }
  return config.default.bgColor;
};

export const partnersLogo = (pid?: string) => {
  if (pid === BINANCE_PARTNER_ID) {
    return config.binance.logo;
  }
  if (pid === UBANK_PARTNER_ID) {
    return config.ubank.logo;
  }
  return config.default.logo;
};

export const partnersMobile = (pid?: string) => {
  if (pid === BINANCE_PARTNER_ID) {
    return config.binance.mobile_logo;
  }
  if (pid === UBANK_PARTNER_ID) {
    return config.ubank.mobile_logo;
  }
  return config.default.mobile_logo;
};

export const isGoldPartner = (pid: string) => {
  return goldPartnersId.includes(pid);
};
