import React from "react";

type Props = {
  minutes: string;
  seconds: string;
};

function ResendCodeCounter({ minutes, seconds }: Props) {
  return (
    <span className="text-[#19AF70]">{`${minutes} ${
      minutes !== "00" ? "m" : ""
    } : ${seconds}s`}</span>
  );
}

export default ResendCodeCounter;
