import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import {
  getConfirmationCode,
  getDisputeFunds,
  getIncomingSettlements,
  getInfoNew,
  withdrawUserWallet,
} from "./thunk";
import { Idashboard, IDashboardInsurance, Iinsurancefilter } from "../types";
import { logoutUser } from "../../appState/store/thunk";
import {
  checkAllHelper,
  checkItemHelper,
  pageNumberUpdateHelper,
  updateTotalDataCountAndPages,
} from "./helper";
import { getCsrfToken } from "../../auth/store/thunk";

const initialState: Idashboard = {
  loading: false,
  error: null,
  wallet_address: "",
  home: {
    admin_fee_usdt_trc20: 0,
    capital_wallet: 0,
    commission_wallet: 0,
    total_processed_user: 0,
    dispute: 0,
    total_request: 0,
    insurance_amount: 0,
    total_funding_txn: 0,
    ranking: 0,
    total_withdrawal_txn: 0,
    locked_fund: 0,
    total_funded_amount: 0,
    total_withdrawal_amount: 0,
  },
  settlements: [],
  dispute_funds: [],
  pagination: {
    dispute_funds: {
      data: [],
      export: {
        checkAll: false,
        enableExport: false,
      },
      filter: {
        data: [],
        isfiltered: false,
        query: {
          start: new Date(),
          stop: null,
        },
      },
      info: {
        currentPage: 1,
        maxPageDataCount: 10,
        totalDataCount: 0,
        totalPages: 0,
      },
    },
    settlements: {
      data: [],
      export: {
        checkAll: false,
        enableExport: false,
      },
      filter: {
        data: [],
        isfiltered: false,
        query: {
          start: new Date(),
          stop: null,
        },
      },
      info: {
        currentPage: 1,
        maxPageDataCount: 10,
        totalDataCount: 0,
        totalPages: 0,
      },
    },
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    checkItem: (state, { payload }: PayloadAction<Iinsurancefilter>) => {
      const { id, type } = payload;
      const data =
        type === "dispute_funds"
          ? state.pagination.dispute_funds
          : state.pagination.settlements;
      checkItemHelper(data, id);
    },
    checkAllItems: (state, { payload }: PayloadAction<IDashboardInsurance>) => {
      const data =
        payload === "dispute_funds"
          ? state.pagination.dispute_funds
          : state.pagination.settlements;
      checkAllHelper(data);
    },
    updatePageNumberDispute: (state, { payload }: PayloadAction<number>) => {
      pageNumberUpdateHelper(
        state.pagination.dispute_funds,
        state.dispute_funds,
        payload
      );
    },
    updatePageNumberSettlements: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      pageNumberUpdateHelper(
        state.pagination.settlements,
        state.settlements,
        payload
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInfoNew.fulfilled, (state, action) => {
        (Object.keys(state.home) as Array<keyof typeof state.home>).forEach(
          (key) => {
            state.home[key] = action.payload[key];
          }
        );
        state.wallet_address = action.payload?.wallet_address;
      })
      .addCase(withdrawUserWallet.fulfilled, (state, action) => {
        // console.log(action.payload);
        state.home.capital_wallet =
          state.home.capital_wallet -
          state.home.admin_fee_usdt_trc20 -
          parseInt(action.payload.amount);
      })
      .addCase(
        getIncomingSettlements.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.settlements = [...payload];
          updateTotalDataCountAndPages(state.pagination.settlements, payload);
          pageNumberUpdateHelper(
            state.pagination.settlements,
            state.settlements
          );
        }
      )
      .addCase(
        getDisputeFunds.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.dispute_funds = [...payload];

          updateTotalDataCountAndPages(state.pagination.dispute_funds, payload);
          pageNumberUpdateHelper(
            state.pagination.dispute_funds,
            state.dispute_funds
          );
        }
      )

      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          switch (key) {
            case "settlements":
            case "dispute_funds":
            case "home":
            case "loading":
            case "error":
              state[key] = initialState[key];
              break;
            case "pagination":
              state[key] = initialState[key];
              break;

            default:
              state[key] = initialState[key];
              break;
          }
        });
      })

      .addMatcher(
        isAnyOf(
          getInfoNew.fulfilled,
          getConfirmationCode.fulfilled,
          getCsrfToken.fulfilled,
          getIncomingSettlements.fulfilled,
          getDisputeFunds.fulfilled,
          getInfoNew.pending,
          getConfirmationCode.pending,
          getCsrfToken.pending,
          getIncomingSettlements.pending,
          getDisputeFunds.pending
        ),
        (state) => {
          if (state.error !== null) {
            state.error = null;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          getInfoNew.rejected,
          withdrawUserWallet.rejected,
          getConfirmationCode.rejected,
          getCsrfToken.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getInfoNew.pending,
          withdrawUserWallet.pending,
          getConfirmationCode.pending,
          getCsrfToken.pending,
          getIncomingSettlements.pending,
          getDisputeFunds.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getInfoNew.rejected,
          getInfoNew.fulfilled,
          withdrawUserWallet.rejected,
          withdrawUserWallet.fulfilled,
          getConfirmationCode.rejected,
          getConfirmationCode.fulfilled,
          getCsrfToken.rejected,
          getCsrfToken.fulfilled,
          getIncomingSettlements.rejected,
          getIncomingSettlements.fulfilled,
          getDisputeFunds.rejected,
          getDisputeFunds.fulfilled
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const {
  checkAllItems,
  checkItem,
  updatePageNumberDispute,
  updatePageNumberSettlements,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
