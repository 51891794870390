import { createAsyncThunk } from "@reduxjs/toolkit";
// import { NavigateFunction } from "react-router-dom";
import customToast from "../../../common/components/CustomToast";
// import routes from "../../../common/routes";
import { clearData } from "../../../common/service/storage";
import service from "../service";
import * as tp from "../types";
import language from "../../../common/utils/language/en";

const { general: PageDictionary } = language;

export const toggleServiceStatus = createAsyncThunk(
  "appstate/onlinestatus",
  async (
    req: tp.ItoggleServiceStateThunk,
    { fulfillWithValue, rejectWithValue }
  ) => {
    return service
      .toggleOfflineState(req.id, req.data)
      .then((res) => {
        return fulfillWithValue(res.data);
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const languageChange = createAsyncThunk(
  "appstate/languageChange",
  async (req: tp.IlanguageChangeThunk, { rejectWithValue }) => {
    return service
      .changeLanguage(req.id, req.data)
      .then((res) => {
        customToast(res.message);
        return req.data;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const switchMultiAccount = createAsyncThunk(
  "appstate/switchMultiAccount",
  async (
    req: tp.ISwitchMultiAccount,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .switchMultiAccount(req)
      .then((res) => {
        // customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getNotificationList = createAsyncThunk(
  "appstate/getNotificationList",
  async (req: tp.IgetNotifications, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getNotificationList(req.user_id)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const readNotification = createAsyncThunk(
  "appstate/readNotification",
  async (req: tp.IreadNotification, { rejectWithValue, fulfillWithValue }) => {
    return service
      .readNotification(req)
      .then((res) => {
        return fulfillWithValue(req.notification_id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const readAllNotification = createAsyncThunk(
  "appstate/readAllNotification",
  async (
    req: tp.IgetNotifications,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .readAllNotification(req)
      .then((res) => {
        dispatch(getNotificationList(req));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const logoutUser = createAsyncThunk(
  "appstate/logoutUser",
  async (token_expired?: boolean) => {
    clearData();
    return Promise.resolve({ payload: null }).then(() => {
      // window.location.assign(routes.nonprotected.login);
      document.title = "IntraPay";
      customToast(
        token_expired
          ? PageDictionary.ExpiredTokenErrorMessage.errorMessage
          : PageDictionary.logoutSuccessful,
        token_expired
      );
    });
  }
);
