import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";

import AgreementInformation from "./AgreementInformation";
import Authorize from "./Authorize";
import UploadBusinessDocument from "./UploadBusinessDocument";
import ShareholderForm from "./ShareholderForm";
import ConfirmShareholderAction from "./ConfirmShareholderAction";

function OnboardingModal() {
  return (
    <Routes>
      <Route
        element={<AgreementInformation />}
        path={routes.protected.onboarding.terms.agreement.index}
      />
      <Route
        element={<Authorize />}
        path={routes.protected.onboarding.terms.authorize.index}
      />
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.onboarding.document.get_started.index}
      />
      <Route
        element={<UploadBusinessDocument />}
        path={routes.protected.onboarding.document.update_doc.index}
      />
      <Route
        element={<ShareholderForm />}
        path={routes.protected.onboarding.shareholders.add.index}
      />
      <Route
        element={<ConfirmShareholderAction />}
        path={routes.protected.onboarding.shareholders.remove.index}
      />
    </Routes>
  );
}

export default OnboardingModal;
