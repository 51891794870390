import CustomCheckBox from "../CustomCheckBox";

type props = {
  selectAll: boolean;
  selectAllHandler?: () => void;
};

function TableHeaderCheckbox({ selectAll, selectAllHandler }: props) {
  return <CustomCheckBox checked={selectAll} onChange={selectAllHandler} />;
}

export default TableHeaderCheckbox;
