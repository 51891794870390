import React from "react";
import dayjs from "dayjs";

type Props = {
  date_data: string;
};

function Date({ date_data }: Props) {
  const res = dayjs(date_data)?.format("DD MMM. hh:mma");
  return <span className="block w-full">{date_data ? res : ""}</span>;
}

export default Date;
