import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../service";
import customToast from "../../../common/components/CustomToast";
import * as tp from "../types";
import { getInfoNew } from "../../dashboard/store/thunk";

export const createWallet = createAsyncThunk(
  "payment/createWallet",
  async (
    data: tp.IcreateWalletThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .createWallet(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const uploadKyc = createAsyncThunk(
  "payment/uploadKyc",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadKyc(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const registerMerchant = createAsyncThunk(
  "payment/registerMerchant",
  async (
    data: tp.IcreateMerchantThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .registerMerchant(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const payoutMerchant = createAsyncThunk(
  "payment/payoutMerchant",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .payoutMerchant(data)
      .then((res) => {
        customToast(res?.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const generateKey = createAsyncThunk(
  "payment/generateKey",
  async (
    data: tp.IgenerateSecretKeyThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .generateKey(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const toggleP2P = createAsyncThunk(
  "payment/toggleP2P",
  async (
    data: tp.ItoggleP2PThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .toggleP2P(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const getBankInfoFunding = createAsyncThunk(
  "payment/getBankInfoFunding",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getBankInfoFunding(id)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const getP2PTransactions = createAsyncThunk(
  "payment/getP2PTransactions",
  async (
    req: tp.IpaymentCategoryThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .getP2PTransactions(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getCollectionDetail = createAsyncThunk(
  "payment/getCollectionDetail",
  async (
    {
      user_id,
      txn_type,
      collection_id,
      bank_ref,
      currency,
      order_no,
    }: tp.IpaymentCollectionThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .getCollectionDetail(
        user_id,
        txn_type,
        collection_id,
        order_no,
        bank_ref,
        currency
      )
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const claimCollectionFunds = createAsyncThunk(
  "payment/claimCollectionFunds",
  async (
    { user_id, order_no, order_type, txn_type }: tp.IclaimCollectionThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .claimCollectionFunds(user_id, order_no)
      .then((res) => {
        customToast(res.message);
        dispatch(
          getP2PTransactions({
            user_id,
            txn_type,
            order_type,
            page: 1,
            limit: 10,
          })
        );
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const claimAllFunds = createAsyncThunk(
  "payment/claimAllFunds",
  async (req: tp.Iuser, { rejectWithValue, fulfillWithValue }) => {
    return service
      .claimAllFunds(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const autoClaim = createAsyncThunk(
  "payment/autoClaim",
  async (
    { id, status }: { id: string; status: boolean },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .autoClaim(id, status)
      .then((res) => {
        dispatch(getMerchantInfo(id));
        customToast(res?.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
// export const getTransactionByOrderNo = createAsyncThunk(
//   "payment/getTransactionByOrderNo",
//   async (
//     req: tp.ISearchTransactionOrderThunk,
//     { rejectWithValue, fulfillWithValue }
//   ) => {
//     return service
//       .getTransactionByOrderNo(req)
//       .then((res) => {
//         return fulfillWithValue(res.data).payload;
//       })
//       .catch((err) => {
//         customToast(err?.message, true);
//         return rejectWithValue(err);
//       });
//   }
// );
// export const filterTransactionByOrderNo = createAsyncThunk(
//   "payment/filterTransactionByOrderNo",
//   async (
//     req: tp.IFilterTransactionOrderThunk,
//     { rejectWithValue, fulfillWithValue }
//   ) => {
//     return service
//       .filterTransactionByOrderNo(req)
//       .then((res) => {
//         return fulfillWithValue(res.data).payload;
//       })
//       .catch((err) => {
//         customToast(err?.message, true);
//         return rejectWithValue(err);
//       });
//   }
// );

export const getMerchantInfo = createAsyncThunk(
  "payment/getMerchantInfo",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getMerchantInfo(id)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const getPartners = createAsyncThunk(
  "payment/getPartners",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getPartners(id)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const authorizePayment = createAsyncThunk(
  "payment/no-auth/authorizePayment",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .authorizePayment(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast(
          err?.message || "Payment can't be completed by at time.",
          true
        );
        console.error(err);
        return rejectWithValue(err);
      });
  }
);
export const isKenyaPhoneVerifiedOnRemit = createAsyncThunk(
  "payment/no-auth/isKenyaPhoneVerifiedOnRemit",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .isKenyaPhoneVerifiedOnRemit(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // console.error(err);
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const kenyaPaymentOtp = createAsyncThunk(
  "payment/no-auth/kenyaPaymentOtp",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .kenyaPaymentOtp(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // console.error(err);
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const verifyKenyaOtp = createAsyncThunk(
  "payment/no-auth/verifyKenyaOtp",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .verifyKenyatOtp(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // console.error(err);
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const ghanaPaymentOtp = createAsyncThunk(
  "payment/no-auth/ghanaPaymentOtp",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .ghanaPaymentOtp(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const paymentStatus = createAsyncThunk(
  "payment/no-auth/paymentStatus",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .paymentStatus(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const finalizeGhs = createAsyncThunk(
  "payment/no-auth/finalizeGhs",
  async (data: any, { rejectWithValue, fulfillWithValue }) => {
    return service
      .finalizeGhs(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const checkStatus = createAsyncThunk(
  "payment/no-auth/checkStatus",
  async (tk: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .checkStatus(tk)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const mobileMoneyInfo = createAsyncThunk(
  "payment/no-auth/mobileMoneyInfo",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    return service
      .mobileMoneyInfo()
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const resolveAccount = createAsyncThunk(
  "payment/no-auth/resolveAccount",
  async (data: tp.IresolveAccount, { rejectWithValue, fulfillWithValue }) => {
    return service
      .resolveAccount(data)
      .then((res) => {
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        // customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getAllBanks = createAsyncThunk(
  "payment/getAllBanks",
  async (data: tp.IallBanksThunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getAllBanks(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const resolveAccountByCurrency = createAsyncThunk(
  "payment/resolveAccountByCurrency",
  async (
    data: tp.IresolveAccountByCurrencyThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .resolveAccountByCurrency(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        return rejectWithValue(err);
      });
  }
);
export const paymentWithdrawal = createAsyncThunk(
  "payment/paymentWithdrawal",
  async (
    data: tp.IwithDrawalPaymentThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .paymentWithdrawal(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        return fulfillWithValue(res).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getFiatExchangeRate = createAsyncThunk(
  "payment/getFiatExchangeRate",
  async (
    data: tp.IexchangeQueryThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    return service
      .getFiatExchangeRate(data)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const swapFunds = createAsyncThunk(
  "payment/swapFunds",
  async (
    data: tp.IswapFundsThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    return service
      .swapFunds(data)
      .then((res) => {
        dispatch(getMerchantInfo(data.user_id));
        dispatch(getInfoNew(data.user_id));
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
