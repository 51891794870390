import routes from "../../../../common/routes";
import { downloadObjectAsCSV } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import { IcollectionOrderType } from "../../types";

const { layout: PageDictionary } = Language.protected.fiatPages;

export const filterHelper = (pathname: string): IcollectionOrderType => {
  let data: IcollectionOrderType = "abandoned";

  switch (true) {
    case pathname === routes.protected.fiat_wallet.overview.transactions.all:
      data = "all";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.abandoned:
      data = "abandoned";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.completed:
      data = "completed";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.expired:
      data = "expired";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.mismatched:
      data = "mismatched";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.pending:
      data = "pending";
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.claimed:
      data = "claimed";
      break;

    default:
      break;
  }

  return data;
};
export const exportClick = (
  pathname: string,
  collections: Record<IcollectionOrderType, { data: any[]; loading: boolean }>
) => {
  const { abandoned, completed, expired, mismatched, pending, claimed, all } =
    collections;

  let data: any[] = [];

  switch (true) {
    case pathname === routes.protected.fiat_wallet.overview.transactions.all:
      data = all.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.abandoned:
      data = abandoned.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.completed:
      data = completed.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.expired:
      data = expired.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.mismatched:
      data = mismatched.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.pending:
      data = pending.data;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.claimed:
      data = claimed.data;
      break;

    default:
      break;
  }

  downloadObjectAsCSV(
    data,
    `${PageDictionary.title} || ${new Date().toDateString()}`
  );
};

export const exportDisableHandler = (
  pathname: string,
  collections: Record<IcollectionOrderType, { data: any[]; loading: boolean }>
) => {
  const { abandoned, completed, expired, mismatched, pending, claimed, all } =
    collections;
  let val = false;

  switch (true) {
    case pathname === routes.protected.fiat_wallet.overview.transactions.all &&
      all.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.abandoned &&
      abandoned.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.completed &&
      completed.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.expired &&
      expired.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.mismatched &&
      mismatched.data.length === 0:
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.pending &&
      pending.data.length === 0:
      val = true;
      break;
    case pathname ===
      routes.protected.fiat_wallet.overview.transactions.claimed &&
      claimed.data.length === 0:
      val = true;
      break;

    default:
      val = false;
      break;
  }

  return val;
};
