import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { navComponents } from "../../../utils/helpers/navHelpers";
import logo from "../../../../static/images/coloredlogo.svg";
import routes from "../../../routes";
import {
  useAppDispatch,
  useAppSelector /* useEffectOnce */,
} from "../../../hooks";
// import { getMetrics } from "../../../../module/performance/store/thunk";
import { getBankInfoFunding } from "../../../../module/fiat/store/thunk";
import { tW } from "../../../utils/helpers";
import MultiUserId from "./MultiUserId";

type props = {
  className?: string;
  isPublic?: boolean;
};

function SideNav({ className, isPublic }: props) {
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const { permission, user_id } = useAppSelector((state) => state.auth.user);
  const { payment_config } = useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();

  // useEffectOnce(() => {
  //   dispatch(getMetrics(user_id));
  // });

  const navClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (disable) return;
    if (
      !e.currentTarget.href.includes("team") &&
      !e.currentTarget.href.includes("preview")
    ) {
      e.preventDefault();
    }
    if (e.currentTarget.href.includes("dashboard")) {
      navigate(routes.protected.dashboard.crypto);
    }
    if (e.currentTarget.href.includes("onboarding")) {
      navigate(routes.protected.onboarding.terms.link("business"));
    }
    if (e.currentTarget.href.includes("settings")) {
      navigate(routes.protected.settings.bank_setting.index);
    }
    if (e.currentTarget.href.includes("fiat")) {
      if (payment_config?.account_name === undefined) {
        setDisable(true);
        dispatch(getBankInfoFunding(user_id))
          .unwrap()
          .then((res) => {
            if (res.account_name) {
              navigate(
                routes.protected.fiat_wallet.overview.transactions.pending
              );
            } else {
              navigate(routes.protected.fiat_wallet.overview.index);
            }
          })
          .finally(() => setDisable(false));
      } else {
        navigate(routes.protected.fiat_wallet.overview.transactions.pending);
      }
    }
    if (e.currentTarget.href.includes("performance")) {
      navigate(routes.protected.performance.metric);
    }
    if (e.currentTarget.href.includes("pool")) {
      if (permission.includes("ACCEPT_REQUEST")) {
        navigate(routes.protected.pool.tray);
        return;
      }
      if (permission.includes("PROCESS_REQUEST")) {
        navigate(routes.protected.pool.pending_request);
        return;
      }
      if (permission.includes("ISSUES")) {
        navigate(routes.protected.pool.issues.index);
        return;
      }
    }
    if (e.currentTarget.href.includes("transaction")) {
      navigate(routes.protected.transactions.withdraw);
    }
    if (e.currentTarget.href.includes("crypto")) {
      navigate(routes.protected.crypto_wallet.capital);
    }
  };

  return (
    <nav
      // className={`${
      //   className ? className : ""
      // } grid w-64 grid-rows-[max-content_2.5rem_max-content_1.125rem_max-content] bg-white pt-11 shadow-side_nav`}
      className={tW(
        "grid w-64 grid-rows-[max-content_2.5rem_max-content_1.125rem_max-content] bg-white pt-11 pb-4 shadow-side_nav",
        disable ? "cursor-wait" : "cursor-default",
        className
      )}
    >
      <Link
        to={routes.protected.dashboard.crypto}
        className="row-start-1 row-end-2 flex h-9 items-center justify-center "
      >
        <img src={logo} alt="instance logo" className="h-10 w-max" />
        <span>
          <span className="font-bold">Intra</span>
          <span>Pay</span>
        </span>
      </Link>
      <div
        data-tut="instextour__nav-navigation"
        className="row-start-3 row-end-4 flex flex-col gap-y-1 pl-10 pr-2"
      >
        {navComponents
          .filter((nav) =>
            isPublic
              ? nav.name === "compliance"
              : nav.access
              ? nav.access.find((perm) => permission?.includes(perm))
              : nav
          )
          .map(({ icon, link, title, name }) => (
            <NavLink
              key={link}
              onClick={navClickHandler}
              to={link}
              data-tut={`instextour__nav-${name}`}
              className={({ isActive }) =>
                tW(
                  "flex items-center gap-x-6 pt-4 pb-5 pl-7 text-base leading-5 text-gray-10",
                  isActive &&
                    "before:content-[' '] relative rounded-2xl bg-blue_gray-10 text-white before:absolute before:left-[-2.5rem] before:top-0 before:bottom-0 before:h-full before:w-2 before:rounded-r-2xl before:bg-blue_gray-10",
                  disable && "cursor-wait"
                )
              }
            >
              {icon}
              <span>{title}</span>
            </NavLink>
          ))}
      </div>
      <MultiUserId className="row-start-6 row-end-7 select-none self-end text-center" />
    </nav>
  );
}

export default SideNav;
