import React from "react";
import Barcode from "react-barcode";
import { useEffectOnce } from "../../../../common/hooks";

type Props = {
  label: string;
  value: string;
  itmRef: React.LegacyRef<HTMLDivElement>;
  generateSignatureHandler: VoidFunction;
};

function CustomBarCode({
  label,
  value,
  itmRef,
  generateSignatureHandler,
}: Props) {
  useEffectOnce(() => {
    generateSignatureHandler();
  });

  return (
    <>
      <div ref={itmRef} className="w-full custom-barcode ">
        <Barcode
          textAlign="right"
          margin={0}
          fontSize={9}
          // height={44}
          // width={2}
          textPosition="center"
          value={label + " " + value}
          
        />
      </div>
    </>
  );
}

export default CustomBarCode;
