import React from "react";
import Banner from "../Banner";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
  usePageTitle,
} from "../../../../common/hooks";
import { Outlet, useNavigate } from "react-router-dom";
import Language from "../../../../common/utils/language/en";
import { getFiatExchangeRate } from "../../../fiat/store/thunk";
import routes from "../../../../common/routes";
import { dashboardNavlinks } from "../../../../common/utils/helpers/navHelpers";
import { toCurrency } from "../../../../common/utils/helpers";

const { DashboardPage: PageDictionary } = Language.protected;

function DashboardLayout() {
  usePageTitle(PageDictionary.pageTitle);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { currencies: countryCurrencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );

  const { exchangeInfo } = useAppSelector((state) => state.payment);

  useEffectOnce(() => {
    if (!countryCurrencies[0]) return;
    const fiat_currency = countryCurrencies[0];
    dispatch(
      getFiatExchangeRate({
        fiat_currency,
        type: "FROM_FIAT",
        user_id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          getFiatExchangeRate({
            fiat_currency,
            type: "TO_FIAT",
            user_id,
          })
        );
      });
  });

  // useEffect(() => {
  //   dispatch(getMerchantInfo(user_id));
  // }, [user_id, dispatch]);

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { href } = e.currentTarget;

    if (href.includes(routes.protected.fiat_wallet.overview.index)) {
      e.preventDefault();
      navigate(routes.protected.fiat_wallet.overview.transactions.pending);
    }
  };
  return (
    <>
      <Banner />
      <nav className="mt-1.5 flex items-end border-b border-gray-20 pt-9">
        <HorizontalNav
          onClick={clickHandler}
          navlinks={dashboardNavlinks}
          containerClassName="pb-0"
          className="border-transparent"
        />
        {!exchangeInfo.isloading ? (
          <div className="flex items-center gap-x-9">
            <div className="flex items-center gap-x-5">
              <p className="text-sm font-semibold text-gray-50">
                {PageDictionary.exchange_rate.usdt_to_fiat(
                  countryCurrencies[0]
                )}
              </p>
              <p className="text-base font-bold text-[#0075E2]">
                $1 ~ {toCurrency(exchangeInfo.usdt_to_localFiat)}
              </p>
            </div>
            <div className="flex items-center gap-x-5">
              <p className="text-sm font-semibold text-gray-50">
                {PageDictionary.exchange_rate.fiat_to_usdt(
                  countryCurrencies[0]
                )}
              </p>
              <p className="text-base font-bold text-[#FF0000]">
                $1 ~ {toCurrency(exchangeInfo.localFiat_to_usdt)}
              </p>
            </div>
          </div>
        ) : null}
      </nav>
      <Outlet />
    </>
  );
}

export default DashboardLayout;
