import { useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";

const { TrayLimit: PageDictionary } = Language.protected.PoolPages.TrayPage;
function TrayLimit() {
  const navigate = useNavigate();
  const gotoPR = () => {
    navigate(routes.protected.pool.pending_request, { replace: true });
  };

  return (
    <CenterModal className="w-[35rem] gap-y-12 rounded-14 text-blue_gray-10 ">
      <header className="flex flex-col items-center gap-y-7 font-bold ">
        <div className="rounded-3xl bg-brown-20 py-2.5 px-12">50 of 50</div>
        <h3>{PageDictionary.title}</h3>
      </header>
      <div className="flex flex-col items-center gap-y-8 text-center">
        <p>{PageDictionary.message}</p>
        <CustomButtom onClick={gotoPR} className="self-center">
          {PageDictionary.action}
        </CustomButtom>
      </div>
    </CenterModal>
  );
}

export default TrayLimit;
