import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  acceptTrayItem,
  fundPendingRequest,
  getCompletedRequests,
  getIssues,
  getJunkItems,
  getPendingRequests,
  getProcessingRequest,
  getResolutionThreadList,
  ignoreTrayItem,
  moveToJunk,
  uploadPaymentEvidence,
} from "./thunk";
import {
  IpendingItem,
  Ipool,
  IpoolItemType,
  ItrayItem,
  IupdatePendingRequestStatus,
  IpoolIssuesType,
  ImessageThreadItem,
  ModuleType,
} from "../types";
import { logoutUser } from "../../appState/store/thunk";
import { pageNumberUpdateHelper, updateTotalDataCountAndPages } from "./helper";
// import socket from "../service/socket";

const initialState: Ipool = {
  loading: false,
  error: null,
  tray: [],
  pending_requests: [],
  processing_requests: [],
  completed_requests: [],
  junk_request: [],
  issues: {
    list: [],
    thread: {
      isloading: false,
      txn_reference: "",
      data: [],
    },
  },
  pageination: {
    completed_requests: {
      info: {
        currentPage: 1,
        totalPages: 0,
        totalDataCount: 0,
        maxPageDataCount: 10,
      },
      data: [],
      isloading: false,
      filterState: {
        withdrawal: true,
        funding: true,
      },
    },
    issues: {
      info: {
        currentPage: 1,
        totalPages: 0,
        totalDataCount: 0,
        maxPageDataCount: 10,
      },
      data: [],
      isloading: false,
      filterState: {
        withdrawal: true,
        funding: true,
        dispute: true,
        new: true,
        resolved: true,
      },
    },
  },
};

const poolSlice = createSlice({
  name: "pool",
  initialState,
  reducers: {
    addTrayItem: (state, { payload }: PayloadAction<ItrayItem>) => {
      // const created_time = new Date().toISOString();
      const a = dayjs();
      const terminate_time = a.add(payload.dur, payload.dur_unit);

      if (state.tray.findIndex((itm) => itm.id === payload.id) === -1) {
        state.tray = [...state.tray, { ...payload, terminate_time }];
      }
    },
    removePoolItemByType: (
      state,
      { payload }: PayloadAction<{ id: string; type: ModuleType }>
    ) => {
      const { id, type } = payload;
      if (type === "tray") {
        const temp_tray = state.tray.filter((itm) => itm.id !== id);
        state.tray = [...temp_tray];
      }
      if (type === "pending_requests") {
        const temp_pend = state.pending_requests.filter((itm) => itm.id !== id);
        state.pending_requests = [...temp_pend];
      }
    },
    // removeAllPoolItemsByType: (
    //   state,
    //   { payload }: PayloadAction<ModuleType>
    // ) => {
    //   if (payload === "tray") {
    //     state.tray = initialState.tray;
    //   }
    //   if (payload === "pending_requests") {
    //     state.pending_requests = initialState.pending_requests;
    //   }
    // },
    addPendingItem: (state, { payload }: PayloadAction<ItrayItem>) => {
      const current_time = new Date().toISOString();
      const temp: IpendingItem = {
        ...payload,
        transaction:
          payload.type === "funding" ? undefined : payload.transaction,
        can_interact: false,
        updated_at: current_time,
        tray_request_status: "ACCEPTED",
        payment_evidence_url: "",
        terminate_time: dayjs(current_time).add(5, "minute"),
      };
      state.pending_requests = [...state.pending_requests, temp];
    },
    updatePoolItemStatus: (
      state,
      { payload }: PayloadAction<IupdatePendingRequestStatus>
    ) => {
      const other_pending = state.pending_requests.filter(
        (itm) => itm.id !== payload.tray_id
      );
      const new_processing = state.pending_requests
        .filter((itm) => itm.id === payload.tray_id)
        .map((itm) =>
          itm.id === payload.tray_id &&
          payload.notification_type === "I_HAVE_PAID_IN_FUNDING"
            ? {
                ...itm,
                can_interact: true,
                payment_evidence_url: payload.payment_evidence,
              }
            : itm
        );
      state.pending_requests = [...other_pending];
      state.processing_requests = [
        ...new_processing,
        ...state.processing_requests,
      ];
    },
    toggleFilterStateCR: (state, { payload }: PayloadAction<IpoolItemType>) => {
      state.pageination.completed_requests.filterState[payload] =
        !state.pageination.completed_requests.filterState[payload];

      const { funding, withdrawal } =
        state.pageination.completed_requests.filterState;
      let temp: any;
      switch (true) {
        case funding && !withdrawal:
          temp = state.completed_requests.filter(
            (reqItm) => reqItm.type === "funding"
          );
          break;
        case !funding && withdrawal:
          temp = state.completed_requests.filter(
            (reqItm) => reqItm.type === "withdrawal"
          );
          break;
        case !funding && !withdrawal:
          temp = state.completed_requests.filter(
            (reqItm) =>
              reqItm.type !== "withdrawal" && reqItm.type !== "funding"
          );
          break;
        case funding && withdrawal:
          temp = state.completed_requests.filter(
            (reqItm) =>
              reqItm.type === "withdrawal" || reqItm.type === "funding"
          );
          break;

        default:
          break;
      }

      state.pageination.completed_requests.data = [...temp];
      updateTotalDataCountAndPages(state.pageination.completed_requests, temp);
    },
    updatePageNumberCRequests: (state, { payload }: PayloadAction<number>) => {
      pageNumberUpdateHelper(
        state.pageination.completed_requests,
        state.completed_requests,
        payload
      );
    },
    toggleFilterStateIssues: (
      state,
      { payload }: PayloadAction<IpoolIssuesType>
    ) => {
      state.pageination.issues.filterState[payload] =
        !state.pageination.issues.filterState[payload];

      const {
        funding,
        withdrawal,
        dispute,
        new: newissue,
        resolved,
      } = state.pageination.issues.filterState;
      let temp: any[] = [];
      if (funding && !withdrawal && !dispute && !newissue && !resolved) {
        temp = state.issues.list.filter((reqItm) => reqItm.type === "funding");
      }
      if (!funding && withdrawal && !dispute && !newissue && !resolved) {
        temp = state.issues.list.filter(
          (reqItm) => reqItm.type === "withdrawal"
        );
      }
      if (!funding && !withdrawal && dispute && !newissue && !resolved) {
        temp = state.issues.list.filter((reqItm) => reqItm.type === "dispute");
      }
      if (!funding && !withdrawal && !dispute && newissue && !resolved) {
        temp = state.issues.list.filter((reqItm) => reqItm.type === "new");
      }
      if (!funding && !withdrawal && !dispute && !newissue && resolved) {
        temp = state.issues.list.filter((reqItm) => reqItm.type === "resolved");
      }
      if (!funding && !withdrawal && !dispute && !newissue && !resolved) {
        temp = state.issues.list.filter(
          (reqItm) =>
            reqItm.type !== "resolved" &&
            reqItm.type !== "new" &&
            reqItm.type !== "dispute" &&
            reqItm.type !== "withdrawal" &&
            reqItm.type !== "funding"
        );
      }
      if (funding && withdrawal && dispute && newissue && resolved) {
        temp = state.issues.list.filter(
          (reqItm) =>
            reqItm.type === "resolved" ||
            reqItm.type === "new" ||
            reqItm.type === "dispute" ||
            reqItm.type === "withdrawal" ||
            reqItm.type === "funding"
        );
      }

      state.pageination.issues.data = [...temp];
      updateTotalDataCountAndPages(state.pageination.issues, temp);
    },
    updatePageNumberIssues: (state, { payload }: PayloadAction<number>) => {
      // pageNumberUpdateHelper(state.pageination.issues, state.issues, payload);
    },
    resetIssuesThread: (state) => {
      state.issues.thread = { ...initialState.issues.thread };
    },
    updateUnreadMessageCountWithTxnRef: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      const temp = state.issues.list.map((itm) =>
        itm.txn_reference === payload
          ? { ...itm, message_count: itm.message_count + 1 }
          : itm
      );
      state.issues.list = [...temp];
    },
    addIssueToThread: (
      state,
      { payload }: PayloadAction<ImessageThreadItem>
    ) => {
      state.issues.thread.data = [payload, ...state.issues.thread.data];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getPendingRequests.fulfilled,
        (state, { payload }: PayloadAction<any[]>) => {
          const temp = payload.map((itm) => ({
            ...itm,
            can_interact: false,
            tray_exchange_rate: itm.exchange_rate,
            terminate_time: dayjs(itm.updated_at).add(5, "minute"),
          }));
          state.pending_requests = [...temp];
        }
      )
      .addCase(
        getProcessingRequest.fulfilled,
        (state, { payload }: PayloadAction<IpendingItem[]>) => {
          const temp = payload.map((itm) => ({
            ...itm,
            // can_interact: true,
            // terminate_time: dayjs(itm.updated_at).add(5, "minute"),
          }));
          state.processing_requests = [...temp];
        }
      )
      .addCase(moveToJunk.fulfilled, (state, { payload, meta }) => {
        const tempPending = state.pending_requests.find(
          (itm) => itm.id.toString() === meta.arg.tray_id
        );
        const tempProccess = state.processing_requests.find(
          (itm) => itm.id.toString() === meta.arg.tray_id
        );

        if (tempPending) {
          state.pending_requests = state.pending_requests.filter(
            (item) => item.id.toString() !== meta.arg.tray_id
          );
          state.junk_request.push(tempPending);
        }
        if (tempProccess) {
          state.processing_requests = state.processing_requests.filter(
            (item) => item.id.toString() !== meta.arg.tray_id
          );
          state.junk_request.push(tempProccess);
        }
      })
      .addCase(
        getJunkItems.fulfilled,
        (state, { payload } /* : PayloadAction<IpendingItem[]> */) => {
          const temp = payload.map((itm: any) => ({
            ...itm,
            dur: 0,
            // can_interact: true,
            // terminate_time: dayjs(itm.updated_at).add(5, "minute"),
          }));
          state.junk_request = [...temp];
        }
      )
      .addCase(getCompletedRequests.fulfilled, (state, { payload }) => {
        const temp = payload.sort((a: any, b: any) =>
          b?.updated_at?.localeCompare(a?.updated_at)
        );
        state.completed_requests = [...temp];
        updateTotalDataCountAndPages(
          state.pageination.completed_requests,
          payload
        );
        pageNumberUpdateHelper(
          state.pageination.completed_requests,
          state.completed_requests
        );
      })
      .addCase(
        fundPendingRequest.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          const temp = state.processing_requests.filter(
            (req) => req.id !== payload
          );
          state.processing_requests = [...temp];
        }
      )
      .addCase(
        uploadPaymentEvidence.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          const temp = state.pending_requests.filter(
            (req) => req.id !== payload
          );
          state.pending_requests = [...temp];
        }
      )
      .addCase(
        getIssues.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.issues.list = [...payload];
          updateTotalDataCountAndPages(state.pageination.issues, payload);
          // pageNumberUpdateHelper(state.pageination.issues, state.issues);
        }
      )
      .addCase(getResolutionThreadList.fulfilled, (state, action) => {
        state.issues.thread.data = [...action.payload];
        state.issues.thread.txn_reference = action.meta.arg.txn_ref;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        // if (socket.connected) {
        //   socket.disconnect();
        // }
        // (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
        //   state[key] = initialState[key];
        // });
      })
      .addMatcher(isAnyOf(getIssues.pending), (state) => {
        state.pageination.issues.isloading = true;
      })
      .addMatcher(isAnyOf(getResolutionThreadList.pending), (state) => {
        state.issues.thread.isloading = true;
      })
      .addMatcher(
        isAnyOf(
          getResolutionThreadList.fulfilled,
          getResolutionThreadList.rejected
        ),
        (state) => {
          state.issues.thread.isloading = false;
        }
      )
      .addMatcher(isAnyOf(getCompletedRequests.pending), (state) => {
        state.pageination.completed_requests.isloading = true;
      })
      .addMatcher(isAnyOf(getIssues.fulfilled, getIssues.rejected), (state) => {
        state.pageination.issues.isloading = false;
      })
      .addMatcher(
        isAnyOf(getCompletedRequests.fulfilled, getCompletedRequests.rejected),
        (state) => {
          state.pageination.completed_requests.isloading = false;
        }
      )

      .addMatcher(
        isAnyOf(acceptTrayItem.fulfilled, ignoreTrayItem.fulfilled),
        (state, { payload }: PayloadAction<string>) => {
          const temp = state.tray.filter((trayItem) => trayItem.id !== payload);
          state.tray = [...temp];
        }
      )
      .addMatcher(
        isAnyOf(
          acceptTrayItem.pending,
          ignoreTrayItem.pending,
          getPendingRequests.pending,
          fundPendingRequest.pending,
          getProcessingRequest.pending,
          uploadPaymentEvidence.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          acceptTrayItem.fulfilled,
          acceptTrayItem.rejected,
          ignoreTrayItem.fulfilled,
          ignoreTrayItem.rejected,
          getPendingRequests.fulfilled,
          getPendingRequests.rejected,
          fundPendingRequest.fulfilled,
          fundPendingRequest.rejected,
          getProcessingRequest.fulfilled,
          getProcessingRequest.rejected,
          uploadPaymentEvidence.fulfilled,
          uploadPaymentEvidence.rejected
        ),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          acceptTrayItem.rejected,
          ignoreTrayItem.rejected,
          getPendingRequests.rejected,
          fundPendingRequest.rejected,
          getProcessingRequest.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          acceptTrayItem.fulfilled,
          acceptTrayItem.pending,
          ignoreTrayItem.fulfilled,
          ignoreTrayItem.pending,
          getPendingRequests.fulfilled,
          getPendingRequests.pending,
          fundPendingRequest.fulfilled,
          fundPendingRequest.pending,
          getProcessingRequest.fulfilled,
          getProcessingRequest.pending
        ),
        (state) => {
          if (state.error === true) {
            state.error = null;
          }
        }
      );
  },
});

export const poolActions = poolSlice.actions;

export default poolSlice.reducer;
