import React from "react";
import Language from "../../../../common/utils/language/en";
import CustomToggle from "../../../../common/components/CustomToggle";
import { LinkIcon } from "../../../../static/Icons";
import CustomInput from "../../../../common/components/CustomInput";

const { apiKeysTab: PageDictionary } =
  Language.protected.SettingsPages.AppsModal;

function AppKeys() {
  return (
    <div className="p-8">
      <CustomToggle
        className="!flex flex-row-reverse items-center justify-end gap-x-8 "
        lclassName="!text-black text-xs"
        label={PageDictionary.liveKeys}
      />
      <p className="text-xs pt-6">{PageDictionary.textLabel}</p>
      <div className="flex flex-col gap-y-10 pt-8" >
        <div>
          <p className="text-xs">{PageDictionary.encryptPublicKey}</p>
          <a
            href="https://intrapay.instance.finance"
            rel="noreferrer"
            target="_blank"
            className="inline-flex items-center gap-x-2.5"
          >
            <span className="text-blue-20 underline">www.externalink.com</span>
            <LinkIcon />
          </a>
        </div>
        <div>
          <p className="text-xs">{PageDictionary.encryptPrivateKey}</p>
          <a
            href="https://intrapay.instance.finance"
            rel="noreferrer"
            target="_blank"
            className="inline-flex items-center gap-x-2.5"
          >
            <span className="text-blue-20 underline">www.externalink.com</span>
            <LinkIcon />
          </a>
        </div>
        <CustomInput lclassName="text-xs" label={PageDictionary.hashLiveKey} />
      </div>
    </div>
  );
}

export default AppKeys;
