import { Outlet } from "react-router-dom";
import PerformanceWrapper from "../../module/performance/components/Layout";
import PoolWrapper from "../../module/pool/components/Layout";
import SettingsWrapper from "../../module/settings/components/Layout";
import PermissionGuard from "../components/Layout/PermissionGuard";
import CryptoLayout from "../../module/crypto/component/Layout";
import Fiat from "../../module/fiat/components/Layout";
import OnboardLayout from "../../module/compliance/components/Layout";
import DashboardLayout from "../../module/dashboard/components/Layout";
import { IgenLayout } from "./types";

export const GenerateLayout = ({ layout, perm }: IgenLayout): JSX.Element => {
  let template: any = null;
  switch (layout) {
    case "dashboard":
      template = <DashboardLayout />;
      break;
    case "performance":
      template = <PerformanceWrapper />;
      break;
    case "pool":
      template = (
        <PermissionGuard previledge={perm}>
          <PoolWrapper />
        </PermissionGuard>
      );
      break;
    case "setting":
      template = <SettingsWrapper />;
      break;
    case "team":
      template = (
        // <PermissionGuard previledge="ADMIN">
        <PermissionGuard previledge={perm}>
          <Outlet />;
        </PermissionGuard>
      );
      break;

    case "crypto":
      template = <CryptoLayout />;
      break;
    case "onboarding":
      template = <OnboardLayout />;
      break;
    case "fiat":
      template = <Fiat />;
      break;

    default:
      template = (
        <>
          <Outlet />
        </>
      );
      break;
  }
  return <>{template}</>;
};
