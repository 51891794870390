import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";

const service = {
  async getCapitalHistory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.crypto_wallet.capital;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getCommissionHistory(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.crypto_wallet.commission;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
