import React, { useEffect, useState } from "react";
import Language from "../../../../../common/utils/language/en";
import KycLayout from "../../../components/KycLayout";
import { iHandleChange, iHandleSubmit } from "../../../types";
import {
  Select,
  SelectItem,
} from "../../../../../common/components/CustomSelect";
import CustomInput from "../../../../../common/components/CustomInput";
import CustomUpload from "../../../../../common/components/CustomUpload";
import CustomButton from "../../../../../common/components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import { uploadKyc } from "../../../store/thunk";
import routes from "../../../../../common/routes";

const { submit: PageDictionary } = Language.protected.fiatModals.kyc;
const { business_type_list: industryList } =
  Language.protected.compalinacePages.business_profile.data;

const initialstate = {
  merchant_type: "individual",
  business_name: "",
  business_industry: "",
  merchant_name: "",
  merchant_industry: "",
};

const business_docs = {
  id_card_url: "",
  cac_url: "",
  proof_of_address_url: "",
  bank_statement_url: "",
};
const individual_docs = {
  gov_approve_id_card_url: "",
  proof_of_address_url: "",
  bank_statement_url: "",
};
const businessDocsKeys = Object.keys(business_docs) as Array<
  keyof typeof business_docs
>;
const individualDocsKeys = Object.keys(individual_docs) as Array<
  keyof typeof individual_docs
>;

function SubmitKyc() {
  const [data, setData] = useState(initialstate);
  const [fileData, setFileData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const selectHandler = (key: string, value: string) => {
    if (key === "merchant_type") {
      setData({ ...initialstate, [key]: value });
      setFileData({});
    } else {
      setData((prev) => ({ ...prev, [key]: value }));
    }
  };
  const mainDataKeys = Object.keys(data) as Array<keyof typeof data>;

  const closeHandler = () => {
    if (isLoading) return;
    navigate(state.background, { replace: true });
  };

  const handleSubmit: iHandleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const inputs = { ...data, ...fileData, user_id };

    dispatch(uploadKyc(inputs))
      .unwrap()
      .then(() => {
        navigate(routes.protected.fiat_wallet.kyc.review, {
          state: { background: state.background },
          replace: true,
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <KycLayout closeHandler={closeHandler}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-1 flex-col gap-y-5 pt-9 pb-20"
      >
        {mainDataKeys.map((itm) =>
          itm === "merchant_type" ? (
            <Select
              key={itm}
              label={PageDictionary.form.merchant_type_label}
              onChange={(val) => selectHandler(itm, val)}
              value={data.merchant_type}
              labelClassName="text-base-2 mb-2 inline-block text-base font-medium"
              className="border-blue_gray-300 h-[3.75rem] w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
              contentClassName="!p-0 z-50"
            >
              <SelectItem value="individual">Individual</SelectItem>
              <SelectItem value="business">Business</SelectItem>
            </Select>
          ) : null
        )}
        {mainDataKeys.map((itm) =>
          (data.merchant_type === "individual" && itm === "merchant_name") ||
          (data.merchant_type === "business" && itm === "business_name") ? (
            <CustomInput
              key={itm}
              onChange={changeHandler}
              name={itm}
              value={data[itm]}
              label={PageDictionary.form[itm]}
            />
          ) : null
        )}
        {mainDataKeys.map((itm) =>
          (data.merchant_type === "individual" &&
            itm === "merchant_industry") ||
          (data.merchant_type === "business" && itm === "business_industry") ? (
            <Select
              key={itm}
              label={PageDictionary.form[itm]}
              onChange={(val) => selectHandler(itm, val)}
              value={data[itm]}
              labelClassName="text-base-2 mb-2 inline-block text-base font-medium"
              className="border-blue_gray-300 h-[3.75rem] w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
              contentClassName="!p-0 z-50"
            >
              {industryList.map((listItm: string) => (
                <SelectItem key={listItm} value={listItm}>
                  {listItm}
                </SelectItem>
              ))}
            </Select>
          ) : null
        )}
        {data.merchant_type === "business"
          ? businessDocsKeys.map((itm) => (
              <CustomUpload
                key={`${itm}-business`}
                setState={setFileData}
                label={PageDictionary.form.business[itm]}
                name={itm}
                value={fileData[itm]}
                disabled={isLoading}
              />
            ))
          : individualDocsKeys.map((itm) => (
              <CustomUpload
                key={`${itm}-individual`}
                setState={setFileData}
                label={PageDictionary.form.individual[itm]}
                name={itm}
                value={fileData[itm]}
                disabled={isLoading}
              />
            ))}

        <div className="rounded-xl bg-[#F7E7DE] px-9 pt-3 pb-7 text-blue_gray-40">
          <h6 className="text-base font-bold">{PageDictionary.cta.title}</h6>
          <p className="text-1016">{PageDictionary.cta.subtitle}</p>
        </div>
        <div className="mt-9 grid flex-1 items-end justify-start ">
          <CustomButton
            className="p-3.5"
            isloading={isLoading}
            disabled={
              (data.merchant_type === "individual" &&
                (Object.values(fileData).filter((itm) => itm !== "").length !==
                  3 ||
                  data.merchant_name === "" ||
                  data.merchant_industry === "")) ||
              (data.merchant_type === "business" &&
                (Object.values(fileData).filter((itm) => itm !== "").length !==
                  4 ||
                  data.business_name === "" ||
                  data.business_industry === ""))
            }
          >
            {PageDictionary.form.save_btn}
          </CustomButton>
        </div>
      </form>
    </KycLayout>
  );
}

export default SubmitKyc;
