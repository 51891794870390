import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCountDown,
  useEffectOnce,
} from "../../../../../common/hooks";
import dayjs from "dayjs";
import Language from "../../../../../common/utils/language/en";
import { iHandleChange, iHandleClick, iHandleSubmit } from "../../../types";
import {
  removeCommas,
  removeNonNumeric,
} from "../../../../../common/utils/helpers";
import { getCsrfToken, getOtp } from "../../../../auth/store/thunk";
import CustomInput from "../../../../../common/components/CustomInput";
import CustomButton from "../../../../../common/components/CustomButton";
import ResendCodeCounter from "../../../../auth/components/ResendCodeCounter";
import { swapFunds } from "../../../store/thunk";

type Istage = "form" | "otp" | "finish";

type Props = {
  from_currency: string;
  to_currency: string;
  amount: string;
  setStage: React.Dispatch<React.SetStateAction<Istage>>;
};

const { enterOtpPage: PageDictionary } = Language.nonprotected;

function OtpForm({ setStage, ...props }: Props) {
  // const loading = useAppSelector((state) => state.auth.loading);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { email, user_id } = useAppSelector((state) => state.auth.user);

  const {
    count: time1,
    counter: counter1,
    start: startCountDown1,
    reset: resetCountDown1,
  } = useCountDown(90);

  const startTime = dayjs(dayjs().add(90, "second").toISOString())?.diff(
    dayjs(),
    "second"
  );

  const { count, counter, start } = useCountDown(startTime);

  const [otp_code, setOtp] = useState("");

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setOtp(removeNonNumeric(value));
  };

  const resendCode: iHandleClick = () => {
    if (loading) return;
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res) => {
        dispatch(getOtp({ email, csrf_token: res }))
          .unwrap()
          .then(() => {
            resetCountDown1();
            startCountDown1();
          });
      });
  };

  useEffectOnce(() => {
    if (startTime > 0 && start !== undefined) {
      start();
    }
  });

  const handlerDisable =
    (counter > 0 && counter1 === 90) || (counter1 > 0 && counter1 < 89);

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (!otp_code) return;
    const req = {
      ...props,
      otp_code,
      user_id,
      amount: removeCommas(props.amount),
    };
    // console.log(req);
    setLoading(true);
    dispatch(swapFunds(req))
      .unwrap()
      .then(() => {
        setStage("finish");
      })
      .catch((err) => {
        setStage("form");
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="">
      <div className="flex flex-col  gap-y-8  px-8 py-20">
        <h3 className="text-2xl font-bold ">Enter Otp</h3>
        <form className="flex flex-col gap-y-4 " onSubmit={submitHandler}>
          <CustomInput
            name="otp_code"
            type="text"
            label={PageDictionary.otpLabel}
            value={otp_code}
            onChange={changeHandler}
          />

          <div className="mt-3.5 flex flex-col items-start gap-y-5">
            <CustomButton
              isloading={loading}
              disabled={otp_code.length <= 6}
              className="h-11 w-40"
            >
              {PageDictionary.submitAction}
            </CustomButton>
          </div>
        </form>

        <button
          onClick={resendCode}
          disabled={handlerDisable}
          className="mt-16 flex cursor-pointer items-center gap-x-5 text-base font-medium text-gray-100 disabled:cursor-not-allowed"
        >
          {PageDictionary.resendCode}
          {counter > 0 ? (
            <ResendCodeCounter
              minutes={count.minutes}
              seconds={count.seconds}
            />
          ) : null}
          {counter <= 0 && counter1 !== 90 && counter1 > 0 ? (
            <ResendCodeCounter
              minutes={time1.minutes}
              seconds={time1.seconds}
            />
          ) : null}
        </button>
      </div>
    </div>
  );
}

export default OtpForm;
