import React from "react";
import { HiChevronDown, HiUserCircle } from "react-icons/hi";
import { useAppSelector } from "../../../../hooks/redux";
import { formatPermissionName } from "../../../../utils/helpers";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "../../../CustomDropDown";

function TeamDropDown() {
  const { first_name, permission } = useAppSelector((state) => state.auth.user);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex cursor-pointer items-center gap-x-2 outline-none">
          <HiUserCircle size="2rem" />
          <p className="text-base font-black leading-[1.1875rem]">
            {first_name}
          </p>
          <HiChevronDown className="ml-1" />
        </button>
      </DropdownMenuTrigger>
      <DropDownMenuContent className="z-10 mt-2 flex w-36 flex-col gap-y-2.5 rounded-lg border border-gray-250 bg-white px-4 pt-3.5 pb-6 font-bold text-blue_gray-10 shadow-team_header">
        {permission?.map((itm) => (
          <DropDownItem key={itm}>
            <div className="rounded-lg bg-brown-20 px-2 py-1 text-[0.75rem] font-bold capitalize text-blue_gray-10">
              {formatPermissionName(itm)}
            </div>
          </DropDownItem>
        ))}
      </DropDownMenuContent>
    </DropdownMenu>
  );
}

export default TeamDropDown;
