import React, { useMemo } from "react";
import { findFlagUrlByCountryName } from "country-flags-svg";
import { tW } from "../../../../utils/helpers";

function CountryFlag({ name, className }) {
  const flag = useMemo(
    () => (name ? findFlagUrlByCountryName(name) : ""),
    [name]
  );

  return (
    <>
      {name ? (
        <h4
          className={tW(
            "flex cursor-pointer items-center gap-x-5 py-1 px-6 text-sm font-bold leading-3 text-blue_gray-10",
            className
          )}
        >
          <img src={flag} className="h-6 w-6" alt={`${name}'s flag`} /> {name}
        </h4>
      ) : null}
    </>
  );
}

export default CountryFlag;
