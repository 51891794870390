import { AiOutlineCalendar } from "react-icons/ai";
import {
  useAppDispatch,
  useAppSelector,
  useScrollToTop,
} from "../../../../common/hooks";
import {
  dateHandler,
  greetHandler,
  toDollar,
  toCurrency,
} from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import HeaderCard from "../../../../common/components/Cards/HeaderCard";
import { useState } from "react";
import { getBankInfoFunding } from "../../../fiat/store/thunk";
import { useNavigate } from "react-router-dom";
import routes from "../../../../common/routes";

const { banner: PageDictionary } = Language.protected.DashboardPage;

const day = new Date();

function Banner() {
  const { titleRef } = useScrollToTop();
  const { capital_wallet, commission_wallet } = useAppSelector(
    (state) => state.dashboard.home
  );
  const { wallet_balance: local_wallet, payment_config } = useAppSelector(
    (state) => state.payment
  );
  const [disable, setDisable] = useState(false);
  const { user_id } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fiatCardClickHandler = () => {
    if (disable) return;
    if (payment_config?.account_name) {
      setDisable(true);
      dispatch(getBankInfoFunding(user_id))
        .unwrap()
        .then((res) => {
          if (res.account_name) {
            navigate(routes.protected.fiat_wallet.overview.transactions.pending);
          } else {
            navigate(routes.protected.fiat_wallet.overview.index);
          }
        })
        .finally(() => setDisable(false));
    } else {
      navigate(routes.protected.fiat_wallet.overview.transactions.pending);
    }
  };

  const cyptoCardClickHandler = () => {
    navigate(routes.protected.crypto_wallet.capital);
  };

  return (
    <div
      ref={titleRef}
      className="relative  col-span-full flex items-center justify-between rounded-md bg-blue_gray-10 px-9 pt-6 pb-4.5 text-white"
    >
      <div>
        <div className="flex items-center gap-x-1.5">
          <AiOutlineCalendar />
          <p className="text-[0.875rem] leading-[1.05rem] ">
            {dateHandler(day)}
          </p>
        </div>
        <h3 className="text-[2rem] font-bold leading-[2.4rem]">
          {`${greetHandler(day, PageDictionary.greeting)}`}
        </h3>
      </div>
      <div className="flex items-center gap-x-12">
        <HeaderCard
          type={PageDictionary.fiat}
          data={toCurrency(local_wallet)}
          clickHandler={fiatCardClickHandler}
        />
        <HeaderCard
          data_tut="instextour__dashboard-wallet"
          type={PageDictionary.capital}
          data={toDollar(capital_wallet)}
          clickHandler={cyptoCardClickHandler}
        />
        <HeaderCard
          type={PageDictionary.commission}
          data={toDollar(commission_wallet)}
          clickHandler={cyptoCardClickHandler}
        />
      </div>
    </div>
  );
}

export default Banner;
