import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";
import { tW } from "../../../../../common/utils/helpers";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import { getCollectionDetail } from "../../../store/thunk";
import CustomButton from "../../../../../common/components/CustomButton";

type Props = {
  id: string;
  order_type: any;
  collection_id: string;
  bank_ref: string;
  currency: string;
  order_no: string;
};

function ViewInformation({
  id,
  collection_id,
  order_type,
  bank_ref,
  currency,
  order_no,
}: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pagination } = useAppSelector((state) => state.payment);
  const { user_id } = useAppSelector((state) => state.auth.user);
  const isCollection = location.pathname.includes(
    routes.protected.fiat_wallet.overview.transactions.index
  );
  const [loading, setLoading] = useState(false);

  const clickHandler = () => {
    let path = "";
    if (isCollection) {
      path = routes.protected.fiat_wallet.overview.transactions.summary;
      setLoading(true);
      dispatch(
        getCollectionDetail({
          user_id,
          collection_id,
          txn_type: "collection",
          order_type,
          bank_ref,
          currency,
          order_no,
          page: 1,
          limit: pagination.limit,
        })
      )
        .unwrap()
        .then((res) => {
          navigate(path, {
            state: { background: location, data: { id, data: res } },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      path = routes.protected.fiat_wallet.overview.withdrawal.withdrawal_info;
      navigate(path, { state: { background: location, data: id } });
    }
  };
  return (
    <CustomButton
      onClick={clickHandler}
      className={tW(
        "flex w-20  items-center justify-center rounded-lg  p-3 text-xs ",
        isCollection
          ? "bg-[#0075E25E] text-[#3E4244]"
          : "bg-green-400/25 text-green-40"
      )}
      // disabled={isCollection}
      isloading={loading}
    >
      View
    </CustomButton>
  );
}

export default ViewInformation;
