import React from "react";
import { tW } from "../../../../../common/utils/helpers";

type Props = {
  data: any;
};

function SettlementAmount({ data }: Props) {
  return (
    <div className="flex items-center justify-center font-bold">
      <span
        className={tW(
          "cursor-pointer rounded-lg px-3 py-1.5 text-white",
          data.insurance === "Released" ? "bg-green-400" : "bg-brown-10"
        )}
      >
        {data.insurance}
      </span>
    </div>
  );
}

export default SettlementAmount;
