import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import { iHandleChange, iHandleSubmit } from "../../types";
import CustomButton from "../../../../common/components/CustomButton";
import ResendCodeCounter from "../../../auth/components/ResendCodeCounter";
import {
  useAppDispatch,
  useAppSelector,
  useCountDown,
  useEffectOnce,
} from "../../../../common/hooks";
import { validationRules } from "../../../../common/utils/helpers";
import { addSettlementWallet } from "../../store/thunk";
import { getCsrfToken, getOtp } from "../../../auth/store/thunk";

const initialState = {
  address: "",
  otp_code: "",
  pay_percentage: "",
};
const initialError = {
  address: false,
  otp_code: false,
  pay_percentage: false,
};

function AddAddress() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const isDashboard = state.title === "settlement";
  const {
    count: time,
    counter,
    start: startCountDown,
    reset,
  } = useCountDown(120);
  const [inData, setInData] = useState(initialState);
  const [inError, setInError] = useState(initialError);
  const dispatch = useAppDispatch();
  const { user_id, email } = useAppSelector((state) => state.auth.user);
  const [isloading, setIsloading] = useState(false);

  const isDisabledDashboard =
    isDashboard &&
    (Object.values(inData).includes("") ||
      Object.values(inError).includes(true));
  const isDisabledApi =
    !isDashboard &&
    (inData.address === "" ||
      inData.otp_code === "" ||
      inError.address === true ||
      inError.otp_code === true);

  useEffectOnce(() => {
    startCountDown();
  });

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
    const rules = e.target.getAttribute("data-rules");
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  };

  const resendOtpHandler = () => {
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .catch(() => setIsloading(false))
      .then((res) => {
        dispatch(getOtp({ email, csrf_token: res ? res : "" }))
          .unwrap()
          .then(() => {
            reset();
            startCountDown();
          })
          .finally(() => setIsloading(false));
      });
  };

  const closeModal = () => {
    if (isloading) return;
    navigate(state?.background?.pathname, { replace: true });
  };
  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    dispatch(
      addSettlementWallet({
        user_id,
        data: {
          ...inData,
          pay_percentage: isDashboard ? inData.pay_percentage : "100",
          filter: isDashboard ? "wallet_address" : "ip_address",
        },
      })
    )
      .unwrap()
      .finally(() => {
        setIsloading(false);
        closeModal();
      });
  };
  return (
    <SideModal className="px-14 pt-24" clickBackDrop={closeModal}>
      <header>
        <h4 className="pb-2 pl-5 text-2xl font-bold leading-6.5 text-blue_gray-10">
          {isDashboard ? "Add Settlement Address" : "Add IP Address"}
        </h4>
      </header>
      <form
        className="flex flex-col gap-y-5 border-t border-gray-20 pt-12 pl-5 pr-11"
        onSubmit={submitHandler}
      >
        <CustomInput
          label={isDashboard ? "Add Address" : "Add IP Address"}
          name="address"
          value={inData.address}
          onChange={changeHandler}
          rules={isDashboard ? validationRules.string : validationRules.ip}
          haserror={inError.address.toString()}
        />

        {isDashboard ? (
          <CustomInput
            label="Split Percentage"
            name="pay_percentage"
            value={inData.pay_percentage}
            className="hover:appearance-none"
            required
            type="number"
            max={100}
            onChange={changeHandler}
            rules={validationRules.number}
            haserror={inError.pay_percentage.toString()}
          />
        ) : null}

        <div>
          <div className="flex flex-col gap-x-3">
            <p>Enter OTP </p>
            <p>
              Input the one time password sent to your email verify your email
            </p>

            <div className="mt-3.5 flex items-center gap-x-2 ">
              <CustomInput
                name="otp_code"
                type="text"
                containerClassName="flex-1"
                lclassName="hidden"
                value={inData.otp_code}
                onChange={changeHandler}
                rules={validationRules.number}
                haserror={inError.otp_code.toString()}
              />
            </div>
            <button
              disabled={counter > 0}
              onClick={resendOtpHandler}
              type="button"
              className="flex cursor-pointer items-center gap-x-5 text-base font-medium text-gray-100 disabled:cursor-not-allowed"
            >
              Didn’t get code? Resend
              {counter ? (
                <ResendCodeCounter
                  minutes={time.minutes}
                  seconds={time.seconds}
                />
              ) : null}
            </button>
          </div>
        </div>

        <CustomButton
          disabled={isDisabledDashboard || isDisabledApi}
          isloading={isloading}
          className="mt-5 self-start bg-green-200"
        >
          Update
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default AddAddress;
