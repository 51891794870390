import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { suspendTeamMember } from "../../store/thunk";
import { iHandleSubmit } from "../../type";

const { suspendMember: PageDictionary } = Language.protected.TeamPages;

function SuspendMember() {
  const { state } = useLocation();
  const { loading } = useAppSelector((state) => state.team);
  const { user_id } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cancelHandler = () => {
    if (loading) return;
    navigate(state.background.pathname, { replace: true, state: null });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    const data = { user_id, team_email: state.data };
    dispatch(suspendTeamMember(data)).unwrap().then(cancelHandler);
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={cancelHandler}
    >
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">{PageDictionary.message}</h3>
        <p className="text-sm text-red-40">
          *{PageDictionary.warningMessage(state.data)}
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButtom variant onClick={cancelHandler}>
            {PageDictionary.reject}
          </CustomButtom>
          <CustomButtom isloading={loading}>
            {PageDictionary.accept}
          </CustomButtom>
        </div>
      </form>
    </CenterModal>
  );
}

export default SuspendMember;
