import React from "react";
import { HiBell } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux";
import routes from "../../../routes";
import CustomToggle from "../../CustomToggle";
import DropDown from "./DropDownMenu";
import TeamDropDown from "./TeamDropDown";
import MultiUserDropDown from "./MultiUserDropDown";
import { tW, toDollar } from "../../../utils/helpers";
import Language from "../../../utils/language/en";

type props = {
  className?: string;
};

const { header: PageDictionary } = Language.protected.Layout;
export const Header = React.forwardRef<HTMLHeadingElement, props>(
  ({ className, ...props }, forwardedRef) => {
    // function Header({ className }: props) {
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const capital_balance = useAppSelector(
      (state) => state.dashboard.home.capital_wallet
    );
    const is_offline = useAppSelector(
      (state) => state.appState.appState.is_offline
    );

    // setCountry_data(res.data)

    const openNotificationHandler = () => {
      navigate(routes.protected.notification);
    };

    const offlineStatusHandler = () => {
      return navigate(routes.protected.confirmOfflineStatus, {
        state: { background: location },
      });
    };

    return (
      <header
        ref={forwardedRef}
        className={tW(
          "flex items-center justify-end gap-x-12 py-4.5 pr-14 pl-8 shadow-header",
          className
        )}
      >
        <div
          className={tW(
            "flex flex-1 items-center gap-x-18",
            pathname.includes(routes.protected.dashboard.index)
              ? "flex-row-reverse justify-end"
              : ""
          )}
        >
          <div
            className={tW(
              pathname.includes(routes.protected.dashboard.index)
                ? "invisible"
                : "visible"
            )}
          >
            <p className="text-[0.9375rem] leading-6.5 text-purple-10">
              {PageDictionary.balance}
            </p>
            <p className="leading-6.6 text-xl font-bold text-blue_gray-10">
              {toDollar(capital_balance)}
            </p>
          </div>
          <MultiUserDropDown />
        </div>
        <TeamDropDown />
        <div
          data-tut="instextour__header-online"
          className=" flex justify-center gap-x-7"
        >
          <p className="text-blue_gray-10">{PageDictionary.status.offline}</p>
          <CustomToggle
            label={PageDictionary.status.online}
            lclassName="!text-blue_gray-10"
            className="gap-x-7"
            check={is_offline}
            onToggle={offlineStatusHandler}
          />
        </div>
        <button
          className="relative cursor-pointer"
          onClick={openNotificationHandler}
          disabled
        >
          <HiBell className="text-gray-200" size="2rem" />
          {/* <span className="absolute right-0 top-1/4 h-2.5 w-2.5 -translate-y-1/2 -translate-x-1/4 rounded-full bg-red-40" /> */}
        </button>
        <DropDown />
      </header>
    );
  }
);

export default Header;
