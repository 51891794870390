import React from "react";
import * as HoverCard from "@radix-ui/react-hover-card";
import tooltip from "../../../static/images/icons/message-question.svg";

type Props = { tip: string };

function CustomTooltip({ tip }: Props) {
  return (
    <HoverCard.Root openDelay={0}>
      <HoverCard.Trigger>
        <img src={tooltip} alt={`tooltip`} className="h-5 w-5 cursor-pointer" />
      </HoverCard.Trigger>
      <HoverCard.Portal>
        <HoverCard.Content
          align="center"
          side="right"
          className="HoverCardContent z-50 w-62 rounded bg-gray-20 py-4 px-2.5 text-base-30 "
        >
          <p className="text-xs leading-5">{tip}</p>
          <HoverCard.Arrow fill="#EDEDED" />
        </HoverCard.Content>
      </HoverCard.Portal>
    </HoverCard.Root>
  );
}

export default CustomTooltip;
