import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import CustomButton from "../../../../common/components/CustomButton";
import { iHandleSubmit } from "../../types";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import {
  activateSettlementAddress,
  deactivateSettlementAddress,
} from "../../store/thunk";

function ToggleAddressStatus() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const [is_loading, setIs_loading] = useState(false);
  const id = location.state?.id;
  const is_active = location.state?.is_active;

  const backDropHandler = () => {
    if (is_loading) return;
    navigate(location.state.background.pathname, { replace: true });
  };

  const dispatchHandler = () => {
    if (is_active) {
      return dispatch(
        activateSettlementAddress({ user_id, data: { address_id: id } })
      );
    }
    return dispatch(
      deactivateSettlementAddress({ user_id, data: { address_id: id } })
    );
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setIs_loading(true);
    dispatchHandler()
      .unwrap()
      .finally(() => {
        setIs_loading(false);
        backDropHandler();
      });
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={backDropHandler}
    >
      <form
        onSubmit={submitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">
          Are you sure you want to take this action
        </h3>
        <p className="text-sm text-red-40">
          Taking this action will {is_active ? "Deactivate" : "Activate"}{" "}
          Address
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButton variant onClick={backDropHandler}>
            Cancel
          </CustomButton>
          <CustomButton isloading={is_loading}>Yes</CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default ToggleAddressStatus;
