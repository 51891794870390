import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";
import { tW } from "../../../../../common/utils/helpers";

type Props = {
  is_active: boolean;
  id: string;
};

export function AddressStatus({ is_active, id }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(routes.protected.settings.settlement.deactivate, {
      state: { background: location, id, is_active },
    });
  };
  return (
    <div className="flex gap-x-14">
      <span
        className={tW(
          "rounded-md px-2 py-0.5 text-xs font-semibold ",
          is_active
            ? "bg-green-40 bg-opacity-25 text-green-40"
            : "bg-red-50 text-red-300"
        )}
      >
        {is_active ? "Active" : "Inactive"}
      </span>
      <button
        onClick={clickHandler}
        className={tW(
          " text-center text-sm font-semibold",
          is_active ? "text-red-300" : "text-green-40"
        )}
      >
        {is_active ? "Deactivate" : "Activate"}
      </button>
    </div>
  );
}
