import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./common/components/Layout";
import Modal from "./common/components/Layout/Modal";
import routes from "./common/routes";
import { generateRoute } from "./common/routes/generateRoute";
import route from "./common/routes/route";
import ErrorPage from "./module/appState/pages/Error";

function App() {
  const location = useLocation();
  const background = location.state && location.state.background;
  return (
    <>
      <Routes location={background || location}>
        <Route element={<Layout />}>
          {route.map((component) => generateRoute(component))}
        </Route>
        <Route
          path="/"
          element={<Navigate to={routes.protected.dashboard.crypto} />}
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      {background && <Modal />}
    </>
  );
}

export default App;
