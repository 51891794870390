import React from "react";
import { toDollar } from "../../../utils/helpers";

type Props = {
  data: number;
};

function DollarAmount({ data }: Props) {
  return <span>{toDollar(data)}</span>;
}

export default DollarAmount;
