import { IexportCheckBox, IpaginationInfo } from "../types";

export function pageNumberUpdateHelper<
  Type2 extends { checkbox: boolean },
  Type1 extends {
    info: IpaginationInfo;
    data: Type2[];
    export: IexportCheckBox;
  }
>(state: Type1, parent: Type2[], payload?: number) {
  if (state.data.find((itm) => itm.checkbox === true)) {
    state.export.checkAll = false;
    state.export.enableExport = false;
  }
  if (payload) {
    state.info.currentPage = payload;
  }
  const start = state.info.maxPageDataCount * (state.info.currentPage - 1);
  const end = start + state.info.maxPageDataCount;
  const data = parent.slice(start, end);
  state.data = [...data];
}

export function updateTotalDataCountAndPages<
  Type1 extends { date: string },
  Type2 extends { info: IpaginationInfo }
>(state: Type2, temp: Type1[]) {
  state.info.totalDataCount = temp.length;
  state.info.totalPages = Math.ceil(temp.length / state.info.maxPageDataCount);
}

export function checkAllHelper<
  Type extends {
    data: any[];
    export: IexportCheckBox;
  }
>(state: Type): void {
  state.export.checkAll = !state.export.checkAll;
  // next line enables export if check all is checked
  state.export.enableExport = state.export.checkAll;

  const temp = state.data.map((itm) => ({
    ...itm,
    checkbox: state.export.checkAll,
  }));

  state.data = [...temp];
}

export function checkItemHelper<
  Type extends {
    data: any[];
    export: IexportCheckBox;
  }
>(state: Type, id: string): void {
  const temp = state.data.map((itm) => {
    if (itm.id === id) {
      return { ...itm, checkbox: !itm.checkbox };
    }
    return { ...itm };
  });
  state.data = [...temp];
  // enables export when an item is checked
  state.export.enableExport = temp.find((itm) => itm.checkbox === true)
    ? true
    : false;
}
