import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";

type Props = {
  data: any;
};

const { action: PageDictonary } =
  Language.protected.SettingsPages.BankSettingsPage;

function BankActions({ data }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const deleteAccountHandler = () => {
    const path = routes.protected.settings.bank_setting.delete_account.link(
      data.bank_id
    );
    navigate(path, { replace: true, state: { background: location, data } });
  };
  return (
    <div className="flex items-center justify-end gap-x-10  font-normal group">
      <button
        onClick={deleteAccountHandler}
        className="col-start-7 col-end-8 row-start-1 row-end-2 flex flex-col items-center gap-y-1 text-xs leading-none text-red-300"
      >
        <RiDeleteBin6Line className="group-hover:animate-bounce" />
        <span>{PageDictonary.delete}</span>
      </button>
    </div>
  );
}

export default BankActions;
