import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import { igetchartThunk } from "../types";

export const getMetrics = createAsyncThunk(
  "performance/getMetrics",
  async (id: string, { rejectWithValue }) => {
    return service
      .getMetrics(id)
      .then((res) => {
        return res?.data;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getChartData = createAsyncThunk(
  "performance/getChartData",
  async (req: igetchartThunk, { rejectWithValue }) => {
    return service
      .getChartData(req.user_id, req.filter_by)
      .then((res) => res.data)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
