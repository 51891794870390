import { useEffect, useRef } from "react";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "../redux";

function useDispatchUnmount(
  action: ActionCreatorWithPayload<any>,
  payload: any
) {
  const dispatch = useAppDispatch();
  const compUnmount = useRef(true);
  useEffect(() => {
    if (compUnmount.current) {
      compUnmount.current = false;
      return;
    }

    return () => {
      dispatch(action(payload));
    };
  }, []); //eslint-disable-line

  return;
}

export { useDispatchUnmount};
