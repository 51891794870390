import Language from "../../../../common/utils/language/en";
import {
  ICreateTeamCheckState,
  ICreateTeamInputState,
  IteamPermission,
} from "../../type";

export const initial = {
  ADMIN: false,
  ACCEPT_REQUEST: false,
  PROCESS_REQUEST: false,
  ISSUES: false,
};
export const errorState = {
  team_email: false,
  first_name: false,
  last_name: false,
  password: false,
};
export const initialState = {
  user_id: "",
  team_email: "",
  first_name: "",
  last_name: "",
  password: "",
  team_permissions: [],
};

export const permissionFormatter = (name: string) => {
  let type: IteamPermission;
  switch (name) {
    case "ADMIN":
    case "ACCEPT_REQUEST":
    case "PROCESS_REQUEST":
    case "ISSUES":
      type = name;
      break;
    default:
      type = "ADMIN";
      break;
  }
  return type;
};

export const { TeamModal: PageDictionary } = Language.protected.TeamPages;

export const shape: ICreateTeamCheckState[] = [
  {
    name: "ADMIN",
    label: PageDictionary.roles.admin.label,
    tip: PageDictionary.roles.admin.tip,
  },
  {
    name: "ACCEPT_REQUEST",
    label: PageDictionary.roles.a_request.label,
    tip: PageDictionary.roles.a_request.tip,
  },
  {
    name: "PROCESS_REQUEST",
    label: PageDictionary.roles.p_request.label,
    tip: PageDictionary.roles.p_request.tip,
  },
  {
    name: "ISSUES",
    label: PageDictionary.roles.issues.label,
    tip: PageDictionary.roles.issues.tip,
  },
];

export const inputshape: ICreateTeamInputState[] = [
  {
    name: "team_email",
    label: PageDictionary.emailLabel,
    type: "email",
  },
  {
    name: "first_name",
    label: PageDictionary.firstNameLabel,
    type: "text",
  },
  {
    name: "last_name",
    label: PageDictionary.lastNameLabel,
    type: "text",
  },
  {
    name: "password",
    label: PageDictionary.PasswordLabel,
    type: "password",
  },
];
