import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

const extendLength = (val: string) => (val.length === 1 ? `0${val}` : val);

function usePeriod(period: string, created?: string) {
  const res = dayjs(period).diff(dayjs(new Date().toISOString()));
  const duration = dayjs.duration(res);

  const time = `${extendLength(
    `${duration.days() * 24 + duration.hours()}`
  )}:${extendLength(`${duration.minutes()}`)}:${extendLength(
    `${duration.seconds()}`
  )}`;

  return `${time.includes("-") ? "00:00:00" : time}`;
}

export { usePeriod };
