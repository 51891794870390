import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";

export const getTransactionFundings = createAsyncThunk(
  "transaction/funding",
  async (user_id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getFunding(user_id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const getTransactionWithdrawals = createAsyncThunk(
  "transaction/withdraw",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getWithdrawal(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
