import cuid from "cuid";
import Language from "../language/en";

const {
  InsuranceAmountPage,
  LockInsurancePage,
  SettingsPages: {
    BankSettingsPage,
    AppsModal: {
      dashboardTab: {
        recordsTable: { tableColumn: AppDashboardRecords },
      },
    },
  },
  TeamPages,
  TransactionPages,
  compalinacePages: {
    business_document: { table: CompalinacePage_bisdoc },
    shareholders: { table: CompalinacePage_shareholder },
  },
  PoolPages: { IssuesPage, CompletedRequestPage },
  CryptoWalletPages,
  fiatPages: {
    overviewPage: {
      tables: {
        transactions: fiatTransactionsPage,
        withdrawals: fiatWithdrawalsPage,
      },
    },
  },
} = Language.protected;

export const tableHeader = {
  incoming_settlement: {
    columns: [
      {
        title: "",
        dataIndex: "checkbox",
        key: "checkbox",
      },
      {
        title: LockInsurancePage.table.amount,
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: LockInsurancePage.table.rate,
        dataIndex: "rate",
        key: "rate",
      },
      {
        title: LockInsurancePage.table.currency,
        dataIndex: "currency",
        key: "currency",
      },
      {
        title: LockInsurancePage.table.date,
        dataIndex: "created_at",
        key: "date",
      },
      {
        title: LockInsurancePage.table.commision,
        dataIndex: "commision",
        key: "total_commision",
      },
      {
        title: LockInsurancePage.table.evidence,
        dataIndex: "evidence",
        key: "evidence",
      },
      {
        title: LockInsurancePage.table.status,
        dataIndex: "status_locked",
        key: "status",
      },
    ],

    rows: [
      {
        id: cuid(),
        amount: "$1,000",
        rate: "N564",
        currency: "NGN",
        date: "2022-11-12T14:11:38.000Z",
        commision: "N3,000",
        status: "locked",
        checkbox: false,
      },
      {
        id: cuid(),
        amount: "$1,000",
        rate: "N564",
        currency: "NGN",
        date: "2022-11-12T14:11:38.000Z",
        commision: "N3,000",
        status: "locked",
        checkbox: false,
      },
    ],
  },
  dispute_funds: {
    columns: [
      {
        title: "",
        dataIndex: "checkbox",
        key: "checkbox",
      },
      {
        title: InsuranceAmountPage.table.amount,
        dataIndex: "dispute_amount",
        key: "txn_amount",
      },
      {
        title: InsuranceAmountPage.table.rate,
        dataIndex: "rate",
        key: "rate",
      },
      {
        title: InsuranceAmountPage.table.currency,
        dataIndex: "currency",
        key: "currency",
      },
      {
        title: InsuranceAmountPage.table.date,
        dataIndex: "date",
        key: "date",
      },
      {
        title: InsuranceAmountPage.table.commision,
        dataIndex: "commision",
        key: "total_commision",
      },
      {
        title: InsuranceAmountPage.table.period,
        dataIndex: "period",
        key: "period",
      },
      {
        title: InsuranceAmountPage.table.status,
        dataIndex: "status_locked",
        key: "status",
      },
    ],

    rows: [
      {
        id: cuid(),
        amount: "$1,000",
        rate: "N564",
        currency: "NGN",
        date: "2022-11-12T14:11:38.000Z",
        commision: "N3,000",
        status: "Pending",
        period: "71:00:02",
        checkbox: false,
      },
      {
        id: cuid(),
        amount: "$1,000",
        rate: "N564",
        currency: "NGN",
        date: "2022-11-12T14:11:38.000Z",
        commision: "N3,000",
        status: "Released",
        period: "Cleared",
        checkbox: false,
      },
    ],
  },
  transactions_withdrawal: {
    columns: [
      {
        title: "",
        dataIndex: "checkbox",
        key: "checkbox",
      },
      {
        title: TransactionPages.table.withdrawal.amount_fiat,
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: TransactionPages.table.withdrawal.amount_dollar,
        dataIndex: "dl_amount",
        key: "amount",
      },
      {
        title: TransactionPages.table.withdrawal.rate,
        dataIndex: "rate",
        key: "rate",
      },
      {
        title: TransactionPages.table.withdrawal.currency,
        dataIndex: "currency",
        key: "currency",
      },
      {
        title: TransactionPages.table.withdrawal.date,
        dataIndex: "date",
        key: "date",
      },
      {
        title: TransactionPages.table.withdrawal.commision,
        dataIndex: "commision",
        key: "total_commission",
      },
      {
        title: TransactionPages.table.withdrawal.evidence,
        dataIndex: "evidence",
        key: "evidence",
      },
      {
        title: TransactionPages.table.withdrawal.status,
        dataIndex: "status_locked",
        key: "status",
      },
    ],
  },
  transactions_funding: {
    columns: [
      {
        title: "",
        dataIndex: "checkbox",
        key: "checkbox",
      },
      {
        title: TransactionPages.table.funding.amount,
        dataIndex: "amount",
        key: "amount",
      },
      {
        title: TransactionPages.table.funding.rate,
        dataIndex: "rate",
        key: "rate",
      },
      {
        title: TransactionPages.table.funding.currency,
        dataIndex: "currency",
        key: "currency",
      },
      {
        title: TransactionPages.table.funding.date,
        dataIndex: "date",
        key: "date",
      },
      {
        title: TransactionPages.table.funding.commision,
        dataIndex: "commision",
        key: "total_commission",
      },
      {
        title: TransactionPages.table.funding.status,
        dataIndex: "status_locked",
        key: "status",
      },
    ],
  },
  team_members: {
    columns: [
      {
        title: TeamPages.table.email,
        dataIndex: "team_email",
        key: "email",
      },
      {
        title: TeamPages.table.date,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: TeamPages.table.access,
        dataIndex: "team_access",
        key: "access",
      },
      {
        title: TeamPages.table.action,
        dataIndex: "team_actions",
        key: "actions",
      },
    ],
  },
  pool_completed_request: {
    columns: [
      {
        title: CompletedRequestPage.table.amount,
        dataIndex: "cr_amount",
        key: "amount",
      },
      {
        title: CompletedRequestPage.table.currency,
        dataIndex: "cr_currency",
        key: "currency",
      },
      {
        title: CompletedRequestPage.table.speed,
        dataIndex: "sp_perk",
        key: "acc_speed_perk",
      },
      {
        title: CompletedRequestPage.table.processing,
        dataIndex: "pr_perk",
        key: "processing_speed_perk",
      },
      {
        title: CompletedRequestPage.table.commisson,
        dataIndex: "total_commision",
        key: "total_commission",
      },
      {
        title: CompletedRequestPage.table.ref,
        dataIndex: "txn_reference",
        key: "txn_reference",
      },
      {
        title: CompletedRequestPage.table.date,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: CompletedRequestPage.table.period,
        dataIndex: "period",
        key: "grace_period",
      },
      {
        title: CompletedRequestPage.table.settlement_amount,
        dataIndex: "settlement_amount",
        key: "insurance",
      },
    ],

    rows: [
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        sp_perk: "$2",
        pr_perk: "9s",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        g_period: "71:00:02",
        insurance: "$1000",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        sp_perk: "$2",
        pr_perk: "9s",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        g_period: "Cleared",
        insurance: "Released",
      },
    ],
  },
  pool_issues: {
    columns: [
      {
        title: IssuesPage.table.amount,
        dataIndex: "cr_",
        // dataIndex: "cr_amount",
        key: "amount",
      },
      {
        title: IssuesPage.table.currency,
        dataIndex: "cr_currency",
        key: "currency",
      },
      {
        title: IssuesPage.table.commisson,
        // dataIndex: "total_commision",
        dataIndex: "",
        key: "total_commission",
      },
      {
        title: IssuesPage.table.date,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: IssuesPage.table.memo,
        dataIndex: "issue_memo",
        key: "",
      },
      {
        title: IssuesPage.table.message,
        dataIndex: "message",
        key: "message_count",
      },
      {
        title: IssuesPage.table.ref,
        // dataIndex: "cr_ref",
        dataIndex: "cr_",
        key: "txn_reference",
      },
    ],

    rows: [
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "new",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "dispute",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "resolved",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "withdrawal",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "funding",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "new",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "dispute",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "resolved",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "withdrawal",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "funding",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "new",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "dispute",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "resolved",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "withdrawal",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "funding",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "dispute",
      },
      {
        id: cuid(),
        amount: "$1,000 ",
        rate: "N845",
        currency: "NGN",
        total_commision: "N3,000",
        created_at: "2022-11-12T14:11:38.000Z",
        ref: "RESHeuouehjsk12311",
        type: "funding",
      },
    ],
  },
  bank_accounts: {
    columns: [
      {
        title: BankSettingsPage.acc_status,
        dataIndex: "bank_status",
        key: "status",
      },
      {
        title: BankSettingsPage.bank_action,
        dataIndex: "bank_action",
        key: "",
      },
    ],

    rows: [
      {
        id: cuid(),
        account_name: "Charles Avis",
        bank_name: "Zenith bank",
        account_number: "0635627292",
      },
      {
        id: cuid(),
        account_name: "Charles Avis",
        bank_name: "Zenith bank",
        account_number: "0635627292",
      },
      {
        id: cuid(),
        account_name: "Charles Avis",
        bank_name: "Zenith bank",
        account_number: "0635627292",
      },
      {
        id: cuid(),
        account_name: "Charles Avis",
        bank_name: "Zenith bank",
        account_number: "0635627292",
      },
    ],
  },
  settlement: {
    wallet_address: {
      columns: [
        {
          title: "Address",
          dataIndex: "",
          key: "address",
        },
        {
          title: "% Split",
          dataIndex: "",
          key: "percentage_amount",
        },
        // {
        //   title: "Last Settlement",
        //   dataIndex: "",
        //   key: "last_amount",
        // },
        // {
        //   title: "Total Settled",
        //   dataIndex: "",
        //   key: "total_amount",
        // },
        {
          title: "",
          dataIndex: "settlement_wallet_manage",
          key: "",
        },
      ],
      rows: [
        {
          id: cuid(),
          address: "ehhrhekdjxjbufhhjsojdsffhihsdfjnioklkskssw",
          service: "33%",
          last_amount: "$1,545",
          total_amount: "$1,545",
        },
        {
          id: cuid(),
          address: "ehhrhekdjxjbufhhjsojdsffhihsdfjnioklkskssw",
          service: "33%",
          last_amount: "$1,545",
          total_amount: "$1,545",
        },
        {
          id: cuid(),
          address: "ehhrhekdjxjbufhhjsojdsffhihsdfjnioklkskssw",
          service: "33%",
          last_amount: "$1,545",
          total_amount: "$1,545",
        },
        {
          id: cuid(),
          address: "ehhrhekdjxjbufhhjsojdsffhihsdfjnioklkskssw",
          service: "33%",
          last_amount: "$1,545",
          total_amount: "$1,545",
        },
      ],
    },
    server_address: {
      columns: [
        {
          title: "IP Address",
          dataIndex: "",
          key: "address",
        },
        {
          title: "",
          dataIndex: "settlement_server_manage",
          key: "",
        },
      ],
      rows: [
        {
          id: cuid(),
          address: "1.223.453.56",
        },
        {
          id: cuid(),
          address: "1.223.453.56",
        },
        {
          id: cuid(),
          address: "1.223.453.56",
        },
        {
          id: cuid(),
          address: "1.223.453.56",
        },
      ],
    },
  },
  app_records: {
    columns: [
      {
        title: AppDashboardRecords.amount,
        dataIndex: "",
        key: "amount",
      },
      {
        title: AppDashboardRecords.date,
        dataIndex: "",
        key: "date",
      },
      {
        title: AppDashboardRecords.time,
        dataIndex: "",
        key: "time",
      },
      {
        title: AppDashboardRecords.status,
        dataIndex: "",
        key: "status",
      },
    ],

    rows: [
      {
        id: cuid(),
        amount: "N100,000",
        date: "01 Dec.  2023",
        time: "3:58pm",
        status: "Successful",
      },
      {
        id: cuid(),
        amount: "N100,000",
        date: "01 Dec.  2023",
        time: "3:58pm",
        status: "Successful",
      },
      {
        id: cuid(),
        amount: "N100,000",
        date: "01 Dec.  2023",
        time: "3:58pm",
        status: "Successful",
      },
    ],
  },
  crypto_wallet: {
    capital_wallet: {
      columns: [
        {
          title: CryptoWalletPages.table.capital.amount,
          dataIndex: "wallet_amount",
          key: "amount",
        },
        {
          title: CryptoWalletPages.table.capital.account_type,
          dataIndex: "",
          key: "account_type",
        },
        {
          title: CryptoWalletPages.table.capital.date,
          dataIndex: "",
          key: "date",
        },
        {
          title: CryptoWalletPages.table.capital.time,
          dataIndex: "",
          key: "time",
        },
        {
          title: CryptoWalletPages.table.capital.status,
          dataIndex: "",
          key: "status",
        },
      ],
    },
    commission_wallet: {
      columns: [
        {
          title: CryptoWalletPages.table.commission.amount_withdrawn,
          dataIndex: "wallet_amount",
          key: "amount",
        },
        {
          title: CryptoWalletPages.table.commission.date,
          dataIndex: "",
          key: "date",
        },
        {
          title: CryptoWalletPages.table.commission.time,
          dataIndex: "",
          key: "time",
        },
        {
          title: CryptoWalletPages.table.commission.status,
          dataIndex: "",
          key: "status",
        },
      ],
    },
  },
  business_document: {
    columns: [
      {
        title: CompalinacePage_bisdoc.document_name,
        dataIndex: "document_name",
        key: "name",
      },
      {
        title: CompalinacePage_bisdoc.action,
        dataIndex: "business_action",
        key: "",
      },
      {
        title: CompalinacePage_bisdoc.status,
        dataIndex: "business_status",
        key: "status",
      },
      {
        title: CompalinacePage_bisdoc.created_at,
        dataIndex: "created_at",
        key: "",
      },
    ],
  },
  shareholders: {
    columns: [
      {
        title: CompalinacePage_shareholder.name,
        dataIndex: "name",
        key: "name",
      },
      {
        title: CompalinacePage_shareholder.email,
        dataIndex: "email",
        key: "email",
      },
      {
        title: CompalinacePage_shareholder.shareholder_id,
        dataIndex: "shareholder_id_proof",
        key: "",
      },
      {
        title: CompalinacePage_shareholder.proof_address,
        dataIndex: "shareholder_address_proof",
        key: "",
      },
      {
        title: CompalinacePage_shareholder.address,
        dataIndex: "",
        key: "address",
      },

      {
        title: CompalinacePage_shareholder.created_at,
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: CompalinacePage_shareholder.action,
        dataIndex: "shareholder_action",
        key: "created_at",
      },
    ],
  },
  fiat: {
    withdrawals: {
      column: [
        {
          title: fiatWithdrawalsPage.amount,
          dataIndex: "fiat_amount",
          key: "",
        },
        {
          title: fiatWithdrawalsPage.fee,
          dataIndex: "fiat_fee",
          key: "",
        },
        {
          title: fiatWithdrawalsPage.balance,
          dataIndex: "fiat_balance",
          key: "",
        },
        {
          title: fiatWithdrawalsPage.bank_ref,
          dataIndex: "",
          key: "txn_ref",
        },
        // {
        //   title: fiatWithdrawalsPage.to,
        //   dataIndex: "view_payment_info",
        //   key: "",
        // },
        {
          title: fiatWithdrawalsPage.currency,
          dataIndex: "",
          key: "currency",
        },
        {
          title: fiatWithdrawalsPage.status,
          dataIndex: "payment_status",
          key: "",
        },
        {
          title: fiatWithdrawalsPage.Timestamp,
          dataIndex: "created_at",
          key: "created_at",
        },
      ],
    },
    transactions: {
      column: [
        // {
        //   title: fiatTransactionsPage.amount,
        //   dataIndex: "fiat_amount",
        //   key: "amount",
        // },
        // {
        //   title: fiatTransactionsPage.charges,
        //   dataIndex: "fiat_fee_amount",
        //   key: "fee_amount",
        // },
        {
          title: fiatTransactionsPage.amount,
          dataIndex: "fiat_amount",
          key: "",
        },
        {
          title: fiatTransactionsPage.fee,
          dataIndex: "fiat_fee",
          key: "",
        },
        {
          title: fiatTransactionsPage.settled_amount,
          dataIndex: "fiat_settled_amount",
          key: "",
        },
        {
          title: fiatTransactionsPage.order_no,
          dataIndex: "fiat_order_no",
          key: "",
        },
        {
          title: fiatTransactionsPage.status,
          dataIndex: "payment_status",
          key: "",
        },
        {
          title: fiatTransactionsPage.time_date,
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: fiatTransactionsPage.action,
          dataIndex: "view_payment_info",
          key: "",
        },

      ],
    },
    partners: {
      column: [
        {
          title: "",
          dataIndex: "payment_partner_name",
          key: "",
        },
        {
          title: "",
          dataIndex: "payment_partner_information",
          key: "",
        },
        {
          title: "",
          dataIndex: "payment_partner_status",
          key: "",
        },
        {
          title: "",
          dataIndex: "payment_partner_map",
          key: "",
        },
        {
          title: "",
          dataIndex: "payment_partner_manage",
          key: "",
        },
      ],
    },
  },
};
