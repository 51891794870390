import {  useMemo } from "react";
// @ts-ignore
import { findFlagUrlByCountryName } from "country-flags-svg";
import { useAppSelector } from "../../../../../common/hooks";
import { toCurrency, toDollar } from "../../../../../common/utils/helpers";
import { iHandleChange } from "../../../types";
import usdticon from "../../../../../static/images/usdticon.png";


type ExchangeRateInputProps = {
  handleChange?: iHandleChange<HTMLInputElement>;
  value: string;
  source: string;
  isReciever?: boolean;
};

export const ExchangeRateInput = ({
  handleChange,
  isReciever,
  value,
  source,
}: ExchangeRateInputProps) => {
  const { capital_wallet } = useAppSelector((state) => state.dashboard.home);
  const { wallet_balance } = useAppSelector((state) => state.payment);
  const { currencies, name: countryName } = useAppSelector(
    (state) => state.appState.appState.country_data
  );

  const flag = useMemo(
    () => (countryName ? findFlagUrlByCountryName(countryName) : ""),
    [countryName]
  );

  const walletType = source !== "USDT" ? "Fiat" : "Cryto";

  return (
    <div className="">
      <label
        htmlFor=""
        className="mb-2 block text-sm font-medium text-[#090914]"
      >
        {!isReciever ? `From ` : `To `} {`${walletType} Wallet`}
      </label>
      <div className="relative mb-2">
        <input
          type="text"
          className=" h-[3.438rem]  w-full rounded-[0.513rem] border border-[#CBD5E1] bg-[#F8FAFC] px-3 pl-6 outline-none transition  duration-150 hover:ring-opacity-30 focus:ring-2 focus:ring-offset-0"
          value={value}
          onChange={handleChange}
          disabled={isReciever}
        />
        <div className="absolute right-6  top-1/2 -translate-y-1/2 flex items-center gap-x-2 font-bold">
          <img
            src={source !== "USDT" ? flag : usdticon}
            alt="flag"
            className="h-6 w-6 rounded-full object-cover"
          />
          <span>{source}</span>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="flex items-center gap-x-3">
          <span className="flex h-[1.125rem] w-[1.125rem] items-center justify-center rounded-full bg-[#6F6F6F] leading-none text-white">
            -
          </span>
          <span className="text-sm font-bold leading-4 text-[#6F6F6F]">
            {source !== "USDT" ? `${currencies[0]} Balance` : "USD Balance"}
          </span>
        </p>
        <p className="text-sm font-bold leading-4 text-[#343435]">
          {source !== "USDT"
            ? toCurrency(wallet_balance)
            : toDollar(capital_wallet)}
        </p>
      </div>
    </div>
  );
};
