import React, { useId, useRef } from "react";
import { tW } from "../../utils/helpers";

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  text_left?: any;
  label?: string;
  checked: boolean;
  sclassName?: string;
}

function CustomCheckBox({
  text_left,
  label,
  checked,
  onChange,
  name,
  className,
  sclassName,
  disabled,
}: props) {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);
  return (
    <div
      className={tW(
        "flex cursor-pointer items-center gap-x-2",
        text_left ? "flex-row-reverse" : "",
        disabled ? "!cursor-not-allowed" : "",
        className
      )}
    >
      <input
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        type="checkbox"
        className="sr-only"
        name={name}
        id={`${id}-checkbox`}
        ref={inputRef}
      />
      <label
        className={tW(
          "inline-flex cursor-pointer items-center gap-x-2 text-sm font-bold leading-[1.375rem] text-blue_gray-10",
          sclassName ? "text-base capitalize leading-none" : "",
          disabled ? "!cursor-not-allowed" : ""
        )}
        htmlFor={`${id}-checkbox`}
      >
        <span
          className={tW(
            "checkbox  bg-gray-70 bg-center",
            sclassName ? sclassName : "h-4.5 w-4.5",
            checked && !sclassName ? "bg-checkbg bg-contain" : "",
            checked && sclassName ? "bg-checkline bg-no-repeat" : ""
          )}
        />
        {label}
      </label>
    </div>
  );
}

export default CustomCheckBox;
