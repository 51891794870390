import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  storeSubUserToken,
  storeUserToken,
} from "../../../common/service/storage";
import { logoutUser, switchMultiAccount } from "../../appState/store/thunk";
import { getInfoNew } from "../../dashboard/store/thunk";
import { Iauth } from "../types";
import {
  loginUser,
  registerUser,
  getOtp,
  resetPassword,
  getCsrfToken,
} from "./thunk";

const initialState: Iauth = {
  loading: false,
  error: null,
  auth: {
    email: "",
    password: "",
    otp: "",
    first_name: "",
    last_name: "",
  },
  user: {
    name: "",
    first_name: "",
    last_name: "",
    user_id: "",
    root_user_id: "",
    user_type: "",
    email: "",
    role: "",
    permission: [],
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateField: (state, { payload }) => {
      state.auth = { ...state.auth, ...payload };
    },
    resetFields: (state) => {
      (Object.keys(state.auth) as Array<keyof typeof state.auth>).forEach(
        (key) => {
          state.auth[key] = initialState.auth[key];
        }
      );
    },
    resetAuthError: (state) => {
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(loginUser.fulfilled, (state, { payload }) => {
      //   storeUserToken(payload.token);
      //   storeSubUserToken(payload.token);
      //   const keys = Object.keys(payload); /* as Array<keyof typeof payload> */
      //   (Object.keys(state.user) as Array<keyof typeof state.user>).forEach(
      //     (key) => {
      //       if (keys.includes(key)) {
      //         state.user[key] = payload[key];
      //       }
      //     }
      //   );
      //   state.user.permission = [...payload.permissions];
      //   state.user.root_user_id = payload.user_id;
      //   state.user.name = `${payload.first_name} ${payload.last_name}`;
      //   state.user.role = "Admin";
      // })
      // .addCase(switchMultiAccount.fulfilled, (state, { payload }) => {
      //   storeUserToken(payload.token);
      //   state.user.permission = [...payload.permissions];
      //   state.user.first_name = payload.first_name;
      //   state.user.last_name = payload.last_name;
      //   state.user.user_id = payload.user_id;
      //   state.user.user_type = payload.user_type;
      //   state.user.email = payload.email;
      //   state.user.name = `${payload.first_name} ${payload.last_name}`;
      //   state.user.role = "";
      // })
      .addCase(getInfoNew.fulfilled, (state, { payload }) => {
        state.user.permission = [...payload.user_data.permissions];
        state.user.first_name = payload.user_data.first_name;
        state.user.last_name = payload.user_data.last_name;
        state.user.user_id = payload.user_data.user_id;
        state.user.email = payload.user_data.email;
        state.user.name = `${payload.user_data.first_name} ${payload.user_data.last_name}`;
      })
      .addCase(registerUser.fulfilled, (state) => {
        (Object.keys(state.auth) as Array<keyof typeof state.auth>).forEach(
          (key) => {
            state.auth[key] = initialState.auth[key];
          }
        );
        // state.auth.email = initialState.auth.email;
        // state.auth.first_name = initialState.auth.first_name;
        // state.auth.last_name = initialState.auth.last_name;
        // state.auth.otp = initialState.auth.otp;
        // state.auth.password = initialState.auth.password;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        // state = { ...initialState };
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })
      .addMatcher(
        isAnyOf(loginUser.fulfilled, switchMultiAccount.fulfilled),
        (state, { payload, type }) => {
          storeUserToken(payload.token);
          state.user.permission = [...payload.permissions];
          state.user.first_name = payload.first_name;
          state.user.last_name = payload.last_name;
          state.user.user_id = payload.user_id;
          state.user.user_type = payload.user_type;
          state.user.email = payload.email;
          state.user.name = `${payload.first_name} ${payload.last_name}`;
          state.user.role = "";
          if (type.includes("auth/loginUser")) {
            storeSubUserToken(payload.token);
            state.user.root_user_id = payload.user_id;
            state.user.role = "Admin";
          }
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.fulfilled,
          getOtp.fulfilled,
          resetPassword.fulfilled,
          registerUser.fulfilled
        ),
        (state) => {
          if (state.error !== null) {
            state.error = null;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.rejected,
          getOtp.rejected,
          getCsrfToken.rejected,
          registerUser.rejected,
          resetPassword.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.pending,
          getOtp.pending,
          getCsrfToken.pending,
          resetPassword.pending,
          registerUser.pending,
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          loginUser.rejected,
          loginUser.fulfilled,
          registerUser.rejected,
          registerUser.fulfilled,
          getOtp.rejected,
          getOtp.fulfilled,
          getCsrfToken.rejected,
          getCsrfToken.fulfilled,
          resetPassword.rejected,
          resetPassword.fulfilled
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { updateField, resetFields, resetAuthError } = authSlice.actions;

export default authSlice.reducer;
