import React from "react";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import ExchangeCalculator from "../../components/ExchangeCalculator";
import { useLocation, useNavigate } from "react-router-dom";

function SwapCalculator() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleBackdrop = () =>
    navigate(state?.background.pathname, { replace: true });
  return (
    <CenterModal handleClose={handleBackdrop} className="p-0 rounded-none bg-transparent">
      <ExchangeCalculator />
    </CenterModal>
  );
}

export default SwapCalculator;
