import React from "react";
import CustomToggle from "../../../../../common/components/CustomToggle";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";
import { iHandleChange } from "../../../types";
import { useAppSelector } from "../../../../../common/hooks";

type Props = {
  status: boolean;
  partner_code: string;
  partner_id: string;
};

function PaymentPartnerMapper({ partner_code, partner_id, status }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const p2pdata = useAppSelector((state) =>
    state.payment.p2p_info.p2p.find((itm) => itm.partner_id === partner_id)
  );
  const toggleMethod: iHandleChange<HTMLInputElement> = (e) => {
    e.stopPropagation();

    if (status === true) {
      navigate(routes.protected.fiat_wallet.payment_methods.turnoff, {
        state: {
          background: location,
          data: { partner_code },
        },
      });
    }
    if (!status && !p2pdata) {
      navigate(routes.protected.fiat_wallet.payment_methods.add_merchant, {
        state: {
          background: location,
          data: { title: partner_code, partner_id },
        },
      });
    }
    if (!status && p2pdata) {
      navigate(routes.protected.fiat_wallet.payment_methods.turnon, {
        state: {
          background: location,
          data: { partner_code },
        },
      });
    }
  };
  return (
    <CustomToggle
      check={status}
      onToggle={toggleMethod}
      label=""
      lclassName="hidden"
    />
  );
}

export default PaymentPartnerMapper;
