import rotateScreen from "../../../../static/images/rotate-screen.gif";

function MobileAppDownload() {
  const portrait = window.matchMedia("(orientation: portrait)").matches;

  if (portrait === false) {
    return (
      <div className="flex h-screen w-full items-center justify-center bg-blue_gray-10">
        <img src={rotateScreen} className="h-20 w-20 -rotate-90" alt="rotate screen" />
      </div>
    );
  }

  return (
    <iframe
      src="https://intrapay-pwa.vercel.app/#/"
      className="relative h-screen w-full"
      title="ios pwa"
    />
  );
}

export default MobileAppDownload;
