import { useLocation } from "react-router-dom";
import SuccessModal from "../../../components/SuccessModal";
import { localCurrencySymbol } from "../../../../../common/utils/helpers";
import Language from "../../../../../common/utils/language/en";

const { success: PageDictionary } = Language.protected.fiatModals.withdrawal;

function WithdrawalSuccess() {
  const { state } = useLocation();
  const path = state?.background?.pathname as string;
  const amount = state.data.amount as string;
  const localAmount = localCurrencySymbol(amount);
  const subtitle = PageDictionary.subtitle(localAmount);

  return (
    <SuccessModal
      path={path}
      title={PageDictionary.title}
      subtitle={subtitle}
    />
  );
}

export default WithdrawalSuccess;
