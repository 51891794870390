import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButtom from "../../../../common/components/CustomButton";
import PermissionCheck from "../../components/PermissionCheck";
import {
  editTeamMember,
  liftTeamMemberSuspension,
  suspendTeamMember,
} from "../../store/thunk";
import { permissionFormatter, shape } from "../CreateTeamMember/Helper";
import { IupdateTeamMember } from "../../type";
import Language from "../../../../common/utils/language/en";
import { tW } from "../../../../common/utils/helpers";

const initial = {
  ADMIN: false,
  ACCEPT_REQUEST: false,
  PROCESS_REQUEST: false,
  ISSUES: false,
};

const initialState = {
  team_email: "",
  user_id: "",
  team_permissions: [],
};

const { TeamModal: PageDictionary } = Language.protected.TeamPages;

function EditTeamMember() {
  const navigate = useNavigate();
  const [check, setCheck] = useState(initial);
  const [disableEdit, setDisableEdit] = useState(true);
  const [data, setData] = useState<IupdateTeamMember>(initialState);
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { loading } = useAppSelector((state) => state.team);
  const itm = useAppSelector((state) =>
    state.team.members.find((itm) => (id ? id === itm.id.toString() : null))
  );

  useEffectOnce(() => {
    if (itm) {
      itm.permission.forEach((permission) => {
        setCheck((prev) => ({ ...prev, [permission]: true }));
      });
      setData((prev) => ({ ...prev, user_id, team_email: itm?.email }));
    }
  });

  useEffect(() => {
    Object.keys(check).forEach((itm) => {
      const type = permissionFormatter(itm);
      if (check[type] && data.team_permissions.includes(type) === false) {
        setData((prev) => ({
          ...prev,
          team_permissions: [...prev.team_permissions, type],
        }));
      }
      if (check[type] === false && data.team_permissions.includes(type)) {
        const prev_permission = data.team_permissions.filter(
          (itm) => itm !== type
        );
        setData((prev) => ({
          ...prev,
          team_permissions: [...prev_permission],
        }));
      }
    });
  }, [JSON.stringify(check)]); //eslint-disable-line

  const backToTeam = () => {
    if (loading) return;
    navigate(state.background.pathname, { replace: true });
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disableEdit) {
      setDisableEdit(false);
      return;
    }
    // const data = {};
    dispatch(editTeamMember(data)).unwrap().then(backToTeam);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCheck((prev) => ({ ...prev, [name]: value }));
  };

  const unSuspendHandler = () => {
    if (itm?.status === "suspended") {
      dispatch(liftTeamMemberSuspension(data)).unwrap().then(backToTeam);
    }
    if (itm?.status !== "suspended") {
      dispatch(suspendTeamMember(data)).unwrap().then(backToTeam);
    }
  };

  const checkHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;

    switch (name) {
      case "ADMIN":
      case "ACCEPT_REQUEST":
      case "PROCESS_REQUEST":
      case "ISSUES":
        setCheck((prev) => ({ ...prev, [name]: !prev[name] }));
        break;
      default:
        break;
    }
  };

  return (
    <SideModal clickBackDrop={backToTeam}>
      <section className="px-18 pt-24">
        <header className="border-b border-gray-50 pb-1">
          <h3 className="text-4xl font-semibold">
            {PageDictionary.updateTitle}
          </h3>
        </header>
        <form className="pt-18" onSubmit={submitHandler}>
          <CustomInput
            onChange={changeHandler}
            name="email"
            type="email"
            label="Enter member email address"
            readOnly
            value={data.team_email}
          />
          <div className="mt-14">
            <p className="text-base text-base-30">
              {PageDictionary.roles.label}
            </p>
            <div className="mt-7 flex flex-col gap-y-4">
              {shape.map((itm) => (
                <PermissionCheck
                  key={itm.name}
                  checkHandler={checkHandler}
                  name={itm.name}
                  is_check={check[itm.name]}
                  label={itm.label}
                  tip={itm.tip}
                  disable={disableEdit || loading}
                />
              ))}
            </div>
          </div>
          <div className="mt-24 flex items-center gap-x-4">
            <CustomButtom
              isloading={loading && disableEdit === false}
              className=" bg-green-400"
            >
              {disableEdit
                ? PageDictionary.updateAction.manage
                : PageDictionary.updateAction.update}
            </CustomButtom>
            {disableEdit && (
              <CustomButtom
                type="button"
                isloading={loading}
                onClick={unSuspendHandler}
                className={tW(
                  itm?.status === "suspended" ? "bg-orange-400" : "bg-red-400"
                )}
              >
                {itm?.status === "suspended"
                  ? PageDictionary.updateAction.unsuspend
                  : PageDictionary.updateAction.suspend}
              </CustomButtom>
            )}
          </div>
        </form>
      </section>
    </SideModal>
  );
}

export default EditTeamMember;
