import React from "react";
import { TbBuildingBank } from "react-icons/tb";

type Props = {
  name: string;
};

function PaymentPartnerName({ name }: Props) {
  return (
    <div className="flex items-center gap-x-3">
      <span className="block h-4 w-4">
        <TbBuildingBank />
      </span>
      <p className="text-sm font-bold"> {name}</p>
    </div>
  );
}

export default PaymentPartnerName;
