import React from "react";
import success from "../../../../../static/images/success.gif";
import CustomButton from "../../../../../common/components/CustomButton";

type Istage = "form" | "otp" | "finish";

type Props = {
  from_currency: string;
  to_currency: string;
  amount: string;
  setStage: React.Dispatch<React.SetStateAction<Istage>>;
  resetHandler: VoidFunction;
};

function Success({ amount, from_currency, to_currency, resetHandler }: Props) {
  return (
    <div className="flex flex-col px-12">
      <img alt="success" src={success} />
      <div className="flex flex-1 flex-col gap-y-7">
        <div className="flex flex-1 flex-col gap-y-7">
          <h3 className="text-center text-2xl font-bold">Swap Complete!</h3>
          <p className="text-center">
            {`You have swapped ${
              from_currency + " " + amount
            } to ${to_currency} successfully`}
          </p>
        </div>
        <CustomButton className="self-center" onClick={resetHandler}>
          Swap again
        </CustomButton>
      </div>
    </div>
  );
}

export default Success;
