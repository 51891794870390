import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
import * as tp from "../types";

export const addBankAccount = createAsyncThunk(
  "settings/addBankAccount",
  async (req: tp.IaddAccountThunk, { rejectWithValue, dispatch }) => {
    const { data, user_id } = req;
    return service
      .addBankAccount(data, user_id)
      .then((res) => {
        dispatch(getAllBanks(user_id));
        customToast(res.message);
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getAllBanks = createAsyncThunk(
  "settings/getAllBanks",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .listAllBankAccounts(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const setDefaultBank = createAsyncThunk(
  "settings/setDefaultBank",
  async (req: tp.IbankdetailThunk, { rejectWithValue, fulfillWithValue }) => {
    const { data, user_id } = req;
    return service
      .setDefaultBank(data, user_id)
      .then((res) => fulfillWithValue(data.bank_id).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const deleteBankDetail = createAsyncThunk(
  "settings/deleteBankDetail",
  async (req: tp.IbankdetailThunk, { rejectWithValue, fulfillWithValue }) => {
    const { data, user_id } = req;
    return service
      .deleteBankDetail(data, user_id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(data.bank_id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const saveWithdrawalIntegration = createAsyncThunk(
  "settings/saveWithdrawalIntegration",
  async (
    req: tp.IsaveWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data, user_id } = req;
    return service
      .saveWithdrawalIntegration(data, user_id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const enableWithdrawalIntegration = createAsyncThunk(
  "settings/enableWithdrawalIntegration",
  async (
    req: tp.IenableWithdrawalThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data, user_id } = req;
    return service
      .enableWithdrawalIntegration(data, user_id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(data.channel).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const enableSettlement = createAsyncThunk(
  "settings/enableSettlement",
  async (
    req: tp.IsettlementTypeThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data, user_id } = req;
    return service
      .enableSettlement(data, user_id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const setPayoutThreshold = createAsyncThunk(
  "settings/setPayoutThreshold",
  async (
    req: tp.IpaymentThresholdThunk,
    { rejectWithValue, fulfillWithValue }
  ) => {
    const { data, user_id } = req;
    return service
      .setPayoutThreshold(data, user_id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(data.threshold_amount).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const addSettlementWallet = createAsyncThunk(
  "settings/addSettlementWallet",
  async (
    req: tp.IaddSettlementWalletThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { data, user_id } = req;
    return service
      .addSettlementWallet(data, user_id)
      .then((res) => {
        customToast(res.message);
        dispatch(getSettlementAddress({ user_id }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getSettlementAddress = createAsyncThunk(
  "settings/getSettlementAddress",
  async (req: tp.thunkData, { rejectWithValue, fulfillWithValue }) => {
    const { user_id } = req;
    return service
      .getSettlementAddress(user_id)
      .then((res) => {
        // customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const deactivateSettlementAddress = createAsyncThunk(
  "settings/deactivateSettlementAddress",
  async (
    req: tp.ItoggleAddressStatusThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { data, user_id } = req;
    return service
      .deactivateSettlementAddress(data, user_id)
      .then((res) => {
        customToast(res.message);
        dispatch(getSettlementAddress({ user_id }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const activateSettlementAddress = createAsyncThunk(
  "settings/activateSettlementAddress",
  async (
    req: tp.ItoggleAddressStatusThunk,
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    const { data, user_id } = req;
    return service
      .activateSettlementAddress(data, user_id)
      .then((res) => {
        customToast(res.message);
        dispatch(getSettlementAddress({ user_id }));
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const downloadWalletCertificate = createAsyncThunk(
  "settings/downloadWalletCertificate",
  async (req: tp.IdownloadCertThunk, { rejectWithValue, fulfillWithValue }) => {
    const { user_id, data } = req;
    return service
      .downloadWalletCertificate(user_id, data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
