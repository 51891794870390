import React from "react";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "../../../CustomDropDown";
import setting_image from "../../../../../static/images/icons/setting.svg";
import logout_image from "../../../../../static/images/icons/log-out.svg";
import { HiChevronDown, HiCog } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import routes from "../../../../routes";
import Language from "../../../../utils/language/en";
import { useAppDispatch } from "../../../../hooks/redux";
import { logoutUser } from "../../../../../module/appState/store/thunk";

const { dropdown: PageDictionary } = Language.protected.Layout.header;

function DropDown() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goTosetting = () => {
    navigate(routes.protected.settings.bank_setting.index);
  };
  const goToProfile = () => {
    navigate(routes.protected.settings.profile);
  };

  const logoutHandler = () => {
    dispatch(logoutUser());
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <button className="flex cursor-pointer items-center gap-x-2 outline-none">
          <HiCog size="2rem" />
          <HiChevronDown className="ml-1" />
        </button>
      </DropdownMenuTrigger>
      <DropDownMenuContent className="z-10 mt-11 flex w-48 flex-col gap-y-2.5 rounded-lg bg-white px-4 pt-3.5 pb-7 font-bold text-blue_gray-10 shadow-header">
        <DropDownItem>
          <button
            onClick={goToProfile}
            className="flex w-full items-center gap-x-2 text-sm text-black"
          >
            <img
              src={setting_image}
              alt={PageDictionary.profile.toLocaleLowerCase()}
            />
            <span>{PageDictionary.profile}</span>
          </button>
        </DropDownItem>
        <DropDownItem>
          <button
            onClick={goTosetting}
            className="flex w-full items-center gap-x-2 text-sm text-black"
          >
            <img
              src={setting_image}
              alt={PageDictionary.setting.toLocaleLowerCase()}
            />
            <span>{PageDictionary.setting}</span>
          </button>
        </DropDownItem>
        <DropDownItem>
          <button
            onClick={logoutHandler}
            className="flex w-full items-center gap-x-2 text-sm text-red-300"
          >
            <img
              src={logout_image}
              alt={PageDictionary.logout.toLocaleLowerCase()}
            />
            <span>{PageDictionary.logout}</span>
          </button>
        </DropDownItem>
      </DropDownMenuContent>
    </DropdownMenu>
  );
}

export default DropDown;
