import { Ionboard } from "./types";

const routes = {
  nonprotected: {
    login: "/auth/login",
    register: "/auth/register",
    two_fa: "/auth/login/2fa",
    resetpassword: "/auth/resetpassword",
    forgotpassword: "/auth/forgotpassword",
    enter_otp: "/auth/enter_otp",
    new_password: "/auth/new_password",
    reset_successful: "/auth/reset_successful",
    error_page: "*",
  },
  protected: {
    notification: "/notification",
    confirmOfflineStatus: "/confirm-status",
    appLoading: "/",
    dashboard: {
      index: "/dashboard",
      fiat: "/dashboard/fiat",
      crypto: "/dashboard/crypto",
    },
    transactions: {
      index: "/transactions",
      withdraw: "/transactions/withdraw",
      fund: "/transactions/fund",
      evidence: "/transactions/view",
    },
    team: {
      index: "/team",
      create: "/team/create",
      suspend: "/team/suspend-member",
      manage_member: {
        index: "/team/:team_id/:id/change-privilege",
        link: (member_id: string, team_id: string) =>
          `/team/${team_id}/${member_id}/change-privilege`,
      },
    },
    settings: {
      index: "/settings",
      bank_setting: {
        index: "/settings/bank-setting",
        add_account: "/settings/bank-setting/add-account",
        delete_account: {
          index: "/settings/bank-setting/:id/delete-account",
          link: (id: string | number) =>
            `/settings/bank-setting/${id}/delete-account`,
        },
      },
      reset_pin: "/settings/reset-password",
      language: "/settings/laguage",
      profile: "/settings/profile",
      api_setting: "/settings/api",
      apps: "/settings/apps",
      price: "/settings/price",
      settlement: {
        index: "/settings/settlement",
        add_address: "/settings/settlement/add",
        deactivate: "/settings/settlement/toggle-status",
        delete: "/settings/settlement/delete",
      },
      selectApp: {
        index: "/settings/apps/:id/",
        link: (id: string) => `/settings/apps/${id}`,
      },
      add_multi_country: "/settings/add-account",
      add_multi_country_otp: "/settings/otp",
    },
    performance: {
      index: "/performance",
      metric: "/performance/metrics",
      chart: "/performance/chart",
    },
    onboarding: {
      index: "/onboarding",
      business: "/onboarding/business",
      individual: "/onboarding/individual",
      terms: {
        index: "/onboarding/:type/terms",
        business: "/onboarding/business/terms",
        individual: "/onboarding/individual/terms",
        link: (type: Ionboard) => `/onboarding/${type}/terms`,
        agreement: {
          index: "/onboarding/:type/terms/agreement-info",
          link: (type: Ionboard) => `/onboarding/${type}/terms/agreement-info`,
        },
        authorize: {
          index: "/onboarding/:type/terms/authorize",
          link: (type: Ionboard) => `/onboarding/${type}/terms/authorize`,
        },
        authorize_with_link: {
          index: "/onboarding/:type/terms/authorize?external-link=true",
          link: (type: Ionboard) =>
            `/onboarding/${type}/terms/authorize?external-link=true`,
        },
      },
      document: {
        index: "/onboarding/:type/document",
        business: "/onboarding/business/document",
        individual: "/onboarding/individual/document",
        link: (type: Ionboard) => `/onboarding/${type}/document`,
        get_started: {
          index: "/onboarding/:type/document/create",
          link: (type: Ionboard) => `/onboarding/${type}/document/create`,
        },
        update_doc: {
          index: `/onboarding/:type/document/update`,
          link: (params: string, type: Ionboard) =>
            `/onboarding/${type}/document/update?type=${params}`,
        },
      },
      shareholders: {
        index: "/onboarding/:type/shareholders",
        business: "/onboarding/business/shareholders",
        individual: "/onboarding/individual/shareholders",
        link: (type: Ionboard) => `/onboarding/${type}/shareholders`,
        add: {
          index: "/onboarding/business/shareholders/add",
          link: (type: Ionboard) => `/onboarding/${type}/shareholders/add`,
        },
        remove: {
          index: "/onboarding/:type/shareholders/:shareid/remove",
          link: (shareid: string, type: Ionboard) =>
            `/onboarding/${type}/shareholders/${shareid}/remove`,
        },
      },
      bussiness_profile: "/onboarding/business/profile",
    },
    preview_app: "/preview",
    pool: {
      index: "/pool",
      tray: "/pool/tray",
      tray_limit: "/pool/tray/limit_full",
      pending_request: "/pool/pending-request",
      processing_request: "/pool/processing-request",
      junks: "/pool/junks",
      request_withdrwal: {
        index: "/pool/pending-request/:id/withdrawal",
        link: (id: string) => `/pool/pending-request/${id}/withdrawal`,
      },
      view_evidence: {
        index: "/pool/payment/:id/evidence",
        link: (id: number | string) => `/pool/payment/${id}/evidence`,
      },
      fund_transaction: {
        index: "/pool/payment/:id/fund-transaction",
        link: (id: number | string) => `/pool/payment/${id}/fund-transaction`,
      },
      fund_transaction_success: {
        index: "/pool/payment/:id/fund-transaction/success",
        link: (id: number | string) =>
          `/pool/payment/${id}/fund-transaction/success`,
      },
      withdrwal_success: {
        index: "/pool/pending-request/:id/successful-withdrawal",
        link: (id: string) =>
          `/pool/pending-request/${id}/successful-withdrawal`,
      },
      completed_request: "/pool/completed-request",
      issues: {
        index: "/pool/issues",
        message: {
          index: "/pool/issues/:id/chat",
          link: (id: string | number) => `/pool/issues/${id}/chat`,
        },
      },
    },
    crypto_wallet: {
      index: "/wallet/crypto",
      capital: "/wallet/crypto/capital",
      commission: "/wallet/crypto/commission",
      withdraw: {
        index: "/wallet/crypto/withdraw",
        network: "/wallet/crypto/withdraw/network-select",
        success: "/wallet/crypto/withdraw/successful",
        confirm: "/wallet/crypto/withdraw/confirm",
      },
      fund: "/wallet/crypto/fund-wallet",
    },
    fiat_wallet: {
      index: "/wallet/fiat",
      success: "/wallet/fiat/success",
      overview: {
        index: "/wallet/fiat/overview",

        transactions: {
          index: "/wallet/fiat/overview/transactions",
          all: "/wallet/fiat/overview/transactions/all",
          completed: "/wallet/fiat/overview/transactions/completed",
          pending: "/wallet/fiat/overview/transactions/pending",
          mismatched: "/wallet/fiat/overview/transactions/mismatched",
          abandoned: "/wallet/fiat/overview/transactions/abandoned",
          expired: "/wallet/fiat/overview/transactions/expired",
          claimed: "/wallet/fiat/overview/transactions/claimed",
          summary: "/wallet/fiat/overview/transaction-summary",
        },
        withdrawals: {
          index: "/wallet/fiat/overview/withdrawals",
          completed: "/wallet/fiat/overview/withdrawals/completed",
          failed: "/wallet/fiat/overview/withdrawals/failed",
          pending: "/wallet/fiat/overview/withdrawals/pending",
          processing: "/wallet/fiat/overview/withdrawals/processing",
        },
        withdrawal: {
          withdrawal: "/wallet/fiat/withdrawal",
          withdrawal_authorize: "/wallet/fiat/withdrawal-authorize",
          withdrawal_info: "/wallet/fiat/withdrawal-info",
          sender_info: "/wallet/fiat/sender-info",
        },
        fund: {
          fund: "/wallet/fiat/fund",
        },
        swap: "/wallet/fiat/currency-swap",
      },
      payment_methods: {
        index: "/wallet/fiat/methods",
        add_merchant: "/wallet/fiat/methods/add",
        add_merchant_success: "/wallet/fiat/method/add/success",
        turnoff: "/wallet/fiat/methods/turnoff",
        turnon: "/wallet/fiat/methods/turnon",
      },
      setup: {
        config: "/wallet/fiat/setup/config",
        success: "/wallet/fiat/setup/sucess",
      },
      claim: {
        confirm: "/wallet/fiat/claim/confirm",
        processing: "/wallet/fiat/claim/processing",
      },
      kyc: {
        submit: "/wallet/fiat/kyc/config",
        review: "/wallet/fiat/kyc/review",
        sucess: "/wallet/fiat/kyc/sucess",
      },
    },
    help: "/help",
    binance_external: {
      form: "/b2b",
      awaiting: "/b2b/awaiting-payment",
      confirmed: "/b2b/payment-confirmed",
    },
  },
};

export default routes;
