import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import Period from "../../../../common/components/CustomTable/Period";
import { useAppDispatch } from "../../../../common/hooks/redux";
import { toDollar } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import timer from "../../../../static/images/icons/timer.svg";
import { poolActions } from "../../store/slice";

const { FundTransactionSuccessful: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

type Iperks = "speed_perk" | "process_speed_perk" | "commission" | "insurance";
const perks: Iperks[] = [
  "speed_perk",
  "process_speed_perk",
  "commission",
  "insurance",
];
const grace_period = dayjs().add(3, "days").toISOString();
const temp = { grace_period };

function FundTransactionSuccessful() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const pool_itm = state.data;
  const dispatch = useAppDispatch();

  const closeModal = () => {
    navigate(state.background.pathname, { replace: true, state: null });
    if (state.id) {
      dispatch(
        poolActions.removePoolItemByType({
          id: state.id,
          type: "pending_requests",
        })
      );
    }
  };

  return (
    <CenterModal
      handleClose={closeModal}
      className="w-157.5 gap-y-7 rounded-xl py-9 px-14 text-blue_gray-10"
    >
      <header className="flex flex-col gap-y-2">
        <h4 className="w-[18.75rem] self-center rounded-3xl bg-green-10 px-3 py-2.5 text-center font-bold text-white">
          {PageDictionary.title}
        </h4>
        <p className="text-center text-lg leading-8">
          {PageDictionary.subtitle}
        </p>
        <div className="flex items-center justify-between text-1919 font-bold">
          <p>{toDollar(pool_itm.amount)}</p>
          <div className="flex w-20 items-center justify-between rounded-xl bg-brown-20 py-1.5 px-3 text-center">
            <img src={timer} alt="timer icon" />
            {pool_itm.dur}s
          </div>
        </div>
      </header>
      <div className="flex flex-col gap-y-9">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-1 items-start justify-around">
            {perks.map((itm) => (
              <div key={itm} className="flex flex-col gap-y-2.5 font-bold">
                <h6 className="text-[9.73px] leading-none text-gray-40">
                  {PageDictionary.performance[itm]}
                </h6>
                <p className="text-base leading-none">
                  {pool_itm.performance[itm]}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-y-14">
          <div className="flex items-center gap-x-7">
            <Period data={temp} />
            <p className="text-xs">
              {PageDictionary.message(
                dayjs(pool_itm?.updated_at).format("MMMM D, YYYY")
              )}
            </p>
          </div>
          <CustomButtom onClick={closeModal} className="self-center">
            {PageDictionary.subitAction}
          </CustomButtom>
        </div>
      </div>
    </CenterModal>
  );
}

export default FundTransactionSuccessful;
