import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import { getInfoNew } from "../../dashboard/store/thunk";
import service from "../service";
import { IfundPendingRequest, IissueThread, ImoveToJunk } from "../types";
import { IacceptrejectTrayItem } from "../types";
import { poolActions } from "./slice";

let abortControllerPending: any;
export const getPendingRequests = createAsyncThunk(
  "pool/pendingRequests",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    if (abortControllerPending) abortControllerPending.abort();
    abortControllerPending = new AbortController();
    const { signal } = abortControllerPending;
    return service
      .getPendingItems(id, signal)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        if (err.message) {
          customToast(err.message, true);
        }
        return rejectWithValue(err);
      });
  }
);
export const getProcessingRequest = createAsyncThunk(
  "pool/processingRequest",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getProccessingItems(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const getCompletedRequests = createAsyncThunk(
  "pool/completedRequests",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getCompletedItems(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getJunkItems = createAsyncThunk(
  "pool/junkRequests",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getJunkItems(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const moveToJunk = createAsyncThunk(
  "pool/moveToJunk",
  async (req: ImoveToJunk, { rejectWithValue, fulfillWithValue }) => {
    return service
      .moveToJunk(req)
      .then((res) => fulfillWithValue(req).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getIssues = createAsyncThunk(
  "pool/allIssues",
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    return service
      .getIssuesItems(id)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const sendIssueRequest = createAsyncThunk(
  "pool/sendIssueRequest",
  async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
    return service
      .sendIssueRequest(data)
      .then((res) => {
        dispatch(
          poolActions.addIssueToThread({
            exchanger_name: data.exchanger_name,
            sent_by: "from_exchanger",
            txn_reference: data.txn_reference,
            message: data.message,
            upload_url: data.upload_url,
          })
        );
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getResolutionThreadList = createAsyncThunk(
  "pool/issues/getResolutionThreadList",
  async (req: IissueThread, { rejectWithValue, fulfillWithValue }) => {
    const { user_id, txn_ref } = req;
    return service
      .getResolutionThreadList(user_id, txn_ref)
      .then((res) => fulfillWithValue(res.data).payload)
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const acceptTrayItem = createAsyncThunk(
  "pool/tray/accept",
  async (req: IacceptrejectTrayItem, { rejectWithValue, fulfillWithValue }) => {
    return service
      .acceptTrayItem(req)
      .then((res) => {
        return fulfillWithValue(req.id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const ignoreTrayItem = createAsyncThunk(
  "pool/tray/accept",
  async (
    data: IacceptrejectTrayItem,
    { rejectWithValue, dispatch, fulfillWithValue }
  ) => {
    return service
      .ignoreTrayItem(data)
      .then((res) => {
        dispatch(getPendingRequests(data.user_id));
        return fulfillWithValue(data.id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const fundPendingRequest = createAsyncThunk(
  "pool/pendingRequests/fund",
  async (
    req: IfundPendingRequest,
    { rejectWithValue, dispatch, fulfillWithValue }
  ) => {
    return service
      .fundPendingItem(req)
      .then((res) => {
        dispatch(getInfoNew(req.user_id));
        return fulfillWithValue(req.id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const uploadPaymentEvidence = createAsyncThunk(
  "pool/processingRequest/uploadPaymentEvidence",
  async (req: IfundPendingRequest, { rejectWithValue, fulfillWithValue }) => {
    return service
      .uploadPaymentEvidence(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(req.id).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
