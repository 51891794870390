import React from "react";
import { toCurrency } from "../../../utils/helpers";

type Props = {
  data: number;
};

function LocalAmount({ data }: Props) {
  return <span>{toCurrency(data)}</span>;
}

export default LocalAmount;
