import React from "react";
import { BsCheck2All } from "react-icons/bs";
import { useCopyToClipboard } from "../../../../../common/hooks";
import { CopyIcon } from "../../../../../static/Icons";
import { truncatedText } from "../../../../../common/utils/helpers";

type Props = {
  txn_ref: string;
};

export function TransactionRefrence({ txn_ref }: Props) {
  const { isCopied, copyToClipboard } = useCopyToClipboard();

  const text = truncatedText(txn_ref, 6);

  const clickHandler = () => copyToClipboard(txn_ref);

  return (
    <div onClick={clickHandler} className="flex items-center gap-x-2 cursor-pointer">
      <span className="">{text}</span>
      <span>{isCopied ? <BsCheck2All /> : <CopyIcon scale={0.5} />}</span>
    </div>
  );
}
