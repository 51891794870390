import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import * as tp from "../types";

const service = {
  async addBankAccount(data: tp.IaddAccount, id: string) {
    const { method, url: makeUrl } = ENDPOINTS.setting.bank.add_bank;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async listAllBankAccounts(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.setting.bank.list_all_banks;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async setDefaultBank(data: tp.Ibankdetail, id: string) {
    const { method, url: makeUrl } = ENDPOINTS.setting.bank.set_default_bank;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async deleteBankDetail(data: tp.Ibankdetail, id: string) {
    const { method, url: makeUrl } = ENDPOINTS.setting.bank.delete_bank;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async saveWithdrawalIntegration(data: tp.IwithdrawalApiSetting, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.withdrawal_integration.save_withdrawal_integration;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async enableWithdrawalIntegration(data: tp.IenableWithdrawal, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.withdrawal_integration.enable_withdrawal_integration;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async enableSettlement(data: tp.IsettlementType, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.enable_settlement;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async setPayoutThreshold(data: tp.IpaymentThreshold, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.set_payout_threshold;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async addSettlementWallet(data: tp.IaddSettlementWallet, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.add_settlement_wallet;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async getSettlementAddress(id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.get_settlment_address;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async deactivateSettlementAddress(data: tp.ItoggleAddressStatus, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.deactivate_settlment_address;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async activateSettlementAddress(data: tp.ItoggleAddressStatus, id: string) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.activate_settlment_address;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async downloadWalletCertificate(id: string, data: { otp_code: string }) {
    const { method, url: makeUrl } =
      ENDPOINTS.setting.settlement.download_wallet_certificate;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
