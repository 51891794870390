import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { deleteBankDetail } from "../../store/thunk";

const { bankDeleteConfirmationModal: PageDictionary } =
  Language.protected.SettingsPages.BankSettingsPage;

function BankDetailsDelete() {
  const { state } = useLocation();
  const { loading } = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      user_id: state.data.user_id,
      data: {
        bank_id: state.data.bank_id,
      },
      navigate,
      route: state.background.pathname,
    };
    dispatch(deleteBankDetail(data))
      .unwrap()
      .then(() => {
        navigate(state.background.pathname, { replace: true, state: null });
      });
  };

  const cancelHandler = () => {
    if (loading) {
      return;
    }
    navigate(state.background.pathname, { replace: true, state: null });
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={cancelHandler}
    >
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">*{PageDictionary.subtitle}</p>
        <div className="flex items-center gap-x-9">
          <CustomButtom disabled={loading} variant onClick={cancelHandler}>
            {PageDictionary.actions.reject}
          </CustomButtom>
          <CustomButtom isloading={loading}>
            {PageDictionary.actions.accept}
          </CustomButtom>
        </div>
      </form>
    </CenterModal>
  );
}

export default BankDetailsDelete;
