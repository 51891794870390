import isBetween from "dayjs/plugin/isBetween";
import dayjs from "dayjs";
import * as t from "../type";

dayjs.extend(isBetween);

export function pageNumberUpdateHelper<
  Type2 extends {checkbox:boolean} ,
  Type1 extends {
    info: t.IpaginationInfo;
    filter: t.Ifilter<Type2>;
    data: Type2[];
    export: t.IexportCheckBox;
  }
>(state: Type1, parent: Type2[], payload?: number) {
  if (state.data.find(itm => itm.checkbox === true)) {
    state.export.checkAll = false;
    state.export.enableExport = false;
  }
  if (payload) {
    state.info.currentPage = payload;
  }
  const start = state.info.maxPageDataCount * (state.info.currentPage - 1);
  const end = start + state.info.maxPageDataCount;
  const data = state.filter.isfiltered
    ? state.filter.data.slice(start, end)
    : parent.slice(start, end);
  state.data = [...data];
}

export function updateTotalDataCountAndPages<
  Type1 extends { date: string },
  Type2 extends { filter: t.Ifilter<Type1>; info: t.IpaginationInfo }
>(state: Type2, temp: Type1[]) {
  state.info.totalDataCount = temp.length;
  state.info.totalPages = Math.ceil(temp.length / state.info.maxPageDataCount);
}

export function dateFilterHelper<
  Type1 extends { date: string },
  Type2 extends { filter: t.Ifilter<Type1>; info: t.IpaginationInfo }
>(state: Type2, parent: Type1[], initialstate: Type2, payload: t.idateFilter) {
  state.filter.isfiltered = true;
  state.filter.query = payload;

  const temp = parent.filter((itm) =>
    dayjs(itm.date).isBetween(
      payload.start.toISOString(),
      payload.stop?.toISOString(),
      "day",
      "[)"
    )
  );

  state.filter.data = temp.length ? [...temp] : [];
  state.info.currentPage = initialstate.info.currentPage;
  updateTotalDataCountAndPages(state, temp);
}

export function checkAllHelper<
  Type extends {
    data: (t.Ifunding | t.Iwithdrawal)[];
    export: t.IexportCheckBox;
  }
>(state: Type): void {
  state.export.checkAll = !state.export.checkAll;
  // next line enables export if check all is checked
  state.export.enableExport = state.export.checkAll;

  const temp = state.data.map((itm) => ({
    ...itm,
    checkbox: state.export.checkAll,
  }));

  state.data = [...temp];
}

export function checkItemHelper<
  Type extends {
    data: (t.Ifunding | t.Iwithdrawal)[];
    export: t.IexportCheckBox;
  }
>(state: Type, id: string): void {
  const temp = state.data.map((itm) => {
    if (itm.id === id) {
      return { ...itm, checkbox: !itm.checkbox };
    }
    return { ...itm };
  });
  state.data = [...temp];
  // enables export when an item is checked
  state.export.enableExport = temp.find((itm) => itm.checkbox === true)
    ? true
    : false;
}
