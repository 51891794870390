import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { logoutUser } from "../../appState/store/thunk";
import { getCapitalHistory, getCommissionHistory } from "./thunk";
import { Iwallet } from "../type";

const initialState: Iwallet = {
  capital: {
    loading: false,
    data: [],
  },
  commission: {
    loading: false,
    data: [],
  },
};

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })
      .addCase(getCapitalHistory.fulfilled, (state, { payload }) => {
        const temp = payload.map((itm: any) => ({
          ...itm,
          date: itm.date.split(",").join(""),
        }));
        state.capital.data = [...temp];
      })
      .addCase(getCommissionHistory.fulfilled, (state, { payload }) => {
        state.commission.data = [...payload];
      })
      .addMatcher(isAnyOf(getCapitalHistory.pending), (state) => {
        state.capital.loading = true;
      })
      .addMatcher(
        isAnyOf(getCapitalHistory.fulfilled, getCapitalHistory.rejected),
        (state) => {
          state.capital.loading = false;
        }
      )
      .addMatcher(isAnyOf(getCommissionHistory.pending), (state) => {
        state.commission.loading = true;
      })
      .addMatcher(
        isAnyOf(getCommissionHistory.fulfilled, getCommissionHistory.rejected),
        (state) => {
          state.commission.loading = false;
        }
      );
  },
});

// export const {} = walletSlice.actions;

export default walletSlice.reducer;
