import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { Ibank_schema } from "../../../module/settings/types";
import Language from "../language/en";
import redux from "../../../store";
import { iteammember, member_perm } from "../../../module/team/type";

const VALIDATION_RULES = [
  {
    id: 1,
    title: "1 Capital Letter",
    passed: false,
    regex: "^(?=.*?[A-Z])",
  },
  { id: 2, title: "1 Number", passed: false, regex: "(?=.*?[0-9])" },
  {
    id: 3,
    title: "1 Special Character from !@#$%^&_",
    passed: false,
    regex: "(?=.*[!@#$%^&_])",
  },
  { id: 4, title: "8 Characters", passed: false, regex: ".{8,}" },
];

export const CheckPasswordStrength = (
  password: string
): { count: number; total: number } => {
  if (!password) return { count: 0, total: VALIDATION_RULES.length };

  let count = 0;

  VALIDATION_RULES.forEach((rule) => {
    if (password.match(rule.regex)) count += 1;
  });

  return { count, total: VALIDATION_RULES.length };
};

export const switchBg = (str: string) => {
  let temp: string;

  switch (str) {
    case "new":
      temp = "bg-brown-10";
      break;
    case "dispute":
      temp = "bg-red-300";
      break;
    case "resolved":
      temp = "bg-gray-120";
      break;
    case "funding":
      temp = "bg-blue_gray-10";
      break;

    default:
      temp = "bg-red-30";
      break;
  }

  return temp;
};

export const validationRules = {
  string: /(.|\s)*\S(.|\s)*/,
  email:
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
  number: /^\d+$/,
  website:
    /^http:\/\/localhost|https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, //eslint-disable-line
  ip: /\b(?:\d{1,3}\.){3}\d{1,3}\b/,
};

export const addCommas = (num: string) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = (num: string) =>
  num?.toString()?.replace(/[^0-9]/g, "");
export const removeCommas = (num: string) =>
  num?.toString()?.replace(/[, ]+/g, "")?.trim();

export const toCurrency = (amount: number) => {
  const country_data = redux.store.getState().appState.appState.country_data;

  const alpha2 = country_data?.alpha2;
  const language = country_data?.languages[0];
  const currency = country_data?.currencies[0];
  const code = `${language}-${alpha2}`;

  const data =
    alpha2 &&
    amount?.toLocaleString(code, {
      style: "currency",
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return data;
};
export const localCurrencySymbol = (amount: string) => {
  const { alpha2, languages, currencies } =
    redux.store.getState().appState.appState?.country_data;
  const format = `${languages[0]}-${alpha2}`;
  const curr = `${currencies[0]}`;
  const symbol = `${
    0
      ?.toLocaleString(format, {
        style: "currency",
        currency: curr,
      })
      .split("0.")[0]
  }`;

  return symbol + amount;
};
export const toDollar = (amount: number) => {
  const currency = amount.toLocaleString(`eng-US`, {
    style: "currency",
    currency: `USD`,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return currency;
};

export const greetHandler = (day: Date, dic: any) => {
  return day.getHours() < 12
    ? dic.morning
    : 12 >= day.getHours() && day.getHours() <= 16
    ? dic.afternoon
    : dic.evening;
};

export const dateHandler = (date: Date): string => {
  const { alpha2, languages } =
    redux.store.getState().appState.appState?.country_data;
  return (
    alpha2 &&
    date.toLocaleDateString(`${languages[0]}-${alpha2}`, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    })
  );
};

export const convertBankSchemaToTableData = (data: Ibank_schema[]) => {
  const res: any = {};
  data.forEach((details) => {
    res[details.key_name] = details.value;
  });
  return res;
};

export const renderPoolStatusCount = (
  pr_args: any[],
  cr_args: any[],
  iss_args: any[],
  name: string
): JSX.Element | null => {
  let template: any = null;
  const { issues, pending_r, processing_r } = Language.protected.PoolPages.nav;
  switch (name) {
    case pending_r:
      template =
        pr_args.length !== 0 ? (
          <span className="rounded-10 bg-green-400 p-2 text-xs text-white">
            {pr_args.length}
          </span>
        ) : null;
      break;
    case processing_r:
      template =
        cr_args.length !== 0 ? (
          <span className="rounded-10 bg-green-400 p-2 text-xs text-white">
            {cr_args.length}
          </span>
        ) : null;
      break;
    case issues:
      template =
        iss_args.length !== 0 ? (
          <span className="rounded-10 bg-red-300 p-2 text-xs text-white">
            {iss_args.length}
          </span>
        ) : null;
      break;

    default:
      template = null;
      break;
  }
  return template;
};

export const ADMIN_PERM = "ADMIN";
export const ACCEPT_REQUEST_PERM = "ACCEPT_REQUEST";
export const PROCESS_REQUEST_PERM = "PROCESS_REQUEST";
export const ISSUES_PERM = "ISSUES";

export const formatTeamPermission = (payload: member_perm[]) => {
  const users: iteammember[] = [];
  payload.forEach((itm) => {
    let index: number = 0;
    if (
      users.find((user, idx) => {
        index = idx;
        return user.email === itm.email;
      })
    ) {
      users[index].permission.push(itm.permission_name);
      return;
    }
    users.push({
      id: itm.team_id,
      email: itm.email,
      status: itm.status,
      permission: [itm.permission_name],
      created_at: itm.created_at,
    });
  });
  return users;
};
export const formatPermissionName = (name: string) => {
  return name.split("_").join(" ").toLowerCase();
};

export const formatInstanceAccount = (transaction: any) => {
  // const details: any = {};
  const details: Record<string, string> = {};
  if (transaction) {
    Object.keys(transaction)?.forEach((key) => {
      details[transaction[key].name] = transaction[key].value;
    });
  }
  return details;
};

export const querySwitch = (
  path: string,
  user_id: string,
  pagenumber: number,
  pagesize: number
): string => {
  return `${path}?user_id=${user_id}&page_no=${pagenumber}&page_size=${pagesize}`;
};

export const tableDataToCsvDownload = (data: any[], title: string) => {
  const formatedTitle = title.trim().split(" ").join("_");
  let csvContent = "data:text/csv;charset=utf-8,";
  data.forEach(function (rowArray, index) {
    let row;
    if (index === 0) {
      row = Object.keys(rowArray).join(",");
      csvContent += formatPermissionName(row) + "\r\n";
    }
    row = Object.values(rowArray).join(",");
    csvContent += row + "\r\n";
  });

  let encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${formatedTitle}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const mockResponseHandler = async (
  mockSuccess: any,
  mockError: any,
  data?: any
): Promise<any> => {
  if (data) {
    if (Object.values(data).includes("") === false) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(mockSuccess);
        }, 3000);
      });
    }
    if (Object.values(data).includes("") === true) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(mockError);
        }, 3000);
      });
    }
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockSuccess);
    }, 3000);
  });
};

export const pageAccessedByReload =
  (window.performance.navigation && window.performance.navigation.type === 1) ||
  window.performance
    .getEntriesByType("navigation")
    .map((nav) => nav?.entryType)
    .includes("reload");

function convertObjectToCSV(data: object[]): string {
  const headers = Object.keys(data[0]);
  const rows = data.map((item: any) =>
    headers.map((header: string) => item[header])
  );

  const csvContent = [
    headers.join(","),
    ...rows.map((row: any[]) => row.join(",")),
  ].join("\n");

  return csvContent;
}

export function downloadObjectAsCSV(data: object[], filename: string): void {
  const csvContent = convertObjectToCSV(data);
  const csvData = new Blob([csvContent], { type: "text/csv" });
  const csvURL = URL.createObjectURL(csvData);

  const linkElement = document.createElement("a");
  linkElement.href = csvURL;
  linkElement.download = filename;
  linkElement.click();

  // Clean up the URL object after the download
  URL.revokeObjectURL(csvURL);
}

export const truncatedText = (text: string, maxlength: number) => {
  if (text?.length > maxlength) {
    return `${text?.substring(0, 3)}...${text?.substring(text?.length - 4)}`;
  }

  return text;
};

export const tW = (...className: clsx.ClassValue[]) => {
  return twMerge(clsx(...className));
};

export const currencyConverter = (
  amount: number,
  currency: string,
  precision = 0
) => {
  let locale = "en-US";

  if (currency === "NGN") {
    locale = "en-NG";
  }

  const CURRENCY_CONVERTER = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    maximumFractionDigits: precision,
  });

  return CURRENCY_CONVERTER.format(amount);
};
