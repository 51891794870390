import React, { useRef, useState } from "react";
import copy from "../../../static/images/icons/copysimple.svg";
import { BsCheckAll } from "react-icons/bs";
import { tW } from "../../utils/helpers";

type Props = {
  walletAddress: string;
};

function ClipboardCopy({ walletAddress }: Props) {
  const addressRef = useRef<HTMLHeadingElement | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(walletAddress)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <h4 className="text-xl font-bold" ref={addressRef}>
        {walletAddress}
      </h4>
      <p className="text-sm">
        Network - <span className="font-bold">TRC-20</span>
      </p>
      <button
        type="button"
        className={tW(
          "flex items-center gap-x-2 text-1415",
          isCopied ? "animate-pulse text-green-10" : "text-gray-30"
        )}
        onClick={handleCopyClick}
      >
        {isCopied ? (
          <BsCheckAll className="text-2xl" />
        ) : (
          <img
            src={copy}
            alt="copy wallet address to clipboard"
            className="h-7 w-6"
          />
        )}
        <span>{isCopied ? "Address Copied!" : "Copy Address"}</span>
      </button>
    </>
  );
}

export default ClipboardCopy;
