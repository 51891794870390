import React from "react";
// import { IbusinessDocumentStatus } from "../../../types";
import Language from "../../../../../common/utils/language/en";
import { tW } from "../../../../../common/utils/helpers";

type Props = {
  status: any;
};

const PageDictionary =
  Language.protected.compalinacePages.business_document.table_component
    .status_text;

function BusinessStatus({ status }: Props) {
  return (
    <span
      className={tW(
        "capitalize",
        status === "PENDING" ? "text-orange-300" : "",
        status === "APPROVED" ? "text-green-40" : "",
        status === null ? "text-red-40" : ""
      )}
    >
      {status === null ? PageDictionary.please_submit : null}
      {status !== null && status === "PENDING"
        ? PageDictionary.pending_approval
        : null}
      {status !== null && status === "APPROVED"
        ? PageDictionary.approved
        : null}
      {status !== null && status === "REJECTED"
        ? PageDictionary.rejected
        : null}
    </span>
  );
}

export default BusinessStatus;
