import { AsyncThunkAction } from "@reduxjs/toolkit";
import { useEffect, useRef } from "react";
import { useAppDispatch } from "../redux";

function useDispatchOnce(action: AsyncThunkAction<any, any, any>) {
  const loadOnceRef = useRef(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loadOnceRef.current === true) {
      return;
    }
    dispatch(action);
    loadOnceRef.current = true;
  }, [action, dispatch]);
  return;
}

export { useDispatchOnce };
