import React, { useId, useState } from "react";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import CustomTooltip from "../CustomTooltip";
import { tW } from "../../utils/helpers";

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  lclassName?: string;
  containerClassName?: string;
  errorLabel?: string;
  cref?: React.LegacyRef<HTMLInputElement>;
  tip?: string;
  rules?: RegExp;
  haserror?: string;
  successLabel?: string;
  wclassName?: string;
}

function CustomInput({
  label,
  type,
  containerClassName,
  errorLabel,
  lclassName,
  cref,
  tip,
  rules,
  haserror,
  successLabel,
  className,
  name,
  wclassName,
  ...props
}: props) {
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={tW("form_group", containerClassName)}>
      <label
        className={tW(
          "text-base-2 mb-2 inline-block text-base font-medium capitalize",
          lclassName
        )}
        htmlFor={`${id}-${name}`}
      >
        {label}
        {tip && <CustomTooltip tip={tip} />}
      </label>
      <div className="relative">
        {type === "password" && (
          <div
            className="absolute right-5 top-6 cursor-pointer text-black"
            onClick={() => setShowPassword((prev) => !prev)}
          >
            {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
          </div>
        )}
        <input
          {...props}
          name={name}
          className={tW(
            "border-blue_gray-300 h-[3.75rem] w-full rounded-xl border border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600",
            name === "password" ? "pr-12" : "",
            haserror === "true" ? "border-red-40 " : "",
            className
          )}
          id={`${id}-${name}`}
          type={showPassword ? "text" : type}
          ref={cref}
          data-rules={rules}
        />
        {haserror === "true" || errorLabel || successLabel ? (
          <p
            className={tW(
              "mt-1 text-right text-xs text-red-40",
              successLabel ? "text-left text-green-10" : "",
              wclassName
            )}
          >
            {successLabel
              ? successLabel
              : errorLabel
              ? `*${errorLabel}`
              : "*Invalid Input Value"}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default CustomInput;
