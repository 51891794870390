import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import Language from "../../../../common/utils/language/en";

export const paymentModalHelper = [
  {
    title: "Amount",
    value: "$1,003.94",
    id: "amount_label",
  },
  {
    title: "Bank",
    value: "Access Bank PLC",
    id: "bank_name",
  },
  {
    title: "Account Number",
    value: "0810101813",
    id: "account_number",
  },
  {
    title: "Account Name",
    value: "Charles Avis A",
    id: "account_name",
  },
];

const { FundWalletForm: PageDictionary } =
  Language.protected.fiatModals.funding;

function FundWallet() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const goBack = () => navigate(state?.background.pathname, { replace: true });

  return (
    <SideModal clickBackDrop={goBack} className="flex px-15 py-24">
      <section className="flex flex-1 flex-col gap-y-14 pr-18">
        <header className="">
          <h3 className="text-2xl font-semibold">{PageDictionary.title}</h3>
        </header>
        <p className="pt-2">{PageDictionary.subtitle}</p>
        <div className="flex flex-col gap-y-5">
          <p>{PageDictionary.section_title}</p>
          <div className="grid grid-cols-3 gap-y-7 gap-x-20">
            {paymentModalHelper.map((itm) =>
              itm.id !== "amount_label" ? (
                <div key={itm.id} className="flex flex-col gap-y-3">
                  <p className="text-sm text-gray-750">{itm.title}</p>
                  <p className="text-1919 font-bold text-base-30">
                    {state.data[itm.id]}
                  </p>
                </div>
              ) : null
            )}
          </div>
        </div>
        <div className="flex flex-1 items-end ">
          <CustomButtom className="" onClick={goBack}>
            {PageDictionary.close}
          </CustomButtom>
        </div>
      </section>
    </SideModal>
  );
}

export default FundWallet;
