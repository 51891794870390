import React from "react";
import { BsDownload } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import Language from "../../../../common/utils/language/en";

const { PaymentEvidence: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

function PaymentEvidence() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const closeModal = () =>
    navigate(state.background.pathname, { replace: true, state: null });

  return (
    <CenterModal
      handleClose={closeModal}
      className="w-157.5 gap-y-5 rounded-xl px-12 pt-8 pb-9 text-blue_gray-10"
    >
      <header className="flex w-full items-center justify-between">
        <h3 className="text-[1.375rem] font-bold leading-9">
          {PageDictionary.title}
        </h3>
        <a
          download={"evidence.jpeg"}
          href={state.data.payment_evidence}
          rel="noreferrer"
          className="flex items-center gap-x-2 rounded border !border-green-10 px-2 py-2 !text-green-10"
          target={"_blank"}
        >
          <BsDownload size={12} /> {PageDictionary.downloadAction}
        </a>
      </header>
      <div className="flex flex-col gap-y-5">
        <p>{PageDictionary.message}</p>
        <img src={state.data.payment_evidence} alt="evidence " />
        <div className="mt-3.5 flex items-center justify-end">
          <CustomButtom onClick={closeModal} className=" bg-green-10">
            {PageDictionary.closeAction}
          </CustomButtom>
        </div>
      </div>
    </CenterModal>
  );
}

export default PaymentEvidence;
