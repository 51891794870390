import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import CustomButton from "../../../../../common/components/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import { claimAllFunds } from "../../../store/thunk";
import routes from "../../../../../common/routes";
import { toCurrency } from "../../../../../common/utils/helpers";

function ConfirmClaim() {
  const { user_id } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();

  const { total_mismatched, total_mismatched_count } = useAppSelector(
    (state) => state.payment
  );
  const { total_failed_payout } = useAppSelector(
    (state) => state.payment.p2p_payout_stats
  );

  const handleBack = () => {
    if (loading) return;
    navigate(state?.background.pathname, { replace: true });
  };
  const handleNext = () => {
    if (loading) return;
    navigate(routes.protected.fiat_wallet.claim.processing, {
      replace: true,
      state: { ...state },
    });
  };

  const claimHandler = () => {
    setLoading(true);
    dispatch(claimAllFunds({ user_id }))
      .unwrap()
      .then(handleNext)
      .catch(handleBack);
  };

  return (
    <CenterModal
      handleClose={handleBack}
      className="rounded-none bg-transparent p-0"
    >
      <div className="min-h-[42.438rem] w-[26.813rem] rounded-[2.625rem]  bg-[#FAFAFA] py-[2.313rem] px-8">
        <header className="mb-8 flex flex-col items-center gap-y-7">
          <h1 className="text-3xl font-bold text-[#0B112F]">Confirm Claim!</h1>
          <p className="text-center text-base  text-[#525869]">
            Confirm that you want to claim all your Mistmatched & Failed
            transactions as at this time.
          </p>
        </header>
        <div className="mb-6 flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold text-[#0F3DB4]">
              Mis-Match Transactions
            </p>
            <p className="text-base font-bold text-[#6F6F6F]">
              {toCurrency(total_mismatched ? total_mismatched : 0)}
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold text-[#0F3DB4]">
              Total Mis-Match
            </p>
            <p className="text-base font-bold text-[#6F6F6F]">
              {total_mismatched_count}
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold text-[#0F3DB4]">
              Expired Transactions Amount
            </p>
            <p className="text-base font-bold text-[#6F6F6F]">
              {toCurrency(total_failed_payout)}
            </p>
          </div>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold text-[#0F3DB4]">
              Total Expired Transactions
            </p>
            <p className="text-base font-bold text-[#6F6F6F]">
              {total_failed_payout}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-y-6">
          <div className="flex flex-col items-center gap-y-2 self-stretch">
            <p className="font-bold text-[#6F6F6F]">Total Order Amount</p>
            <div className="flex justify-center self-stretch rounded-xl bg-[#D7FAECC2] py-5">
              <p className="text-2xl text-[#555555] ">
                {toCurrency(
                  total_failed_payout +
                    parseInt(total_mismatched ? total_mismatched : "0")
                )}
              </p>
            </div>
            <p className="text-xs text-[#FF0000]">
              Total Mistmatched + Expired transactions
            </p>
          </div>
          <CustomButton isloading={loading} onClick={claimHandler}>
            Continue
          </CustomButton>
        </div>
      </div>
    </CenterModal>
  );
}

export default ConfirmClaim;
