import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import { usePageTitle } from "../../../../common/hooks";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";

const { errorPage: PageDictionary } = Language.nonprotected;

function ErrorPage() {
  usePageTitle(PageDictionary.pageTitle);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const renderErrorPageBtn = (path: string): string =>
    path.includes("auth") ? PageDictionary.login : PageDictionary.dashboard;
  const clickHandler = () => {
    pathname.includes("auth")
      ? navigate(routes.nonprotected.login)
      : navigate(routes.protected.dashboard.crypto);
  };

  return (
    <div className="flex h-screen p-20 design_screen:mx-auto design_screen:max-w-screen-design_screen">
      <div className="flex-1 bg-blue_gray-10 bg-errorBg bg-contain bg-right-top bg-no-repeat px-10 py-40 text-white ">
        <div className="flex w-1/3 flex-col gap-y-8">
          <header>
            <h3 className="text-[4rem] font-bold leading-none">
              {PageDictionary.heading}
            </h3>
            <h1 className="text-[3.5rem] font-normal leading-none">
              {PageDictionary.subheading}
            </h1>
          </header>

          <CustomButtom
            onClick={clickHandler}
            className="mt-20 self-start rounded-none"
          >
            {renderErrorPageBtn(pathname)}
          </CustomButtom>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
