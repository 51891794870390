import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineCalendar } from "react-icons/ai";
import {
  useAppDispatch,
  useAppSelector,
  useScrollToTop,
} from "../../../../common/hooks";
import CustomButton from "../../../../common/components/CustomButton";
import {
  dateHandler,
  greetHandler,
  toCurrency,
} from "../../../../common/utils/helpers";
import routes from "../../../../common/routes";
import { getBankInfoFunding } from "../../store/thunk";
import Language from "../../../../common/utils/language/en";
import customToast from "../../../../common/components/CustomToast";
import {
  DropDownItem,
  DropDownMenuContent,
  DropdownMenu,
  DropdownMenuTrigger,
} from "../../../../common/components/CustomDropDown";
import { HiChevronDown } from "react-icons/hi";
import { isGoldPartner } from "../../../../common/utils/helpers/partners";

const { banner: PageDictionary } = Language.protected.fiatPages.overviewPage;

const day = new Date();

function Banner() {
  const { titleRef } = useScrollToTop();
  const navigate = useNavigate();
  const location = useLocation();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const {
    wallet_balance,
    payment_config,
    pending_balance,
    min_payout_amounts,
    partner_mapping,
    p2p_info: { p2p },
  } = useAppSelector((state) => state.payment);
  const { currencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );
  const userCurrency = currencies[0] as keyof typeof min_payout_amounts;
  const minPayoutAmount = min_payout_amounts[userCurrency];
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const isBinance = p2p.find(
    (itm) => isGoldPartner(itm.partner_id) && itm.is_p2p_turned_on === "ON"
  );

  const state = { state: { background: location } };

  const navigateFundWallet = () => {
    setLoading(true);
    dispatch(getBankInfoFunding(user_id))
      .unwrap()
      .then((res) => {
        navigate(routes.protected.fiat_wallet.overview.fund.fund, {
          state: { ...state.state, data: res },
        });
      })
      .finally(() => setLoading(false));
  };
  const navigateWithdralWallet = () => {
    if (wallet_balance <= minPayoutAmount) {
      customToast("Fiat wallet balance is not more than minimum balance", true);
    } else {
      navigate(
        routes.protected.fiat_wallet.overview.withdrawal.withdrawal,
        state
      );
    }
  };

  const handleNavigateClaimRecords = () => {
    navigate(routes.protected.fiat_wallet.overview.transactions.claimed);
  };

  const swapHandler = () => {
    if (loading) return;
    navigate(routes.protected.fiat_wallet.overview.swap, state);
  };

  return (
    <div
      ref={titleRef}
      className="relative col-span-full mt-6  flex items-center justify-between gap-x-32 rounded-md bg-gray-420 px-9 pl-11 pt-9 pb-8 text-white"
    >
      <div>
        <div className="flex items-center gap-x-1.5">
          <AiOutlineCalendar />
          <p className="text-[0.875rem] leading-[1.05rem] ">
            {dateHandler(day)}
          </p>
        </div>
        <h3 className="text-[2rem] font-bold leading-[2.4rem]">
          {`${greetHandler(day, PageDictionary.greeting)}`}
        </h3>
        <p className="text-[0.875rem] leading-[1.05rem] ">{`Hello ${payment_config?.account_name}`}</p>
      </div>
      <div className="flex flex-1 flex-col gap-y-3 ">
        <div className="flex items-center justify-start gap-x-14">
          <div className="flex flex-col gap-y-2">
            <p className="text-sm font-semibold">{PageDictionary.balance}</p>
            <p className="text-2226 font-bold">{toCurrency(wallet_balance)}</p>
          </div>
          <div className="flex items-center justify-center gap-x-7">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <button
                  // onClick={navigateWithdralWallet}
                  className=" flex  cursor-pointer items-center gap-x-1 rounded-lg border border-green-400 bg-transparent py-2 px-12 font-semibold text-green-400 disabled:cursor-not-allowed disabled:bg-slate-200"
                >
                  Wallet
                  <HiChevronDown className="ml-1" />
                </button>
              </DropdownMenuTrigger>
              <DropDownMenuContent className="z-10 mt-2 grid w-44 grid-cols-1 gap-y-4 rounded-lg  border border-gray-250 bg-white p-3.5 font-bold text-[#1E3F52] shadow-team_header">
                <DropDownItem className="">
                  <button
                    onClick={navigateWithdralWallet}
                    className="h-6 w-full rounded-10 bg-gray-70 py-1 px-3 text-start text-sm font-medium leading-3 text-[#1E3F52]"
                  >
                    {PageDictionary.withdrawal}
                  </button>
                </DropDownItem>
                {/* <DropDownItem className="">
                  <button
                    disabled={loading}
                    onClick={navigateFundWallet}
                    className="h-6 w-full rounded-10 bg-gray-70 py-1 px-3 text-start text-sm font-medium leading-3 text-[#1E3F52]"
                  >
                    {PageDictionary.fund}
                  </button>
                </DropDownItem> */}
              </DropDownMenuContent>
            </DropdownMenu>
            {isBinance?.is_p2p_turned_on === "ON" ? null : (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <button className="text-whiite  flex cursor-pointer items-center gap-x-1 rounded-lg border border-green-400 bg-green-400 py-2 px-12 font-semibold disabled:cursor-not-allowed disabled:bg-slate-200">
                    {PageDictionary.swap}
                    <HiChevronDown className="ml-1" />
                  </button>
                </DropdownMenuTrigger>
                <DropDownMenuContent className="z-10 mt-2 grid w-44 grid-cols-1 gap-y-4 rounded-lg  border border-gray-250 bg-white p-3.5 font-bold text-[#1E3F52] shadow-team_header">
                  <DropDownItem className="">
                    <button
                      onClick={swapHandler}
                      className="h-6 w-full rounded-10 bg-gray-70 py-1 px-3 text-start text-sm font-medium leading-3 text-[#1E3F52]"
                    >
                      {PageDictionary.swap}
                    </button>
                  </DropDownItem>
                  <DropDownItem className="">
                    <button
                      onClick={handleNavigateClaimRecords}
                      className="h-6 w-full rounded-10 bg-gray-70 py-1 px-3 text-start text-sm font-medium leading-3 text-[#1E3F52]"
                    >
                      Records
                    </button>
                  </DropDownItem>
                </DropDownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
        <p className="text-xs font-bold text-orange-10">
          {PageDictionary.settlement}{" "}
          <span className="font-normal">
            {" "}
            {/* {pending_balance.currency}{" "} */}
            {toCurrency(pending_balance.pending_amount)}{" "}
            <span>({pending_balance.date_time})</span>
          </span>{" "}
        </p>
      </div>
    </div>
  );
}

export default Banner;
