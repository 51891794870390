import { AiOutlineCalendar } from "react-icons/ai";
import { useAppSelector, useScrollToTop } from "../../../../common/hooks";
import {
  dateHandler,
  greetHandler,
  toDollar,
} from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import HeaderCard from "../HeaderCard";

const { banner: PageDictionary } = Language.protected.DashboardPage;

const day = new Date();

function Banner() {
  const { titleRef } = useScrollToTop();
  const { capital_wallet, commission_wallet } = useAppSelector(
    (state) => state.dashboard.home
  );

  return (
    <div
      ref={titleRef}
      className="relative  col-span-full flex items-center justify-between rounded-md bg-blue_gray-10 px-9 pt-6 pb-4.5 text-white"
    >
      <div>
        <div className="flex items-center gap-x-1.5">
          <AiOutlineCalendar />
          <p className="text-[0.875rem] leading-[1.05rem] ">
            {dateHandler(day)}
          </p>
        </div>
        <h3 className="text-[2rem] font-bold leading-[2.4rem]">
          {`${greetHandler(day, PageDictionary.greeting)}`}
        </h3>
      </div>
      <div className="flex items-center gap-x-12">
        <HeaderCard
          data_tut="instextour__dashboard-wallet"
          type={PageDictionary.capital}
          data={toDollar(capital_wallet)}
        />
        <HeaderCard
          type={PageDictionary.commission}
          data={toDollar(commission_wallet)}
        />
      </div>
    </div>
  );
}

export default Banner;
