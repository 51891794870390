import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ClipboardCopy from "../../../../common/components/ClipboardCopy";
import CustomButtom from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { iHandleSubmit } from "../../../dashboard/types";

const { FundWalletModal: PageDictionary } = Language.protected.DashboardPage;

function FundWallet() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { wallet_address } = useAppSelector((state) => state.dashboard);
  const backToTeam = () =>
    navigate(state?.background.pathname, { replace: true });

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    backToTeam();
  };

  return (
    <SideModal clickBackDrop={backToTeam} className="px-15 pt-24">
      <section className="pr-18">
        <header className="border-b border-gray-50 pb-1">
          <h3 className="text-4xl font-semibold">{PageDictionary.title}</h3>
        </header>
        <p className="pt-6">{PageDictionary.subtitle}</p>
        <form
          className="flex flex-col gap-y-6 pr-8 pt-9"
          onSubmit={submitHandler}
        >
          <CustomInput label={PageDictionary.fundLabel} value="USDT" readOnly />
          <div className="flex flex-col gap-y-6">
            <p className="text-sm">{PageDictionary.walletAddressLabel}</p>
            <ClipboardCopy walletAddress={wallet_address} />
            <p className="text-sm leading-6 text-red-40">
              {PageDictionary.walletInstruction}
            </p>

            <ul className="my-4 list-inside list-disc text-base">
              {PageDictionary.list.map((itm) => (
                <li key={itm}>{itm}</li>
              ))}
            </ul>
          </div>

          <CustomButtom className="self-start ">
            {PageDictionary.submitAction}
          </CustomButtom>
        </form>
      </section>
    </SideModal>
  );
}

export default FundWallet;
