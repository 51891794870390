import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import { chartFilterType } from "../types";

const service = {
  async getMetrics(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.performance.metric;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async getChartData(id: string, filterby: chartFilterType) {
    const { method, url: makeUrl } = ENDPOINTS.performance.chart;
    const url = makeUrl(id, filterby);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
};

export default service;
