import React, { useEffect, useState } from "react";
import ReactJoyride, { CallBackProps } from "react-joyride";
import { useLocation } from "react-router-dom";
import TourGuide from "../../../module/appState/components/TourGuide";
import {
  endTour,
  updateTourStepIndex,
} from "../../../module/appState/store/slice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";

function CustomTourGuide() {
  const { pathname } = useLocation();
  const [startTour, setStartTour] = useState(true);

  const dispatch = useAppDispatch();

  //eslint-disable-next-line
  const { run, stepIndex, steps } = useAppSelector(
    (state) => state.appState.tour
  );
  // injects tooltip steps into the application
  useEffect(() => {
    if (startTour === false) {
      return;
    }
    // if (pathname === routes.protected.dashboard.index) {
    //   dispatch(updateTourSteps(newsteps.dashboardsteps));
    // }
  }, [pathname, startTour]);

  // callback functiont to manipulate state of the tooltip steps
  const handleCallback = (data: CallBackProps) => {
    //eslint-disable-next-line
    const { action, index, lifecycle, type } = data;

    if (action === "next" && type === "step:after") {
      dispatch(updateTourStepIndex(index + 1));
    }
    if (action === "prev" && type === "step:after") {
      dispatch(updateTourStepIndex(index - 1));
    }
    if (action === "close" && type === "step:after") {
      dispatch(endTour());
      setStartTour(false);
    }
  };
  return (
    <ReactJoyride
      callback={handleCallback}
      continuous
      disableScrolling
      stepIndex={stepIndex}
      steps={steps}
      tooltipComponent={TourGuide}
      styles={{
        options: { arrowColor: "transparent" },
      }}
    />
  );
}

export default CustomTourGuide;
