import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import { tW } from "../../utils/helpers";

export type iCalendarChange = (
  date: [Date | null, Date | null],
  event?: React.SyntheticEvent<any, Event>
) => void;

export interface Icalendar {
  startDate?: Date | null;
  endDate?: Date | null;
  onChange: iCalendarChange;
  type: "range" | "picker";
  disable?: boolean;
  minDate?: boolean;
  maxDate?: boolean;
  placeholder?: string;
  calRef?: React.LegacyRef<DatePicker<never, true>>;
  className?: string;
}
const CustomCalender = ({
  startDate,
  endDate,
  onChange,
  type,
  disable,
  minDate,
  placeholder,
  maxDate,
  calRef,
  className,
}: Icalendar) => {
  let template;
  switch (type) {
    case "picker":
      template = (
        <DatePicker
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
          }) => (
            <div className="calender_header">
              <button
                type="button"
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                style={{
                  visibility: customHeaderCount === 1 ? "hidden" : "visible",
                }}
                onClick={decreaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span className="react-datepicker__current-month">
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                type="button"
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                style={{
                  visibility: customHeaderCount === 1 ? "hidden" : "visible",
                }}
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </div>
          )}
          className={tW(
            "w-full cursor-pointer rounded border border-gray-800 py-2.5 pr-2 pl-9 text-sm",
            className
          )}
          selected={startDate}
          onChange={onChange}
          monthsShown={2}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          disabled={disable}
          maxDate={maxDate ? new Date() : null}
          minDate={minDate ? new Date() : null}
          placeholderText={placeholder}
          required
          ref={calRef}
        />
      );
      break;

    case "range":
      template = null;
      // <DatePicker
      //   renderCustomHeader={({
      //     monthDate,
      //     customHeaderCount,
      //     decreaseMonth,
      //     increaseMonth,
      //   }) => (
      //     <div className="calender_header">
      //       <button
      //         type="button"
      //         aria-label="Previous Month"
      //         className={
      //           "react-datepicker__navigation react-datepicker__navigation--previous"
      //         }
      //         style={
      //           customHeaderCount === 1 ? { visibility: "hidden" } : null
      //         }
      //         onClick={decreaseMonth}
      //       >
      //         <span
      //           className={
      //             "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
      //           }
      //         >
      //           {"<"}
      //         </span>
      //       </button>
      //       <span className="react-datepicker__current-month">
      //         {monthDate.toLocaleString("en-US", {
      //           month: "long",
      //           year: "numeric",
      //         })}
      //       </span>
      //       <button
      //         type="button"
      //         aria-label="Next Month"
      //         className={
      //           "react-datepicker__navigation react-datepicker__navigation--next"
      //         }
      //         style={
      //           customHeaderCount === 0 ? { visibility: "hidden" } : null
      //         }
      //         onClick={increaseMonth}
      //       >
      //         <span
      //           className={
      //             "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
      //           }
      //         >
      //           {">"}
      //         </span>
      //       </button>
      //     </div>
      //   )}
      //   selected={startDate}
      //   onChange={onChange}
      //   startDate={startDate}
      //   endDate={endDate}
      //   selectsRange
      //   monthsShown={2}
      //   maxDate={maxDate && new Date()}
      //   minDate={minDate && new Date()}
      //   disabled={disable}
      //   placeholderText={placeholder}
      //   className="test"
      //   wrapperClassName="tester"
      //   required
      // />
      break;
    default:
      template = <div></div>;
      break;
  }

  return template;
};

export default CustomCalender;
