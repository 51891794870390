import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GoKebabHorizontal } from "react-icons/go";
import {
  DropDownItem,
  DropdownMenu,
  DropDownMenuContent,
  DropdownMenuTrigger,
} from "../../../../../common/components/CustomDropDown";
import edit from "../../../../../static/images/icons/edit.svg";
import trashicon from "../../../../../static/images/icons/delete.svg";
import routes from "../../../../../common/routes";
import Language from "../../../../../common/utils/language/en";
import { useAppSelector } from "../../../../../common/hooks";

type Props = {
  member_id: string;
  status: string;
};

const { tableAction: PageDictionary } = Language.protected.TeamPages;

function TeamActions({ member_id, status }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const team_id = useAppSelector((state) => state.auth.user.user_id);
  const disable_suspend = status === "suspended";

  const suspendHandler = () => {
    navigate(routes.protected.team.suspend, {
      replace: true,
      state: { background: location, data: member_id },
    });
  };

  const manageTeamMember = () => {
    const path = routes.protected.team.manage_member.link(member_id, team_id);
    navigate(path, { replace: true, state: { background: location } });
  };

  return (
    <div className="flex items-center">
      <button
        onClick={suspendHandler}
        disabled={disable_suspend}
        className="flex h-full w-full flex-1 items-center gap-x-2 p-4 text-sm font-medium capitalize text-gold-100 hover:outline-none disabled:cursor-not-allowed disabled:text-red-40"
      >
        {status === "inactive" || status === "active" ? (
          <>
            <img
              src={trashicon}
              alt={PageDictionary.suspend}
              className="h-4 w-4"
            />
            <span>{PageDictionary.suspend}</span>
          </>
        ) : (
          <span>{status}</span>
        )}
      </button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <button className="flex w-full flex-1 cursor-pointer items-center justify-center">
            <GoKebabHorizontal />
          </button>
        </DropdownMenuTrigger>
        <DropDownMenuContent className="z-10 flex w-48 flex-col rounded-lg bg-white px-4 pt-6 pb-8 font-bold text-blue_gray-10 shadow-header">
          <DropDownItem className="outline-none hover:outline-none">
            <button
              onClick={manageTeamMember}
              className="border-bborder-gray-240 flex w-full items-center gap-x-2 pb-3 text-sm font-medium text-gray-230 outline-none hover:outline-none "
            >
              <img src={edit} alt={PageDictionary.manage} className="h-4 w-4" />
              <span>{PageDictionary.manage}</span>
            </button>
          </DropDownItem>
          {/* <DropDownItem className="outline-none hover:outline-none">
            <button className="flex items-center gap-x-2 pt-4 text-sm font-medium text-gold-100 outline-none hover:outline-none ">
              <img
                src={trashicon}
                alt={PageDictionary.suspend}
                className="h-4 w-4"
              />
              <span>{PageDictionary.suspend}</span>
            </button>
          </DropDownItem> */}
        </DropDownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default TeamActions;
