import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import CreateTeamMember from "./CreateTeamMember";
import EditTeamMember from "./EditTeamMember";
import SuspendMember from "./SuspendMember";

function TeamModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.team.create}
        element={<CreateTeamMember />}
      />
      <Route
        path={routes.protected.team.manage_member.index}
        element={<EditTeamMember />}
      />
      <Route path={routes.protected.team.suspend} element={<SuspendMember />} />
    </Routes>
  );
}

export default TeamModal;
