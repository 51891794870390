import { ENDPOINTS } from "../../../common/service/config/endpoint";
import {
  makeRequestWithFormData,
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../../../common/service/request";

import * as tp from "../types";

const service = {
  async saveAgreementInfo(data: any): Promise<tp.APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateSignatureRef(
    data: Record<string, any>
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async completeAgreement(data: any): Promise<tp.APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async generateSignatureAgreementLink(
    data: any
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async noAuthRetrieveAgreement(req: any): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, req);
  },
  async noAuthGenerateSignatureRefrence(
    req: any
  ): Promise<tp.APIResponseModel<any>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },
  async noAuthCompleteAgreement(req: any): Promise<tp.APIResponseModel<{}>> {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeUnauthorizedRequestWithHeadersAndPayload(method, url, req.data);
  },
  business_document: {
    async create(data: any): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeRequestWithFormData(method, url, data, true);
    },
    async list(): Promise<tp.APIResponseModel<any[]>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async update(req: any): Promise<tp.APIResponseModel<any>> {
      const { /* docid, */ file } = req;
      const { method, url } = ENDPOINTS.team.create_team_member;

      return makeRequestWithFormData(method, url, { file }, true);
    },
    async retrive(req: any): Promise<tp.APIResponseSuccessModel> {
      const { /* document_id, */ data } = req;
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
    },
  },
  shareholder: {
    async add(data: any): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeRequestWithFormData(method, url, data, true);
    },
    async list(): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async remove(id: string): Promise<tp.APIResponseSuccessModel> {
      const { method, url } = ENDPOINTS.team.create_team_member;

      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async retrive(id: string): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;

      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
  },
  business_profile: {
    async create(data: any): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
    },
    async retrive(): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;
      return makeAuthorizedRequestWithHeadersAndPayload(method, url);
    },
    async update(req: any): Promise<tp.APIResponseModel<any>> {
      const { method, url } = ENDPOINTS.team.create_team_member;

      return makeAuthorizedRequestWithHeadersAndPayload(method, url, req.data);
    },
  },
};

export default service;
