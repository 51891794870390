import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import service from "../service";
// import * as tp from "../types";

export const createBusinessDocument = createAsyncThunk(
  "compliance/business-document/create",
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .create(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const listBusinessDocuments = createAsyncThunk(
  "compliance/business-document/list",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .list()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const updateBusinessDocument = createAsyncThunk(
  "compliance/business-document/update",
  async (req: any, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .update(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const retriveBusinessDocument = createAsyncThunk(
  "compliance/business-document/retrive",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.business_document
      .retrive(id)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const addShareholder = createAsyncThunk(
  "compliance/shareholder/add",
  async (req: any, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .add(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const listShareholders = createAsyncThunk(
  "compliance/shareholder/list",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .list()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const removeShareholder = createAsyncThunk(
  "compliance/shareholder/remove",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .remove(id)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const retriveShareholder = createAsyncThunk(
  "compliance/shareholder/retrive",
  async (id: string, { fulfillWithValue, rejectWithValue }) => {
    return service.shareholder
      .retrive(id)
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const createBusinessProfile = createAsyncThunk(
  "compliance/business-profile/create",
  async (req: any, { fulfillWithValue, rejectWithValue }) => {
    return service.business_profile
      .create(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const retriveBusinessProfile = createAsyncThunk(
  "compliance/business-profile/retrive",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return service.business_profile
      .retrive()
      .then((res) => {
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        // customToast(err.message,true);
        return rejectWithValue(err);
      });
  }
);

export const updateBusinessProfile = createAsyncThunk(
  "compliance/business-profile/update",
  async (req: any, { fulfillWithValue, rejectWithValue }) => {
    return service.business_profile
      .update(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);

export const saveAgreementInfo = createAsyncThunk(
  "onboarding/saveAgreementInfo",
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    return service
      .saveAgreementInfo(data)
      .then((res) => fulfillWithValue(data).payload)
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const generateSignatureRef = createAsyncThunk(
  "onboarding/generateSignatureRef",
  async (data: Record<string, any>, { fulfillWithValue, rejectWithValue }) => {
    // const { company_id, token } = fetchSessionData();

    // if (company_id && token) {
    //   return service
    //     .noAuthGenerateSignatureRefrence({ id: company_id, token, data })
    //     .then((res) => {
    //       customToast(res.message);
    //       return fulfillWithValue(res?.data).payload;
    //     })
    //     .catch((err) => {
    //       customToast(err?.message,true);
    //       return rejectWithValue(err);
    //     });
    // }

    return service
      .generateSignatureRef(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const completeAgreement = createAsyncThunk(
  "onboarding/completeAgreement",
  async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
    // const { company_id, token } = fetchSessionData();

    // if (company_id && token) {
    //   return service
    //     .noAuthCompleteAgreement({ id: company_id, token, data })
    //     .then((res) => {
    //       customToast(res.message);
    //       return fulfillWithValue(res?.data).payload;
    //     })
    //     .catch((err) => {
    //       customToast(err?.message,true);
    //       return rejectWithValue(err);
    //     });
    // }

    return service
      .completeAgreement(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res?.data).payload;
      })
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
export const generateSignatureAgreementLink = createAsyncThunk(
  "onboarding/generateSignatureAgreementLink",
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    return service
      .generateSignatureAgreementLink(data)
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);

export const refrieveAgreement = createAsyncThunk(
  "onboarding/refrieveAgreement",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    let csmToken = "";
    let id = "";
    // const { company_id, token } = fetchSessionData();

    // if (company_id && token) {
    //   csmToken = token;
    //   id = company_id;
    // }
    return service
      .noAuthRetrieveAgreement({ id: id, token: csmToken })
      .then((res) => fulfillWithValue(res?.data).payload)
      .catch((err) => {
        customToast(err?.message, true);
        return rejectWithValue(err);
      });
  }
);
