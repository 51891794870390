import React from "react";
import instance from "../../../../static/images/instancelogo.svg";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { fetchUserToken } from "../../../service/storage";
import routes from "../../../routes";
import { tW } from "../../../utils/helpers";
import { isGoldPartner, partnersLogo } from "../../../utils/helpers/partners";

function Auth() {
  const { pathname } = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const query = JSON.parse(JSON.stringify(params));
  const partner_id = query?.partner_id;
  if (
    fetchUserToken() &&
    pathname.includes(routes.protected.binance_external.form) === false
  ) {
    return <Navigate replace to={routes.protected.dashboard.crypto} />;
  }

  return (
    <div className={tW("flex h-screen w-full lg:flex-row")}>
      {/* {pathname !== routes.protected.binance_external.form ? ( */}
      <div
        className={tW(
          "grid w-1/2 grid-rows-[1fr_max-content] bg-customBrand-background py-27.5 px-4 sm:px-16 lg:px-24",
          pathname === routes.protected.binance_external.form
            ? "hidden h-full lg:grid"
            : ""
        )}
      >
        <div className="flex flex-col items-center justify-center gap-y-8 text-center">
          {pathname === routes.protected.binance_external.form &&
          isGoldPartner(partner_id) &&
          partnersLogo(partner_id) ? (
            <img
              src={partnersLogo(partner_id)}
              className="mx-auto"
              alt="partner logo"
            />
          ) : null}
          {isGoldPartner(partner_id) === false ? (
            <img
              src={
                pathname === routes.protected.binance_external.form &&
                isGoldPartner(partner_id)
                  ? partnersLogo(partner_id)
                  : instance
              }
              className="mx-auto"
              alt="instance logo"
            />
          ) : null}
        </div>
        {/* <p className="self-end justify-self-center">By signing up, you agree to Finswich Privacy and Terms</p> */}
      </div>
      {/* ) : null} */}
      <main
        className={tW(
          "w-1/2",
          pathname === routes.protected.binance_external.form
            ? "h-full w-full overflow-y-scroll md:h-screen lg:w-1/2"
            : null
        )}
      >
        <Outlet />
      </main>
    </div>
  );
}

export default Auth;
