import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { PersistGate } from "redux-persist/integration/react";
import store from "./store";
import { pdfjs } from "react-pdf";
import "./static/styles/index.css";
import "react-toastify/dist/ReactToastify.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@3.6.172/legacy/build/pdf.worker.js `;



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
      <React.StrictMode>
        <Provider store={store.store}>
          <PersistGate persistor={store.persistor} loading={null}>
            <BrowserRouter>
              <App />
              <ToastContainer />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </React.StrictMode>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
