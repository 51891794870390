const English = {
  nonprotected: {
    LoginPage: {
      pageTitle: "Login",
      heading: "Welcome,  Merchant",
      subHeading: "Input your details to log into your account",
      emailLabel: "Email address",
      passwordLabel: "Password",
      rememberMeLabel: "Remember me",
      forgotpaswordText: "Forgot Password ?",
      loginActionButton: "Sign In",
      swithPage: {
        text: "Don't have an account? ",
        registerActionLink: "Sign up",
      },
    },
    registerPage: {
      pageTitle: "Register",
      heading: "Welcome,  Merchant",
      subHeading: "Input your details to Sign up your account",
      firstNameLabel: "First name",
      lastNameLabel: "Last name",
      accountOwnerEmailAddress: "Root Account Email (optional)",
      emailLabel: "Email address",
      passwordLabel: "Password",
      countryLabel: "Country",
      registerActionButton: "Sign up",
      swithPage: {
        text: "Already have an account? ",
        loginActionLink: "Sign In",
      },
      multiAccount: {
        heading: "Hello, Merchant",
        subHeading: "Sign up new account",
      },
    },
    forgotPasswordPage: {
      pageTitle: "Forgot Password",
      heading: "Forget password",
      subHeading: {
        forgotpassword: "Input your details to reset your account password",
        enterotp: "Input the one time password sent to your email",
        newpassword: "Input the one time password sent to your email",
      },
      emailLabel: "Enter Email address",
      otplabel: "Enter OTP pin",
      resendCode: "Resend it",
      newPasswordlabel: "New Password",
      confirmPasswordlabel: "Confirm Password",
      submitAction: "Submit",
    },
    enterOtpPage: {
      pageTitle: "",
      heading: "Enter OTP",
      twoFAHeading: "Enter 2FA",
      subHeading: "Input the one time password sent to your email",
      twoFAsubHeading:
        "Input the one time password sent to your email verify your email",
      otpLabel: "Enter Otp pin",
      submitAction: "Submit",
      resendCode: "Didn’t get code? Resend",
    },
    resetSuccessfullPage: {
      pageTitle: "",
      heading: "Password Reset Successful",
      subHeading:
        "Your password has been successfully reset, you proceed to log in",
      action: "Go to Login",
    },
    errorPage: {
      pageTitle: "Error Page",
      heading: "Ooops...",
      subheading: "Page Not Found",
      login: "To Login",
      dashboard: "To Dashboard",
    },
    mobilePage: {
      copy: "Please Download mobile app for a better experience",
    },
  },
  protected: {
    Layout: {
      header: {
        balance: "Capital Balance",
        status: {
          online: "Online",
          offline: "Offline",
        },
        dropdown: {
          profile: "Profile",
          setting: "Setting",
          logout: "Logout",
        },
      },
      status: {
        title: "Are you sure you want to take this action?",
        messageOffline: `Going offline means no transaction will be routed
        to you.`,
        messageOnline: `Going online means transactions will be routed
        to you.`,
        actions: {
          accept: "Yes",
          reject: "No",
        },
      },
      sidenav: {
        nav: {
          dashboard: "Overview",
          pool: "Pool",
          transaction: "Crypto TXN",
          performance: "Performance",
          team: "Team",
          setting: "Setting",
          crypto_wallet: "Crypto Wallet",
          compliance: "Compliance",
          preview: "Preview App",
          help: "Help",
          fiat_wallet: "Fiat Wallet",
        },
        metric: {
          header_title: "Today’s Performance Monitor",
          footer_title:
            "Good job, accepting request increases your chances of getting more request routed to you.",
          data: {
            speed_of_accepting_request: "Speed of Accepting Request",
            speed_of_completing_request: "Speed of Completing Request",
            total_txn_processed: "Total Transaction Processed",
            total_retracted_request: "Total Re-tracted Request",
            issue_dispute: "Issue/Dispute",
            dispute_won: "Dispute Won",
          },
        },
      },
      tour: {
        dashboard: {
          step_one: {
            title: "You make money when you are online",
            content: {
              part_one:
                "The online availability button helps you maintain good scores and ranking on our platform. Putting on the online availability button means you are on your device and you are ready to start processing the transactions routed to you",
              part_head:
                "Offline Merchants don’t get transactions routed to them!",
              //   "Merchants that are offline don’t get any transaction routed to them;  ",
              part_three: "",
              //   "and being online and not processing transaction results in lower scores and commission.",
            },
          },
          step_two: {
            title: "Fund your capital wallet and immediately start processing.",
            content: {
              part_one:
                "Funding is seamless. Fund your instance wallet with USDT from third party platforms like Binance, and Coinbase.",
              part_two:
                "And start earning commission on the requests you have processed. Your commision is saved in your commision wallet and you can withdraw it anytime!!",
            },
          },
          step_three: {
            title: "Business Insight",
            content:
              "The dashboard exposes to you all data flowing through the system. This data can be leveraged and analyzed to provide insights that help you improve your business processes and grow your business.",
          },
          step_four: {
            title: "Run your business with ease",
            content:
              "The side bar contains a list of features to administer your business in an effortless manner. In the pool area you see all that’s necessary to process a request routed to you, the transactions tab contains your withdrawal and funding records , the performance tab exposes how you are doing on each of the metrics we use to evaluate Merchants, and the team tab allows you to add team members, and manage their access level",
          },
          step_five: {
            title: "Efficiency is the soul of business",
            content:
              "The Daily performance monitor helps you see how well your are doing for the day. Exposing metrics like your average speed of accepting request, average speed of processing request, number of disputes, etc. it can also to used to provide data that can help you improve your business processes.",
          },
        },
      },
    },
    fiatPages: {
      layout: {
        title: "Fiat Wallet",
        copy: (country: string) =>
          `This feature allow you to create a “Virtual Wallet” in ${country} to handle all your collection & payout.  To get virtual wallet for other countries, create additional profile for the preferred country & switch your account.`,
        layout_data: {
          cards: {
            collection: "Total Collection",
            payout: "Total Payout",
            pending: "Pending Txn",
            mismatched: "Mismatched Txn",
            completed_payout: "Completed Payout",
            failed_payout: "Failed Payout",
          },
          transactions: "txns",
          exchange_rate: {
            usdt_to_fiat: (fiat_symbol?: string) =>
              `USDT to ${fiat_symbol} Rate`,
            fiat_to_usdt: (fiat_symbol?: string) =>
              `${fiat_symbol} to USDT Rate`,
          },
        },
        nav: {
          overview: "Overview",
          method: "Partner Mapping",
        },
        overview_nav: {
          withdrawals: "Payouts",
          transactions: "Collections",
        },
        collections_nav: {
          all: "All",
          completed: "Completed",
          pending: "Pending",
          mismatched: "Mismatched",
          abandoned: "Abandoned",
          expired: "Expired",
          claimed: "Claimed",
        },
        payout_nav: {
          completed: "Completed",
          failed: "Failed",
          pending: "Pending",
          processing: "Processing",
        },
        export: "Export",
      },
      paymentPage: {
        headline: "Start your Payout/Collection journey here",
        subtitle:
          "You need to provide basic information we will use in creating the “Virtual Wallet” that will be used in handling all your collection/Payout in Nigeria",
        action: "Setup Wallet",
      },
      overviewPage: {
        banner: {
          greeting: {
            morning: "Good Morning",
            afternoon: "Good Afternoon",
            evening: "Good Evening",
          },
          balance: "Available  Balance",
          withdrawal: "Withdraw",
          fund: "Fund Wallet",
          swap: "Swap",
          settlement: "Next Settlement: ",
        },
        kyc: {
          copy: (amount: string) =>
            `Without KYC, you won’t be able to accept more than ${amount} only on this account.`,
          start: "Start KYC",
          status: "In-active KYC",
        },
        tables: {
          withdrawals: {
            amount: "Amount",
            fee: "Fee",
            balance: "Balance",
            bank_ref: "Bank Ref",
            to: "To",
            currency: "Currency",
            status: "Status",
            Timestamp: "Timestamp",
          },
          transactions: {
            amount: "Amount",
            fee: "Fee",
            settled_amount: "Settled",
            order_no: "Order No.",
            status: "Status",
            time_date: "Time/date",
            action: "Action",
          },
        },
      },
      paymentMethodPage: {
        secret_key: "Secret Key",
        regenerate_key: "Re-generate key",
        secret_warning:
          "Copy the secret key and save on Partner’s platform before your setup can be complete. Don’t share your secret key with anyone. If you think your secret key is compromised, click “Re-generate” and reconfigure on Partner’s platform.",
        filterLabel: "Select Payment Methods",
        available_partners: "Available Partners",
        warning_caption:
          "Map your payment collection to partner system. Mistakenly map to a wrong partner may lead to loss of funds.",
        table: {
          api_keys: "Get API Keys",
          activate: "Active",
          threshold: {
            set: "Set Threshold",
            update: "Update Threshold",
          },
        },
      },
    },
    fiatModals: {
      funding: {
        FundWalletForm: {
          title: "Funding your wallet",
          subtitle:
            "Make transfer to the account below. Once funded, your account will automatically be updated.",
          section_title: "Bank Funding Details",
          close: "Close",
        },
      },
      withdrawal: {
        AuthorizeWithdrawal: {
          title: "Authorize Withdrawal",
          subtitle: "Confirm withdrawal Information",
          form: {
            password_label: "Enter OTP to confirm transaction",
            submit_btn: "Submit",
          },
        },
        WithdrawlForm: {
          title: "Withdrawal",
          subtitle: "Choose  from your wallet",

          form: {
            amount_label: "Amount to withdraw",
            bank_label: "Bank Name",
            account_number_label: "Account Number",
            description_label: "Description",
            submit_btn: "Continue",
          },
        },
        _info: {
          withdrawal_info: "Withdrawal Info",
          sender_info: "Sender Info",
        },
        success: {
          title: "Transaction Completed",
          subtitle: (amount: string) =>
            `You have just completed a transaction of ${amount}`,
        },
      },
      payment_method: {
        toggle_cred: {
          send_credentials_btn: {
            save: "Save",
            update: "Update",
          },
          partner_url: "Partner Callback URL",
          merchant_id_label: "Partner Merchant ID",
          title: "Map your settings",
        },
        success: {
          title: (id: string) => `We have notified ${id}`,
          subtitle: (id: string) =>
            `Your configuration details has been shared with ${id} and once your account is approved. You will be notified via Email.`,
          action: "Close",
        },
        toggle_off: {
          title: "Are you sure you want to take this action?",
          messageOffline: `Toggling off will turn off link to partner`,
          messageOnline: `Toggling on will turn on link to partner`,
          actions: {
            accept: "Yes",
            reject: "No",
          },
        },
      },
      setup: {
        config: {
          banner: {
            title: "Binance P2P Auto-payment",
            subtitle:
              "Get started by setting up your account to start receiving automatic payment on Binance P2P as a merchant.",
          },
          title: "Setup Collection Wallet",
          subtitle:
            "Before you can turn on your Intrapay wallet for Binance P2P Payment, create your collection Wallet",
          form: {
            merchant_type_label: "Merchant Type",
            merchant_type_options: ["Individual", "Bussiness"],
            bvn_label: "BVN",
            nin_label: "NIN",
            international_id_label: "International Passport ID",
            select_bank_label: "Select Bank",
            dob_label: "Date of birth",
            gender: {
              title: "Gender",
              radio_first: "Male",
              radio_second: "Female",
            },
            address_label: "Address",
            business_name_label: "Business Name",
            phone_label: "Phone No",
            save_btn: "Save",
          },
          error_messages: {
            invalid_bvn: "Invalid BVN",
            invalid_international_id: "Invalid International Passport ID",
          },
        },
        success: {
          title: "Setup Collection Wallet",
          subtitle:
            "Before you can turn on your Intrapay wallet for Partner P2P Payment, create your collection Wallet",
          page_cta:
            "Your wallet has been set up Successfully. Your wallet is currently limited to N50,000 maximum collection. Do your KYC to increase limit.",
          start_kyc: "Start KYC Now",
          skip_kyc: "Skip for Later",
        },
      },
      kyc: {
        nav: {
          submit: "Submit",
          review: "Review",
          success: "Successful",
        },
        submit: {
          form: {
            merchant_type_label: "Merchant Type",
            business_name: "Business Name",
            business_industry: "Business Industry",
            merchant_name: "Merchant Name",
            merchant_industry: "Merchant Industry",

            save_btn: "Complete Compliance",
            media: {
              upload: "Upload ",
              reupload: "Re-Upload ",
              submit: "SUBMIT",
              submitted: "submitted",
            },

            business: {
              id_card_url: "ID Card of Major Shareholder (e.g NIN)",
              cac_url: "Certificate of incorporation",
              proof_of_address_url: "Proof of Address (MajorShareholder)",
              bank_statement_url: "Bank Statement (Last 3 Months)",
            },
            individual: {
              gov_approve_id_card_url: "Govt Approved ID card",
              proof_of_address_url: "Proof of Address (MajorShareholder)",
              bank_statement_url: "Bank Statement (Last 3 Months)",
            },
          },
          cta: {
            title: "Note",
            subtitle:
              "Confirm that your KYC information matches with the name you registered on Intrapay.",
          },
        },
        review: {
          new: {
            title: "Under Review",
            subtitle:
              "Your KYC is under-review and should completed within 24 hours.",
          },
          old: {
            title: "Under Review",
            subtitle:
              "You are required to resubmit some information. Check your email for more details.",
          },
          close_btn: "Complete Compliance",
        },
        success: {
          cta: {
            title: "KYC Approved Successfully",
            subtitle:
              "Thank you! Your KYC has been review & approved by our compliance team. Your account limit has been removed.",
          },

          close_btn: "Home",
        },
      },
    },

    compalinacePages: {
      business_document: {
        table: {
          document_name: "Document Name",
          action: "Action",
          status: "Status",
          created_at: "Date Created",
        },
        table_component: {
          document_name: {
            CERTIFICATE_OF_INCORPORATION:
              "Business Certificate of Incorporation",
            BUSINESS_OPERATING_LICENSE: "Business operating License",
            SOURCE_OF_WEALTH: "Source of Wealth",
            ANTI_MONEY_LAUNDERING: "Anti money laundering",
            PROOF_OF_ADDRESS: "Proof of business Address",
            BANK_STATEMENT: "3 month Bank Statement",
          },
          action_text: {
            view: "View",
            submited: "Submited",
            re_submit: "Re-submit",
          },
          status_text: {
            please_submit: "please submit",
            pending_approval: "pending Approval",
            approved: "APPROVED",
            rejected: "REJECTED",
          },
        },
      },
      shareholders: {
        table: {
          name: "Name of shareholder",
          email: "Email of shareholder",
          shareholder_id: "ID of shareholder",
          proof_address: "Proof of address",
          address: "Address",
          created_at: "Date Created",
          action: "Action",
        },
        table_component: {
          view: "View",
          remove: "Remove",
        },
      },
      business_profile: {
        add_profile: "Click to Add Profile",
        data: {
          type: "Business Type",
          business_type_list: [
            "Fishing",
            "Horticulture",
            "Tobacco",
            "Wood",
            "Aerospace",
            "Automotive",
            "Pharmaceutical",
            "Construction",
            "Defense",
            "Electric power",
            "Electronics",
            "Energy",
            "Food",
            "Industrial robot",
            "Low technology",
            "Meat",
            "Mining",
            "Petroleum",
            "Pulp and paper",
            "Steel",
            "Shipbuilding",
            "Telecommunications",
            "Textile",
            "Water",
            "Advertising",
            "Fashion",
            "Floral",
            "Cultural",
            "Culture",
            "Education",
            "Film",
            "Gambling",
            "Music",
            "Sex",
            "Video game",
            "Insurance",
            "Healthcare",
            "Hospitality",
            "Information",
            "Leisure",
            "Broadcasting",
            "Internet",
            "News media",
            "Publishing",
            "Entertainment",
            "Professional services",
            "Real estate",
            "Retail",
            "Software",
            "Sport",
            "Transport",
            "Banking",
            "Finance",
            "Blockchain",
            "Crypto",
            "Fintech",
          ].sort((a, b) => a.localeCompare(b)),
          address: "Address",
          description: "Description",
          country_of_operation: "Country Of Operation",
          support_email: "Support Email",
          support_phone_number: "Support Phone Number",
        },
        form_btn: {
          submit: "Submit",
          update: "Update",
        },
      },
    },
    compalinaceModals: {
      confirm_shareholder_action: {
        title: "Are you sure you want to take this action?",
        subtitle:
          "You are about to remove this Shareholder, confirm if you want to continue with this action?",
        btn_actions: {
          cancel: "Cancel",
          confirm: "Yes",
        },
      },
      shareholder_form: {
        title: "Shareholder info",
        form_elements: {
          name: "Name of shareholder",
          email: "Email of shareholder",
          shareholder_id: "ID card of  shareholder",
          proof_address: "Proof of address",
          upload_support_text: "max image size 5 mb",
          address: "Address",
          submit: "Submit",
        },
      },
      upload_businesss_document: {
        title: {
          upload: "Upload business document",
          update: "Change business document",
        },
        form: {
          upload_label: {
            upload: (doc_title: string) => `Upload ${doc_title}`,
            update: (doc_title: string) => `Update ${doc_title}`,
          },
          upload_supported_format_text:
            "Supported formats: JPEG, PNG, PDF, Word, max upload size is 5 mb",
          operation_license: "File Name",
          submitform: {
            submit: "Submit",
            update: "Update",
          },
        },
      },
    },
    onboarding_layout: {
      page_title: "Onboarding",
      title: "Compliance",
      copy: "Kindly Agree to our terms, provide information below to perform your KYC before you can access the full capabilities of IntraPay.",
      add_shareholder: "Add Shareholder",
      nav: {
        main: {
          business: "Business",
          individual: "Individual",
        },
        business: {
          terms: "Terms",
          bussiness_document: "Document",
          bussiness_shareholders: "Shareholders",
          bussiness_profile: "Business Profile",
        },
        individual: {
          terms: "Terms",
          personal_document: "Document",
          personal_account_infos: "Account Holder",
        },
      },
    },
    onboardingPages: {
      termsPage: {
        title: "Payment Integration Services Agreement",
        agreement_copy:
          "This Payment Integration Services Agreement includes this introduction, Terms and Conditions, Privacy Policy, Cookie Policy, Acceptable Use Policy, Know Your Customer Policy (together the “Agreement”) forms a legal agreement",
        between_highlight: "BETWEEN",
        fuspay:
          "FusPay Technologies Inc., a private limited liability company, with its registered address at 221 W 9th st PMB 248, Wilmington Delaware, 19801]. (“FusPay” “We”, “Us”, “Our”, or the &quot;Company&quot;)",
        and_highlight: "AND",
        partner:
          "[Insert Name], a company organized and existing under the laws of [insert Jurisdiction], with its principal place of business at [Insert Your Address] (&quot;You”, “Your&quot;). Each of the parties to this Agreement shall, in addition to the pronouns, be referred to as a &quot;Party&quot; and together the Parties shall be referred to as the &quot;Parties&quot;. References made to customers, user or any third party in the Terms and Conditions, Privacy Policy, Cookie Policy, Acceptable Use Policy, Know Your Customer Policy, or any other referenced policies in this Agreement shall be construed as references to &#39;You&#39; as defined in this Agreement, unless explicitly stated otherwise.",
        whereas_highlight: "WHEREAS:",
        agree_btn: "Agree & Continue",
        scroll_down: "SCROLL DOWN",
      },
      appPreviewPage: {
        title: "App Preview",
        copy: "Demo of how Intrapay works",
      },
    },
    onboardingModals: {
      agreement_infromation: {
        agreement_title: "Agreement information",
        agreement_copy:
          "This is an automated generated SLA. Kindly put your company information and we will automatically send you the PDF version to your email.",
        authorizer_title: "Details of Authorizer",
        authorizer_copy:
          "The only acceptable “Authoriser Details” must be the name of one of the “Director” whose ID & Proof of Address will be uploaded for KYC.",
        required: "Required",
        formLabels: {
          company_name: "Company Name  (As Registered)",
          select_country: "Select Country",
          address:
            "Enter Address (We will request Proof of address to this Address)",
          authorizer_name: "Full name of Authorizer (Company Shareholder/CEO)",
          authorizer_designation:
            "Designation of Authorizer (Company Shareholder/CEO)",
          button: "Next",
        },
      },
      authorize: {
        page_title: "Sign & Authorize",
        page_subtitle:
          "This is an automated generated SLA. Kindly put your company information and we will automatically send you the PDF version to your email.",
        formLabels: {
          email: "Email to Receive signed copy of this SLA",
          enter_initials: "Enter Initial",
          read_terms: "I have read the Payment Integration Service Agreement",
          signature: "Signature ID:",
          clear_field: "Clear Field",
          generate_signature: "Generate Signature",
          no_authority: {
            question: "I Don’t have the Power to Sign?",
            action: "Generate Link to Share with Authorizer",
          },
          copy_placeholder:
            "https://compliance.instance.finance/e-link/?23ujueuueue...",
          share_email: "or share via email",
          authorize_email_placeholder: "Authorizer’s email",
          check_label: {
            initial_as_signature: "Use Initial as Signature",
            draw_signature: "Draw Signature",
            upload_signature: "Upload Signature",
          },
          button: "Sign & Complete",
        },
      },
    },
    DashboardPage: {
      pageTitle: "Dashboard",
      nav: {
        crypto: "Crypto",
        fiat: "Fiat",
      },
      banner: {
        greeting: {
          morning: "Good Morning",
          afternoon: "Good Afternoon",
          evening: "Good Evening",
        },
        fiat: "Fiat Wallet",
        capital: "Capital Wallet",
        commission: "Commission Wallet",
        cards: {
          fund: "Fund Wallet",
          withdraw: "Withdraw",
        },
      },
      exchange_rate: {
        usdt_to_fiat: (fiat_symbol?: string) => `USDT to ${fiat_symbol} Rate`,
        fiat_to_usdt: (fiat_symbol?: string) => `${fiat_symbol} to USDT Rate`,
      },
      cards: {
        users: "Total Users",
        total_funded_amount: "Total Funded Amount",
        total_withdrawal_amount: "Total Withdrawal Amount",
        dispute: "Dispute",
        requests: "Total Requests",
        settlement_amount: "Settlement Amount",
        funding: "Total Funding Transaction",
        ranking: "Ranking",
        withdrawal: "Total Withdrawal Tx",
        dispute_fund: "Dispute Fund",
        viewMore: "View More",
        unresolved: "Unresolved Dispute Insurance",
      },
      FundWalletModal: {
        title: "Fund Wallet",
        subtitle: "Fund your wallet now to be able to trade",
        fundLabel: "Coin to fund",
        walletAddressLabel: "Wallet Address",
        walletInstruction:
          "Make sure the address you want to withdraw to is the same network as TRC20, if you withdraw to another network you might lose your funds.",
        list: [
          "Send only USDT to this deposit address.",
          "Ensure the network is TRON (TRC20).",
          "Do not send other coin to this address.",
        ],
        submitAction: "Close",
      },
      withdrawalModal: {
        title: "Withdrawal",
        subtitle: "Withdraw from your wallet",
        amountLabel: "Enter amount to withdraw",
        walletLabel: "Select wallet",
        walletOption: [
          { label: "Capital Wallet", value: "CAPITAL" },
          { label: "Commission Wallet", value: "COMMISSION" },
        ],
        addressLabel: "Recipient address",
        networkLabel: "Network",
        networkTitle: "TRC-20",
        networkMessage:
          "make sure the address you want to withdraw to is the same network as TRC20, if you withdraw to another network you might lose your funds",
        balanceLabel: "Capital Wallet Balance",
        feeLabel: "Network withdraw fee",
        withdrawalAction: "Submit",
      },
      withdrawalSuccessModal: {
        title: "Your Transaction Is On Its Way",
        subtitle: "Your withdrawal request has been successfully submitted",
        message: (amount: string) =>
          `You sent ${amount} to another external address`,
        action: "Close",
      },
    },
    InsuranceAmountPage: {
      pageTitle: "Dashboard",
      export: "Export",
      nav: "Incoming Settlement",
      table: {
        amount: "Amount Locked",
        rate: "Rate",
        currency: "Currency",
        date: "Date & Time",
        commision: "Total Commision",
        period: "Clearing",
        status: "Status",
      },
    },
    LockInsurancePage: {
      pageTitle: "Dashboard",
      nav: "Dispute Fund",
      export: "Export",
      table: {
        amount: "Transaction Amount",
        rate: "Rate",
        currency: "Currency",
        date: "Date & Time",
        commision: "Total Commision",
        evidence: "Payment Evidence",
        status: "Status",
      },
    },
    NotificationPage: {
      pageTitle: "Notification",
      Title: "Notification",
      readAll: "Mark all as read",
      read: "Mark as read",
    },
    PerformancePages: {
      PageTitle: "Performance",
      nav: {
        metric: "Performance & Metrics",
        chart: "Chart",
      },
      MetricsPage: {
        metric: {
          metric_headings: "Metrics & Scores",
          metric_uno: "Metric",
          metric_duo: "Scores",
          data: {
            speed_of_accepting_request: "Speed of Accepting Request",
            speed_of_completing_request: "Speed of Completing Request",
            total_txn_processed: "Total Transaction Processed",
            total_retracted_request: "Total Re-tracted Request",
            issue_dispute: "Issue/Dispute",
            dispute_won: "Dispute Won",
            speed_of_processing_request: "Speed of Processing Request",
            retracted_txn_index: "Retracted Transaction Index",
          },
          m_warning:
            "Your scores and metrics are performance driven. The faster you accept and complete request without lesser issues and dispute, the more you improve your scores and your chances of processing more transactions.",
        },
        score: {
          score_headings: "Overall Score",
          headerdata: {
            tray_limit: "Tray limits",
            pending_request_limits: "Pending request limits",
            speed_of_accepting_request_perks:
              "Speed of accepting Request Perks",
            speed_of_completing_request_perks:
              "Speed of completing Request Perks",
            txn_commision: "Transaction Commission",
          },
          level: "Level",
          badge: "Barge",
          position: "Position",
          date_joined: "Date Joined",
        },
      },
      chartPage: {
        options: {
          optionLabel_first: { label: "Today", value: "today" },
          optionLabel_second: { label: "This Week", value: "this_week" },
          optionLabel_third: { label: "This Month", value: "this_month" },
          optionLabel_fourth: { label: "All Time", value: "all_time" },
        },
        datasetLabel_one: "Dataset 1",
        datasetLabel_two: "Dataset 2",
      },
    },
    SettingsPages: {
      pageTitle: "Settings",
      nav: {
        bank: "Bank Setting",
        pin: "Reset Password",
        lang: "Language settings",
        api: "API setting",
        apps: "Apps",
        price: "Pricing",
        settlement: "Settlement",
      },
      addAccountAction: "Add Account",
      BankSettingsPage: {
        acc_status: "Status",
        bank_action: "Action",
        action: {
          edit: "Edit",
          delete: "Delete",
        },
        status: {
          default: "Default",
          inactive: "Mark as default",
        },
        warning_texts: {
          not_configured:
            "*Bank setting is not configured for this Country. Please contact support",
          no_details: "*Please Add  Bank details",
        },
        bankModal: {
          addtitle: "Add Bank Account",
          edititle: "Edit Bank Account",
          bankNameLabel: "Bank name",
          bankAcclabel: "Bank account number",
          accountNameLabel: "Account name",
          addSubmitAction: "Add Account",
          updateSubmitAction: "Update",
        },
        bankDeleteConfirmationModal: {
          title: "Are you sure you want to take this action?",
          subtitle: "Deleting Account details is irreversible",
          actions: {
            accept: "Yes",
            reject: "No",
          },
        },
      },
      ResetPassword: {
        title: "Click the button “ generate OTP” to get a code in your email",
        otp: "Enter OTP",
        n_password: "New Password",
        cn_password: "Confirm Password",
        btnAction: "Update",
        btnOtp: "Generate Otp",
      },
      LanguagePage: {},
      ProfilePage: {
        pageTitle: "Settings",
        title: (name: string) => `${name}'s Profile`,
        form: {
          name: "Name",
          email: "Email",
          role: "Role",
          permissions: "Permissions",
        },
        action: {
          manage: "Manage",
          update: "Update",
        },
      },
      ApiSettingsPage: {
        layout: {
          withdrawal: "Auto Withdrawal",
          funding: "Automatic Funding",
        },
        withdrawal: {
          autoProcessWithdrawalLabel: "Process Withdrawal Auto via API",
          autoProcessWithdrawalText1:
            "By turning this ON, all withdrawal request will be routed automatically via API integration",
          autoProcessWithdrawalText2:
            "Check here for our documentation on how to set up automatic payment for Withdrawal",
          manualProcessWithdrawalLabel: "Process Withdrawal Manually",
          manualProcessWithdawalText:
            "By turning this ON, all withdrawal request will be routed via Pool for manual confirmation of requests",
          turnLiveKeyLabel: "Turn Live for Automatic payment for Withdrawal",
          turnLiveKeytext:
            "If your turn off LIVE, processing manually will be auto turn on and you will need to confirm transaction through the POOL",
          processFiatLabel: "Process from Fiat Wallet",
          processFiatText:
            "Use Balance in your Fiat Wallet to process auto-withdrawal",
          bankUrlLabel: "Bank Verification URL",
          authorizationUrlLabel: "Authorization URL",
          balancePoolUrlLabel: "Balance Pool URL",
          whiteListLabel: "Whitelist IP",
          liveKeyLabel: "Live Key",
          actions: {
            viewDocsAction: "View Doc",
            save: "Save",
            manage: "Manage",
            update: "Update",
          },
        },
        funding: {
          autoProcessFundingLabelApi: "Process Funding Auto via API",
          autoProcessFundingText1Api:
            "By turning this ON, all Funding request will be routed automatically via API integration",
          autoProcessFundingText2Api:
            "Check here for our documentation on how to set up automatic payment for Withdrawal",
          manualProcessFundingLabel: "Process Funding  Manually",
          manualProcessFundingText1:
            "By turning this ON, all withdrawal request will be routed via Pool for manual confirmation of requests",
          toggleLiveLabel: "Turn Live for Automatic payment for Funding",
          toggleLiveText:
            "If your turn off LIVE, processing funding manually will be auto turn on and you will need to confirm transaction through the POOL",
          actions: {
            viewDocsAction: "View Doc",
            save: "Save",
            manage: "Manage",
            update: "Update",
          },
          setup: {
            title: "Select Apps to Set up",
            sub: "We have configured this application/platforms with our system so that you don’t have to build from scratch.",
            warn: "You must have automatic funding before you can activate any App",
          },
        },
      },
      AppsPages: {
        nav: {
          allApps: "All Apps",
          activeApps: "Active Apps",
        },
        allAppsPage: {
          title: "Apps",
          headlineText:
            "List of apps you can connect to your IntraPay account to make processing request faster",
          cards: {
            flutterwave: {
              headline: "Flutterwave Transfer",
              text: "Allow you to process automatic withdrawals in NIgeria",
            },
            leatherBack: {
              headline: "Leatherback App",
              text: "Allow you to process automatic withdrawals & funding in 27 countries",
            },
          },
          suggestApps: "Suggest Apps",
          footerText:
            "What company in your region would you want us to add on the app segment. Suggest possible apps you like to see.",
        },
      },
      AppsModal: {
        title: {
          flutterwave: "Flutterwave Transfer",
          leatherBack: "Leatherback App",
        },
        text: {
          flutterwave: "Allow you to process automatic withdrawals in NIgeria",
          leatherBack:
            "Allow you to process automatic withdrawals & funding in 27 countries",
        },
        toggle: "Process Automatic Funding & Withdrawal",
        nav: {
          dashboard: "Dashboard",
          config: "Configurations",
          keys: "API Keys",
        },
        dashboardTab: {
          walletBalance: "Wallet  Balance",
          accountDetails: "Account details",
          recordsTable: {
            title: "Records",
            tableColumn: {
              amount: "Amount",
              date: "Date",
              time: "Time",
              status: "Status",
            },
          },
        },
        configurationTab: {
          turnLiveAutomatic: "Turn Live for Automatic payment for Withdrawal",
          text: "If your turn off LIVE, processing manually will be auto turn on and you will need to confirm transaction through the POOL",
          bankVerificationlUrl: {
            label: "Bank Verification URL",
            tooltip:
              "This endpoint allows us to send and verify a user account number in the UK",
          },
          balancePoolUrl: {
            label: "Balance Pool URL",
            tooltip:
              "This is your local balance. When your balance depletes, we will stop sending requests to you.",
          },
          authorizationUrl: {
            label: "Authorisation/Withdrawal URL",
            tooltip:
              "This end point where we will post all payload of payment to",
          },
          whiteListIp: {
            label: "Whitelist our IP ",
            tooltip:
              "This is our IP. It enables you to know the request is from our end ",
          },
          kycEndpoint: {
            label: "KYC Endpoint",
            tooltip: "allow us to send KYC data",
          },
          rateEndpoint: {
            label: "Rate Endpoint",
            tooltip: "For displaying the rate on our end.",
          },
          revertToManual: "Revert to manual Processing",
          submitButton: {
            save: "Save",
            update: "Update",
          },
        },
        apiKeysTab: {
          liveKeys: "Turn Live for Automatic payment for Withdrawal",
          textLabel:
            "If your turn off LIVE, processing manually will be auto turn on and you will need to confirm transaction through the POOL",
          encryptPublicKey: "Encrypt Public Key",
          encryptPrivateKey: "Encrypt Private Key",
          hashLiveKey: "Hashed Live Keys",
        },
      },
    },
    CryptoWalletPages: {
      pageTitle: "Crypto Wallet",
      title: "Cypto Wallet",
      nav: {
        capital: "Capital Wallet",
        commission: "Commission Wallet",
      },
      export: "Export",
      table: {
        capital: {
          amount: "Amount",
          account_type: "Transaction Type",
          date: "Date",
          time: "Time",
          status: "Status",
        },
        commission: {
          amount_withdrawn: "Amount withdrawn",
          date: "Date",
          time: "Time",
          status: "Status",
        },
      },
    },
    TransactionPages: {
      pageTitle: "Transactions",
      title: "Transaction History",
      nav: {
        withdraw: "User Withdrawal",
        funding: "User Funding",
      },
      datePickerLabel: "Filter",
      datePickerPlaceholder: "Select Date",
      export: "Export",
      table: {
        funding: {
          amount: "Transaction amount",
          rate: "Rate",
          currency: "Currency",
          date: "Date & time",
          commision: "Total commision",
          status: "Status",
        },
        withdrawal: {
          amount_fiat: "Amount Withdrawn",
          amount_dollar: "Amount In Dollar",
          rate: "Rate",
          currency: "Currency",
          date: "Date & time",
          commision: "Total commision",
          evidence: "Payment evidence",
          status: "Status",
        },
      },
    },
    TeamPages: {
      pageTitle: "Team",
      title: "Team member",
      action: "Create member",
      suspendMember: {
        message: "Are you sure you want to perform this action",
        warningMessage: (email: string) =>
          `This action will suspend ${email} from your team`,
        reject: "Cancel",
        accept: "Confirm",
      },
      table: {
        email: "Member’s Email",
        date: "Date Created",
        access: "Access",
        action: "Actions",
      },
      tableAction: {
        manage: "Manage",
        suspend: "Suspend",
      },
      TeamModal: {
        createTitle: "Create team member",
        updateTitle: "Edit team member",
        emailLabel: "Enter member email address",
        firstNameLabel: "Enter member First Name",
        lastNameLabel: "Enter member Last Name",
        PasswordLabel: "Enter member initial Password",
        roles: {
          label: "Choose access role",
          admin: {
            label: "Admin",
            tip: "Admins can add team members, suspend team members and change team members access",
          },
          a_request: {
            label: "Accept Request",
            tip: "Team members can accept or ignore transactions in the tray area, Automatically moving request to the pending request area",
          },
          p_request: {
            label: "Process Request",
            tip: "Team members can process funding and withdrawal request in the pending area of the pool",
          },
          issues: {
            label: "Issues",
            tip: "Team members can click on the view more options in the issues area of the pool and can chat with the instance admin",
          },
        },
        createAction: "Submit",
        updateAction: {
          manage: "Manage",
          update: "Update",
          suspend: "Suspend",
          unsuspend: "Lift suspension",
        },
      },
    },
    PoolPages: {
      pageTitle: "Pool",
      nav: {
        tray: "Tray",
        pending_r: "Pending Request",
        processing_r: "Processing Request",
        completed_r: "Completed Requests",
        issues: "Issues",
        junks: "Junk",
      },
      TrayPage: {
        pageFilter: {
          withdraw: "withdrawal",
          funding: "funding",
        },
        pageMessage:
          "The faster you accept transaction the more Perks you get. You have a maximum limit of 20 transaction your can see. Ignore any transaction you don’t want to process.",
        TrayItem: {
          speed: "Speed Pecks",
          process: "Process Speed Pecks",
          commission: "Commission",
          ignore: "Ignore",
          accept: "Accept transaction",
        },
        TrayLimit: {
          title: "Limit Exceeded!",
          message: `You have exceeded your limit. kindly clear your pending request to allow you continue to accept requests.`,
          action: "Goto Pending Requests",
        },
      },
      PendingRequestPage: {
        pageFilter: {
          withdraw: "withdrawal",
          funding: "funding",
        },
        pageMessage:
          "Every pending transaction not processed in 5min will return to the request pool for other Merchants to treat.",
        junkPageMessage:
          "Every transaction that the evidence are reported of fraud or fake are dump here",
        CompleteWithdrawal: {
          title: "Complete Withdrawal Trasaction",
          subtitle:
            "Uploading a fake payment evidence may lead to freezing the equivalent of the transaction amount",
          label: "Upload Payment Evidence",
          required: "Payment Evidence is Required",
          checkBoxLabel: "Accept Terms & Conditions",
          submitAction: "Submit",
        },
        FundTransactionForm: {
          title: "Enter password and remark to proceed with funding",
          passwordLabel: "Enter Password",
          remarkLabel: "Remark",
          cancelButton: "Cancel",
          submitButton: "Submit",
        },
        FundTransactionSuccessful: {
          title: "Success!",
          subtitle: "Funding Completed Successfully",
          performance: {
            speed_perk: "Speed Pecks",
            process_speed_perk: "Process Speed Pecks",
            commission: "Commission",
            insurance: "Insurance",
            ignore: "Ignore",
            accept: "Accept transaction",
          },
          message: (date: string) =>
            `The insurance for this transaction will be released on ${date} if the user doesn’t have complaints`,
          subitAction: "Submit",
        },
        WithDrawalSuccess: {
          title: "Success!",
          subtitle: "Payment Completed Successfully",
          TrayItem: {
            speed: "Speed Pecks",
            process: "Process Speed Pecks",
            commission: "Commission",
            ignore: "Ignore",
            accept: "Accept transaction",
          },
          bank: {
            bankName: "Bank",
            accountNumber: "Acc. Number",
            accountName: "Name",
            memo: "Memo",
            evidence: "View Evidence",
            checkLabel: "Mark as being treated",
            action: "I have Transfered",
            fund: "Fund User",
          },
          message: (date: string) =>
            `The insurance for this transaction will be released on ${date} if the user doesn’t have complaints`,
          action: "Submit",
        },
        PaymentEvidence: {
          title: "Payment Evidence",
          downloadAction: "Download",
          message:
            "If this is a fake payment evidence from user, pleease report this user",
          reportAction: "Report",
          closeAction: "Close",
          addJunkAction: "Add to Junk",
        },
      },
      IssuesPage: {
        pageFilter: {
          new: "new",
          dispute: "dispute",
          resolved: "resolved",
          withdrawal: "withdrawal",
          funding: "funding",
        },
        message:
          "Our dispute resolution team is a Chat away. Click on transaction to chat with us.",
        Chat: {
          title: "Dispute Resolution Thread",
          body: {
            exchanger_title: "Me",
            user_title: "",
            admin_title: "Admin",
            image_alt_description: "evidence",
          },
          action: {
            upload_alt_description: "attach document",
            text_input_label: "chat box",
            text_input_placeholder: "Type a message",
            send_message_alt_description: "send message",
          },
        },
        table: {
          amount: "Amount",
          currency: "Currency",
          commisson: "Total Com.",
          date: "Date",
          memo: "Issues Memo",
          message: "Message",
          ref: "Reference",
        },
        tableData: {
          view: "View",
        },
      },
      CompletedRequestPage: {
        pageFilter: {
          withdrawal: "withdrawal",
          funding: "funding",
        },
        message:
          "Check the status of all your recent transactions. Know the transactions that have been cleared and those yet to be cleared",
        table: {
          amount: "Amount",
          currency: "Currency",
          speed: "Acc. Speed Perk",
          processing: "Processing Perk",
          commisson: "Total Com.",
          ref: "Reference",
          date: "Date",
          period: "Clearing",
          settlement_amount: "Settlement Amount",
        },
      },
    },
  },
  general: {
    NetworkErrorMessage: {
      errorMessage: "Please check your Network connection.",
    },
    ExpiredTokenErrorMessage: {
      errorMessage: "Login session expired, please login again",
    },
    logoutSuccessful: "Logout Successful",
    table: {
      fetchingData: "fetching data...",
      updatingData: "updating data...",
      noDataAvail: "No data Available",
    },
  },
};

export default English;
