import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import ToggleOfflineStatus from "./ToggleOfflineStatus";
import AppLoader from "./AppLoader";

function AppStateModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.confirmOfflineStatus}
        element={<ToggleOfflineStatus />}
      />
      <Route path={routes.protected.appLoading} element={<AppLoader />} />
    </Routes>
  );
}

export default AppStateModal;
