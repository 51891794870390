import React, { useEffect, useState } from "react";
import CustomLoader from "../../../../common/components/CustomLoader";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { setDefaultBank } from "../../store/thunk";

type Props = {
  data: any;
};

const { status: PageDictonary } =
  Language.protected.SettingsPages.BankSettingsPage;

function BankStatus({ data }: Props) {
  const { loading } = useAppSelector((state) => state.settings);
  const [localLoading, setLocalLoading] = useState(false);
  const dispatch = useAppDispatch();
  const is_disabled = data?.status?.toLowerCase() === "default";
  const clickHandler = () => {
    if (loading) {
      return;
    }
    const req = { data: { bank_id: data.bank_id }, user_id: data.user_id };
    dispatch(setDefaultBank(req));
    setLocalLoading(true);
  };

  useEffect(() => {
    if (localLoading === true && loading === false) {
      setLocalLoading(false);
    }
  }, [loading, localLoading]);

  return (
    <button
      onClick={clickHandler}
      className="flex cursor-pointer items-center gap-x-1 p-1 capitalize text-green-10 underline underline-offset-4 hover:animate-pulse hover:decoration-dashed disabled:animate-none disabled:cursor-default disabled:text-gold-100 disabled:no-underline"
      disabled={is_disabled}
    >
      {data.status === "DEFAULT"
        ? PageDictonary.default
        : PageDictonary.inactive}
      {localLoading ? <CustomLoader /> : null}
    </button>
  );
}

export default BankStatus;
