import { usePeriod } from "../../../hooks";
import { tW } from "../../../utils/helpers";

type Props = {
  data: any;
};

function Period({ data }: Props) {
  const period = usePeriod(data.grace_period, data.created_at);
  const res = period === "00:00:00" ? "Cleared" : period;
  return (
    <div className="flex items-center justify-center font-bold">
      <span
        className={tW(
          "cursor-pointer rounded-lg px-3 py-1.5 text-white",
          res === "Cleared" ? "bg-blue-20" : "bg-brown-10"
        )}
      >
        {res}
      </span>
    </div>
  );
}

export default Period;
