import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getNotificationList,
  languageChange,
  readNotification,
  switchMultiAccount,
  toggleServiceStatus,
} from "./thunk";
import { getInfoNew } from "../../dashboard/store/thunk";
import { Iappstate } from "../types";
import { loginUser, registerUser } from "../../auth/store/thunk";

const initialState: Iappstate = {
  loading: false,
  error: null,
  showMetrics: false,
  appState: {
    language: "English - EN",
    is_offline: false,
    country_data: {
      alpha2: "",
      alpha3: "",
      countryCallingCodes: [],
      currencies: [],
      ioc: "",
      languages: [],
      name: "",
    },
  },
  notification: {
    loading: false,
    data: [],
  },
  subAccounts: [],
  tour: {
    run: true,
    stepIndex: 0,
    steps: [],
    tourActive: false,
  },
};

const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    // toggleOfflineStatus: (state) => {
    //   state.appState.is_offline = !state.appState.is_offline;
    // },
    toggleMetricsVisbility: (state) => {
      state.showMetrics = !state.showMetrics;
    },
    updateLanguage: (state, action) => {
      state.appState.language = action.payload;
    },
    updateTourSteps: (state, action) => {
      state.tour.steps = action.payload;
    },
    updateTourStepIndex: (state, action) => {
      state.tour.run = true;
      state.tour.stepIndex = action.payload;
    },
    startTour: (state) => {
      state.tour.tourActive = true;
    },
    endTour: (state) => {
      state.tour.run = initialState.tour.run;
      state.tour.stepIndex = initialState.tour.stepIndex;
      state.tour.steps = [];
      state.tour.tourActive = initialState.tour.tourActive;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(languageChange.fulfilled, (state, action) => {
        state.appState.language = action.payload.language;
      })
      .addCase(getNotificationList.fulfilled, (state, action) => {
        state.notification.data = action.payload;
      })
      .addCase(readNotification.fulfilled, (state, action) => {
        state.notification.data = state.notification.data.map((itm) =>
          itm.notification_id === action.payload
            ? { ...itm, is_read: true }
            : itm
        );
      })
      .addCase(getInfoNew.fulfilled, (state, { payload }) => {
        state.appState.is_offline =
          payload.online_status === "ONLINE" ? true : false;
        state.appState.country_data = payload.country_data;
      })
      .addCase(toggleServiceStatus.fulfilled, (state) => {
        state.appState.is_offline = !state.appState.is_offline;
      })
      .addCase(registerUser.fulfilled, (state, { payload }) => {
        if (payload?.account_owner_email_address) {
          state.subAccounts.push({
            country: payload.country,
            email: payload.email,
            is_main: false,
          });
        }
      })
      .addMatcher(isAnyOf(getNotificationList.pending), (state) => {
        state.notification.loading = true;
      })
      .addMatcher(
        isAnyOf(getNotificationList.fulfilled, getNotificationList.rejected),
        (state) => {
          state.notification.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(switchMultiAccount.fulfilled, loginUser.fulfilled),
        (state, { payload, type }) => {
          state.appState.language = payload.language;
          state.appState.country_data = payload.country_data;
          if (type.includes("auth/loginUser")) {
            const temp = payload.intrapay_sub_accounts.map((itm) => ({
              ...itm,
              is_main: false,
            }));
            state.subAccounts = [
              {
                email: payload.email,
                country: payload.country_data.name,
                is_main: true,
              },
              ...temp,
            ];
          }
        }
      )
      .addMatcher(isAnyOf(toggleServiceStatus.pending), (state) => {
        state.loading = true;
      })
      .addMatcher(
        isAnyOf(toggleServiceStatus.fulfilled, toggleServiceStatus.rejected),
        (state) => {
          state.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(toggleServiceStatus.fulfilled, toggleServiceStatus.pending),
        (state) => {
          state.error = false;
        }
      )
      .addMatcher(isAnyOf(toggleServiceStatus.rejected), (state) => {
        state.error = true;
      });
  },
});

export const {
  // toggleOfflineStatus,
  toggleMetricsVisbility,
  updateLanguage,
  updateTourSteps,
  updateTourStepIndex,
  startTour,
  endTour,
} = appStateSlice.actions;

export default appStateSlice.reducer;
