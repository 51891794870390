import { Outlet } from "react-router-dom";
import { isMobile } from "react-device-detect";
import MobileAppDownload from "../../../module/appState/pages/MobileAppDownload";
import { partnersColors } from "../../utils/helpers/partners";

function Layout() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const query = JSON.parse(JSON.stringify(params));
  const partner_id = query?.partner_id;

  return (
    <>
      <style>
        {`
    :root {
      --defaultBackground: ${partnersColors(partner_id)};
    }
  `}
      </style>
      {isMobile && !partner_id && process.env.NODE_ENV === "production" ? (
        <MobileAppDownload />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Layout;

// export async function runOneSignal() {
//   OneSignal.push(() => {
//     OneSignal.init(
//       {
//         appId: ONESIGNAL_KEY ? ONESIGNAL_KEY : "", //STEP 9
//         promptOptions: {
//           slidedown: {
//             enabled: true,
//             actionMessage:
//               "We'd like to show you notifications for the latest news and updates about the following categories.",
//             acceptButtonText: "OMG YEEEEESS!",
//             cancelButtonText: "NAHHH",
//             categories: {
//               tags: [
//                 {
//                   tag: "react",
//                   label: "ReactJS",
//                 },
//                 {
//                   tag: "angular",
//                   label: "Angular",
//                 },
//                 {
//                   tag: "vue",
//                   label: "VueJS",
//                 },
//                 {
//                   tag: "js",
//                   label: "JavaScript",
//                 },
//               ],
//             },
//           },
//         },
//         welcomeNotification: {
//           title: "One Signal",
//           message: "Thanks for subscribing!",
//         },
//       }

//       //Automatically subscribe to the new_app_version tag
//       // OneSignal.sendTag("new_app_version", "new_app_version", (tagsSent) => {
//       //   // Callback called when tag has finished sending
//       //   console.log("new_app_version TAG SENT", tagsSent);
//       // })
//     );
//   });
// }
