import { useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import success from "../../../../static/images/success.gif";

type Props = {
  path: string;
  title: string;
  subtitle?: string;
};

function SuccessModal({ path, title, subtitle }: Props) {
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(path, { replace: true });
  };

  return (
    <SideModal clickBackDrop={closeHandler} className="px-15 pt-14">
      <section className="flex flex-col gap-y-9 pr-18">
        <header className="mt-7 pt-0.5">
          <h3 className="text-2xl font-semibold">{title}</h3>
          {subtitle ? <p className="pt-2">{subtitle}</p> : null}
        </header>
        <img src={success} alt="successful" className="self-center" />
      </section>
    </SideModal>
  );
}

export default SuccessModal;
