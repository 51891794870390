import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import FundWallet from "./FundWallet";
import WithdrawalForm from "./Withdrawal/WithdrawalForm";
import AuthorizeWithdrawalForm from "./Withdrawal/AuthorizeWithdrawalForm";
import TogglePaymentNotification from "./TogglePaymentNotification";
import PaymentNotification from "./PaymentNotification";
import TransactionInformation from "./Transactions/TransactionInformation";
import SetupConfig from "./Setup/Config";
import SetupSuccess from "./Setup/Success";
import SubmitKyc from "./Kyc/SubmitKyc";
import ReviewKyc from "./Kyc/ReviewKyc";
import TurnOffPayment from "./TurnOffPayment";
import WithdrawalSuccess from "./Withdrawal/WithdrawalSuccess";
import SwapCalculator from "./SwapCalculator";
import { TransactionSummary } from "./Transactions/TransactionSummary";
import ConfirmClaim from "./Claim/ConfirmClaim";
import ClaimProcessing from "./Claim/ClaimProcessing";

function PaymentModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.fiat_wallet.overview.fund.fund}
        element={<FundWallet />}
      />
      <Route
        path={routes.protected.fiat_wallet.overview.withdrawal.withdrawal}
        element={<WithdrawalForm />}
      />
      <Route
        path={
          routes.protected.fiat_wallet.overview.withdrawal.withdrawal_authorize
        }
        element={<AuthorizeWithdrawalForm />}
      />
      <Route
        path={routes.protected.fiat_wallet.success}
        element={<WithdrawalSuccess />}
      />
      <Route
        path={routes.protected.fiat_wallet.overview.swap}
        element={<SwapCalculator />}
      />
      <Route
        path={routes.protected.fiat_wallet.payment_methods.add_merchant}
        element={<TogglePaymentNotification />}
      />
      <Route
        path={routes.protected.fiat_wallet.payment_methods.add_merchant_success}
        element={<PaymentNotification />}
      />
      <Route
        path={routes.protected.fiat_wallet.payment_methods.turnoff}
        element={<TurnOffPayment isOnline={false} />}
      />
      <Route
        path={routes.protected.fiat_wallet.payment_methods.turnon}
        element={<TurnOffPayment isOnline={true} />}
      />
      <Route
        path={routes.protected.fiat_wallet.overview.withdrawal.withdrawal_info}
        element={
          <TransactionInformation
            title="Withdrawal Info"
            isWithdrawalInfo={true}
          />
        }
      />
      <Route
        path={routes.protected.fiat_wallet.overview.withdrawal.sender_info}
        element={
          <TransactionInformation
            title="Sender Info"
            isWithdrawalInfo={false}
          />
        }
      />
      <Route
        path={routes.protected.fiat_wallet.overview.transactions.summary}
        element={<TransactionSummary />}
      />
      <Route
        path={routes.protected.fiat_wallet.claim.confirm}
        element={<ConfirmClaim />}
      />
      <Route
        path={routes.protected.fiat_wallet.claim.processing}
        element={<ClaimProcessing />}
      />
      <Route
        path={routes.protected.fiat_wallet.setup.config}
        element={<SetupConfig />}
      />
      <Route
        path={routes.protected.fiat_wallet.setup.success}
        element={<SetupSuccess />}
      />
      <Route
        path={routes.protected.fiat_wallet.kyc.submit}
        element={<SubmitKyc />}
      />
      <Route
        path={routes.protected.fiat_wallet.kyc.review}
        element={<ReviewKyc />}
      />
      <Route
        path={routes.protected.fiat_wallet.kyc.sucess}
        element={<ReviewKyc />}
      />
    </Routes>
  );
}

export default PaymentModal;
