import React from "react";
import { tW } from "../../../../../common/utils/helpers";

type Props = {
  status: boolean;
};

function PaymentPartnerStatus({ status }: Props) {
  return (
    <div className="flex items-center gap-x-2 text-xs font-medium">
      <p>Status:</p>
      <span
        className={tW(
          "rounded-lg p-3",
          status ? "bg-green-400/25 text-green-40" : "bg-red-400/25 text-red-40"
        )}
      >
        {status ? "Active" : "InActive"}
      </span>
    </div>
  );
}

export default PaymentPartnerStatus;
