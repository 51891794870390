import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import { tW } from "../../../../common/utils/helpers";

type props = {
  className?: string;
  type?: string;
  data: any;
  data_tut?: string;
};

const { banner: PageDictionary } = Language.protected.DashboardPage;

function HeaderCard({ className, type, data, data_tut }: props) {
  const location = useLocation();
  const navigate = useNavigate();
  const fundWalletHandler = () => {
    navigate(routes.protected.crypto_wallet.fund, {
      state: { background: location },
    });
  };
  const withdrawWalletHandler = () => {
    navigate(routes.protected.crypto_wallet.withdraw.index, {
      state: { background: location },
    });
  };
  // console.log(data, "headercard");
  return (
    <div
      data-tut={data_tut}
      className={tW(
        "min-w-67.5 flex-1 rounded-md border border-purple-200 bg-white px-6 pt-3.5 pb-2",
        className
      )}
    >
      <header>
        <p className="text-sm font-semibold capitalize text-base-50">{type}</p>
      </header>
      <h5 className="text-3xl font-bold leading-10 text-base-50">{data}</h5>
      <div className="mt-2.5 flex items-center justify-between">
        {type === PageDictionary.capital ? (
          <button
            onClick={fundWalletHandler}
            className="bg-transparent pr-3 text-sm font-bold leading-none text-green-10"
          >
            {PageDictionary.cards.fund}
          </button>
        ) : null}
        <button
          onClick={withdrawWalletHandler}
          className="bg-transparent pr-3 text-sm font-bold leading-none text-green-10"
        >
          {PageDictionary.cards.withdraw}
        </button>
      </div>
    </div>
  );
}

export default HeaderCard;
