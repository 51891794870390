import dayjs from "dayjs";
import React, { Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import Period from "../../../../common/components/CustomTable/Period";
import routes from "../../../../common/routes";
import {
  formatInstanceAccount,
  formatPermissionName,
  toCurrency,
} from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import timer from "../../../../static/images/icons/timer.svg";

const { WithDrawalSuccess: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

const grace_period = dayjs().add(3, "days").toISOString();
const temp = { grace_period };

function WithDrawalSuccess() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { background, data } = useLocation().state;

  const userBankObject = formatInstanceAccount(data?.transaction);

  const closeModal = () =>
    navigate(background.pathname, {
      replace: true,
      state: null,
    });

  const viewEvidenceHandler = () => {
    if (id) {
      const path = routes.protected.pool.view_evidence.link(id);
      navigate(path, {
        state: { background, data: data?.payment_evidence_url },
        replace: true,
      });
    }
  };

  return (
    <CenterModal
      handleClose={closeModal}
      className="w-157.5 gap-y-11 rounded-xl py-9 px-14 text-blue_gray-10"
    >
      <header className="flex flex-col gap-y-2">
        <h4 className="w-[18.75rem] self-center rounded-3xl bg-green-10 px-3 py-2.5 text-center font-bold text-white">
          {PageDictionary.title}
        </h4>
        <p className="text-center text-lg leading-8">
          {PageDictionary.subtitle}
        </p>
        <div className="flex items-center justify-between text-1919 font-bold">
          <p>{toCurrency(data?.amount_to_pay)}</p>
          <div className="flex w-20 items-center justify-between rounded-xl bg-brown-20 py-1.5 px-3 text-center">
            <img src={timer} alt="timer icon" />
            {data?.dur}s
          </div>
        </div>
      </header>
      <div className="flex flex-col gap-y-4">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-1 flex-col gap-y-4">
            {Object.keys(data?.performance)?.map((key) => (
              <div key={key} className="flex flex-col gap-y-2.5 font-bold">
                <h6 className="text-[9.73px] capitalize leading-none text-gray-40">
                  {formatPermissionName(key)}
                </h6>
                <p className="text-base leading-none">
                  {data?.performance[key]}
                </p>
              </div>
            ))}
          </div>
          <div className="grid flex-[1.6] grid-cols-[repeat(2,max-content)] justify-between gap-y-1 rounded-md border border-dashed border-blue_gray-10 px-4 pt-7 pb-4.5 text-1016 ">
            {Object.keys(userBankObject).map((key) => (
              <Fragment key={key}>
                <p className="capitalize">{formatPermissionName(key)}</p>
                <p className="text-right font-bold">{userBankObject[key]}</p>
              </Fragment>
            ))}

            <button
              onClick={viewEvidenceHandler}
              className="col-span-2 mt-6 justify-self-start rounded bg-green-30 py-1 px-4 font-bold text-blue_gray-10"
            >
              {PageDictionary.bank.evidence}
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-3">
          <div className="flex items-center gap-x-7">
            <Period data={temp} />
            <p className="text-xs">
              {PageDictionary.message(
                dayjs(data?.updated_at).format("MMMM D, YYYY")
              )}
            </p>
          </div>
          <CustomButtom
            onClick={closeModal}
            className="self-center !bg-blue_gray-10"
          >
            {PageDictionary.action}
          </CustomButtom>
        </div>
      </div>
    </CenterModal>
  );
}

export default WithDrawalSuccess;
