import React, { useState } from "react";
import SideModal from "../../../../../common/components/CustomModal/SideModal";
import CustomInput from "../../../../../common/components/CustomInput";
import {
  Select,
  SelectItem,
} from "../../../../../common/components/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../../common/hooks";
import {
  IcreateWalletThunk,
  iHandleChange,
  iHandleSubmit,
} from "../../../types";
import routes from "../../../../../common/routes";
import Language from "../../../../../common/utils/language/en";
import CustomButton from "../../../../../common/components/CustomButton";
import { createWallet } from "../../../store/thunk";
import { validationRules } from "../../../../../common/utils/helpers";
// import bnb from "../../../../../static/images/bnb.svg";

const { config: PageDictionary } = Language.protected.fiatModals.setup;

const initialState: IcreateWalletThunk = {
  user_id: "",
  merchant_type: "INDIVIDUAL",
  business_name: "",
  bvn: "",
  nin: "",
  dob: "",
  gender: "MALE",
  address: "",
  phone: "",
  identity: "",
};

// var eighteenYearsAgo = new Date();
// eighteenYearsAgo.setTime(
//   eighteenYearsAgo.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000
// );

// const today = new Date(eighteenYearsAgo);

function SetupConfig() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { is_loading } = useAppSelector((state) => state.payment);
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { name: user_country } = useAppSelector(
    (state) => state.appState.appState.country_data
  );
  const dispatch = useAppDispatch();
  const [inputs, setInputs] = useState(initialState);
  const [inError, setInError] = useState<Record<string, boolean>>({
    bvn: false,
    phone: false,
    identity: false,
    nin: false,
  });

  useEffectOnce(() => {
    setInputs((prev) => ({ ...prev, user_id }));
  });

  const changeHandler: iHandleChange<HTMLInputElement | HTMLTextAreaElement> = (
    e
  ) => {
    const { name, value } = e.target;

    const rules = e.target.getAttribute("data-rules");
    setInputs((prev) => ({ ...prev, [name]: value }));
    if (!rules) return;
    const regex = new RegExp(rules?.substring(1, rules.length - 1));
    if (regex.test(value)) {
      setInError((prev) => ({ ...prev, [name]: false }));
    } else {
      setInError((prev) => ({ ...prev, [name]: true }));
    }
  };

  const selectHandler = (value: string) => {
    setInputs((prev) => ({
      ...prev,
      merchant_type:
        value.toUpperCase() === "INDIVIDUAL" ? "INDIVIDUAL" : "BUSINESS",
    }));
  };

  const goBack = () => {
    if (is_loading) {
      return;
    }
    navigate(state?.background?.pathname, { replace: true });
    // navigate(-2);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    const dudValues = Object.values(inputs).filter((itm) => itm === "");
    if (user_country === "Nigeria") {
      if (inputs.merchant_type === "BUSINESS" && dudValues.length > 1) {
        return;
      }
      if (inputs.merchant_type === "INDIVIDUAL" && dudValues.length > 2) {
        return;
      }
    }
    if (user_country !== "Nigeria") {
      if (inputs.merchant_type === "BUSINESS" && dudValues.length > 2) {
        return;
      }
      if (inputs.merchant_type === "INDIVIDUAL" && dudValues.length > 3) {
        return;
      }
    }

    const date = inputs.dob.split("-");
    const dob = `${date[1]}/${date[2]}/${date[0]}`;
    const data = { ...inputs, dob };

    dispatch(createWallet(data))
      .unwrap()
      .then(() => {
        navigate(routes.protected.fiat_wallet.setup.success, {
          state: {
            background: {
              ...state.background,
              pathname: routes.protected.fiat_wallet.overview.withdrawals,
            },
            data: inputs,
          },
          replace: true,
        });
      });
  };
  return (
    <SideModal clickBackDrop={goBack} className="px-15 py-5">
      <section className="pr-18">
        <header className="mt-7 pt-0.5">
          <h3 className="text-2xl font-semibold">{PageDictionary.title}</h3>
          <p className="pt-2">{PageDictionary.subtitle}</p>
        </header>
        <form
          className="flex flex-col gap-y-6 pr-8 pt-9"
          onSubmit={submitHandler}
        >
          <Select
            onChange={selectHandler}
            label={PageDictionary.form.merchant_type_label}
            labelClassName="text-base-2 mb-2 inline-block text-base font-medium"
            value={inputs.merchant_type.toLowerCase()}
            className="border-blue_gray-300 h-12 w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 capitalize text-black placeholder:text-base placeholder:text-gray-600"
            contentClassName="!p-0 z-50 capitalize"
          >
            {/* <SelectItem className="!px-0 first:!border-b" value="Access">
              Access Bank
            </SelectItem> */}

            {PageDictionary.form.merchant_type_options.map((itm) => (
              <SelectItem
                key={itm}
                className="!px-0 first:!border-b"
                value={itm}
              >
                {itm}
              </SelectItem>
            ))}
          </Select>

          {inputs.merchant_type === "BUSINESS" ? (
            <CustomInput
              label={PageDictionary.form.business_name_label}
              name="business_name"
              value={inputs.business_name}
              onChange={changeHandler}
              className="h-12"
            />
          ) : null}
          {user_country === "Nigeria" ? (
            <>
              <CustomInput
                label={PageDictionary.form.bvn_label}
                name="bvn"
                value={inputs.bvn}
                onChange={changeHandler}
                className="h-12"
                rules={validationRules.number}
                haserror={inError.bvn.toString()}
              />
              <CustomInput
                label={PageDictionary.form.nin_label}
                name="nin"
                value={inputs.nin}
                onChange={changeHandler}
                className="h-12"
                rules={validationRules.number}
                haserror={inError.nin.toString()}
              />
            </>
          ) : (
            <CustomInput
              label={PageDictionary.form.international_id_label}
              name="identity"
              value={inputs.identity}
              onChange={changeHandler}
              className="h-12"
              rules={validationRules.string}
              haserror={inError.bvn.toString()}
            />
          )}
          <CustomInput
            label={PageDictionary.form.dob_label}
            name="dob"
            value={inputs.dob}
            onChange={changeHandler}
            type="date"
            className="h-12 pr-5"

            // max={today.toDateString()}
          />
          <fieldset
            id="policy_type"
            className="rounded-xl border border-transparent  py-3.5 pl-0.5"
            // onBlur={fieldSetBlurHandler}
          >
            <legend className="inline-block bg-white pl-0.5 pr-4 text-base font-medium text-base-10">
              {PageDictionary.form.gender.title}
            </legend>

            <div className="flex items-center gap-x-10">
              <CustomInput
                type="radio"
                label={PageDictionary.form.gender.radio_first}
                name="gender"
                containerClassName="flex items-center gap-x-3 flex-row-reverse justify-start flex-grow-0"
                lclassName="flex-1 mb-0 text-sm leading-6 cursor-pointer flex-grow-[8]"
                className="h-auto cursor-pointer"
                value="MALE"
                onChange={changeHandler}
                checked={inputs.gender === "MALE"}
              />
              <CustomInput
                type="radio"
                label={PageDictionary.form.gender.radio_second}
                name="gender"
                containerClassName="flex items-center gap-x-3 flex-row-reverse justify-start flex-grow-0"
                lclassName="flex-1 mb-0 text-sm leading-6 cursor-pointer flex-grow-[8]"
                className="h-auto cursor-pointer"
                value="FEMALE"
                onChange={changeHandler}
                checked={inputs.gender === "FEMALE"}
              />
            </div>
          </fieldset>
          <CustomInput
            label={PageDictionary.form.address_label}
            name="address"
            value={inputs.address}
            onChange={changeHandler}
            className="h-12"
          />
          <CustomInput
            label={PageDictionary.form.phone_label}
            name="phone"
            value={inputs.phone}
            onChange={changeHandler}
            className="h-12"
            rules={validationRules.number}
            haserror={inError.phone.toString()}
          />

          <CustomButton
            isloading={is_loading}
            disabled={Object.values(inError).includes(true)}
            className="self-start "
          >
            {PageDictionary.form.save_btn}
          </CustomButton>
        </form>
      </section>
    </SideModal>
  );
}

export default SetupConfig;
