import { Outlet, useLocation } from "react-router-dom";
import { cryptoWalletNavlinks } from "../../../../common/utils/helpers/navHelpers";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import Language from "../../../../common/utils/language/en";
import { CgExport } from "react-icons/cg";
import {
  useAppSelector,
  usePageTitle,
  useScrollToTop,
} from "../../../../common/hooks";
import Banner from "../Banner";
import CustomButton from "../../../../common/components/CustomButton";
import routes from "../../../../common/routes";
import { downloadObjectAsCSV } from "../../../../common/utils/helpers";

const { CryptoWalletPages: PageDictionary } = Language.protected;

function Wallet() {
  usePageTitle(PageDictionary.pageTitle);
  const { titleRef } = useScrollToTop();
  const { pathname } = useLocation();
  const {
    commission: { loading: commission_loading, data: commission_data },
    capital: { loading: capital_loading, data: capital_data },
  } = useAppSelector((state) => state.cryptoWallet);

  const clickHandler = () => {
    if (pathname === routes.protected.crypto_wallet.capital) {
      downloadObjectAsCSV(
        capital_data,
        `${PageDictionary.pageTitle} || ${PageDictionary.nav.capital}`
      );
    } else {
      downloadObjectAsCSV(
        commission_data,
        `${PageDictionary.pageTitle} || ${PageDictionary.nav.commission}`
      );
    }
  };

  const disableHandler =
    pathname === routes.protected.crypto_wallet.capital
      ? capital_loading || capital_data.length === 0
      : commission_loading || commission_data.length === 0;

  return (
    <>
      <header ref={titleRef} className="flex flex-col gap-y-9">
        <Banner />
        <nav className="flex items-center justify-between">
          <HorizontalNav navlinks={cryptoWalletNavlinks} />
          <CustomButton disabled={disableHandler} onClick={clickHandler}>
            {PageDictionary.export}
            <CgExport />
          </CustomButton>
        </nav>
      </header>
      <Outlet />
    </>
  );
}

export default Wallet;
