import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClear } from "react-icons/ai";
import { CgExport } from "react-icons/cg";
import CollectionCards from "../CollectionCards";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButton from "../../../../common/components/CustomButton";
import CustomCalender, {
  iCalendarChange,
} from "../../../../common/components/CustomCalender";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../../../common/hooks";
import { paymentNavlinks } from "../../../../common/utils/helpers/navHelpers";
import { exportClick, exportDisableHandler, filterHelper } from "./helper";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import { iHandleChange } from "../../types";
import { getP2PTransactions } from "../../store/thunk";
import { paymentActions } from "../../store/slice";

const { layout: PageDictionary } = Language.protected.fiatPages;

const initialDateParams = {
  startDate: null,
  endDate: null,
};

function CollectionLayout() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { collections, pagination } = useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = useState("");
  const loading = useRef<boolean>(false);
  // const [loading, setLoading] = useState({ search: false, date: false });
  const [dateParams, setDateParams] =
    useState<Record<string, Date | null>>(initialDateParams);

  const queryDebounce = useDebounce(query);
  const calenderRef = useRef<any>(null);

  useEffect(() => {
    if (query.length !== 0) {
      setQuery("");
    }
    if (dateParams.endDate) {
      setDateParams(initialDateParams);
      dispatch(paymentActions.resetPagination());
    }
  }, [pathname]); //eslint-disable-line

  const requestHandler = (path: string, order_no?: string) => {
    if (loading.current === true || queryDebounce !== query) return;
    loading.current = true;
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "collection",
        order_type: filterHelper(path),
        order_no,
        limit: pagination.limit,
        page: 1,
      })
    )
      .unwrap()
      .finally(() => (loading.current = false));
  };

  useEffect(() => {
    requestHandler(pathname, queryDebounce);
  }, [queryDebounce]); // eslint-disable-line

  const collectionNavHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const primary = "/wallet";
    const seconday = e.currentTarget.href.split(primary)?.[1] || "";
    const path = primary + seconday;
    requestHandler(path);
  };

  const queryChangeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const calanderChangeHandler: iCalendarChange = (date) => {
    const [start, end] = date;
    setDateParams({
      startDate: start,
      endDate: end,
    });

    if (date.includes(null)) return;
    loading.current = true;
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "collection",
        order_type: filterHelper(pathname),
        date_from: start ? dayjs(start).format("YYYY-MM-DD") : "",
        date_to: end ? dayjs(end).format("YYYY-MM-DD") : "",
        limit: pagination.limit,
        page: 1,
      })
    )
      .unwrap()
      .finally(() => (loading.current = false));
  };

  const resetCalenderHandler = () => {
    calenderRef.current?.clear();
    setDateParams(initialDateParams);
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "collection",
        order_type: filterHelper(pathname),
        limit: pagination.limit,
        page: 1,
      })
    );
  };

  const overviewClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { href } = e.currentTarget;

    if (
      href.includes(routes.protected.fiat_wallet.overview.transactions.index)
    ) {
      navigate(routes.protected.fiat_wallet.overview.transactions.pending);
    } else {
      navigate(routes.protected.fiat_wallet.overview.withdrawals.completed);
    }
  };

  const exportClickHandler = () => exportClick(pathname, collections);
  const exportDisable = exportDisableHandler(pathname, collections);

  return (
    <>
      <div className="">
        <CollectionCards />
      </div>
      <nav className=" flex items-end justify-between  gap-x-1 border-b border-gray-20 pt-9">
        <div className="flex-1 ">
          <HorizontalNav
            navlinks={paymentNavlinks.overview}
            onClick={overviewClickHandler}
            containerClassName="pb-0"
            className="border-transparent"
          />
        </div>
        <div className="flex items-center gap-x-4 pb-3">
          <CustomInput
            type="search"
            className="h-10 min-w-[308px] appearance-none placeholder:text-[#AEAEAE]"
            placeholder="Search by order number"
            lclassName="hidden"
            value={query}
            onChange={queryChangeHandler}
          />
        </div>
      </nav>

      <nav className=" flex items-end justify-between  gap-x-1 pt-10 pb-14">
        <div className="flex-1 ">
          <HorizontalNav
            navlinks={paymentNavlinks.collections}
            containerClassName="pb-0"
            className="border-transparent px-0"
            activeClassName="py-2.5 px-4.5 bg-blue-10 text-white rounded-xl border-none"
            itemClassName="py-2.5 px-4.5 bg-transparent text-blue-10"
            onClick={collectionNavHandler}
          />
        </div>
        <div className="flex items-center gap-x-6">
          <div className="flex items-center gap-x-1">
            <CustomCalender
              onChange={calanderChangeHandler}
              startDate={dateParams.startDate}
              endDate={dateParams.endDate}
              type="picker"
              className="h-10 min-w-fit appearance-none rounded-lg border-blue_gray-30 bg-white py-2 px-4 text-blue_gray-10 placeholder:text-[#AEAEAE]"
              placeholder="Filter by Date"
              calRef={calenderRef}
              maxDate
            />
            {dateParams.endDate ? (
              <button onClick={resetCalenderHandler} className="p-2">
                <AiOutlineClear />
              </button>
            ) : null}
          </div>
          <CustomButton
            disabled={exportDisable}
            onClick={exportClickHandler}
            className=""
            variant
          >
            {PageDictionary.export}
            <CgExport />
          </CustomButton>
        </div>
      </nav>
    </>
  );
}

export default CollectionLayout;
