import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { toggleP2P } from "../../store/thunk";
// import { toggleOfflineStatus } from "../../store/slice";

type Props = {
  isOnline: boolean;
};

const { toggle_off: PageDictionary } =
  Language.protected.fiatModals.payment_method;

function TurnOffPayment({ isOnline }: Props) {
  const { state } = useLocation();
  const partner_code = state?.data?.partner_code;
  const [loading, setLoading] = useState(false);
  const { user_id } = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cancelHandler = () => {
    if (loading) {
      return;
    }
    navigate(state.background.pathname, { replace: true, state: null });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      user_id,
      partner_code,
      status: isOnline,
    };
    dispatch(toggleP2P(data))
      .unwrap()
      .finally(() => {
        setLoading(false);
        cancelHandler();
      });
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={cancelHandler}
    >
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">
          *
          {isOnline
            ? PageDictionary.messageOnline
            : PageDictionary.messageOffline}
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButtom variant onClick={cancelHandler}>
            {PageDictionary.actions.reject}
          </CustomButtom>
          <CustomButtom disabled={loading}>
            {PageDictionary.actions.accept}
          </CustomButtom>
        </div>
      </form>
    </CenterModal>
  );
}

export default TurnOffPayment;
