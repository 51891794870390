import React, { useState } from "react";
import { BsDownload, BsTrash } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks";
import { moveToJunk } from "../../store/thunk";

const { PaymentEvidence: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

function PaymentEvidence() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const user_id = useAppSelector((state) => state.auth.user.user_id);
  const [addToJunkLoader, setAddToJunkLoader] = useState(false);

  const closeModal = () =>
    navigate(state.background.pathname, { replace: true, state: null });

  const reportHandler = () => {
    if (id) {
      const path = routes.protected.pool.issues.message.link(id);
      navigate(path, { replace: true });
    }
  };

  const addToJunkHandler = () => {
    if (!id) return;
    setAddToJunkLoader(true);
    dispatch(moveToJunk({ user_id, tray_id: id }))
      .unwrap()
      .then(closeModal)
      .finally(() => setAddToJunkLoader(false));
  };

  return (
    <CenterModal
      handleClose={closeModal}
      className="w-157.5 gap-y-5 rounded-xl px-12 pt-8 pb-9 text-blue_gray-10"
    >
      <header className="flex w-full items-center justify-between">
        <h3 className="text-[1.375rem] font-bold leading-9">
          {PageDictionary.title}
        </h3>
        <CustomButtom
          variant
          className="flex items-center gap-x-2 !border-green-10 px-2 !text-green-10"
        >
          <BsDownload size={12} /> {PageDictionary.downloadAction}
        </CustomButtom>
      </header>
      <div className="flex flex-col gap-y-5">
        <p>{PageDictionary.message}</p>
        <img src={state.data} alt="evidence " />
        <div className="mt-3.5 flex items-center justify-between">
          {state?.background?.pathname !== routes.protected.pool.junks && (
            <CustomButtom
              onClick={addToJunkHandler}
              isloading={addToJunkLoader}
              variant
              className="flex items-center gap-x-2 border-red-300 px-2 text-sm font-medium leading-5 text-red-300"
            >
              <BsTrash size={10} /> {PageDictionary.addJunkAction}
            </CustomButtom>
          )}
          <button onClick={reportHandler} className="font-bold text-red-300">
            {PageDictionary.reportAction}
          </button>
          <CustomButtom onClick={closeModal} className=" bg-green-10">
            {PageDictionary.closeAction}
          </CustomButtom>
        </div>
      </div>
    </CenterModal>
  );
}

export default PaymentEvidence;
