import { useEffect, useRef } from "react";

function usePageTitle(pageTitle: string) {
  const mountOnce = useRef(false);

  useEffect(() => {
    if (mountOnce.current === true) {
      return;
    }
    document.title = `IntraPay | ${pageTitle}`;
    mountOnce.current = true;
  }, [pageTitle]);

  return;
}

export { usePageTitle };
