import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import CompleteWithdrawal from "./CompleteWithdrawal";
import FundTransactionForm from "./FundTransactionForm";
import FundTransactionSuccessful from "./FundTransactionSuccessful";
import PaymentEvidence from "./PaymentEvidence";
import TrayLimit from "./TrayLimit";
import WithDrawalSuccess from "./WithDrawalSuccess";

function PoolModal() {
  return (
    <Routes>
      <Route path={routes.protected.pool.tray_limit} element={<TrayLimit />} />
      <Route
        path={routes.protected.pool.request_withdrwal.index}
        element={<CompleteWithdrawal />}
      />
      <Route
        path={routes.protected.pool.withdrwal_success.index}
        element={<WithDrawalSuccess />}
      />
      <Route
        path={routes.protected.pool.view_evidence.index}
        element={<PaymentEvidence />}
      />
      <Route
        path={routes.protected.pool.fund_transaction.index}
        element={<FundTransactionForm />}
      />
      <Route
        path={routes.protected.pool.fund_transaction_success.index}
        element={<FundTransactionSuccessful />}
      />
    </Routes>
  );
}

export default PoolModal;
