import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import SideModal from "../../../../common/components/CustomModal/SideModal";
// import routes from "../../../../common/routes";
import Language from "../../../../common/utils/language/en";
import success from "../../../../static/images/success.gif";

const { success: PageDictionary } =
  Language.protected.fiatModals.payment_method;

function PaymentNotification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const title = state?.data?.title;

  const backToDashboard = () =>
    navigate(state?.background?.pathname, { replace: true });
  return (
    <SideModal clickBackDrop={backToDashboard} className="px-15 pt-24">
      <div className="flex h-full flex-col items-center justify-center gap-y-20">
        <img src={success} alt="successful" />
        <div className="w-[28.8125rem] text-center">
          <h2 className="text-base-2 text-[2rem] font-semibold leading-none">
            {PageDictionary.title(title)}
          </h2>
          <div className="mt-6">
            <p className=" text-lg font-normal leading-[1.875rem] text-gray-600">
              {PageDictionary.subtitle(title)}
            </p>
          </div>
        </div>
        <CustomButtom onClick={backToDashboard}>
          {PageDictionary.action}
        </CustomButtom>
      </div>
    </SideModal>
  );
}

export default PaymentNotification;
