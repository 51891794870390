import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { IperformanceSlice, ResGetChart } from "../types";
import { getChartData, getMetrics } from "./thunk";
import { logoutUser } from "../../appState/store/thunk";

const initialState: IperformanceSlice = {
  loading: false,
  error: null,
  metrics_score: {
    speed_of_accepting_request: 0,
    speed_of_completing_request: 0,
    total_txn_processed: 0,
    total_retracted_request: 0,
    issue_dispute: 0,
    dispute_won: 0,
    speed_of_processing_request: 0,
    retracted_txn_index: 0,
  },
  overral_score: {
    overall: 0,
    current_score: 0,
    tray_limit: 0,
    pending_request_limits: 0,
    speed_of_accepting_request_perks: 0,
    speed_of_completing_request_perks: 0,
    txn_commision: 0,
    level: 0,
    badge: 0,
    position: "0 of 0",
    date_joined: 0,
  },
  chart: {
    data: {
      labels: [],
      datasets: [
        {
          label: "Performance",
          data: [],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "y",
        },
        {
          label: "Total Request",
          data: [],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          yAxisID: "y1",
        },
      ],
    },
  },
};

const performanceSlice = createSlice({
  name: "performance",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMetrics.fulfilled, (state, { payload }) => {
        state.metrics_score = { ...payload?.metrics_score };
        state.overral_score = { ...payload?.overral_score };
      })
      .addCase(
        getChartData.fulfilled,
        (state, { payload }: PayloadAction<ResGetChart>) => {
          state.chart.data.labels = [...payload?.duration];
          const tempdata = state.chart.data?.datasets.map((itm, idx) => {
            if (idx === 0) {
              return { ...itm, data: [...payload.performance] };
            }
            return { ...itm, data: [...payload.total_request] };
          });
          state.chart.data.datasets = [...tempdata];
        }
      )
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })
      .addMatcher(
        isAnyOf(getMetrics.pending, getChartData.pending),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getMetrics.rejected,
          getMetrics.fulfilled,
          getChartData.rejected,
          getChartData.fulfilled
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      )
      .addMatcher(
        isAnyOf(getMetrics.rejected, getChartData.rejected),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getMetrics.pending,
          getMetrics.fulfilled,
          getChartData.pending,
          getChartData.fulfilled
        ),
        (state) => {
          if (state.error) {
            state.error = initialState.error;
          }
        }
      );
  },
});

export default performanceSlice.reducer;
