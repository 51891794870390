import React from "react";
import { useLocation } from "react-router-dom";
import AppStateModal from "../../../../module/appState/Modal";
import CryptoModal from "../../../../module/crypto/Modal";
import PoolModal from "../../../../module/pool/Modal";
import SettingsModal from "../../../../module/settings/Modal";
import TeamModal from "../../../../module/team/Modal";
import TransactionsModal from "../../../../module/transactions/Modal";
import OnboardingModal from "../../../../module/compliance/Modal";
import FiatModal from "../../../../module/fiat/Modal";
import routes from "../../../routes";

function Modal() {
  const { pathname } = useLocation();
  return (
    <>
      {pathname.includes("status") ||
      pathname === routes.protected.appLoading ? (
        <AppStateModal />
      ) : null}
      {pathname.includes(routes.protected.crypto_wallet.index) ? (
        <CryptoModal />
      ) : null}
      {pathname.includes("pool") ? <PoolModal /> : null}
      {pathname.includes("settings") ? <SettingsModal /> : null}
      {pathname.includes("team") ? <TeamModal /> : null}
      {pathname.includes("transactions") ? <TransactionsModal /> : null}
      {pathname.includes("onboarding") ? <OnboardingModal /> : null}
      {pathname.includes(routes.protected.fiat_wallet.index) ? (
        <FiatModal />
      ) : null}
    </>
  );
}

export default Modal;
