import { Icollections } from "../../../types";

export const getData = (
  backgroundPath: string,
  collections: Icollections,
  clickedId: string
) => {
  const { abandoned, completed, expired, mismatched, pending, claimed } =
    collections;

  let tempData: any;

  switch (true) {
    case backgroundPath.includes("abandoned"):
      tempData = abandoned.data.find((itm) => itm?.collection_id === clickedId);
      break;
    case backgroundPath.includes("completed"):
      tempData = completed.data.find((itm) => itm?.collection_id === clickedId);
      break;
    case backgroundPath.includes("expired"):
      tempData = expired.data.find((itm) => itm?.collection_id === clickedId);
      break;
    case backgroundPath.includes("mismatched"):
      tempData = mismatched.data.find(
        (itm) => itm?.collection_id === clickedId
      );
      break;
    case backgroundPath.includes("pending"):
      tempData = pending.data.find((itm) => itm?.collection_id === clickedId);
      break;
    case backgroundPath.includes("claimed"):
      tempData = claimed.data.find((itm) => itm?.collection_id === clickedId);
      break;

    default:
      break;
  }

  return tempData;
};
export const getTransactionType = (backgroundPath: string) => {
  let tempData: any;

  switch (true) {
    case backgroundPath.includes("abandoned"):
      tempData = "abandoned";
      break;
    case backgroundPath.includes("completed"):
      tempData = "completed";
      break;
    case backgroundPath.includes("expired"):
      tempData = "expired";
      break;
    case backgroundPath.includes("mismatched"):
      tempData = "mismatched";
      break;
    case backgroundPath.includes("pending"):
      tempData = "pending";
      break;
    case backgroundPath.includes("claimed"):
      tempData = "claimed";
      break;

    default:
      break;
  }

  return tempData;
};
