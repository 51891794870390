import React from "react";
import { formatPermissionName } from "../../../../../common/utils/helpers";
import { IteamPermission } from "../../../type";

type props = {
  permissions: IteamPermission[];
};

function TeamAccess({ permissions }: props) {
  return (
    <div className=" flex items-center gap-x-2 font-bold">
      {permissions.map((permission) => (
        <button
          key={permission}
          className="rounded-lg bg-brown-20 px-2 py-1 text-[0.75rem] font-bold capitalize text-blue_gray-10"
        >
          {formatPermissionName(permission)}
        </button>
      ))}
    </div>
  );
}

export default TeamAccess;
