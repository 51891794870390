import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";

import * as tk from "./thunk";
import Language from "../../../common/utils/language/en";

const {
  business_document: {
    table_component: { document_name: DocumentTileDictionary },
  },
} = Language.protected.compalinacePages;

type IcomplainceSlice = Record<string, any>;

const initialState: IcomplainceSlice = {
  loading: false,
  error: null,
  business_documents: {
    loading: false,
    data: {
      CERTIFICATE_OF_INCORPORATION: {
        name: DocumentTileDictionary.CERTIFICATE_OF_INCORPORATION,
        data: {
          company: { name: "", _id: "" },
          _id: "",
          created_at: "",
          deleted_at: "",
          file: "",
          is_active: false,
          is_deleted: false,
          meta_data: "",
          model_id: "",
          status: null,
          type: "CERTIFICATE_OF_INCORPORATION",
          updated_at: "",
        },
      },
      BUSINESS_OPERATING_LICENSE: {
        name: DocumentTileDictionary.BUSINESS_OPERATING_LICENSE,
        data: {
          company: { name: "", _id: "" },
          _id: "",
          created_at: "",
          deleted_at: "",
          file: "",
          is_active: false,
          is_deleted: false,
          meta_data: "",
          model_id: "",
          status: null,
          type: "BUSINESS_OPERATING_LICENSE",
          updated_at: "",
        },
      },
      // INDEMNITY_FORM: {
      //   name: DocumentTileDictionary.INDEMNITY_FORM,
      //   data: {
      //     company: { name: "", _id: "" },
      //     _id: "",
      //     created_at: "",
      //     deleted_at: "",
      //     file: "",
      //     is_active: false,
      //     is_deleted: false,
      //     meta_data: "",
      //     model_id: "",
      //     status: null,
      //     type: "INDEMNITY_FORM",
      //     updated_at: "",
      //   },
      // },
      // SOURCE_OF_WEALTH: {
      //   name: DocumentTileDictionary.SOURCE_OF_WEALTH,
      //   data: {
      //     company: { name: "", _id: "" },
      //     _id: "",
      //     created_at: "",
      //     deleted_at: "",
      //     file: "",
      //     is_active: false,
      //     is_deleted: false,
      //     meta_data: "",
      //     model_id: "",
      //     status: null,
      //     type: "SOURCE_OF_WEALTH",
      //     updated_at: "",
      //   },
      // },
      ANTI_MONEY_LAUNDERING: {
        name: DocumentTileDictionary.ANTI_MONEY_LAUNDERING,
        data: {
          company: { name: "", _id: "" },
          _id: "",
          created_at: "",
          deleted_at: "",
          file: "",
          is_active: false,
          is_deleted: false,
          meta_data: "",
          model_id: "",
          status: null,
          type: "ANTI_MONEY_LAUNDERING",
          updated_at: "",
        },
      },
      PROOF_OF_ADDRESS: {
        name: DocumentTileDictionary.PROOF_OF_ADDRESS,
        data: {
          company: { name: "", _id: "" },
          _id: "",
          created_at: "",
          deleted_at: "",
          file: "",
          is_active: false,
          is_deleted: false,
          meta_data: "",
          model_id: "",
          status: null,
          type: "PROOF_OF_ADDRESS",
          updated_at: "",
        },
      },
      BANK_STATEMENT: {
        name: DocumentTileDictionary.BANK_STATEMENT,
        data: {
          company: { name: "", _id: "" },
          _id: "",
          created_at: "",
          deleted_at: "",
          file: "",
          is_active: false,
          is_deleted: false,
          meta_data: "",
          model_id: "",
          status: null,
          type: "BANK_STATEMENT",
          updated_at: "",
        },
      },
    },
  },
  business_profile: {
    loading: false,
    data: {
      _id: "",
      created_at: "",
      deleted_at: "",
      is_active: false,
      is_deleted: false,
      meta_data: "",
      model_id: "",
      type: "",
      updated_at: "",
      address: "",
      company: {
        _id: "",
        name: "",
      },
      country_of_operation: "",
      description: "",
      support_email: "",
      support_phone_number: "",
    },
  },
  shareholders: {
    loading: false,
    data: [],
  },
};

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(tk.listBusinessDocuments.fulfilled, (state, { payload }) => {
        payload.forEach((itm) => {
          if (!state?.business_documents?.data[itm?.type]?.data?.type) return;
          if (itm.type === state.business_documents.data[itm.type].data.type) {
            state.business_documents.data[itm.type].data = {
              ...itm,
            };
          }
        });
      })
      .addCase(
        tk.createBusinessDocument.fulfilled,
        (state, { payload, meta }) => {
          state.business_documents.data[meta.arg.type].data = {
            ...payload,
          };
        }
      )
      .addCase(tk.updateBusinessDocument.fulfilled, (state, { payload }) => {
        state.business_documents.data[payload.type].data = {
          ...payload,
        };
      })
      .addCase(tk.listShareholders.fulfilled, (state, { payload }) => {
        state.shareholders.data = [...payload];
      })
      .addCase(tk.addShareholder.fulfilled, (state, { payload }) => {
        state.shareholders.data.push(payload);
      })
      .addCase(
        tk.removeShareholder.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          const temp = state.shareholders.data.filter(
            (shareholder: any) => shareholder._id !== payload
          );
          state.shareholders.data = [...temp];
        }
      )
      // .addCase(tk.retriveBusinessProfile.fulfilled, (state, { payload }) => {
      //   if (payload?._id) {
      //     state.business_profile.data = { ...payload };
      //   }
      // })
      .addMatcher(
        isAnyOf(
          tk.createBusinessProfile.fulfilled,
          tk.updateBusinessProfile.fulfilled,
          tk.retriveBusinessProfile.fulfilled
        ),
        (state, { payload }) => {
          state.business_profile.data = { ...payload };
        }
      )
      .addMatcher(
        isAnyOf(
          tk.createBusinessDocument.pending,
          tk.listBusinessDocuments.pending,
          tk.updateBusinessDocument.pending
        ),
        (state) => {
          state.business_documents.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.createBusinessDocument.fulfilled,
          tk.createBusinessDocument.rejected,
          tk.listBusinessDocuments.fulfilled,
          tk.listBusinessDocuments.rejected,
          tk.updateBusinessDocument.fulfilled,
          tk.updateBusinessDocument.rejected
        ),
        (state) => {
          state.business_documents.loading = false;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listShareholders.pending,
          tk.addShareholder.pending,
          tk.removeShareholder.pending
        ),
        (state) => {
          state.shareholders.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.listShareholders.fulfilled,
          tk.listShareholders.rejected,
          tk.addShareholder.fulfilled,
          tk.addShareholder.rejected,
          tk.removeShareholder.fulfilled,
          tk.removeShareholder.rejected
        ),
        (state) => {
          state.shareholders.loading = initialState.shareholders.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.createBusinessProfile.pending,
          tk.updateBusinessProfile.pending,
          tk.retriveBusinessProfile.pending
        ),
        (state) => {
          state.business_profile.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          tk.createBusinessProfile.fulfilled,
          tk.createBusinessProfile.rejected,
          tk.updateBusinessProfile.fulfilled,
          tk.updateBusinessProfile.rejected,
          tk.retriveBusinessProfile.fulfilled,
          tk.retriveBusinessProfile.rejected
        ),
        (state) => {
          state.business_profile.loading =
            initialState.business_profile.loading;
        }
      );
  },
});

// export const {} = complianceSlice.actions;

export default complianceSlice.reducer;
