import React from "react";
import { IconProps } from "../type";

export const CopyIcon = ({ scale = 1 }: IconProps) => {
  return (
    <svg
      width={scale * 24}
      height={scale * 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 6.9V11.1C21 14.6 19.6 16 16.1 16H15V12.9C15 9.4 13.6 8 10.1 8H7V6.9C7 3.4 8.4 2 11.9 2H16.1C19.6 2 21 3.4 21 6.9Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
