import React from "react";
import { useAppSelector } from "../../../hooks";
import { Navigate } from "react-router-dom";
import routes from "../../../routes";
import { iGuard } from "./types";

function PermissionGuard({ children, previledge }: iGuard) {
  const { permission } = useAppSelector((state) => state.auth.user);

  if (previledge && !permission?.includes(previledge)) {
    return <Navigate replace to={routes.protected.dashboard.crypto} />;
  }

  return <>{children}</>;
}

export default PermissionGuard;
