import React, { useContext } from "react";
import { TableContext } from ".";
import CustomCheckBox from "../CustomCheckBox";

type Props = {
  id: string;
  check: boolean;
};

function TableBodyCheckBox({ id, check }: Props) {
  const checkItem = useContext(TableContext)?.checkRowItem;
  const toggleCheck = () => {
    if (checkItem) {
      checkItem(id);
    }
  };

  return (
    <div>
      <CustomCheckBox checked={check} onChange={toggleCheck} />
    </div>
  );
}

export default TableBodyCheckBox;
