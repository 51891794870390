import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";

export function DeleteAddress() {
  const location = useLocation();
  const navigate = useNavigate();
  const clickHandler = () => {
    navigate(routes.protected.settings.settlement.delete, {
      state: { background: location },
    });
  };
  return (
    <button
      className="text-center text-sm font-semibold text-red-300"
      onClick={clickHandler}
    >
      Delete
    </button>
  );
}
