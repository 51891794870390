import { ENDPOINTS } from "../../../common/service/config/endpoint";
import { makeAuthorizedRequestWithHeadersAndPayload } from "../../../common/service/request";
import { IcreateTeam, IsuspendTeamMember, IupdateTeamMember } from "../type";

const service = {
  async listTeamMembers(id: string) {
    const { method, url: makeUrl } = ENDPOINTS.team.list_team_member;
    const url = makeUrl(id);
    return makeAuthorizedRequestWithHeadersAndPayload(method, url);
  },
  async createTeamMembers(data: IcreateTeam) {
    const { method, url } = ENDPOINTS.team.create_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async editTeamMember(data: IupdateTeamMember) {
    const { method, url } = ENDPOINTS.team.updatae_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async suspendTeamMember(data: IsuspendTeamMember) {
    const { method, url } = ENDPOINTS.team.suspend_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
  async liftTeamMemberSuspension(data: IsuspendTeamMember) {
    const { method, url } = ENDPOINTS.team.unsuspend_team_member;
    return makeAuthorizedRequestWithHeadersAndPayload(method, url, data);
  },
};

export default service;
