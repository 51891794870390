import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNav from "../SideNav";
import routes from "../../../routes";
import HorizontalNav from "../HorizontalNav";
import { onboardNavLinks } from "../../../utils/helpers/navHelpers";
import { tW } from "../../../utils/helpers";

function Public() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //   const { user_id } = useAppSelector((state) => state.auth.user);

  const navClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { href } = e.currentTarget;
    let path = "";
    switch (true) {
      case href.includes("business"):
        path = routes.protected.onboarding.terms.link("business");
        break;
      case href.includes("individual"):
        path = routes.protected.onboarding.terms.link("individual");
        break;

      default:
        break;
    }
    // console.log(href);
    navigate(path);
  };

  return (
    <div className="bg-gold-10 bg-authBg">
      <div
        className={tW(
          "relative flex h-full design_screen:mx-auto design_screen:max-w-screen-design_screen",
          pathname.includes(routes.protected.dashboard.index)
            ? "bg-base-3"
            : "bg-white"
        )}
      >
        <SideNav isPublic className="absolute top-0 left-0 bottom-0 bg-white" />
        <div className="relative ml-64 flex h-full flex-1 flex-col">
          <header className="public-header absolute right-0 left-0 z-10 flex items-center justify-end gap-x-12 bg-white pt-9 pb-1 pr-14 pl-8 shadow-header">
            <HorizontalNav
              navlinks={onboardNavLinks}
              onClick={navClickHandler}
            />
          </header>
          <section
            className={tW(
              "mt-[8.25rem]  flex-1 overflow-y-scroll px-14 pt-6.5 pb-18 ",
              pathname.includes(routes.protected.dashboard.index) ? "" : "pb-[4.5rem]"
            )}
          >
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Public;
