import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { formatTeamPermission } from "../../../common/utils/helpers";
import { logoutUser } from "../../appState/store/thunk";
import { Iteam, iteammember, IupdateTeamMember, member_perm } from "../type";
import { pageNumberUpdateHelper, updateTotalDataCountAndPages } from "./helper";
import {
  createTeamMember,
  editTeamMember,
  liftTeamMemberSuspension,
  listTeamMembers,
  suspendTeamMember,
} from "./thunk";

const initialState: Iteam = {
  loading: false,
  error: null,
  members: [],
  pagination: {
    data: [],
    export: {
      checkAll: false,
      enableExport: false,
    },
    filter: {
      data: [],
      isfiltered: false,
      query: {
        start: new Date(),
        stop: null,
      },
    },
    info: {
      currentPage: 1,
      maxPageDataCount: 10,
      totalDataCount: 0,
      totalPages: 0,
    },
  },
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    updatePageNumber: (state, { payload }: PayloadAction<number>) => {
      pageNumberUpdateHelper(state.pagination, state.members, payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        listTeamMembers.fulfilled,
        (state, { payload }: PayloadAction<member_perm[]>) => {
          const result = formatTeamPermission(payload).sort(
            (a, b) => a.id - b.id
          );
          state.members = [...result];
          updateTotalDataCountAndPages(state.pagination, state.members);
          pageNumberUpdateHelper(state.pagination, state.members);
        }
      )
      .addCase(
        suspendTeamMember.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          const temp: iteammember[] = state.members.map((itm) =>
            itm.email === payload ? { ...itm, status: "suspended" } : { ...itm }
          );
          const new_page: iteammember[] = state.pagination.data.map((itm) =>
            itm.email === payload ? { ...itm, status: "suspended" } : { ...itm }
          );
          state.members = [...temp];
          state.pagination.data = [...new_page];
        }
      )
      .addCase(
        editTeamMember.fulfilled,
        (state, { payload }: PayloadAction<IupdateTeamMember>) => {
          const temp = state.members.map((itm) =>
            itm.email === payload.team_email
              ? { ...itm, permission: [...payload.team_permissions] }
              : { ...itm }
          );
          const new_page = state.pagination.data.map((itm) =>
            itm.email === payload.team_email
              ? { ...itm, permission: [...payload.team_permissions] }
              : { ...itm }
          );
          state.members = [...temp];
          state.pagination.data = [...new_page];
        }
      )
      .addCase(
        liftTeamMemberSuspension.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          const temp: iteammember[] = state.members.map((itm) =>
            itm.email === payload ? { ...itm, status: "active" } : { ...itm }
          );
          const new_page: iteammember[] = state.pagination.data.map((itm) =>
            itm.email === payload ? { ...itm, status: "active" } : { ...itm }
          );
          state.members = [...temp];
          state.pagination.data = [...new_page];
        }
      )
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })

      .addMatcher(
        isAnyOf(
          listTeamMembers.pending,
          createTeamMember.pending,
          suspendTeamMember.pending,
          editTeamMember.pending,
          liftTeamMemberSuspension.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          listTeamMembers.fulfilled,
          listTeamMembers.rejected,
          createTeamMember.fulfilled,
          createTeamMember.rejected,
          suspendTeamMember.fulfilled,
          suspendTeamMember.rejected,
          editTeamMember.fulfilled,
          liftTeamMemberSuspension.fulfilled,
          editTeamMember.rejected,
          liftTeamMemberSuspension.rejected
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          listTeamMembers.rejected,
          createTeamMember.rejected,
          suspendTeamMember.rejected,
          editTeamMember.rejected,
          liftTeamMemberSuspension.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          listTeamMembers.fulfilled,
          listTeamMembers.pending,
          createTeamMember.fulfilled,
          createTeamMember.pending,
          suspendTeamMember.fulfilled,
          suspendTeamMember.pending,
          editTeamMember.fulfilled,
          liftTeamMemberSuspension.fulfilled,
          editTeamMember.pending,
          liftTeamMemberSuspension.pending
        ),
        (state) => {
          state.error = initialState.error;
        }
      );
  },
});

export const { updatePageNumber } = teamSlice.actions;

export default teamSlice.reducer;
