import React from "react";
import { tW } from "../../../../../common/utils/helpers";

type Props = {
  status: string;
};

function PaymentStatus({ status }: Props) {
  const istatus = status?.toLowerCase();

  return (
    <div
      className={tW(
        "flex items-center justify-center rounded-md p-3 text-xs capitalize text-[#3E4244]",
        istatus === "pending" && "bg-[#F7CB80] ",
        istatus === "successful" && "bg-green-400/20",
        istatus === "matched" && "bg-[#44CF9552] ",
        istatus === "completed" && "bg-[#44CF9552] ",
        istatus === "expired" && "bg-[#E41D1D80]",
        istatus === "mismatched" && "bg-[#E41D1D] text-white",
        istatus === "abandoned" && "bg-[#760000] text-white"
      )}
    >
      {istatus}
    </div>
  );
}

export default PaymentStatus;
