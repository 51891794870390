import React from "react";

type props = {
  count: string;
};

function MessageCount({ count }: props) {
  return (
    <div className="flex cursor-pointer items-center justify-center font-bold">
      <span className="rounded-10 bg-red-300 py-1.5 px-3 text-base text-white">
        {count}
      </span>
    </div>
  );
}

export default MessageCount;
