import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineClear } from "react-icons/ai";
import { CgExport } from "react-icons/cg";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
} from "../../../../common/hooks";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import CustomButton from "../../../../common/components/CustomButton";
import { getP2PTransactions } from "../../store/thunk";
import customToast from "../../../../common/components/CustomToast";
import routes from "../../../../common/routes";
import { removeNonNumeric, toCurrency } from "../../../../common/utils/helpers";
import { paymentNavlinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";
import PayoutCards from "../PayoutCards";
import CustomCalender, {
  iCalendarChange,
} from "../../../../common/components/CustomCalender";
import CustomInput from "../../../../common/components/CustomInput";
import { iHandleChange } from "../../types";
import { exportClick, exportDisableHandler, filterHelper } from "./helper";
import { paymentActions } from "../../store/slice";

const { layout: PageDictionary, overviewPage } = Language.protected.fiatPages;

const initialDateParams = {
  startDate: null,
  endDate: null,
};

function PayoutLayout() {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { kyc_level, merchant_id } = useAppSelector(
    (state) => state.payment.p2p_info
  );

  const { txn_limit_per_day } = useAppSelector((state) => state.payment);
  const { withdrawals, pagination } = useAppSelector((state) => state.payment);
  const dispatch = useAppDispatch();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const [query, setQuery] = useState("");
  // const [loading, setLoading] = useState({ search: false, date: false });
  const loading = useRef(false);
  const [dateParams, setDateParams] =
    useState<Record<string, Date | null>>(initialDateParams);

  const queryDebounce = useDebounce(query);
  const calenderRef = useRef<any>(null);

  useEffect(() => {
    if (query.length !== 0) {
      setQuery("");
    }
    if (dateParams.endDate) {
      setDateParams(initialDateParams);
      dispatch(paymentActions.resetPagination());
    }
  }, [pathname]); //eslint-disable-line

  useEffect(() => {
    if (loading.current === true) return;
    loading.current = true;
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "payout",
        order_type: filterHelper(pathname),
        order_no: queryDebounce,

        limit: pagination.limit,
        page: 1,
      })
    )
      .unwrap()
      .finally(() => (loading.current = false));
  }, [queryDebounce, query, pathname, user_id]); //eslint-disable-line

  const queryChangeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const calanderChangeHandler: iCalendarChange = (date) => {
    const [start, end] = date;
    setDateParams({
      startDate: start,
      endDate: end,
    });

    if (date.includes(null)) return;
    loading.current = true;
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "payout",
        order_type: filterHelper(pathname),
        date_from: start ? dayjs(start).format("YYYY-MM-DD") : "",
        date_to: end ? dayjs(end).format("YYYY-MM-DD") : "",
        limit: pagination.limit,
        page: 1,
      })
    )
      .unwrap()
      .finally(() => (loading.current = false));
  };

  const resetCalenderHandler = () => {
    calenderRef.current?.clear();
    setDateParams(initialDateParams);
    dispatch(
      getP2PTransactions({
        user_id,
        txn_type: "payout",
        order_type: filterHelper(pathname),
        limit: pagination.limit,
        page: 1,
      })
    );
  };

  const overviewClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { href } = e.currentTarget;

    if (
      href.includes(routes.protected.fiat_wallet.overview.transactions.index)
    ) {
      navigate(routes.protected.fiat_wallet.overview.transactions.pending);
    } else {
      navigate(routes.protected.fiat_wallet.overview.withdrawals.completed);
    }
  };

  const startKycHandler = () => {
    if (kyc_level === null && merchant_id) {
      navigate(routes.protected.fiat_wallet.kyc.submit, {
        state: { background: location },
        replace: true,
      });
    } else {
      customToast("Complete Partner Mapping to Start Kyc", false);
    }
  };

  const inactiveKycHandler = () => {
    navigate(routes.protected.fiat_wallet.kyc.review, {
      state: { background: location },
      replace: true,
    });
  };

  const exportClickHandler = () => exportClick(pathname, withdrawals);

  const exportDisable = exportDisableHandler(pathname, withdrawals);
  return (
    <>
      {kyc_level !== "ACTIVE" ? (
        <div className="relative mb-3 flex items-center overflow-hidden rounded !bg-red-100 py-3 pl-6 pr-12 before:absolute before:left-0 before:top-0 before:bottom-0 before:w-1 before:bg-red-300">
          <p>
            {overviewPage.kyc.copy(
              toCurrency(parseInt(removeNonNumeric(txn_limit_per_day)))
            )}
          </p>
          <div className="flex flex-1 items-center justify-around">
            {kyc_level === null ? (
              <button
                onClick={startKycHandler}
                className="rounded-md bg-blue-60 p-3 text-white"
              >
                {overviewPage.kyc.start}
              </button>
            ) : (
              <button
                onClick={inactiveKycHandler}
                className="rounded-md bg-gold-30 p-3 text-[#3E4244]"
              >
                {overviewPage.kyc.status}
              </button>
            )}
          </div>
        </div>
      ) : null}
      <div className="">
        <PayoutCards />
      </div>
      <nav className=" flex items-end justify-between  gap-x-1 border-b border-gray-20 pt-9">
        <div className="flex-1 ">
          <HorizontalNav
            navlinks={paymentNavlinks.overview}
            onClick={overviewClickHandler}
            containerClassName="pb-0"
            className="border-transparent"
          />
        </div>
        <div className="flex items-center gap-x-4 pb-3">
          <CustomInput
            type="search"
            className="h-10 min-w-[308px] appearance-none placeholder:text-[#AEAEAE]"
            placeholder="Search by order number"
            lclassName="hidden"
            value={query}
            onChange={queryChangeHandler}
          />
        </div>
      </nav>

      <nav className=" flex items-end justify-between  gap-x-1 pt-10 pb-14">
        <div className="flex-1 ">
          <HorizontalNav
            navlinks={paymentNavlinks.payout}
            containerClassName="pb-0"
            className="border-transparent px-0"
            activeClassName="py-2.5 px-4.5 bg-blue-10 text-white rounded-xl border-none"
            itemClassName="py-2.5 px-4.5 bg-transparent text-blue-10"
          />
        </div>
        <div className="flex items-center gap-x-6">
          <div className="flex items-center gap-x-1">
            <CustomCalender
              onChange={calanderChangeHandler}
              startDate={dateParams.startDate}
              endDate={dateParams.endDate}
              type="picker"
              className="h-10 min-w-fit appearance-none rounded-lg border-blue_gray-30 bg-white py-2 px-4 text-blue_gray-10 placeholder:text-[#AEAEAE]"
              placeholder="Filter by Date"
              calRef={calenderRef}
              maxDate
            />
            {dateParams.endDate ? (
              <button onClick={resetCalenderHandler} className="p-2">
                <AiOutlineClear />
              </button>
            ) : null}
          </div>
          <CustomButton
            disabled={exportDisable}
            onClick={exportClickHandler}
            className=""
            variant
          >
            {PageDictionary.export}
            <CgExport />
          </CustomButton>
        </div>
      </nav>
    </>
  );
}

export default PayoutLayout;
