import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import PaymentStatus from "../../../components/table/PaymentStatus";
import CustomButton from "../../../../../common/components/CustomButton";
import { tW, toCurrency } from "../../../../../common/utils/helpers";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { claimCollectionFunds } from "../../../store/thunk";
import { getData, getTransactionType } from "./helper";

export const TransactionSummary = () => {
  const { state } = useLocation();
  const backgroundPath = state?.background?.pathname as string;
  const clickedId = state?.data?.id as string;
  const resData = state?.data?.data as Record<string, any>;
  const collections = useAppSelector((state) => state.payment.collections);
  const navigate = useNavigate();
  const { user_id } = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const data = getData(backgroundPath, collections, clickedId);
  const order_type = getTransactionType(backgroundPath);

  const handleBackdrop = () => {
    navigate(backgroundPath, { replace: true });
  };

  const claimFundsHandler = () => {
    if (!data.order_no) return;
    setLoading(true);
    dispatch(
      claimCollectionFunds({
        user_id,
        order_no: data.order_no,
        order_type,
        txn_type: "collection",
      })
    )
      .unwrap()
      .then(() => {
        handleBackdrop();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CenterModal
      handleClose={handleBackdrop}
      className="flex flex-col items-start gap-y-20 rounded-3xl px-14 py-7"
    >
      <h3 className="w-full text-center text-2xl font-bold text-[#6F6F6F]">
        Transaction Summary
      </h3>
      <div className="grid grid-cols-2  gap-y-8 gap-x-14">
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Order Amount
          </p>
          <div className="flex items-center justify-between">
            <p className="text-base font-bold leading-5 text-[#6F6F6F]">
              {toCurrency(resData?.amount ? resData.amount : data?.amount)}
            </p>
            {data?.status?.toLowerCase() === "completed" ||
            data?.status?.toLowerCase() === "mismatched" ? (
              <div className="flex flex-col items-center gap-y-2">
                <div
                  className={tW(
                    "flex h-10 w-10 items-center justify-center rounded-full text-white",
                    data?.status?.toLowerCase() === "completed"
                      ? "bg-[#44CF95]"
                      : "bg-[#FF0000]"
                  )}
                >
                  {data?.status?.toLowerCase() === "completed" ? (
                    <AiOutlineCheck />
                  ) : (
                    <AiOutlineClose />
                  )}
                </div>
                <PaymentStatus
                  status={
                    data?.status?.toLowerCase() === "completed"
                      ? "Matched"
                      : data?.status
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Amount Paid
          </p>
          <div className="flex items-center gap-x-4">
            <p className="text-base font-bold leading-5 text-[#6F6F6F]">
              {resData?.amount_paid === "n/a"
                ? "n/a"
                : toCurrency(parseInt(resData?.amount_paid))}
            </p>
            <PaymentStatus
              status={resData?.status ? resData.status : data?.status}
            />
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Bank Reference
          </p>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold leading-5 text-[#6F6F6F]">
              {resData?.bank_ref ? resData.bank_ref : data?.bank_ref}
            </p>
            <button className="self-start rounded-lg bg-[#44CF9552] px-2 py-0.5 text-[#42A87D]">
              Copy
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Order Number
          </p>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold leading-5 text-[#6F6F6F]">
              {resData?.order_no ? resData.order_no : data?.order_no}
            </p>
            <button className="self-start rounded-lg bg-[#44CF9552] px-2 py-0.5 text-[#42A87D]">
              Copy
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Sender’s name
          </p>
          <p className="text-base font-bold uppercase leading-5 text-[#6F6F6F]">
            {resData?.sender_name ? resData.sender_name : data?.sender_name}
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Session ID
          </p>
          <div className="flex flex-col gap-y-2">
            <p className="text-base font-bold leading-5 text-[#6F6F6F]">
              {resData?.session_id ? resData.session_id : data?.session_id}
            </p>
            <button className="self-start rounded-lg bg-[#44CF9552] px-2 py-0.5 text-[#42A87D]">
              Copy
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Date/time:
          </p>
          <p className="text-base font-bold leading-5 text-[#6F6F6F]">
            {resData?.created_at ? resData.created_at : data?.created_at}
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Transaction Duration
          </p>
          <p className="text-base font-bold leading-5 text-[#6F6F6F]">
            {resData?.txn_duration ? resData.txn_duration : data?.txn_duration}
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">Fee</p>
          <p className="text-base font-bold leading-5 text-[#FF0000]">
            -{toCurrency(resData?.fee ? resData.fee : data?.fee)}
          </p>
        </div>
        <div className="flex flex-col gap-y-4">
          <p className="text-base font-bold leading-5 text-[#C3C0C0]">
            Currency
          </p>
          <p className="text-base font-bold leading-5 text-[#6F6F6F]">
            {resData?.currency ? resData.currency : data?.currency}
          </p>
        </div>
        {data.status.toLowerCase() === "mismatched" ||
        data.status.toLowerCase() === "expired" ? (
          <p className="font-bold leading-5 text-[#FF7A00]">
            By claiming this transaction, only the amount that the user paid
            will be settled to your wallet balance.
          </p>
        ) : null}
      </div>
      <div
        className={tW(
          "flex items-center self-stretch",
          data.status.toLowerCase() === "mismatched" ||
            data.status.toLowerCase() === "expired"
            ? "justify-between"
            : "justify-end"
        )}
      >
        {data.status.toLowerCase() === "mismatched" ||
        data.status.toLowerCase() === "expired" ? (
          <CustomButton
            className="bg-[#0F3DB4]"
            onClick={claimFundsHandler}
            isloading={loading}
          >
            Claim
          </CustomButton>
        ) : null}
        <CustomButton className="bg-[#9EA0A6]" onClick={handleBackdrop}>
          Close
        </CustomButton>
      </div>
    </CenterModal>
  );
};
