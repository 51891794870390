import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../common/routes";
import { settingsNavlinks } from "../../../../common/utils/helpers/navHelpers";
import Language from "../../../../common/utils/language/en";
import CustomButtom from "../../../../common/components/CustomButton";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import {
  useAppSelector,
  usePageTitle,
  useScrollToTop,
} from "../../../../common/hooks";

const { SettingsPages: PageDictionary } = Language.protected;

function SettingsWrapper() {
  usePageTitle(PageDictionary.pageTitle);
  const { titleRef } = useScrollToTop();
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const { add_bank_account_schema } = useAppSelector((state) => state.settings);

  const disable_add_account_btn = add_bank_account_schema.length === 0;
  const addAccountHandler = () => {
    navigate(routes.protected.settings.bank_setting.add_account, {
      state: { background: location },
    });
  };
  return (
    <section>
      <header ref={titleRef} className="mb-8 font-bold text-blue_gray-10">
        <h3 className="text-2226">{PageDictionary.pageTitle}</h3>
      </header>
      <nav ref={titleRef} className="flex items-center">
        <HorizontalNav navlinks={settingsNavlinks} />
        <div className="flex flex-grow-[5] justify-end">
          {pathname === routes.protected.settings.bank_setting.index ? (
            <CustomButtom
              onClick={addAccountHandler}
              disabled={disable_add_account_btn}
              className="bg-green-200 py-2.5 px-9 text-sm leading-6.5"
            >
              {PageDictionary.addAccountAction}
            </CustomButtom>
          ) : null}
        </div>
      </nav>
      <section className="h-full w-full  flex-1 rounded-xl bg-gray-90">
        <Outlet />
      </section>
    </section>
  );
}

export default SettingsWrapper;
