import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import AddAccount from "./AddAccount";
import BankDetailsDelete from "./BankDetailsDelete";
import AppModal from "./AppModal";
import AddCountry from "./AddCountry";
import EnterOtp from "./EnterOtp";
import AddAddress from "./AddAddress";
import ToggleAddressStatus from "./ToggleAddressStatus";

function SettingsModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.settings.bank_setting.add_account}
        element={<AddAccount />}
      />
      <Route
        path={routes.protected.settings.bank_setting.delete_account.index}
        element={<BankDetailsDelete />}
      />
      <Route
        path={routes.protected.settings.selectApp.index}
        element={<AppModal />}
      />
      <Route
        path={routes.protected.settings.add_multi_country}
        element={<AddCountry />}
      />
      <Route
        path={routes.protected.settings.add_multi_country_otp}
        element={<EnterOtp />}
      />
      <Route
        path={routes.protected.settings.settlement.add_address}
        element={<AddAddress />}
      />
      <Route
        path={routes.protected.settings.settlement.deactivate}
        element={<ToggleAddressStatus />}
      />
      <Route
        path={routes.protected.settings.settlement.delete}
        element={<ToggleAddressStatus />}
      />
    </Routes>
  );
}

export default SettingsModal;
