import React from "react";
import SuccessModal from "../../../components/SuccessModal";
import Language from "../../../../../common/utils/language/en";
import routes from "../../../../../common/routes";

const { success: PageDictionary } = Language.protected.fiatModals.setup;

function SetupSuccess() {
  return (
    <SuccessModal
      path={routes.protected.fiat_wallet.overview.withdrawals.completed}
      title={PageDictionary.title}
      subtitle={PageDictionary.subtitle}
    />
  );
}

export default SetupSuccess;
