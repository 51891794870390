import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import FundWallet from "./FundWallet";
import WithdrawalForm from "./WithdrawalForm";
import WithdrawalSuccessful from "./WithdrawalSuccessful";
import WithdrawConfirmation from "./WithdrawConfirmationModal";

function CyptoWalletModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.crypto_wallet.withdraw.index}
        element={<WithdrawalForm />}
      />
      <Route
        path={routes.protected.crypto_wallet.withdraw.success}
        element={<WithdrawalSuccessful />}
      />
      <Route
        path={routes.protected.crypto_wallet.fund}
        element={<FundWallet />}
      />
      <Route
        path={routes.protected.crypto_wallet.withdraw.confirm}
        element={<WithdrawConfirmation />}
      />
    </Routes>
  );
}

export default CyptoWalletModal;
