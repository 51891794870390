import cuid from "cuid";
import React, { useCallback, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import SideModal from "../../../../common/components/CustomModal/SideModal";
// import MediaUpload from "../../../applications/components/MediaUpload";
import CustomInput from "../../../../common/components/CustomInput";
import CustomButton from "../../../../common/components/CustomButton";
import {
  createBusinessDocument,
  updateBusinessDocument,
} from "../../store/thunk";
import * as tp from "../../types";
import Language from "../../../../common/utils/language/en";
import MediaUpload from "../../components/MediaUpload";
// import { removeUnderScoreAddSpace } from "../../../../common/utils/helpers";

const initialstate = {
  type: null,
  file: [],
};

type stateType = {
  type: any | null;
  file: tp.iFileToUpload;
};

const { upload_businesss_document: PageDictionary } =
  Language.protected.compalinaceModals;

export function dataDifference(state: any, data?: any) {
  let count = 0;

  if (!data) return { count };

  if (state[0]?.id !== data?.file) {
    count++;
  }

  return {
    count,
  };
}

function UploadBusinessDocument() {
  const [query] = useSearchParams();
  const update_doc_name = query.get("type");
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.state?.background?.pathname;
  const title = location.state?.title;
  const doc_type: any | undefined = location.state?.type;
  const { loading, data: docs } = useAppSelector(
    (state) => state.compliance.business_documents
  );

  const dispatch = useAppDispatch();
  const [inData, setInData] = useState<stateType>(initialstate);

  const tempform = () => {
    // const temp = (Object.keys(docs) as Array<keyof typeof docs>).find(
    //   (key) =>
    //     docs[key].name ===
    //     removeUnderScoreAddSpace(update_doc_name ? update_doc_name : "")
    // );
    const temp = "";
    if (!temp) return;

    return docs[temp];
  };
  const tempData = tempform();

  useEffectOnce(() => {
    const tempData = tempform();
    if (!tempData) return;

    setInData((prev) => ({
      ...prev,
      file: [
        {
          data: null,
          id: tempData.data.file ? tempData.data.file : "",
          name: tempData.name,
        },
      ],
      type: tempData.data.type,
    }));
  });

  const { count } = dataDifference(inData.file, tempData?.data);
  const submitDisable =
    !!update_doc_name === false && (inData.file.length === 0 || !inData.type);
  const updateDisable = !!update_doc_name && count === 0;

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.map((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setInData((prev) => ({
          ...prev,
          file: [{ data: file, id: cuid(), name: file.name }],
        }));
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  const onRemoveMedia = (id: string) => {
    const temp = inData.file.filter((itm) => itm.id !== id);
    setInData((prev) => ({ ...prev, file: temp }));

    if (tempData) {
      setInData((prev) => ({ ...prev, id: "" }));
    }
  };

  const changeHandler: tp.iHandleChange<HTMLInputElement> = useCallback((e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value === "" ? value : doc_type }));
  }, []); //eslint-disable-line

  const closeModal = () => {
    if (loading) return;
    navigate(pathname, { replace: true });
  };

  const submitHandler: tp.iHandleSubmit = (e) => {
    e.preventDefault();
    if (!pathname || !inData.type) return;

    if (update_doc_name && tempData) {
      const data = {
        file: inData.file[0].data,
        docid: tempData?.data._id,
      };
      dispatch(updateBusinessDocument(data)).unwrap().finally(closeModal);
    } else {
      const data = { type: inData.type, file: inData.file[0].data };
      dispatch(createBusinessDocument(data)).unwrap().finally(closeModal);
    }
  };

  return (
    <SideModal clickBackDrop={closeModal} className="pt-15 pl-14 pr-28">
      <h3 className="text-4xl">
        {update_doc_name
          ? PageDictionary.title.update
          : PageDictionary.title.upload}
      </h3>
      <form onSubmit={submitHandler} className="flex flex-col gap-y-10 pt-11">
        <MediaUpload
          title={
            update_doc_name
              ? PageDictionary.form.upload_label.update(title)
              : PageDictionary.form.upload_label.upload(title)
          }
          images={inData.file}
          onDrop={onDrop}
          onRemoveMedia={onRemoveMedia}
          supportFormatText={PageDictionary.form.upload_supported_format_text}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"],
            "application/msword": [".doc", ".docx"],
          }}
          disabled={loading}
          name="cert"
        />
        {update_doc_name ? null : (
          <CustomInput
            className="mt-3 h-12 !py-1.5"
            // containerclassname=""
            // labelclassname="mb-2.5"
            label={PageDictionary.form.operation_license}
            name="type"
            disabled={loading}
            onChange={changeHandler}
          />
        )}
        <CustomButton
          disabled={submitDisable || updateDisable}
          isloading={loading}
          className="mt-2.5 self-start px-[3.25rem] py-3.5 text-base leading-7"
        >
          {update_doc_name
            ? PageDictionary.form.submitform.update
            : PageDictionary.form.submitform.submit}
        </CustomButton>
      </form>
    </SideModal>
  );
}

export default UploadBusinessDocument;
