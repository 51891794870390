import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { convertBankSchemaToTableData } from "../../../common/utils/helpers";
import { logoutUser, switchMultiAccount } from "../../appState/store/thunk";
import { loginUser } from "../../auth/store/thunk";
import { getInfoNew } from "../../dashboard/store/thunk";
import {
  IlistBanksPayload,
  Isettings,
  IsettlementBaseType,
  iupdateUrl,
  IwithdrawalApiSetting,
  Iwithdrawalchannel,
} from "../types";
import {
  addBankAccount,
  deleteBankDetail,
  enableSettlement,
  enableWithdrawalIntegration,
  getAllBanks,
  getSettlementAddress,
  saveWithdrawalIntegration,
  setDefaultBank,
  setPayoutThreshold,
} from "./thunk";

const initialState: Isettings = {
  loading: false,
  error: null,
  bank_accounts: [],
  add_bank_account_schema: [],
  bank_table_headers: [],
  api_setting: {
    withdrawal_api: {
      authorization_url: "",
      balance_pool_url: "",
      deposit_notification_url: "",
      fuspay_ip_address: "",
      pricing_url: "",
      secret: "",
      settlement_url: "",
      txn_amount_per_day: "",
      txn_amount_per_time: "",
      verification_url: "",
      withdrawal_ip_addresses: "",
    },
    withdrawal_api_status: false,
  },
  settlement_info: {
    settlement_data: {
      min_payout_amount: 0,
      settlement_type: "DASHBOARD",
    },
    wallet_doc_url: "",
    addresses: {
      dashbord: [],
      api: [],
    },
  },
};

const settingSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateAddress: (state, { payload }: PayloadAction<iupdateUrl>) => {
      const { type, value } = payload;
      state.api_setting.withdrawal_api[type] = value;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInfoNew.fulfilled, (state, action) => {
        state.add_bank_account_schema = action.payload.bank_scheme;
        const temp = state.add_bank_account_schema.map((itm) => ({
          title: itm.name,
          dataIndex: "",
          key: itm.key_name,
        }));
        state.bank_table_headers = [...temp];
      })
      .addCase(
        getAllBanks.fulfilled,
        (state, { payload }: PayloadAction<IlistBanksPayload[]>) => {
          const temp = payload
            .map((itm) => ({
              ...convertBankSchemaToTableData(itm.bank_scheme_data),
              bank_id: itm.bank_id,
              user_id: itm.user_id,
              status: itm.status,
            }))
            .sort((a, b) => a.status.localeCompare(b.status));
          state.bank_accounts = [...temp];
        }
      )
      .addCase(deleteBankDetail.fulfilled, (state, { payload }) => {
        const temp = state.bank_accounts.filter(
          (account) => account.bank_id !== payload
        );
        state.bank_accounts = [...temp];
      })
      .addCase(
        setDefaultBank.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          const temp = state.bank_accounts
            .map((itm) => {
              if (itm.bank_id === payload) {
                return { ...itm, status: "DEFAULT" };
              }
              if (itm.bank_id !== payload && itm.status === "DEFAULT") {
                return { ...itm, status: "INACTIVE" };
              }
              return itm;
            })
            .sort((a, b) => a.status.localeCompare(b.status));
          state.bank_accounts = [...temp];
        }
      )
      .addCase(
        saveWithdrawalIntegration.fulfilled,
        (state, { payload }: PayloadAction<IwithdrawalApiSetting>) => {
          const withdrawal_api_keys = Object.keys(payload) as Array<
            keyof typeof payload
          >;

          withdrawal_api_keys.forEach((key) => {
            if (payload[key]) {
              state.api_setting.withdrawal_api[key] = payload[key];
            }
          });
        }
      )
      .addCase(
        enableWithdrawalIntegration.fulfilled,
        (state, { payload }: PayloadAction<Iwithdrawalchannel>) => {
          state.api_setting.withdrawal_api_status =
            payload === "manual" ? false : true;
        }
      )
      .addCase(
        enableSettlement.fulfilled,
        (state, { payload }: PayloadAction<IsettlementBaseType>) => {
          state.settlement_info.settlement_data.settlement_type = payload;
        }
      )
      .addCase(
        setPayoutThreshold.fulfilled,
        (state, { payload }: PayloadAction<string>) => {
          state.settlement_info.settlement_data.min_payout_amount =
            parseInt(payload);
        }
      )
      .addCase(
        getSettlementAddress.fulfilled,
        (state, { payload }: PayloadAction<any[]>) => {
          const dashboard = payload.filter(
            (itm: any) => itm?.filter === "wallet_address"
          );

          const api = payload.filter(
            (itm: any) => itm?.filter === "ip_address"
          );

          state.settlement_info.addresses.api = api;
          state.settlement_info.addresses.dashbord = dashboard;
        }
      )
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })

      .addMatcher(
        isAnyOf(switchMultiAccount.fulfilled, loginUser.fulfilled),
        (state, { payload }) => {
          const withdrawal_api_keys = Object.keys(
            payload.withdrawal_api
          ) as Array<keyof typeof payload.withdrawal_api>;

          withdrawal_api_keys.forEach((key) => {
            if (payload.withdrawal_api[key]) {
              state.api_setting.withdrawal_api[key] =
                payload.withdrawal_api[key];
            }
          });

          state.settlement_info.wallet_doc_url =
            payload.settlement_data.wallet_doc_url;
          state.settlement_info.settlement_data.min_payout_amount =
            payload.settlement_data.settlement_data.min_payout_amount;
          state.settlement_info.settlement_data.settlement_type =
            payload.settlement_data.settlement_data.settlement_type;

          state.api_setting.withdrawal_api_status =
            payload.withdrawal_api_status === "manual" ? false : true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAllBanks.fulfilled,
          deleteBankDetail.fulfilled,
          addBankAccount.fulfilled,
          setDefaultBank.fulfilled
        ),
        (state) => {
          if (state.error !== null) {
            state.error = null;
          }
        }
      )
      .addMatcher(
        isAnyOf(
          getAllBanks.rejected,
          deleteBankDetail.rejected,
          setDefaultBank.rejected,
          addBankAccount.rejected
        ),
        (state) => {
          state.error = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAllBanks.pending,
          deleteBankDetail.pending,
          addBankAccount.pending,
          setDefaultBank.pending,
          saveWithdrawalIntegration.pending,
          enableWithdrawalIntegration.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getAllBanks.rejected,
          getAllBanks.fulfilled,
          setDefaultBank.rejected,
          setDefaultBank.fulfilled,
          deleteBankDetail.rejected,
          deleteBankDetail.fulfilled,
          addBankAccount.rejected,
          addBankAccount.fulfilled,
          saveWithdrawalIntegration.rejected,
          saveWithdrawalIntegration.fulfilled,
          enableWithdrawalIntegration.rejected,
          enableWithdrawalIntegration.fulfilled
        ),
        (state) => {
          state.loading = false;
        }
      );
  },
});

export const { updateAddress } = settingSlice.actions;

export default settingSlice.reducer;
