import routes from "../../routes";
import {
  // AiFillQuestionCircle,
  AiTwotonePieChart,
  AiOutlineSetting,
  AiOutlineWallet,
} from "react-icons/ai";
// import { VscGoToFile } from "react-icons/vsc";
import { HiOutlineUserCircle } from "react-icons/hi";
import { BsArrowLeftRight } from "react-icons/bs";
import Language from "../language/en";
import { IteamPermission } from "../../../module/team/type";
import { ACCEPT_REQUEST_PERM, ISSUES_PERM, PROCESS_REQUEST_PERM } from ".";
import { VscGoToFile } from "react-icons/vsc";

type Isidenavcomp = {
  title: string;
  link: string;
  icon: any;
  name: string;
  access?: IteamPermission[];
};
type Inavcomp = {
  link: string;
  name: string;
  access?: IteamPermission;
};

const {
  DashboardPage,
  PoolPages,
  SettingsPages,
  PerformancePages,
  TransactionPages,
  CryptoWalletPages,
  onboarding_layout: {
    nav: {
      main: onboardnav,
      business: onboardnavbus,
      individual: onboardnavind,
    },
  },
  fiatPages: {
    layout: {
      nav: paymentMainNav,
      overview_nav: paymentOverviewNav,
      collections_nav: paymentCollectionNav,
      payout_nav: paymentPayoutNav,
    },
  },
  fiatModals: {
    kyc: { nav: paymentKycNav },
  },

  Layout: { sidenav },
} = Language.protected;

export const navComponents: Isidenavcomp[] = [
  {
    title: sidenav.nav.dashboard,
    icon: <AiTwotonePieChart />,
    link: routes.protected.dashboard.index,
    name: "dashboard",
  },
  {
    title: sidenav.nav.pool,
    icon: <VscGoToFile />,
    link: routes.protected.pool.index,
    name: "pool",
    access: ["ACCEPT_REQUEST", "ISSUES", "PROCESS_REQUEST"],
  },
  // {
  //   title: sidenav.nav.transaction,
  //   icon: <BsArrowLeftRight />,
  //   link: routes.protected.transactions.index,
  //   name: "transaction",
  // },

  {
    title: sidenav.nav.crypto_wallet,
    icon: <AiOutlineWallet />,
    link: routes.protected.crypto_wallet.index,
    name: "cypto",
  },
  {
    title: sidenav.nav.fiat_wallet,
    icon: <BsArrowLeftRight />,
    link: routes.protected.fiat_wallet.index,
    name: "fiat",
  },
  // {
  //   title: sidenav.nav.performance,
  //   icon: <AiFillQuestionCircle />,
  //   link: routes.protected.performance.index,
  //   name: "performance",
  // },
  {
    title: sidenav.nav.team,
    icon: <HiOutlineUserCircle />,
    link: routes.protected.team.index,
    name: "team",
    access: ["ADMIN"],
  },
  {
    title: sidenav.nav.setting,
    icon: <AiOutlineSetting />,
    link: routes.protected.settings.index,
    name: "setting",
  },
  // {
  //   title: sidenav.nav.compliance,
  //   icon: <AiOutlineSetting />,
  //   link: routes.protected.onboarding.index,
  //   name: "compliance",
  // },
  // {
  //   title: sidenav.nav.preview,
  //   icon: <AiOutlineSetting />,
  //   link: routes.protected.preview_app,
  //   name: "compliance",
  // },

  // {
  //   title: sidenav.nav.help,
  //   icon: <AiFillQuestionCircle />,
  //   link: routes.protected.help,
  //   name: "help",
  // },
];

export const dashboardNavlinks = [
  {
    name: DashboardPage.nav.crypto,
    link: routes.protected.dashboard.crypto,
  },
  {
    name: DashboardPage.nav.fiat,
    link: routes.protected.dashboard.fiat,
  },
];
export const settingsNavlinks = [
  {
    name: SettingsPages.nav.bank,
    link: routes.protected.settings.bank_setting.index,
  },
  {
    name: SettingsPages.nav.pin,
    link: routes.protected.settings.reset_pin,
  },
  {
    name: SettingsPages.nav.lang,
    link: routes.protected.settings.language,
  },
  {
    name: SettingsPages.nav.api,
    link: routes.protected.settings.api_setting,
  },
  // {
  //   name: SettingsPages.nav.apps,
  //   link: routes.protected.settings.apps,
  // },
  {
    name: SettingsPages.nav.price,
    link: routes.protected.settings.price,
  },
  {
    name: SettingsPages.nav.settlement,
    link: routes.protected.settings.settlement.index,
  },
];
export const performanceNavlinks = [
  {
    name: PerformancePages.nav.metric,
    link: routes.protected.performance.metric,
  },
  {
    name: PerformancePages.nav.chart,
    link: routes.protected.performance.chart,
  },
];
export const poolNavlinks: Inavcomp[] = [
  {
    name: PoolPages.nav.tray,
    link: routes.protected.pool.tray,
    access: ACCEPT_REQUEST_PERM,
  },
  {
    name: PoolPages.nav.pending_r,
    link: routes.protected.pool.pending_request,
    access: PROCESS_REQUEST_PERM,
  },
  {
    name: PoolPages.nav.processing_r,
    link: routes.protected.pool.processing_request,
    access: PROCESS_REQUEST_PERM,
  },
  {
    name: PoolPages.nav.completed_r,
    link: routes.protected.pool.completed_request,
    access: PROCESS_REQUEST_PERM,
  },
  {
    name: PoolPages.nav.issues,
    link: routes.protected.pool.issues.index,
    access: ISSUES_PERM,
  },
  {
    name: PoolPages.nav.junks,
    link: routes.protected.pool.junks,
    access: PROCESS_REQUEST_PERM,
  },
];
export const transactionNavlinks = [
  {
    name: TransactionPages.nav.withdraw,
    link: routes.protected.transactions.withdraw,
  },
  {
    name: TransactionPages.nav.funding,
    link: routes.protected.transactions.fund,
  },
];
export const cryptoWalletNavlinks = [
  {
    name: CryptoWalletPages.nav.capital,
    link: routes.protected.crypto_wallet.capital,
  },
  {
    name: CryptoWalletPages.nav.commission,
    link: routes.protected.crypto_wallet.commission,
  },
];
export const onboardNavLinks = [
  {
    name: onboardnav.business,
    link: routes.protected.onboarding.business,
  },
  {
    name: onboardnav.individual,
    link: routes.protected.onboarding.individual,
  },
];
export const onboardBusinessNavLinks = [
  {
    name: onboardnavbus.terms,
    link: routes.protected.onboarding.terms.business,
  },
  {
    name: onboardnavbus.bussiness_shareholders,
    link: routes.protected.onboarding.shareholders.business,
  },
  {
    name: onboardnavbus.bussiness_profile,
    link: routes.protected.onboarding.bussiness_profile,
  },
  {
    name: onboardnavbus.bussiness_document,
    link: routes.protected.onboarding.document.business,
  },
];
export const onboardIndividualNavLinks = [
  {
    name: onboardnavind.terms,
    link: routes.protected.onboarding.terms.individual,
  },
  {
    name: onboardnavind.personal_account_infos,
    link: routes.protected.onboarding.shareholders.individual,
  },
  {
    name: onboardnavind.personal_document,
    link: routes.protected.onboarding.document.individual,
  },
];
export const paymentNavlinks = {
  payment: [
    {
      name: paymentMainNav.overview,
      link: routes.protected.fiat_wallet.overview.index,
    },
    {
      name: paymentMainNav.method,
      link: routes.protected.fiat_wallet.payment_methods.index,
    },
  ],
  overview: [
    {
      name: paymentOverviewNav.transactions,
      link: routes.protected.fiat_wallet.overview.transactions.index,
    },
    {
      name: paymentOverviewNav.withdrawals,
      link: routes.protected.fiat_wallet.overview.withdrawals.index,
    },
  ],
  collections: [
    // {
    //   name: paymentCollectionNav.all,
    //   link: routes.protected.fiat_wallet.overview.transactions.all,
    // },
    {
      name: paymentCollectionNav.pending,
      link: routes.protected.fiat_wallet.overview.transactions.pending,
    },
    {
      name: paymentCollectionNav.completed,
      link: routes.protected.fiat_wallet.overview.transactions.completed,
    },
    {
      name: paymentCollectionNav.mismatched,
      link: routes.protected.fiat_wallet.overview.transactions.mismatched,
    },
    {
      name: paymentCollectionNav.abandoned,
      link: routes.protected.fiat_wallet.overview.transactions.abandoned,
    },
    {
      name: paymentCollectionNav.expired,
      link: routes.protected.fiat_wallet.overview.transactions.expired,
    },
    {
      name: paymentCollectionNav.claimed,
      link: routes.protected.fiat_wallet.overview.transactions.claimed,
    },
  ],
  payout: [
    {
      name: paymentPayoutNav.completed,
      link: routes.protected.fiat_wallet.overview.withdrawals.completed,
    },
    {
      name: paymentPayoutNav.failed,
      link: routes.protected.fiat_wallet.overview.withdrawals.failed,
    },
    {
      name: paymentPayoutNav.pending,
      link: routes.protected.fiat_wallet.overview.withdrawals.pending,
    },
    {
      name: paymentPayoutNav.processing,
      link: routes.protected.fiat_wallet.overview.withdrawals.processing,
    },
  ],
};
export const paymentKycNavlinks = [
  {
    name: paymentKycNav.submit,
    link: routes.protected.fiat_wallet.kyc.submit,
  },
  {
    name: paymentKycNav.review,
    link: routes.protected.fiat_wallet.kyc.review,
  },
  {
    name: paymentKycNav.success,
    link: routes.protected.fiat_wallet.kyc.sucess,
  },
];
