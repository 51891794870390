import React from "react";

import * as SelectPrimitive from "@radix-ui/react-select";
import { FiChevronDown, FiChevronUp, FiCheck } from "react-icons/fi";
import { Label } from "@radix-ui/react-label";
import { tW } from "../../utils/helpers";

interface Ibasic {
  children: React.ReactNode;
  className?: string;
  idisable?: boolean;
  ihidden?: boolean;
}

interface IselectItem extends Ibasic {
  value: any;
}

interface Iselect extends Ibasic {
  name?: string;
  onChange?: (e: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLButtonElement, Element>) => void;
  disabled?: boolean;
  id?: string;
  className?: string;
  contentClassName?: string;
  iconclassName?: string;
  placeholder?: string;
  value?: any;
  label?: string;
  labelClassName?: string;
  required?: any;
}
export const Select = React.forwardRef(
  (
    { children, label, labelClassName, ...props }: Iselect,
    forwardedRef: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <div>
        {label ? (
          <Label
            className={tW(
              "text-base-2 mb-2 inline-block text-base font-medium",
              labelClassName
            )}
          >
            {label}
          </Label>
        ) : null}
        <SelectPrimitive.Root
          required={props.required}
          onValueChange={props.onChange}
        >
          <SelectPrimitive.Trigger
            disabled={props.disabled}
            id={props.id}
            className={tW(
              "relative flex w-full items-center justify-between rounded-md border border-solid border-gray-400 bg-white py-2 pl-3 pr-[1.875rem]",
              props.className
            )}
            ref={forwardedRef}
            name={props.name}
            value={props.value}
            onFocus={props.onFocus}
          >
            <SelectPrimitive.Value
              placeholder={props.value}
              aria-label={props.value}
            >
              {props.value}
            </SelectPrimitive.Value>

            <SelectPrimitive.Icon
              className={tW(
                "absolute top-1/2 right-3 -translate-y-1/2",
                props.iconclassName
              )}
            >
              <FiChevronDown />
            </SelectPrimitive.Icon>
          </SelectPrimitive.Trigger>

          <SelectPrimitive.Portal className="">
            <SelectPrimitive.Content
              className={tW(
                "z-[51] p-0 rounded-md border border-gray-400 bg-white",
                props.contentClassName
              )}
            >
              <SelectPrimitive.ScrollUpButton className="select-scroll-btn flex items-center justify-center">
                <FiChevronUp />
              </SelectPrimitive.ScrollUpButton>

              <SelectPrimitive.Viewport className="">
                {children}
              </SelectPrimitive.Viewport>

              <SelectPrimitive.ScrollDownButton className="select-scroll-btn flex items-center justify-center">
                <FiChevronDown />
              </SelectPrimitive.ScrollDownButton>
            </SelectPrimitive.Content>
          </SelectPrimitive.Portal>
        </SelectPrimitive.Root>
      </div>
    );
  }
);
export const SelectItem = React.forwardRef<HTMLDivElement, IselectItem>(
  ({ children, ...props }, forwardedRef) => {
    return (
      // eslint-disable-next-line
      <SelectPrimitive.Item
        disabled={props.idisable}
        hidden={props.ihidden}
        className="flex cursor-pointer items-center justify-between  px-7 py-4 outline-none first:pt-8 last:pb-8 hover:bg-gray-100 hover:text-white"
        value={props.value}
        ref={forwardedRef}
        onSelect={(e) => console.log(e)}
      >
        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>

        <SelectPrimitive.ItemIndicator>
          <FiCheck />
        </SelectPrimitive.ItemIndicator>
      </SelectPrimitive.Item>
    );
  }
);
