import React from "react";
import CustomTable from "../../../../common/components/CustomTable";
import { tableHeader } from "../../../../common/utils/helpers/tableHeaders";
import Language from "../../../../common/utils/language/en";

const { dashboardTab: PageDictionary } =
  Language.protected.SettingsPages.AppsModal;

function AppDashboard() {
  const { columns, rows } = tableHeader.app_records;
  return (
    <section>
      <div className="flex  items-center gap-x-10 py-10">
        <div className="flex  flex-1 flex-col gap-y-4.5 rounded-lg bg-gray-370 pt-4 pr-18 pb-5 pl-6">
          <h6 className="font-medium">{PageDictionary.walletBalance}</h6>
          <p className="text-2xl font-bold leading-6.5">$12,340.43</p>
        </div>
        <div className="flex-1 rounded-lg border py-4 px-6">
          <h6 className="mb-2.5 font-medium text-gray-300" >{PageDictionary.accountDetails}</h6>
          <div className="grid grid-cols-2 font-bold gap-y-1">
            <p>22273893340</p>
            <p>GTbank</p>
            <p>Charlse Avis</p>
          </div>
        </div>
      </div>
      <CustomTable
        title={PageDictionary.recordsTable.title}
        cols={columns}
        rows={rows}
        layout_type="setting"
      />
    </section>
  );
}

export default AppDashboard;
