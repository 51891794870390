import React, { useId, useRef } from "react";
import { tW } from "../../utils/helpers";

type Props = {
  label: string;
  className?: string;
  lclassName?: string;
  check?: boolean;
  name?: string;
  tclassName?: string;
  onToggle?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable?: boolean;
};

function CustomToggle({
  label,
  className,
  lclassName,
  check,
  onToggle,
  name,
  tclassName,
  disable,
}: Props) {
  const id = useId();
  const ref = useRef<any>(null);
  const clickHandler = () => ref.current.click();

  return (
    <div className={tW("grid-col-[max-content_max-content] grid", className)}>
      <label
        htmlFor={`${id}-toogle`}
        className={tW(
          "relative inline-flex cursor-pointer",
          tclassName,
          disable ? "!cursor-not-allowed" : ""
        )}
      >
        <input
          disabled={disable}
          ref={ref}
          type="checkbox"
          id={`${id}-toogle`}
          className="sr-only"
          checked={check}
          name={name}
          onChange={onToggle}
        />
        <div
          onClick={clickHandler}
          className="toggle-bg !m-0 h-6 w-11 rounded-full border-2 border-gray-70 bg-gray-70"
        />
      </label>
      {label ? (
        <span
          className={tW(
            "text-950 col-start-2 col-end-3 row-start-1 row-end-3 justify-self-start text-left text-gray-150",
            lclassName
          )}
          onClick={clickHandler}
        >
          {label}
        </span>
      ) : null}
    </div>
  );
}

export default CustomToggle;
