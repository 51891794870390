import React, { useState } from "react";
import Language from "../../../../common/utils/language/en";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useCountDown,
  useEffectOnce,
} from "../../../../common/hooks";
import { iHandleChange, iHandleClick, iHandleSubmit } from "../../types";
import { updateField } from "../../../auth/store/slice";
import { removeNonNumeric } from "../../../../common/utils/helpers";
import { getCsrfToken, getOtp, registerUser } from "../../../auth/store/thunk";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import CustomButton from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import ResendCodeCounter from "../../../auth/components/ResendCodeCounter";
import customToast from "../../../../common/components/CustomToast";
import { downloadWalletCertificate } from "../../store/thunk";

const { enterOtpPage: PageDictionary } = Language.nonprotected;

function EnterOtp() {
  const data = useLocation().state;
  const { count: time, counter, start: startCountDown } = useCountDown(90);
  const auth_state = useAppSelector((state) => state.auth.auth);
  const {
    auth: state,
    loading,
    user: { user_id, email },
  } = useAppSelector((state) => state.auth);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isloading, setIsloading] = useState(false);

  useEffectOnce(() => {
    if (state.email !== "") {
      return;
    }
    dispatch(updateField(data));
    if (state.otp) {
      const tempData = { ...state, otp: "" };
      dispatch(updateField(tempData));
    }
  });

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    dispatch(updateField({ otp: removeNonNumeric(value) }));
  };

  const resendCode: iHandleClick = () => {
    startCountDown();
    dispatch(getCsrfToken({ email: state.email ? state.email : email }))
      .unwrap()
      .then((res) =>
        dispatch(
          getOtp({ email: state.email ? state.email : email, csrf_token: res })
        )
      );
  };

  useEffectOnce(() => {
    startCountDown();
  });

  const handlerDisable = counter > 0;

  const backDropHandler = () => {
    if (isloading) return;
    navigate(data.background.pathname);
  };

  const dispatchHandler = () => {
    if (data?.is_download) {
      return dispatch(
        downloadWalletCertificate({
          user_id,
          data: { otp_code: auth_state.otp },
        })
      )
        .unwrap()
        .then((res: any) => {
          navigate(data.background.pathname, {
            replace: true,
          });

          Object.keys(res).forEach((key) => {
            const link = document.createElement("a");
            link.href = res[key];
            link.target = "_blank";
            link.download = `Encryption Certificate ${key}`;
            link.click();
          });

          console.log(res);
        });
    } else {
      return dispatch(registerUser(auth_state))
        .unwrap()
        .then(() => {
          navigate(data.background.pathname, {
            replace: true,
          });
        })
        .then(() => customToast("New Account Added"));
    }
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setIsloading(true);
    dispatchHandler().finally(() => setIsloading(false));
  };
  return (
    <CenterModal className=" w-4/12 p-8" handleClose={backDropHandler}>
      <header className="flex flex-col gap-y-3.5">
        <h2 className="text-base-2 text-2xl font-semibold leading-[3.875rem] tracking-tighter">
          {PageDictionary.heading}
        </h2>
        <p className="text-lg font-normal leading-[1.875rem] text-gray-600">
          {PageDictionary.subHeading}
        </p>
      </header>
      <form
        className="flex flex-1 flex-col gap-y-4 self-stretch px-8 py-4"
        onSubmit={submitHandler}
      >
        <CustomInput
          name="otp"
          type="text"
          label={PageDictionary.otpLabel}
          value={state.otp}
          onChange={changeHandler}
        />

        <div className="mt-3.5 flex flex-col items-start gap-y-5">
          <CustomButton
            disabled={state.otp.length !== 9 || loading}
            isloading={loading || isloading}
            className="h-11 w-40"
          >
            {PageDictionary.submitAction}
          </CustomButton>
        </div>
      </form>

      <button
        onClick={resendCode}
        disabled={handlerDisable}
        className="mt-16 flex cursor-pointer items-center gap-x-5 text-base font-medium text-gray-100 disabled:cursor-not-allowed"
      >
        {PageDictionary.resendCode}
        {counter ? (
          <ResendCodeCounter minutes={time.minutes} seconds={time.seconds} />
        ) : null}
      </button>
    </CenterModal>
  );
}

export default EnterOtp;
