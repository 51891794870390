import { PayloadAction, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IcollectionOrderType, Ipayment } from "../types";
import { loginUser } from "../../auth/store/thunk";
import { logoutUser, switchMultiAccount } from "../../appState/store/thunk";
import {
  createWallet,
  generateKey,
  getBankInfoFunding,
  getFiatExchangeRate,
  getMerchantInfo,
  getP2PTransactions,
  getPartners,
  toggleP2P,
  uploadKyc,
} from "./thunk";
import { getInfoNew } from "../../dashboard/store/thunk";

const initialState: Ipayment = {
  is_loading: false,
  wallet_balance: 0,
  total_collection: 0,
  total_payout: 0,
  currency: "",
  txn_limit_per_day: "",
  pagination: {
    limit: 10,
    nextPage: 1,
    page: 1,
    otherQuery: {},
  },
  withdrawals: {
    completed: {
      loading: false,
      data: [],
    },
    failed: {
      loading: false,
      data: [],
    },
    pending: {
      loading: false,
      data: [],
    },
    processing: {
      loading: false,
      data: [],
    },
  },
  collections: {
    all: {
      loading: false,
      data: [],
    },
    abandoned: {
      loading: false,
      data: [],
    },
    completed: {
      loading: false,
      data: [],
    },
    expired: {
      loading: false,
      data: [],
    },
    mismatched: {
      loading: false,
      data: [],
    },
    pending: {
      loading: false,
      data: [],
    },
    claimed: {
      loading: false,
      data: [],
    },
  },
  payment_config: null,
  p2p_info: {
    kyc_level: null,
    merchant_id: "",
    p2p: [],
    wallet_limit: { payout_amount_per_day: "" },
  },
  min_payout_amounts: {
    NGN: 0,
    KHS: 0,
    KES: 0,
    GHS: 0,
  },
  pending_balance: {
    date_time: "",
    pending_amount: 0,
    next_all_settlement_hours: 0,
    currency: "",
    settled_amount: 0,
  },
  partner_mapping: {
    list: [],
    secret_key: "",
  },
  total_pending: "0",
  total_mismatched: "0",
  total_collection_count: "0",
  total_payout_count: "0",
  total_pending_count: "0",
  total_mismatched_count: "0",

  exchangeInfo: {
    isloading: false,
    localFiat_to_usdt: 0,
    usdt_to_localFiat: 0,
  },
  p2p_payout_stats: {
    total_failed_payout: 0,
    total_completed_payout: 0,
  },
  is_auto_claim_collection_enabled: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    replaceCollectionData: (
      state,
      { payload }: PayloadAction<{ type: IcollectionOrderType; value: any[] }>
    ) => {
      state.collections[payload.type].data = payload.value;
    },
    resetPagination: (state) => {
      state.pagination = initialState.pagination;
    },
  },
  extraReducers(builder) {
    builder
      // .addCase(loginUser.fulfilled, (state, { payload }) => {
      //   state.p2p_info = { ...payload.p2p_info };
      // })
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          if (
            key === "wallet_balance" ||
            key === "total_collection" ||
            key === "total_payout"
          ) {
            state[key] = initialState[key];
          }
          if (key === "currency" || key === "txn_limit_per_day") {
            state[key] = initialState[key];
          }
          if (key === "withdrawals") {
            state[key] = initialState[key];
          }
          if (key === "collections") {
            state[key] = { ...initialState[key] };
          }
          if (key === "payment_config") {
            state[key] = initialState[key];
          }
          if (key === "p2p_info") {
            state[key] = { ...initialState[key] };
          }
          if (key === "min_payout_amounts") {
            state[key] = { ...initialState[key] };
          }
          if (key === "partner_mapping") {
            state[key] = { ...initialState[key] };
          }
        });
      })
      .addCase(toggleP2P.fulfilled, (state, { meta }) => {
        state.p2p_info.p2p = state.p2p_info.p2p.map((itm) =>
          itm.partner_code === meta.arg.partner_code
            ? {
                ...itm,
                is_p2p_turned_on: meta.arg.status === false ? "OFF" : "ON",
              }
            : itm
        );
      })
      .addCase(getMerchantInfo.fulfilled, (state, { payload }) => {
        state.p2p_info.kyc_level = payload.kyc_level;
        state.p2p_info.merchant_id = payload.merchant_id;
        state.p2p_info.p2p = payload.p2p;
        state.currency = payload.currency;
        state.txn_limit_per_day = payload.txn_limit_per_day;
        state.wallet_balance = payload.wallet_balance;
        state.total_collection = payload.total_collection;
        state.total_collection_count = payload.total_collection_count;
        state.total_payout = payload.total_payout;
        state.total_payout_count = payload.total_payout_count;
        state.total_pending = payload.total_pending;
        state.total_pending_count = payload.total_pending_count;
        state.total_mismatched = payload.total_mismatched;
        state.total_mismatched_count = payload.total_mismatched_count;
        state.is_auto_claim_collection_enabled =
          payload.is_auto_claim_collection_enabled;
      })
      .addCase(getP2PTransactions.fulfilled, (state, { payload, meta }) => {
        const hasNextPage = meta.arg.limit === payload.list.length;
        // const order_no = meta.arg.order_no;
        const date_from = meta.arg.date_from;
        const date_to = meta.arg.date_to;
        const page = meta.arg.page;
        const txn_type = meta.arg.txn_type;

        if (date_from || date_to) {
          state.pagination.otherQuery = { date_to, date_from };
        } else {
          state.pagination.otherQuery = {};
        }

        if (hasNextPage && state.pagination.nextPage === page) {
          state.pagination.nextPage = page + 1;
        } else {
          state.pagination.nextPage = page;
        }

        if (txn_type === "payout") {
          const args_type = meta.arg.order_type;
          // state.withdrawals[args_type].page = page;

          if (page === 1) {
            state.withdrawals[args_type].data = payload.list;
            return;
          }
          state.withdrawals[args_type].data = [
            ...state.withdrawals[args_type].data,
            ...payload.list,
          ];
        }

        if (meta.arg.txn_type === "collection") {
          const args_type = meta.arg.order_type;
          // state.collections[args_type].page = page;

          if (page === 1) {
            state.collections[args_type].data = payload.list;
            return;
          }

          state.collections[args_type].data = [
            ...state.collections[args_type].data,
            ...payload.list,
          ];
        }
      })
      .addCase(getPartners.fulfilled, (state, { payload }) => {
        state.partner_mapping.list = payload;
      })
      .addCase(generateKey.fulfilled, (state, { payload, meta }) => {
        const temp = state.p2p_info.p2p.map((itm) =>
          itm.partner_id === meta.arg.partner_id
            ? { ...itm, merchant_secret: payload.secret_key }
            : itm
        );
        state.p2p_info.p2p = [...temp];
      })
      .addCase(uploadKyc.fulfilled, (state) => {
        state.p2p_info.kyc_level = "PENDING";
      })
      .addCase(getFiatExchangeRate.fulfilled, (state, { payload, meta }) => {
        if (meta.arg.type === "TO_FIAT") {
          state.exchangeInfo.usdt_to_localFiat = payload.value;
        }
        if (meta.arg.type === "FROM_FIAT") {
          state.exchangeInfo.localFiat_to_usdt = payload.value;
        }
      })
      .addCase(getInfoNew.fulfilled, (state, { payload }) => {
        state.p2p_payout_stats = payload.p2p_payout_stats;
      })
      .addMatcher(isAnyOf(getP2PTransactions.pending), (state, { meta }) => {
        if (meta.arg.txn_type === "collection") {
          state.collections[meta.arg.order_type].loading = true;
        }
        if (meta.arg.txn_type === "payout") {
          state.withdrawals[meta.arg.order_type].loading = true;
        }
      })
      .addMatcher(
        isAnyOf(getP2PTransactions.fulfilled, getP2PTransactions.rejected),
        (state, { meta }) => {
          if (meta.arg.txn_type === "collection") {
            state.collections[meta.arg.order_type].loading = false;
          }
          if (meta.arg.txn_type === "payout") {
            state.withdrawals[meta.arg.order_type].loading = false;
          }
        }
      )
      .addMatcher(isAnyOf(getFiatExchangeRate.pending), (state) => {
        state.exchangeInfo.isloading = true;
      })
      .addMatcher(
        isAnyOf(getFiatExchangeRate.fulfilled, getFiatExchangeRate.rejected),
        (state) => {
          state.exchangeInfo.isloading = initialState.exchangeInfo.isloading;
        }
      )
      .addMatcher(
        isAnyOf(switchMultiAccount.fulfilled, loginUser.fulfilled),
        (state, { payload }) => {
          state.min_payout_amounts = { ...payload.p2p_info.min_payout_amounts };
        }
      )
      .addMatcher(
        isAnyOf(
          switchMultiAccount.fulfilled,
          loginUser.fulfilled,
          getInfoNew.fulfilled
        ),
        (state, { payload }) => {
          state.p2p_info = { ...payload.p2p_info };
          state.pending_balance = { ...payload.pending_balance };
        }
      )
      .addMatcher(
        isAnyOf(createWallet.fulfilled, getBankInfoFunding.fulfilled),
        (state, { payload }) => {
          state.payment_config = payload;
        }
      )
      .addMatcher(
        isAnyOf(
          createWallet.pending,
          getBankInfoFunding.pending,
          uploadKyc.pending,
          getMerchantInfo.pending
        ),
        (state) => {
          state.is_loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createWallet.fulfilled,
          createWallet.rejected,
          getBankInfoFunding.fulfilled,
          getBankInfoFunding.rejected,
          uploadKyc.fulfilled,
          uploadKyc.rejected,
          getMerchantInfo.fulfilled,
          getMerchantInfo.rejected
        ),
        (state) => {
          state.is_loading = false;
        }
      );
  },
});

export const paymentActions = paymentSlice.actions;

export default paymentSlice.reducer;
