import React, { useId, useRef, useState } from "react";
import { iHandleChange } from "../../types";
import { AxiosProgressEvent } from "axios";
import { makeCloudinaryPostRequest } from "../../service/request";
import { tW, truncatedText } from "../../utils/helpers";

type Props = {
  name: string;
  value: string;
  setState: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  label?: string;
  disabled: boolean;
};

function CustomUpload({ name, label, setState, value, disabled }: Props) {
  const id = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const progressRef = useRef<HTMLDivElement | null>(null);
  const [imageName, setImageName] = useState("");
  const [imageUploadStatus, setImageUploadStatus] = useState(false);
  const clickHandler = () => {
    inputRef.current?.click();
    setState((prev: any) => ({
      ...prev,
      [name]: "",
    }));
  };

  const propgressHandler = ({ loaded, total }: AxiosProgressEvent) => {
    if (!total || !progressRef?.current) return;
    const percentage = Math.round((loaded * 100) / total);
    progressRef.current.style.width = `${percentage}%`;
  };

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { files } = e.target;
    const file = files?.item(0);
    if (!name) return;
    setImageName(file?.name ? truncatedText(file.name, 16) : "");
    setImageUploadStatus(true);
    makeCloudinaryPostRequest(file, propgressHandler)
      .then((res) => {
        setState((prev: any) => ({
          ...prev,
          [name]: res.data.url,
        }));
      })
      .finally(() => setImageUploadStatus(false));
  };

  return (
    <div>
      <label htmlFor={id} className="mb-2.5 inline-block">
        {" "}
        {label}
      </label>
      <div
        className={tW(
          "flex items-center justify-between rounded-md px-3 pt-2.5 pb-1.5",
          value ? "bg-green-400/40" : "bg-gray-270 text-blue_gray-10",
          imageUploadStatus || disabled ? "cursor-progress" : "",
          imageUploadStatus
            ? "relative before:absolute before:inset-0 before:z-0 before:w-1/2 before:animate-leftRight before:bg-green-400/40 "
            : ""
        )}
        ref={progressRef}
      >
        <div className="z-[1] flex items-center gap-x-5">
          {imageName ? <span>{imageName}</span> : null}
          <input
            ref={inputRef}
            type="file"
            onChange={changeHandler}
            className="sr-only"
            name={name}
            id={id}
            disabled={imageUploadStatus || disabled}
          />
          <button
            disabled={imageUploadStatus || disabled}
            onClick={clickHandler}
            type="button"
            className="rounded-10 border border-black py-2 px-10 text-xs font-medium disabled:cursor-not-allowed"
          >
            {value ? "Re-Upload " : "Upload"}
          </button>
        </div>
        <span className="mr-1.5 block text-sm font-bold">
          {value ? "submitted" : "SUBMIT"}
        </span>
      </div>
    </div>
  );
}

export default CustomUpload;
