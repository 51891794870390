import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";
// import { IbusinessDocumentStatus, IbusinessDocumentType } from "../../../types";
import Language from "../../../../../common/utils/language/en";
import { tW } from "../../../../../common/utils/helpers";
// import { removeSpaceAddUnderScore } from "../../../../../common/utils/helpers";

type Props = {
  status: any;
  doc_title: string;
  doc_link: string;
  doc_type: any;
};

const PageDictionary =
  Language.protected.compalinacePages.business_document.table_component
    .action_text;

function BusinessAction({ status, doc_title, doc_link, doc_type }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  const clickHandler = () => {
    let path = "";
    if (status === null) {
      const makePath = routes.protected.onboarding.document.get_started.link;
      path = makePath(
        location.pathname.includes("business") ? "business" : "individual"
      );
    }
    if (status === "REJECTED") {
      // const makePath = routes.protected.onboarding.document.update_doc.link;
      // path = makePath(
      //   removeSpaceAddUnderScore(doc_title),
      //   location.pathname.includes("business") ? "business" : "individual"
      // );
    }
    navigate(path, {
      state: { background: location, title: doc_title, type: doc_type },
    });
  };

  return (
    <div className="flex items-center gap-x-3">
      {status === "APPROVED" ? (
        <a
          className="leading-5.5 flex h-8 w-[5.5rem] items-center justify-center  rounded-10 bg-green-30 text-sm text-green-40"
          href={doc_link}
          target={"_blank"}
          rel="noreferrer"
        >
          {PageDictionary.view}
        </a>
      ) : (
        <button
          onClick={clickHandler}
          className={tW(
            "leading-5.5 flex h-8 w-[5.5rem] items-center justify-center  rounded-10  text-xs",
            status === "PENDING" ? "bg-brown-20 text-brown-30" : "",
            status === null ? "bg-blue-60 bg-opacity-20 text-blue-60" : ""
          )}
        >
          <span>
            {status === null ? "Get Started" : null}
            {status === "PENDING" || status === "REJECTED"
              ? PageDictionary.submited
              : null}
          </span>
        </button>
      )}
      {status === "REJECTED" ? (
        <button
          onClick={clickHandler}
          className="leading-5.5 text-sm text-blue-40"
        >
          {PageDictionary.re_submit}
        </button>
      ) : null}
    </div>
  );
}

export default BusinessAction;
