import React from "react";
import CustomButton from "../../../../../common/components/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../../../common/routes";

type Props = {
  status: boolean;
  partner_code: string;
  partner_id: string;
};

function PaymentPartnerManage({ partner_code, partner_id, status }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const clickHandler = () => {
    navigate(routes.protected.fiat_wallet.payment_methods.add_merchant, {
      state: {
        background: location,
        data: { title: partner_code, partner_id },
      },
    });
  };
  return <CustomButton disabled={!status} onClick={clickHandler} >Manage</CustomButton>;
}

export default PaymentPartnerManage;
