import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { getConfirmationCode } from "../../../dashboard/store/thunk";
import { IfundPendingRequest, iHandleChange, iHandleSubmit } from "../../types";
import { fundPendingRequest } from "../../store/thunk";
import { useCountDown } from "../../../../common/hooks/useCountDown";
import routes from "../../../../common/routes";
import { getCsrfToken } from "../../../auth/store/thunk";

const { FundTransactionForm: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

const initialState = {
  user_id: "",
  tray_request_id: "",
  id: "",
  remark: "",
  payment_evidence_url: "",
  code: "",
};

function FundTransactionForm() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const { email, user_id } = useAppSelector((state) => state.auth.user);
  const { loading } = useAppSelector((state) => state.pool);
  const { loading: ver_loading } = useAppSelector((state) => state.dashboard);
  const pool_itm = state.data;
  const [formState, setFormState] = useState<IfundPendingRequest>(initialState);

  const mountOnce = useRef(false);

  const { counter, start } = useCountDown(300);

  useEffect(() => {
    if (mountOnce.current) {
      return;
    }
    start();

    setFormState((prev) => ({
      ...prev,
      id: pool_itm.id,
      user_id,
      tray_request_id: pool_itm.tray_request_id,
    }));
    mountOnce.current = true;
  }, []); //eslint-disable-line

  const closeModal = () => {
    if (loading) {
      return;
    }

    navigate(state.background.pathname, { replace: true, state: null });
  };

  const getCodeHandler = () => {
    if (ver_loading) {
      return;
    }
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res) => {
        dispatch(getConfirmationCode({ email, csrf_token: res }));
      });
    // dispatch(getConfirmationCode({ email }));
  };

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const changeHandlerTextArea: iHandleChange<HTMLTextAreaElement> = (e) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    const path = routes.protected.pool.fund_transaction_success.link(
      formState.id
    );
    dispatch(fundPendingRequest(formState))
      .unwrap()
      .then(() => {
        navigate(path, { replace: true, state });
      });
  };

  return (
    <CenterModal
      handleClose={closeModal}
      className="w-157.5 gap-y-11 rounded-xl py-10 px-12 text-blue_gray-10"
    >
      <header className="w-full">
        <h3>{PageDictionary.title}</h3>
      </header>
      <form className="flex w-full flex-col gap-y-4" onSubmit={submitHandler}>
        <div className="flex flex-col gap-y-1">
          <div className="flex items-center justify-between ">
            <label htmlFor="">Enter Verification code</label>
            <button
              type="button"
              disabled={ver_loading || counter > 0}
              className="text-green-10 disabled:text-red-40"
              onClick={getCodeHandler}
            >
              {ver_loading
                ? "Fetching Verification code..."
                : counter
                ? `get new code in ${counter}s`
                : "Get New code"}
            </button>
          </div>
          <input
            name="code"
            value={formState.code}
            onChange={changeHandler}
            className="border-blue_gray-300 h-[3.75rem] w-full rounded-xl border border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
            type="text"
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <label htmlFor=""> {PageDictionary.remarkLabel} </label>
          <textarea
            className="border-blue_gray-300 w-full rounded-xl border border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black outline-none placeholder:text-base placeholder:text-gray-600 "
            name="remark"
            id=""
            cols={30}
            rows={4}
            value={formState.remark}
            onChange={changeHandlerTextArea}
          />
        </div>

        <div className="mt-6 flex items-center justify-between">
          <CustomButtom
            type="button"
            variant
            onClick={closeModal}
            disabled={loading}
            className=" px-8 py-3 leading-none hover:border-transparent hover:bg-blue_gray-10 hover:text-white"
          >
            {PageDictionary.cancelButton}
          </CustomButtom>
          <CustomButtom
            isloading={loading}
            className="bg-green-10 px-8 py-3"
            type="submit"
          >
            {PageDictionary.submitButton}
          </CustomButtom>
        </div>
      </form>
    </CenterModal>
  );
}

export default FundTransactionForm;
