import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import Language from "../../../../common/utils/language/en";
import CustomButton from "../../../../common/components/CustomButton";
import { Ip2pItem, iHandleChange, iHandleSubmit } from "../../types";
import CustomInput from "../../../../common/components/CustomInput";
import routes from "../../../../common/routes";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import { registerMerchant, toggleP2P } from "../../store/thunk";

const initialstate = {
  partner_merchant_id: "",
  merchant_callback_url: "",
};

const { toggle_cred: PageDictionary } =
  Language.protected.fiatModals.payment_method;

const differenceHandler = (
  localData: typeof initialstate,
  globalData?: Ip2pItem
) => {
  type res = {
    count: number;
    difference: any;
  };

  let response: res = {
    count: 0,
    difference: {},
  };

  if (!globalData) return response;

  const keys = Object.keys(localData) as Array<keyof typeof localData>;
  keys.forEach((key) => {
    switch (key) {
      case "merchant_callback_url":
        if (localData[key] !== globalData.callback_url) {
          response.count++;
          response.difference[key] = localData[key];
        }
        break;

      default:
        if (localData[key] !== globalData[key]) {
          response.count++;
          response.difference[key] = localData[key];
        }
        break;
    }
  });
  return response;
};

function TogglePaymentNotification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useAppDispatch();
  const title: string = state?.data?.title;
  // const logo: string = state?.data?.logo;
  const partner_id = state?.data?.partner_id;
  const partner_code: string = state?.data?.title;
  const [inData, setInData] = useState(initialstate);
  const [isLoading, setIsLoading] = useState(false);
  const { user_id } = useAppSelector((state) => state.auth.user);
  const p2pdata = useAppSelector((state) =>
    state.payment.p2p_info.p2p.find((itm) => itm.partner_id === partner_id)
  );

  const { count } = differenceHandler(inData, p2pdata);

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setInData((prev) => ({ ...prev, [name]: value }));
  };

  useEffectOnce(() => {
    if (!p2pdata) return;
    setInData((prev) => ({
      ...prev,
      merchant_callback_url: p2pdata?.callback_url,
      partner_merchant_id: p2pdata?.partner_merchant_id,
    }));
  });

  const backToDashboard = () =>
    navigate(state?.background?.pathname, { replace: true });

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    dispatch(registerMerchant({ ...inData, user_id, partner_id }))
      .unwrap()
      .then(() => {
        dispatch(toggleP2P({ partner_code, status: true, user_id }))
          .unwrap()
          .then(() => {
            navigate(
              routes.protected.fiat_wallet.payment_methods.add_merchant_success,
              {
                state: { background: state?.background, data: { title } },
              }
            );
          })
          .finally(() => setIsLoading(false));
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <SideModal clickBackDrop={backToDashboard} className="px-15 pt-24">
      <div className="flex h-full flex-col items-start justify-center gap-y-14">
        <div className="flex w-[28.8125rem] items-center gap-x-4 text-center">
          {/* <img src={logo} alt="logo" /> */}

          <h2 className="text-base-2 text-[2rem] font-semibold capitalize leading-none">
            {title}
          </h2>
        </div>
        <form onSubmit={submitHandler} className="flex w-full flex-col gap-y-7">
          <h3 className="text-2xl font-bold leading-9">
            {PageDictionary.title}
          </h3>
          <CustomInput
            label={PageDictionary.merchant_id_label}
            name="partner_merchant_id"
            value={inData.partner_merchant_id}
            className="h-12"
            disabled={isLoading}
            onChange={changeHandler}
          />
          <CustomInput
            label={PageDictionary.partner_url}
            type="url"
            name="merchant_callback_url"
            value={inData.merchant_callback_url}
            className="h-12"
            disabled={isLoading}
            onChange={changeHandler}
          />
          <CustomButton
            className="mt-7 self-start"
            isloading={isLoading}
            disabled={
              Object.values(inData).includes("") || (p2pdata && count === 0)
            }
          >
            {!p2pdata
              ? PageDictionary.send_credentials_btn.save
              : PageDictionary.send_credentials_btn.update}
          </CustomButton>
        </form>
      </div>
    </SideModal>
  );
}

export default TogglePaymentNotification;
