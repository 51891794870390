import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import {
  Itransaction,
  APIResFunding,
  APIResWithdrawal,
  transactionType,
  idateFilter,
  Itransactionfilter,
} from "../type";
import { getTransactionFundings, getTransactionWithdrawals } from "./thunk";
import {
  checkAllHelper,
  checkItemHelper,
  dateFilterHelper,
  pageNumberUpdateHelper,
  updateTotalDataCountAndPages,
} from "./helper";
import { logoutUser } from "../../appState/store/thunk";

const initialState: Itransaction = {
  loading: false,
  error: null,
  funding: [],
  withdrawal: [],
  pageination: {
    funding: {
      info: {
        currentPage: 1,
        totalPages: 0,
        totalDataCount: 0,
        maxPageDataCount: 10,
      },
      data: [],
      filter: {
        data: [],
        isfiltered: false,
        query: {
          start: new Date(),
          stop: null,
        },
      },
      export: {
        checkAll: false,
        enableExport: false,
      },
    },
    withdrawal: {
      info: {
        currentPage: 1,
        totalPages: 0,
        totalDataCount: 0,
        maxPageDataCount: 10,
      },
      data: [],
      filter: {
        data: [],
        isfiltered: false,
        query: {
          start: new Date(),
          stop: null,
        },
      },
      export: {
        checkAll: false,
        enableExport: false,
      },
    },
  },
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    checkItem: (state, { payload }: PayloadAction<Itransactionfilter>) => {
      const { id, type } = payload;
      const data =
        type === "funding"
          ? state.pageination.funding
          : state.pageination.withdrawal;
      checkItemHelper(data, id);
    },
    checkAllItems: (state, { payload }: PayloadAction<transactionType>) => {
      const data =
        payload === "funding"
          ? state.pageination.funding
          : state.pageination.withdrawal;
      checkAllHelper(data);
    },

    updatePageNumberFunding: (state, { payload }: PayloadAction<number>) => {
      pageNumberUpdateHelper(state.pageination.funding, state.funding, payload);
    },
    updatePageNumberWithdrawal: (state, { payload }: PayloadAction<number>) => {
      pageNumberUpdateHelper(
        state.pageination.withdrawal,
        state.withdrawal,
        payload
      );
    },
    addDateFilterFunding: (state, { payload }: PayloadAction<idateFilter>) => {
      dateFilterHelper(
        state.pageination.funding,
        state.funding,
        initialState.pageination.funding,
        payload
      );
    },
    addDateFilterWithdrawal: (
      state,
      { payload }: PayloadAction<idateFilter>
    ) => {
      dateFilterHelper(
        state.pageination.withdrawal,
        state.withdrawal,
        initialState.pageination.withdrawal,
        payload
      );
    },
    resetDateFilter: (state, { payload }: PayloadAction<transactionType>) => {
      state.pageination[payload].filter = {
        ...initialState.pageination[payload].filter,
      };
      state.pageination[payload].info.totalDataCount = state[payload].length;
      state.pageination[payload].info.totalPages = Math.round(
        state[payload].length / state.pageination[payload].info.maxPageDataCount
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getTransactionWithdrawals.fulfilled,
        (state, { payload }: PayloadAction<APIResWithdrawal>) => {
          const { list } = payload;
          const temp = list.map((itm) => ({ ...itm, checkbox: false }));
          state.withdrawal = [...temp];
          updateTotalDataCountAndPages(state.pageination.withdrawal, list);
          pageNumberUpdateHelper(
            state.pageination.withdrawal,
            state.withdrawal
          );
        }
      )
      .addCase(
        getTransactionFundings.fulfilled,
        (state, { payload }: PayloadAction<APIResFunding>) => {
          const { list } = payload;
          const temp = list.map((itm) => ({ ...itm, checkbox: false }));
          state.funding = [...temp];
          updateTotalDataCountAndPages(state.pageination.funding, list);
          pageNumberUpdateHelper(state.pageination.funding, state.funding);
        }
      )
      .addCase(logoutUser.fulfilled, (state) => {
        (Object.keys(state) as Array<keyof typeof state>).forEach((key) => {
          state[key] = initialState[key];
        });
      })

      .addMatcher(
        isAnyOf(
          getTransactionWithdrawals.pending,
          getTransactionFundings.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getTransactionWithdrawals.rejected,
          getTransactionFundings.rejected
        ),
        (state) => {
          state.error = true;
        }
      )

      .addMatcher(
        isAnyOf(
          getTransactionWithdrawals.rejected,
          getTransactionWithdrawals.fulfilled,
          getTransactionFundings.rejected,
          getTransactionFundings.fulfilled
        ),
        (state) => {
          state.loading = initialState.loading;
        }
      )
      .addMatcher(
        isAnyOf(
          getTransactionWithdrawals.pending,
          getTransactionWithdrawals.fulfilled,
          getTransactionFundings.pending,
          getTransactionFundings.fulfilled
        ),
        (state) => {
          state.error = initialState.error;
        }
      );
  },
});

export const {
  checkItem,
  checkAllItems,
  updatePageNumberFunding,
  updatePageNumberWithdrawal,
  addDateFilterFunding,
  addDateFilterWithdrawal,
  resetDateFilter,
} = transactionSlice.actions;

export default transactionSlice.reducer;
