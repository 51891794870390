import { TableLayout } from "./types";

export const tableHeadStyle = (layout: TableLayout, index: number) => {
  let template;
  switch (layout) {
    case "setting":
      template =
        "z-[1] px-9 text-left text-xs font-bold  text-gray-40 first:pl-10 last:pr-9 last:text-start";
      break;
    case "team":
      template =
        "sticky top-0 bg-white z-[1] pb-2 pt-7 text-base font-normal text-gray-40 last:pr-9 px-9 text-left first:pl-5 ";
      break;

    default:
      template = ` sticky top-0 bg-white z-[1] pb-5 pt-10 text-base font-normal text-gray-40  ${
        index === 1 ? "pl-5" : ""
      } last:pr-9 px-1 ${layout === "issues" ? "pt-0" : ""}`;
      break;
  }
  return template;
};
export const tableBodyStyle = (layout: TableLayout, index?: number) => {
  let template;
  switch (layout) {
    case "setting":
      template = "border-spacing-y-6 text-left";
      break;
    case "team":
      template = "border-spacing-y-4 text-left";
      break;

    default:
      template = "border-spacing-y-2.5 text-center ";
      break;
  }
  return `w-full border-separate bg-white ${template}`;
};

export const tableDataStyle = (layout: TableLayout, c_index: number) => {
  let template;
  switch (layout) {
    case "setting":
      template = `border-y border-purple-300 py-4 px-9 text-start text-base font-bold first:rounded-l-2xl first:border-l first:pl-10 last:w-[30px] last:rounded-r-2xl last:rounded-tr-2xl last:border-r last:pr-16`;
      break;
    case "team":
      template = `pt-4 pb-3 first:pl-5 first:rounded-tl-2xl text-base px-9 first:rounded-bl-2xl first:border-l last:text-center last:pr-9 last:rounded-tr-2xl last:rounded-br-2xl last:border-r px-4  border-y border-purple-300`;
      break;

    default:
      template = `pt-4 pb-3 ${c_index === 0 ? "" : "border-y"} ${
        c_index === 1 ? "pl-5 rounded-tl-2xl rounded-bl-2xl border-l" : ""
      } last:pr-5 last:rounded-tr-2xl last:rounded-br-2xl last:border-r px-1   border-purple-300 text-gray-30 `;
      break;
  }
  return template;
};
