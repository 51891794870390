import React from "react";
import { useAppSelector } from "../../../../../common/hooks/redux";

// type Props = {
//     value: string
// }

function Currency() {
  const { currencies } = useAppSelector(
    (state) => state.appState.appState.country_data
  );
  return <span className="font-bold">{`${currencies[0]}/USDT`}</span>;
}

export default Currency;
