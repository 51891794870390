import { tW } from "../../utils/helpers";

interface props {
  isDisabled?: boolean;
  currentPage?: number;
  totalPages?: number;
  setPage?: (page: number) => void;
  loading?: boolean;
  dataCount?: number;
  tableRef: React.MutableRefObject<HTMLTableRowElement | null>;
}

function Pagination({
  currentPage = 9,
  dataCount = 10,
  setPage,
  totalPages = 10,
  isDisabled,
  loading,
  tableRef,
}: props) {
  const pageNumberArray = () => {
    var numberArray = [];

    for (var i = 1; i <= totalPages; i++) {
      numberArray.push(i);
    }
    return numberArray;
  };

  const pageArray = pageNumberArray();

  const pageClickHandler = (item: number) => {
    if (!setPage) return;
    setPage(item);
    tableRef?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const nextPrevClickHandler = (name: string) => {
    if (!setPage) return;
    if (name === "prev") {
      setPage(currentPage - 1);
    }
    if (name === "next") {
      setPage(currentPage + 1);
    }
    tableRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  return (
    <>
      {dataCount > 10 ? (
        <nav
          aria-label="Page navigation"
          className={tW(
            "my-2 ml-auto flex max-w-[38.75rem] place-items-center items-center justify-between overflow-hidden rounded-lg border border-gray-260 bg-white text-gray-280",
            loading ? "paginator_loading" : ""
          )}
        >
          <button
            className={tW(
              "flex cursor-pointer items-center justify-center gap-x-3 place-self-stretch bg-white pl-4 disabled:cursor-not-allowed",
              totalPages > 9 ? "flex-1" : ""
            )}
            disabled={isDisabled || currentPage <= 1 || loading}
            onClick={() => nextPrevClickHandler("prev")}
          >
            <span>{"<"}</span> <span>Previous</span>
          </button>
          {totalPages > 0 && (
            <div
              className={tW(
                "flex items-center",
                totalPages < 10 ? "flex-1 justify-center" : ""
              )}
            >
              {totalPages - currentPage > 8
                ? pageArray
                    .filter(
                      (item) =>
                        item <= currentPage + 8 && item > currentPage - 1
                    )
                    .map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={tW(
                            "w-10 border-l border-gray-260 bg-transparent py-2.5 text-sm font-medium last:border-r",
                            item === currentPage
                              ? "bg-gray-270 text-green-10 "
                              : "text-gray-280",
                            totalPages > 9 ? "flex-1" : ""
                          )}
                          onClick={() => {
                            pageClickHandler(item);
                          }}
                          disabled={loading}
                        >
                          {item}
                        </button>
                      );
                    })
                : pageArray
                    .filter((item) => item > totalPages - 9)
                    .map((item, index) => {
                      return (
                        <button
                          key={index}
                          className={tW(
                            "w-10 border-l border-gray-260 bg-transparent bg-white py-2.5 text-sm font-medium last:border-r",
                            item === currentPage
                              ? "bg-gray-270 text-green-10 "
                              : "text-gray-280",
                            totalPages > 9 ? "flex-1" : ""
                          )}
                          onClick={() => {
                            pageClickHandler(item);
                          }}
                          disabled={loading}
                        >
                          {item}
                        </button>
                      );
                    })}
            </div>
          )}

          <button
            className={tW(
              "flex cursor-pointer items-center justify-center gap-x-3 place-self-stretch bg-white pr-4 disabled:cursor-not-allowed",
              totalPages > 9 ? "flex-1" : ""
            )}
            disabled={
              isDisabled ||
              currentPage === totalPages ||
              totalPages === 0 ||
              loading
            }
            onClick={() => nextPrevClickHandler("next")}
          >
            <span>Next</span> <span>{">"}</span>
          </button>
        </nav>
      ) : null}
    </>
  );
}

export default Pagination;
