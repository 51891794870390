import React from "react";

type Props = {
  index: number;
  email: string;
};

function TeamEmail({ index, email }: Props) {
  return (
    <div className="flex items-center gap-x-18 font-bold">
      <span className="justify-self-start">{index}</span>
      <span>{email}</span>
    </div>
  );
}

export default TeamEmail;
