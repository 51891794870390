import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
  useEffectOnce,
} from "../../../../common/hooks";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import CustomInput from "../../../../common/components/CustomInput";
import * as Slt from "../../../../common/components/CustomSelect";
import CustomButton from "../../../../common/components/CustomButton";
import { updateField } from "../../../auth/store/slice";
import {
  getAllCountries,
  getCsrfToken,
  getOtp,
} from "../../../auth/store/thunk";
import routes from "../../../../common/routes";
import {
  IcountryList,
  iHandleChange,
  iHandleSubmit,
} from "../../../auth/types";
import Language from "../../../../common/utils/language/en";

const { registerPage: PageDictionary } = Language.nonprotected;

const initialstate = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  country: "",
  account_owner_email_address: "",
};

function AddCountry() {
  const location = useLocation().state;
  const prefillData = location?.email;
  const dispatch = useAppDispatch();
  const [countiesList, setCountiesList] = useState<IcountryList>([]);
  const [state, setState] = useState(initialstate);
  const { loading } = useAppSelector((state) => state?.auth);
  const { subAccounts } = useAppSelector((state) => state.appState);
  const navigate = useNavigate();

  useEffectOnce(() => {
    dispatch(getAllCountries())
      .unwrap()
      .then((res) => setCountiesList(res));

    if (prefillData) {
      setState((prev) => ({
        ...prev,
        account_owner_email_address: prefillData,
      }));
    }
  });

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const selectHandler = (value: string) => {
    setState((prev) => ({ ...prev, country: value }));
  };

  const backDropHandler = () => {
    navigate(location?.background.pathname);
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateField(state));
    dispatch(getCsrfToken({ email: state.email }))
      .unwrap()
      .then((res) => {
        dispatch(getOtp({ email: state.email, csrf_token: res }))
          .unwrap()
          .then(() => {
            // navigate(routes.nonprotected.enter_otp, { state });
            navigate(routes.protected.settings.add_multi_country_otp, {
              state: { ...state, background: location?.background },
              replace: true,
            });
          });
      });
  };

  return (
    <CenterModal className=" w-4/12 p-8" handleClose={backDropHandler}>
      <header className="flex flex-col gap-y-3.5">
        <h2 className="text-base-2 text-[3.25rem] font-semibold leading-[3.875rem] tracking-tighter">
          {PageDictionary.multiAccount.heading}
        </h2>
        <p className="text-lg font-normal leading-[1.875rem] text-gray-600">
          {PageDictionary.multiAccount.subHeading}
        </p>
      </header>
      <form
        className="flex flex-1 flex-col gap-y-4 self-stretch px-8 py-4"
        onSubmit={submitHandler}
      >
        <CustomInput
          name="first_name"
          type="text"
          label={PageDictionary.firstNameLabel}
          value={state.first_name}
          onChange={changeHandler}
          disabled={loading}
          required
        />
        <CustomInput
          name="last_name"
          type="text"
          label={PageDictionary.lastNameLabel}
          value={state.last_name}
          onChange={changeHandler}
          disabled={loading}
          required
        />
        <CustomInput
          name="email"
          type="email"
          label={PageDictionary.emailLabel}
          value={state.email}
          onChange={changeHandler}
          disabled={loading}
          autoComplete="register"
          required
        />
        <CustomInput
          name="password"
          type="password"
          label={PageDictionary.passwordLabel}
          value={state.password}
          onChange={changeHandler}
          disabled={loading}
          autoComplete="register"
          required
        />
        <Slt.Select
          onChange={selectHandler}
          label={PageDictionary.countryLabel}
          labelClassName="text-base-2 mb-2 inline-block text-base font-medium"
          value={state.country}
          className="border-blue_gray-300 h-[3.75rem] w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
          contentClassName="!p-0 z-50 "
          required
        >
          {countiesList
            ?.filter(
              (country) =>
                !subAccounts.find((itm) => itm.country === country.name)
            )
            .map((itm) => (
              <Slt.SelectItem
                key={itm.alpha2}
                className="!px-0 first:!border-b"
                value={itm.name}
              >
                {itm.name}
              </Slt.SelectItem>
            ))}
        </Slt.Select>
        <div className="mt-3.5 flex flex-col gap-y-5 ">
          <CustomButton
            isloading={loading}
            type="submit"
            className="h-11 self-start"
          >
            {PageDictionary.registerActionButton}
          </CustomButton>
        </div>
      </form>
    </CenterModal>
  );
}

export default AddCountry;
