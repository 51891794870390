import React from "react";
import { toCurrency } from "../../../../../common/utils/helpers";

type Props = {
  amount: number;
  rate: number;
};

function Amount({ amount, rate }: Props) {
  const value = amount * rate;
  return <span className="font-bold">{toCurrency(value)}</span>;
}

export default Amount;
