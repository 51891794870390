import React from "react";
import { AiOutlineLoading } from "react-icons/ai";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";

function AppLoader() {
  return (
    <CenterModal className=" rounded-xl bg-transparent px-12">
      <AiOutlineLoading className="animate-spin text-[4rem] text-white" />
    </CenterModal>
  );
}

export default AppLoader;
