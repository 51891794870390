import React, { useState } from "react";
import { CalculatorForm } from "./CalculatorForm";
import OtpForm from "./OtpForm";
import Success from "./Success";

type Istage = "form" | "otp" | "finish";

const initialData = {
  from_currency: "",
  to_currency: "",
  amount: "0",
};

function ExchangeCalculator() {
  const [stage, setStage] = useState<Istage>("form");
  const [inData, setInData] = useState(initialData);

  const resetSwapHandler = () => {
    setStage("form");
    setInData({ ...initialData });
  };

  return (
    <div className="min-h-[42.438rem] w-[26.813rem] rounded-[2.625rem]  bg-[#FAFAFA] py-[2.313rem] ">
      {stage === "form" ? (
        <CalculatorForm
          amount={inData.amount}
          from_currency={inData.from_currency}
          to_currency={inData.to_currency}
          setStage={setStage}
          setInData={setInData}
        />
      ) : null}
      {stage === "otp" ? (
        <OtpForm
          amount={inData.amount}
          from_currency={inData.from_currency}
          to_currency={inData.to_currency}
          setStage={setStage}
        />
      ) : null}
      {stage === "finish" ? (
        <Success
          amount={inData.amount}
          from_currency={inData.from_currency}
          to_currency={inData.to_currency}
          setStage={setStage}
          resetHandler={resetSwapHandler}
        />
      ) : null}
    </div>
  );
}

export default ExchangeCalculator;
