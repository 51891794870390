import React from "react";
import CustomToggle from "../../../../common/components/CustomToggle";
import CustomInput from "../../../../common/components/CustomInput";
import CustomTooltip from "../../../../common/components/CustomTooltip";
import CustomCheckBox from "../../../../common/components/CustomCheckBox";
import CustomButton from "../../../../common/components/CustomButton";
import { iHandleSubmit } from "../../types";
import Language from "../../../../common/utils/language/en";

const { configurationTab: PageDictionary } =
  Language.protected.SettingsPages.AppsModal;

function AppConfig() {
  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <form
      onSubmit={submitHandler}
      className="flex h-[100%-10vh] flex-col gap-y-5 overflow-y-auto pt-11 pr-40 pl-10 pb-24"
    >
      <CustomToggle
        className="!flex flex-row-reverse items-center justify-end gap-x-8 "
        lclassName="!text-black text-xs"
        label={PageDictionary.turnLiveAutomatic}
      />
      <p className="text-xs">{PageDictionary.text}</p>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.bankVerificationlUrl.label}
        />
        <CustomTooltip tip={PageDictionary.bankVerificationlUrl.tooltip} />
      </div>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.balancePoolUrl.label}
        />
        <CustomTooltip tip={PageDictionary.balancePoolUrl.tooltip} />
      </div>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.authorizationUrl.label}
        />
        <CustomTooltip tip={PageDictionary.authorizationUrl.tooltip} />
      </div>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.whiteListIp.label}
        />
        <CustomTooltip tip={PageDictionary.whiteListIp.tooltip} />
      </div>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.kycEndpoint.label}
        />
        <CustomTooltip tip={PageDictionary.kycEndpoint.tooltip} />
      </div>
      <div className="flex items-center gap-x-4">
        <CustomInput
          containerClassName="flex-1"
          lclassName="text-xs"
          label={PageDictionary.rateEndpoint.label}
        />
        <CustomTooltip tip={PageDictionary.rateEndpoint.tooltip} />
      </div>
      <CustomCheckBox checked label={PageDictionary.revertToManual} />
      <CustomButton  className="self-start mt-10" >{PageDictionary.submitButton.save}</CustomButton>
    </form>
  );
}

export default AppConfig;
