import React, { ReactNode } from "react";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { paymentKycNavlinks } from "../../../../common/utils/helpers/navHelpers";
import HorizontalNav from "../../../../common/components/Layout/HorizontalNav";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../common/hooks";

type Props = {
  children: ReactNode;
  closeHandler?: VoidFunction;
};

function KycLayout({ children, closeHandler }: Props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { kyc_level } = useAppSelector((state) => state.payment.p2p_info);

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const { name } = e.currentTarget;
    // const { href, getAttribute } = e.currentTarget;
    const href = e.currentTarget.getAttribute("href") as string;
    if (!kyc_level) return;
    if (name === "") {
      return;
    }
    navigate(href, { state: { background: state.background } });
  };
  return (
    <SideModal
      clickBackDrop={closeHandler}
      className="flex flex-col px-15 pt-14"
    >
      <nav className=" flex items-end border-b border-gray-20 ">
        <HorizontalNav
          navlinks={paymentKycNavlinks}
          containerClassName="pb-0"
          className="border-transparent"
          onClick={clickHandler}
        />
      </nav>
      {children}
    </SideModal>
  );
}

export default KycLayout;
