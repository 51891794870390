import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CustomCheckBox from "../../../../common/components/CustomCheckBox";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import routes from "../../../../common/routes";
import { switchBg, tW } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import { iHandleChange, iHandleSubmit } from "../../types";
import { uploadPaymentEvidence } from "../../store/thunk";
import ImageUploader from "../../components/ImageUploader";

export type IcompleteWithdrawalstate = {
  file: any;
  accept_terms: boolean;
  payment_evidence_url: string;
  isUploading: boolean;
  uploadstatus: string;
};

const { CompleteWithdrawal: PageDictionary } =
  Language.protected.PoolPages.PendingRequestPage;

const initialState = {
  file: { name: "", value: null },
  accept_terms: false,
  payment_evidence_url: "",
  isUploading: false,
  uploadstatus: "",
};

function CompleteWithdrawal() {
  const { background, data } = useLocation().state;
  const [state, setState] = useState<IcompleteWithdrawalstate>(initialState);
  const navigate = useNavigate();
  const [pool_itm] = useAppSelector((state) =>
    state.pool.pending_requests.filter((reqItm) => reqItm.id === data.id)
  );
  const { user_id } = useAppSelector((state) => state.auth.user);
  const { loading } = useAppSelector((state) => state.pool);
  const dispatch = useAppDispatch();

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (state.accept_terms === true && state.file.value) {
      const link = routes.protected.pool.withdrwal_success.link(data.id);
      const temp = {
        code: "",
        id: pool_itm.id,
        payment_evidence_url: state.payment_evidence_url,
        remark: "",
        tray_request_id: pool_itm.tray_request_id,
        user_id: user_id,
      };
      dispatch(uploadPaymentEvidence(temp))
        .unwrap()
        .then(() =>
          navigate(link, {
            replace: true,
            state: {
              background,
              data: {
                ...data,
                payment_evidence_url: state.payment_evidence_url,
              },
            },
          })
        );
    }
  };

  const closeModalHandler = () => {
    if (loading) return;
    navigate(routes.protected.pool.pending_request);
  };

  const toggleCheckBox: iHandleChange<HTMLInputElement> = () => {
    setState((prev) => ({ ...prev, accept_terms: !state.accept_terms }));
  };

  return (
    <CenterModal
      handleClose={closeModalHandler}
      className="max-w-[39.375rem] rounded-xl text-center text-blue_gray-10"
    >
      <header>
        <h3 className="rounded-3xl bg-brown-20 py-2.5 px-5 font-bold">
          {PageDictionary.title}
        </h3>
      </header>
      <p className="mt-2 text-lg leading-8">{PageDictionary.subtitle}</p>
      <form onSubmit={submitHandler} className="mt-8 flex flex-col gap-y-8">
        <div className="flex flex-col gap-y-2 text-start">
          <ImageUploader
            setState={setState}
            state={state.file.name}
            isuploading={state.isUploading}
          />
          {state.file === null ? (
            <p className="text-xs text-red-300">{PageDictionary.required}</p>
          ) : null}
        </div>

        <div className="flex justify-center">
          <CustomCheckBox
            checked={state.accept_terms}
            label={PageDictionary.checkBoxLabel}
            sclassName={tW("w-7 h-7 p-2 rounded-10", switchBg("funding"))}
            className="!font-normal"
            onChange={toggleCheckBox}
          />
        </div>

        <CustomButtom
          disabled={
            !state.accept_terms || !state.file.value || state.isUploading
          }
          isloading={loading}
          className="self-center"
          type="submit"
        >
          {PageDictionary.submitAction}
        </CustomButtom>
      </form>
    </CenterModal>
  );
}

export default CompleteWithdrawal;
