import React from "react";
import { tW } from "../../../utils/helpers";

type props = {
  children: React.ReactNode;
  clickBackDrop?: () => void;
  position?: any;
  className?: string;
};

function SideModal({ children, clickBackDrop, position, className }: props) {
  return (
    <div
      onClick={clickBackDrop}
      className=" fixed top-0 left-0 z-50 h-full w-full overflow-y-auto bg-base-20 "
    >
      <div
        className={tW(
          "flex h-full w-full items-start",
          position ? "justify-start" : "justify-end"
        )}
      >
        <section
          onClick={(e) => e.stopPropagation()}
          className={tW("min-h-screen w-1/2  bg-white ", className)}
        >
          {children}
        </section>
      </div>
    </div>
  );
}

export default SideModal;
