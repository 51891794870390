export const BASE_URL = process.env.REACT_APP_INSTANCEEXCHANGER;
export const SOCKET_URL = process.env.REACT_APP_EXCHANGER_SOCKET;

export const PREVIEW_URL = process.env.REACT_APP_PREVIEW;

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export const STORAGE_KEYS = {
  CLIENT_TOKEN_STORAGE_KEY: "instance.exchanger.token",
  CLIENT_TOKEN_REFRESH_KEY: "instance.exchanger.refresh_token",
};

export const TRAY_REQUEST = "TRAY_REQUEST";
export const TRAY_REQUEST_NOTIFY = "TRAY_REQUEST_NOTIFY";
export const DISPUTE_CHAT = "DISPUTE_CHAT";

export const CLOUD_NAME = process.env.REACT_APP_EXCHANGER_CLOUDINARY_NAME;
export const CLOUD_KEY = process.env.REACT_APP_EXCHANGER_CLOUDINARY_APIKEY;
export const CLOUD_SECRET = process.env.REACT_APP_EXCHANGER_CLOUDINARY_SECRET;

export const ONESIGNAL_KEY = process.env.REACT_APP_EXCHANGER_ONESIGNAL_APPID;

export const BINANCE_PARTNER_ID = "p60037";
export const UBANK_PARTNER_ID = "p41678";

export const SITE_KEY = process.env.REACT_APP_EXCHANGER_RECAPTCHA_SITEKEY;
