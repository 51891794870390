import React from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../../../common/routes";
import PaymentEvidence from "./PaymentEvidence";

function TransactionModal() {
  return (
    <Routes>
      <Route
        path={routes.protected.transactions.evidence}
        element={<PaymentEvidence />}
      />
    </Routes>
  );
}

export default TransactionModal;
