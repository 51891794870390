import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../common/hooks";
import routes from "../../../../../common/routes";
import { getResolutionThreadList } from "../../../store/thunk";
import CustomButton from "../../../../../common/components/CustomButton";

type Props = {
  txn_ref: string;
  issue_id: string;
};

function Memo({ txn_ref, issue_id }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user_id = useAppSelector((state) => state.auth.user.user_id);
  const loading = useAppSelector((state) => state.pool.issues.thread.isloading);
  const [localLoading, setLocalLoading] = useState(false);

  const openIssueMessage = () => {
    setLocalLoading(true);
    dispatch(getResolutionThreadList({ user_id, txn_ref }))
      .unwrap()
      .then(() => {
        const path = routes.protected.pool.issues.message.link(issue_id);
        navigate(path);
      })
      .finally(() => setLocalLoading(false));
  };
  return (
    <div className="flex items-center justify-center font-bold">
      <CustomButton
        className="rounded-10 bg-gray-120 py-1.5 px-4 text-white"
        onClick={openIssueMessage}
        disabled={loading}
        isloading={localLoading}
      >
        View
      </CustomButton>
    </div>
  );
}

export default Memo;
