import { useEffect, useRef } from "react";
import socket from "../../../module/pool/service/socket";
import { DISPUTE_CHAT } from "../../service/config/constant";
import { /* useAppDispatch, */ useAppSelector } from "../redux";
// import { useEffectOnce } from "../useEffectOnce";

function useSocketSub(eventName: string, handleSubscribe: (res: any) => void) {
  const { user_id } = useAppSelector((state) => state.auth.user);
  const is_online = useAppSelector(
    (state) => state.appState.appState.is_offline
  );
  // const dispatch = useAppDispatch();
  //   subscribe tp the socket connection
  const startListeningRef = useRef(false);
  useEffect(() => {
    if (is_online === false && eventName !== DISPUTE_CHAT) {
      return;
    }
    if (startListeningRef.current) {
      return;
    }
    socket.on(`${eventName}${user_id}`, handleSubscribe);

    socket.emit(`${eventName}`, { user_id });
    startListeningRef.current = true;
  }, [handleSubscribe, user_id, eventName]); //eslint-disable-line

  // unsubscribe to the socket connection
  const stopListeningRef = useRef(true);
  useEffect(() => {
    if (stopListeningRef.current === true) {
      stopListeningRef.current = false;
      return;
    }

    return () => {
      socket.off(`${eventName}${user_id}`, handleSubscribe);
    };
  }, [user_id, eventName, handleSubscribe]); //eslint-disable-line

  // useEffectOnce(() => {
  //   if (!user_id) return;
  //   if (is_online === false && eventName !== DISPUTE_CHAT) {
  //     return;
  //   }
  //   socket.on(`${eventName}${user_id}`, handleSubscribe);

  //   socket.emit(`${eventName}`, { user_id });
  //   return () => {
  //     socket.off(`${eventName}${user_id}`, handleSubscribe);
  //   };
  // });

  return;
}

export { useSocketSub };
