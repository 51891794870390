import React from "react";
import { useAppSelector } from "../../../../common/hooks";
import InformationCard from "../../../../common/components/Cards/InformationCard";
import { toCurrency } from "../../../../common/utils/helpers";
import LinkCard from "../../../../common/components/Cards/LinkCard";
import Language from "../../../../common/utils/language/en";

const { cards: PageDictionary } =
  Language.protected.fiatPages.layout.layout_data;

function PayoutCards() {
  const { total_completed_payout, total_failed_payout } = useAppSelector(
    (state) => state.payment.p2p_payout_stats
  );
  return (
    <div className="flex w-auto flex-1 items-center gap-x-5 overflow-x-scroll pt-4">
      <InformationCard
        data={toCurrency(0)}
        type={PageDictionary.completed_payout}
        extradata={`${total_completed_payout} txns`}
        className="w-full min-w-fit flex-shrink"
      />
      <InformationCard
        data={toCurrency(0)}
        type={PageDictionary.failed_payout}
        extradata={`${total_failed_payout} txns`}
        className="w-full min-w-fit flex-shrink"
      />
      <div className="w-full min-w-fit flex-shrink" />
      <div className="w-full min-w-fit flex-shrink" />
    </div>
  );
}

export default PayoutCards;
