import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CenterModal from "../../../../../common/components/CustomModal/CenterModal";
import CustomButton from "../../../../../common/components/CustomButton";
import success from "../../../../../static/images/success.gif";

function ClaimProcessing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleBackdrop = () =>
    navigate(state?.background.pathname, { replace: true });
  return (
    <CenterModal
      handleClose={handleBackdrop}
      className="rounded-none bg-transparent p-0"
    >
      <div className="flex min-h-[42.438rem] w-[26.813rem]  flex-col rounded-[2.625rem] bg-[#FAFAFA] py-[2.313rem] px-8 pb-12">
        <img src={success} alt="loading" className="flex-1" />
        <div className="flex flex-col gap-y-6">
          <h2 className="text-center text-3xl font-bold text-[#0B112F]">
            Claim Processing!
          </h2>
          <p className="text-center text-[#525869] ">
            All your “Expired” & “Mismatched” transactions are being processed.
            It might take up to 12 hours before your claim request is complete.
            The Paid Amount will be settled and override the Order Amount. You
            can find the records in your “Completed” transactions once the
            process is finished.
          </p>
          <CustomButton className="mt-6 self-center" onClick={handleBackdrop}>
            Continue
          </CustomButton>
        </div>
      </div>
    </CenterModal>
  );
}

export default ClaimProcessing;
