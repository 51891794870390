import * as socketIO from "socket.io-client";
import { SOCKET_URL } from "../../../common/service/config/constant";

const getUrl = () => { //eslint-disable-line
  if (SOCKET_URL) {
    return SOCKET_URL;
  }
  return "";
};

const socket = socketIO.io(getUrl());

export default socket;
