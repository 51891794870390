import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import {
  getConfirmationCode,
  withdrawUserWallet,
} from "../../../dashboard/store/thunk";
import { iHandleChange, iHandleSubmit } from "../../../dashboard/types";
import { getCsrfToken } from "../../../auth/store/thunk";
import { useCountDown } from "../../../../common/hooks";

const initialstate = {
  withdrawal_code: "",
};

function WithdrawConfirmation() {
  const { state } = useLocation();
  const { loading } = useAppSelector((state) => state.dashboard);
  const [confirmState, setConfirmState] = useState(initialstate);
  const { email } = useAppSelector((state) => state.auth.user);
  const {
    counter,
    start: startCountDown,
    reset: resetCountDown,
  } = useCountDown(90);
  const counterRef = useRef(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (counter === 90) {
      startCountDown();
    }
    if (counter === 0) {
      counterRef.current = false;
    }
  }, [counter]);

  const getCodeHandler = () => {
    resetCountDown();
    counterRef.current = true;
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res) => {
        dispatch(getConfirmationCode({ email, csrf_token: res }));
      });
    // dispatch(getConfirmationCode({ email }));
  };

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const regex = new RegExp("^[0-9]*$");
    if (regex.test(value)) {
      setConfirmState((prev) => ({ ...prev, withdrawal_code: value }));
    }
  };

  const cancelHandler = () => {
    if (loading) {
      return;
    }
    navigate(state.background.pathname, { replace: true, state: null });
  };

  const onSubmitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (confirmState.withdrawal_code === "") {
      return;
    }
    const data = {
      ...state.data,
      withdrawal_code: confirmState.withdrawal_code,
    };
    dispatch(withdrawUserWallet(data)).unwrap().finally(cancelHandler);
    // .then(() => {
    //   navigate(routes.protected.crypto_wallet.withdraw.success, {
    //     state,
    //   });
    // });
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={cancelHandler}
    >
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">
          Are you sure you want to take this action?
        </h3>
        <p className="text-sm text-red-40">
          {`* ${state.data?.amount} ${state.data?.coin} will be withdrawn from your account into ${state.data?.receiver_address}`}
        </p>
        <div className="flex w-full flex-col">
          <button
            onClick={getCodeHandler}
            disabled={counter > 0 && counterRef.current}
            type="button"
            className="cursor-pointer self-end text-right text-sm text-green-10 disabled:cursor-not-allowed disabled:text-gray-110"
          >
            {counter
              ? `Get new confirmation code in ${counter}s`
              : "Get confirmation code"}
          </button>
          <div className="flex flex-col gap-y-3 self-stretch">
            <CustomInput
              containerClassName="self-stretch text-left"
              label="Enter confirmation code"
              onChange={changeHandler}
              value={confirmState.withdrawal_code}
            />
            <CustomButtom
              isloading={loading}
              disabled={confirmState.withdrawal_code.length <= 4}
              className="justify-center self-stretch"
            >
              Complete Withdrawal
            </CustomButtom>
          </div>
        </div>
      </form>
    </CenterModal>
  );
}

export default WithdrawConfirmation;
