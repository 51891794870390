import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import { usePageTitle, useScrollToTop } from "../../../../common/hooks";
import { performanceNavlinks } from "../../../../common/utils/helpers/navHelpers";

function PerformanceWrapper() {
  usePageTitle("Performance");
  const { titleRef } = useScrollToTop();
  return (
    <section>
      <header ref={titleRef} className="flex items-center justify-between">
        <nav>
          <ul className="flex items-center gap-x-14">
            {performanceNavlinks.map(({ link, name }) => (
              <li key={link}>
                <NavLink
                  to={link}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center justify-center rounded-2xl bg-blue_gray-10 py-3 px-5 text-xl font-bold leading-6.5 text-white "
                      : " flex items-center text-xl  font-bold leading-6.5 text-blue_gray-10"
                  }
                >
                  <span>{name}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </header>
      <Outlet />
    </section>
  );
}

export default PerformanceWrapper;
