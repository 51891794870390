import { useAppSelector, useCopyToClipboard } from "../../../../hooks";
import { BsCheckAll } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import routes from "../../../../routes";

type Props = {
  className?: string;
};

function MultiUserId({ className }: Props) {
  const { pathname } = useLocation();
  const { merchant_id } = useAppSelector((state) => state.payment.p2p_info);
  const { email } = useAppSelector((state) => state.auth.user);
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyToClipboard(merchant_id);
  };
  return (
    <>
      {pathname.includes(routes.protected.fiat_wallet.index) && merchant_id ? (
        <div className={className}>
          <p className="mx-auto w-60 overflow-hidden truncate text-ellipsis text-center text-sm">
            {email}
          </p>
          <div className="">
            <button
              onClick={handleCopyClick}
              className="inline-flex items-center gap-x-1"
            >
              <span className="text-917">MID: {merchant_id}</span>
              {isCopied ? (
                <BsCheckAll className="animate-pulse text-sm text-green-10" />
              ) : (
                <span className="fill-inherit text-sm">
                  <AiOutlineCopy />
                </span>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MultiUserId;
