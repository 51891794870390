import React from "react";
import { IconProps } from "../type";

export function DocumentIcon({ scale = 1 }: IconProps) {
  return (
    <svg
      width={scale * 36}
      height={scale * 36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="32" height="32" rx="16" fill="#F4EBFF" />
      <path
        d="M18.6666 11.333H14C13.6463 11.333 13.3072 11.4735 13.0572 11.7235C12.8071 11.9736 12.6666 12.3127 12.6666 12.6663V23.333C12.6666 23.6866 12.8071 24.0258 13.0572 24.2758C13.3072 24.5259 13.6463 24.6663 14 24.6663H22C22.3536 24.6663 22.6927 24.5259 22.9428 24.2758C23.1928 24.0258 23.3333 23.6866 23.3333 23.333V15.9997M18.6666 11.333L23.3333 15.9997M18.6666 11.333V15.9997H23.3333"
        stroke="#7F56D9"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="2"
        width="32"
        height="32"
        rx="16"
        stroke="#F9F5FF"
        strokeWidth="4"
      />
    </svg>
  );
}
