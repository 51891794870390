import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import CustomToggle from "../../../../common/components/CustomToggle";
import AppDashboard from "../../components/AppDashboard";
import AppConfig from "../../components/AppConfig";
import AppKeys from "../../components/AppKeys";
import Language from "../../../../common/utils/language/en";
import { tW } from "../../../../common/utils/helpers";

const PageDictionary = Language.protected.SettingsPages.AppsModal;

const tabData = Object.values(PageDictionary.nav);

function AppModal() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(PageDictionary.nav.dashboard);

  const tabChangeHandler = (value: string) => {
    setSelectedTab(value);
  };

  const backDropHandler = () => {
    navigate(state?.background.pathname);
  };

  return (
    <SideModal className="px-14 pt-20" clickBackDrop={backDropHandler}>
      <header className="flex items-center gap-x-7 px-8">
        <img
          className="h-32 w-44 rounded-md border-none bg-red-10"
          src=""
          alt=""
        />
        <div>
          <h4 className="text-2xl font-bold leading-6.5 text-black">
            {PageDictionary.title.flutterwave}
          </h4>
          <p className="mt-4 mb-3 text-sm">{PageDictionary.text.flutterwave}</p>
          <CustomToggle
            className="!flex items-center gap-x-3.5"
            lclassName="text-xs !text-black"
            label={PageDictionary.toggle}
          />
        </div>
      </header>
      <nav className="pt-15">
        <ul className="flex justify-between border-b">
          {tabData.map((tab) => (
            <li className="px-9" key={tab}>
              <button
                onClick={() => tabChangeHandler(tab)}
                className={tW(
                  "border-b-2 pb-4 text-2xl leading-6.5",
                  selectedTab === tab
                    ? "border-black font-bold text-blue-40"
                    : "border-transparent font-normal text-gray-300"
                )}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {selectedTab === PageDictionary.nav.dashboard ? <AppDashboard /> : null}
      {selectedTab === PageDictionary.nav.config ? <AppConfig /> : null}
      {selectedTab === PageDictionary.nav.keys ? <AppKeys /> : null}
    </SideModal>
  );
}

export default AppModal;
