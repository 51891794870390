import { combineReducers } from "redux";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import appState from "../module/appState/store/slice";
import auth from "../module/auth/store/slice";
import dashboard from "../module/dashboard/store/slice";
import pool from "../module/pool/store/slice";
import settings from "../module/settings/store/slice";
import team from "../module/team/store/slice";
import transactions from "../module/transactions/store/slice";
import performance from "../module/performance/store/slice";
import cryptoWallet from "../module/crypto/store/slice";
import compliance from "../module/compliance/store/slice";
import payment from "../module/fiat/store/slice";

// const MIGRATE_DEBUG = false;
const appPersistConfig = {
  key: "appState",
  storage: storage,
  whitelist: ["appState", "subAccounts"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  whitelist: ["user"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};
const settingPersistConfig = {
  key: "settings",
  storage: storage,
  whitelist: ["api_setting", "settlement_info"],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};
const paymentPersistConfig = {
  key: "payment",
  storage: storage,
  whitelist: [
    "p2p_info",
    "payment_config",
    "min_payout_amounts",
    "pending_balance",
  ],
  version: 1,
  migrate: (state: any) => {
    return Promise.resolve(state);
  },
};

const rootReducer = combineReducers({
  appState: persistReducer(appPersistConfig, appState),
  auth: persistReducer(authPersistConfig, auth),
  dashboard,
  pool,
  settings: persistReducer(settingPersistConfig, settings),
  team,
  transactions,
  performance,
  cryptoWallet,
  compliance,
  payment: persistReducer(paymentPersistConfig, payment),
});

export default rootReducer;
