import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CenterModal from "../../../../common/components/CustomModal/CenterModal";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import Language from "../../../../common/utils/language/en";
import { toggleServiceStatus } from "../../store/thunk";
// import { toggleOfflineStatus } from "../../store/slice";

const { status: PageDictionary } = Language.protected.Layout;

function ToggleOfflineStatus() {
  const { state } = useLocation();
  const {
    loading,
    appState: { is_offline },
  } = useAppSelector((state) => state.appState);
  const { user_id } = useAppSelector((state) => state.auth.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const cancelHandler = () => {
    if (loading) {
      return;
    }
    navigate(state.background.pathname, { replace: true, state: null });
  };

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      id: user_id,
      data: {
        action: is_offline ? "OFFLINE" : "ONLINE",
        possible_action: "OFFLINE | ONLINE",
      },
    };
    dispatch(toggleServiceStatus(data)).unwrap().finally(cancelHandler);
  };

  return (
    <CenterModal
      className="w-[39.375rem] rounded-xl px-12 pt-26 pb-32"
      handleClose={cancelHandler}
    >
      <form
        onSubmit={onSubmitHandler}
        className="flex flex-col items-center gap-y-10 text-center"
      >
        <h3 className="text-2xl text-blue_gray-10">{PageDictionary.title}</h3>
        <p className="text-sm text-red-40">
          *
          {is_offline === true
            ? PageDictionary.messageOffline
            : PageDictionary.messageOnline}
        </p>
        <div className="flex items-center gap-x-9">
          <CustomButtom variant onClick={cancelHandler}>
            {PageDictionary.actions.reject}
          </CustomButtom>
          <CustomButtom disabled={loading}>
            {PageDictionary.actions.accept}
          </CustomButtom>
        </div>
      </form>
    </CenterModal>
  );
}

export default ToggleOfflineStatus;
