import React from "react";
import { NavLink } from "react-router-dom";
import { tW } from "../../../utils/helpers";

type Inavi = {
  name: string;
  link: string;
};

type Props = {
  navlinks: Inavi[];
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  className?: string;
  containerClassName?: string;
  activeClassName?: string;
  itemClassName?: string;
};

function HorizontalNav({
  navlinks,
  onClick,
  className,
  containerClassName,
  activeClassName,
  itemClassName,
}: Props) {
  return (
    <ul
      className={tW("inapp flex flex-grow items-end pb-2", containerClassName)}
    >
      {navlinks.map(({ link, name }) => (
        <li
          key={name}
          className={tW(
            "border-b border-gray-20 px-10 pb-2 text-[1.375rem] leading-6 first:pr-5 first:pl-0 last:pr-0 last:pl-5",
            className
          )}
        >
          <NavLink
            to={link}
            onClick={onClick}
            className={({ isActive }) =>
              tW(
                "pb-2",
                isActive
                  ? "border-b-2 border-blue-10 bg-white font-bold text-blue-10"
                  : "font-semibold text-gray-30",
                isActive ? activeClassName : itemClassName
              )
            }
          >
            {name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
}

export default HorizontalNav;
