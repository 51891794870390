import React from "react";
import { RiGroupLine } from "react-icons/ri";
import { GiCycle } from "react-icons/gi";
import { VscGoToFile } from "react-icons/vsc";
import { useAppSelector } from "../../../hooks/redux";
import send_img from "../../../../static/images/icons/card-send.svg";
import recive_img from "../../../../static/images/icons/card-receive.svg";
import { tW, toDollar } from "../../../utils/helpers";

type props = {
  className?: string;
  type: "users" | "requests" | "funding transaction" | string;
  data: number | string;
  extradata?: string;
  isLoading?: boolean;
  claim?: React.ReactNode;
  onClickHandler?: VoidFunction;
};

// const { cards: PageDictionary } = Language.protected.DashboardPage;

function InformationCard({
  className,
  type,
  data,
  extradata,
  isLoading,
  claim,
  onClickHandler,
}: props) {
  const { loading } = useAppSelector((state) => state.dashboard);
  const tempData = typeof data === "number" ? data : parseInt(data);

  let iconTemplate: JSX.Element | null = null;

  switch (type) {
    case "Total Users":
      iconTemplate = <RiGroupLine className="text-[1.5rem]" />;
      break;
    case "Total Requests":
      iconTemplate = <VscGoToFile className="text-[1.5rem]" />;
      break;
    case "Total Funding Transaction":
      iconTemplate = <GiCycle className="text-[1.5rem]" />;
      break;
    case "Total Funded Amount":
    case "Total Collection":
      iconTemplate = <img src={send_img} alt="send" />;
      break;
    case "Total Withdrawal Amount":
    case "Total Payout":
      iconTemplate = <img src={recive_img} alt="send" />;
      break;

    default:
      iconTemplate = <img src={recive_img} alt="send" />;
      break;
  }

  return (
    <div
      onClick={onClickHandler}
      className={tW(
        " rounded-[1.25rem] border border-purple-200 bg-white p-8",
        className
      )}
    >
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-semibold capitalize text-gray-300">{`${type}`}</p>
          <h5
            className={tW(
              "text-[2rem] font-bold leading-[2.4rem]",
              loading || isLoading ? "animate-pulse " : ""
            )}
          >
            {type.toLowerCase().includes("amount") ? toDollar(tempData) : data}
          </h5>
        </div>
        {iconTemplate}
      </div>
      {extradata || claim ? (
        <div className={tW("mt-4 flex items-center justify-between")}>
          <p className="text-sm text-gray-300">{extradata}</p>

          {claim}
        </div>
      ) : null}
    </div>
  );
}

export default InformationCard;
