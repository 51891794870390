import "./style.css";
import { startTransition, useMemo, useState } from "react";
import {
  Combobox,
  ComboboxItem,
  ComboboxList,
  ComboboxProvider,
} from "@ariakit/react";
import * as RadixSelect from "@radix-ui/react-select";
import { matchSorter } from "match-sorter";
import { CheckIcon, ChevronUpDownIcon, SearchIcon } from "./icons";
import { tW } from "../../utils/helpers";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

type Props = {
  className: Record<string, string>;
  options: any[];
  placeHolder: string;
  value: string;
  onChange: (e: any) => void;
};

export default function Example({
  className,
  options,
  placeHolder,
  value,
  onChange,
}: Props) {
  const [open, setOpen] = useState(false);
  // const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const matches = useMemo(() => {
    if (!searchValue) return options;
    const keys = ["label", "value"];
    const matches = matchSorter(options, searchValue, { keys });
    // Radix Select does not work if we don't render the selected item, so we
    // make sure to include it in the list of matches.
    const selectedValue = options.find((lang) => lang.value === value);
    if (selectedValue && !matches.includes(selectedValue)) {
      matches.push(selectedValue);
    }
    return matches;
  }, [searchValue, value, options.length]);

  return (
    <RadixSelect.Root
      value={value}
      onValueChange={onChange}
      open={open}
      onOpenChange={setOpen}
    >
      <ComboboxProvider
        open={open}
        setOpen={setOpen}
        resetValueOnHide
        includesBaseElement={false}
        setValue={(value) => {
          startTransition(() => {
            setSearchValue(value);
          });
        }}
      >
        <RadixSelect.Trigger
          aria-label="Language"
          className={tW("select", className.trigger)}
        >
          <RadixSelect.Value placeholder={value} aria-label={value}>
            {value}
          </RadixSelect.Value>
          <RadixSelect.Icon className="select-icon">
            <ChevronUpDownIcon />
          </RadixSelect.Icon>
        </RadixSelect.Trigger>
        <RadixSelect.Content
          role="dialog"
          aria-label="Languages"
          // position="popper"
          className={tW("popover", className.content)}
          // sideOffset={4}
          // alignOffset={-16}
        >
          <div className="combobox-wrapper w-full">
            <div className="combobox-icon">
              <SearchIcon />
            </div>
            <Combobox
              autoSelect
              placeholder={placeHolder}
              className="combobox w-full"
              onBlurCapture={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
            />
          </div>
          <ComboboxList className={tW("listbox w-full ", className.list)}>
            <RadixSelect.ScrollUpButton className="select-scroll-btn flex items-center justify-center">
              <FiChevronUp />
            </RadixSelect.ScrollUpButton>
            <RadixSelect.Viewport className="">
              {matches.map(({ label, value }) => (
                <RadixSelect.Item
                  key={value}
                  value={value}
                  asChild
                  className={tW("item", className.listitem)}
                >
                  <ComboboxItem>
                    <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                    <RadixSelect.ItemIndicator className="item-indicator">
                      <CheckIcon />
                    </RadixSelect.ItemIndicator>
                  </ComboboxItem>
                </RadixSelect.Item>
              ))}
            </RadixSelect.Viewport>
            <RadixSelect.ScrollDownButton className="select-scroll-btn flex items-center justify-center">
              <FiChevronDown />
            </RadixSelect.ScrollDownButton>
          </ComboboxList>
        </RadixSelect.Content>
      </ComboboxProvider>
    </RadixSelect.Root>
  );
}
