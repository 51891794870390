import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomButtom from "../../../../common/components/CustomButton";
import CustomInput from "../../../../common/components/CustomInput";
import SideModal from "../../../../common/components/CustomModal/SideModal";
import { Select, SelectItem } from "../../../../common/components/CustomSelect";
import { useAppDispatch, useAppSelector } from "../../../../common/hooks/redux";
import routes from "../../../../common/routes";
import { toDollar } from "../../../../common/utils/helpers";
import Language from "../../../../common/utils/language/en";
import { getConfirmationCode } from "../../../dashboard/store/thunk";
import { iHandleChange, iHandleSubmit } from "../../../dashboard/types";
import { getCsrfToken } from "../../../auth/store/thunk";

const { withdrawalModal: PageDictionary } = Language.protected.DashboardPage;

const initialState = {
  user_id: "",
  amount: "",
  receiver_address: "",
  coin: "USDT",
  network: "TRC20",
  exchanger_wallet_type: "",
};

function WithdrawalForm() {
  const navigate = useNavigate();
  const { capital_wallet: capital, admin_fee_usdt_trc20 } = useAppSelector(
    (state) => state.dashboard.home
  );
  const { loading } = useAppSelector((state) => state.dashboard);
  const { user_id, email } = useAppSelector((state) => state.auth.user);
  const { state } = useLocation();
  const [inputs, setInputs] = useState(initialState);
  const dispatch = useAppDispatch();

  const loadOnceRef = useRef(false);
  useEffect(() => {
    if (loadOnceRef.current === true) {
      return;
    }
    setInputs((prev) => ({ ...prev, user_id }));
    loadOnceRef.current = true;
  }, [user_id]);

  const changeHandler: iHandleChange<HTMLInputElement> = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "amount":
        const regex = new RegExp("^[0-9]*$");
        if (regex.test(value)) {
          setInputs((prev) => ({ ...prev, [name]: value }));
        }
        break;
      case "receiver_address":
        setInputs((prev) => ({ ...prev, [name]: value }));
        break;

      default:
        setInputs((prev) => ({ ...prev, [name]: value }));
        break;
    }
  };

  const selectHandler = (value: string) => {
    setInputs((prev) => ({ ...prev, exchanger_wallet_type: value }));
  };

  const backToDashboard = () => {
    if (loading) {
      return;
    }
    navigate(state?.background?.pathname, { replace: true });
  };

  const submitHandler: iHandleSubmit = (e) => {
    e.preventDefault();
    if (Object.values(inputs).includes("")) {
      return;
    }
    dispatch(getCsrfToken({ email }))
      .unwrap()
      .then((res) => {
        dispatch(getConfirmationCode({ email, csrf_token: res }))
          .unwrap()
          .then(() => {
            navigate(routes.protected.crypto_wallet.withdraw.confirm, {
              state: { background: state.background, data: inputs },
              replace: true,
            });
          });
      });
    // dispatch(getConfirmationCode({ email }));
  };
  return (
    <SideModal clickBackDrop={backToDashboard} className="px-15 pt-24">
      <section className="pr-18">
        <header className="border-b border-gray-50 pb-1">
          <h3 className="text-4xl font-semibold">{PageDictionary.title}</h3>
        </header>
        <p className="pt-6">{PageDictionary.subtitle}</p>
        <form
          className="flex flex-col gap-y-6 pr-8 pt-9"
          onSubmit={submitHandler}
        >
          <CustomInput
            type="text"
            autoComplete="off"
            name="amount"
            value={inputs.amount}
            onChange={changeHandler}
            label={PageDictionary.amountLabel}
            errorLabel="*Minimum balance should be up to $10"
          />
          <Select
            onChange={selectHandler}
            label={PageDictionary.walletLabel}
            labelClassName="text-base-2 mb-2 inline-block text-base font-medium"
            value={inputs.exchanger_wallet_type}
            className="border-blue_gray-300 h-[3.75rem] w-full !rounded-xl border !border-blue_gray-30 bg-inputbg pt-2 pb-3 pl-4 text-black placeholder:text-base placeholder:text-gray-600"
            contentClassName="!p-0 z-50"
          >
            {PageDictionary.walletOption.map((itm) => (
              <SelectItem
                key={itm.value}
                className="!px-0 first:!border-b"
                value={itm.value}
              >
                {itm.label}
              </SelectItem>
            ))}
          </Select>

          <CustomInput
            label={PageDictionary.addressLabel}
            name="receiver_address"
            value={inputs.receiver_address}
            onChange={changeHandler}
          />
          <div className="flex flex-col gap-y-2">
            <p className="text-sm">{PageDictionary.networkLabel}</p>
            <h4 className="text-xl font-bold">{PageDictionary.networkTitle}</h4>
            <p className="text-sm leading-6">{PageDictionary.networkMessage}</p>
          </div>
          <div className="flex items-start justify-between py-4">
            <div className="flex flex-col gap-y-2">
              <p className="text-sm ">{PageDictionary.balanceLabel} </p>
              <h4 className="text-xl font-bold">{toDollar(capital)}</h4>
            </div>
            <div className="flex flex-col gap-y-2">
              <p className="text-sm ">{PageDictionary.feeLabel}</p>
              <h4 className="text-xl font-bold">
                {toDollar(admin_fee_usdt_trc20)}
              </h4>
            </div>
          </div>

          <CustomButtom
            isloading={loading}
            disabled={
              Object.values(inputs).includes("") || parseInt(inputs.amount) < 10
            }
            className="self-start bg-green-400"
          >
            {PageDictionary.withdrawalAction}
          </CustomButtom>
        </form>
      </section>
    </SideModal>
  );
}

export default WithdrawalForm;
