import { createAsyncThunk } from "@reduxjs/toolkit";
import customToast from "../../../common/components/CustomToast";
import auth from "../service";
import { IloginUser, IrequestOtp } from "../types";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (req: IloginUser, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .loginUser(req)
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (req: any, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .registerUser(req)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(req).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getOtp = createAsyncThunk(
  "auth/sendOtp",
  async (data: IrequestOtp, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .sendOtp(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getCsrfToken = createAsyncThunk(
  "auth/csrfToken",
  async (data: IrequestOtp, { rejectWithValue, fulfillWithValue }) => {
    return auth
      .csrfToken(data)
      .then((res) => {
        // customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: any, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .resetPassword(data)
      .then((res) => {
        customToast(res.message);
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
export const getAllCountries = createAsyncThunk(
  "auth/getAllCountries",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    return auth
      .getAllCountries()
      .then((res) => {
        return fulfillWithValue(res.data).payload;
      })
      .catch((err) => {
        customToast(err.message, true);
        return rejectWithValue(err);
      });
  }
);
// export const getAllCountries = async (
//   setCountiesList: React.Dispatch<React.SetStateAction<IcountryList>>
// ) => {
//   return auth
//     .getAllCountries()
//     .then((res) => {
//       setCountiesList(res.data);
//     })
//     .catch((err) => {
//       customToast(err.message, true);
//     });
// };
